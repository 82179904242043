import ComponentSpinner from '@src/@core/components/spinner/Loading-spinner'
import { OMS_RETURN_ORDER_TYPE } from '@src/views/inventory/constants.inventory'
import { GRN_TYPES } from '@src/views/inventory/inventory.constants'
import {
  getSingleGrnDetail,
  setHasBarcodeMapped
} from '@src/views/inventory/store'
import ReturnOrderDetail from '@src/views/returns/pages/return-order/return-order-details'
import { RETURN_ORDER_PROCESSING_STEPS } from '@src/views/returns/returns.constants'
import OrderDetailsSideSlider from '@src/views/sales/OrderDetailsSideSlider'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BIN_TYPE } from '../../constant'
import BinDetail from './binDetail'
import BinDetailCard from './binDetailCard'

const GrnDetails = ({ grnDetails, type, currentStep }) => {
  const { t } = useTranslation()
  const [selectedBin, setSelectedBin] = useState({
    id: null,
    barcode: '',
    type: null,
    container_type: ''
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isReturnOrderDetailModalOpen, setReturnOrderDetailModalOpen] = useState(false)
  const [returnOrderToShowDetail, setReturnOrderToShowDetail] = useState(null)
  const dispatch = useDispatch()
  const singleGrnDetail = useSelector(
    (state) => state.inventory.grn.singleGrnDetail
  )
  const has_barcode_mapped = useSelector(
    (store) => store.inventory.grn.has_barcode_mapped
  )
  const pass_bin_id = useSelector((store) => store.inventory.grn.pass_bin_id)
  const fail_bin_id = useSelector((store) => store.inventory.grn.fail_bin_id)
  const handleReturnOrderDetailModal = () => {
    setReturnOrderDetailModalOpen((prev) => !prev)
  }
  const handleOrderDetailSidebar = () => {
    setIsModalOpen(pre => !pre)
  }
  useEffect(() => {
    if (singleGrnDetail?.id && !selectedBin.id) {
      if (singleGrnDetail.pass_bin?.length > 0) {
        setSelectedBin({
          id: singleGrnDetail.pass_bin[0].id,
          barcode: singleGrnDetail.pass_bin[0].barcode,
          container_type: singleGrnDetail.pass_bin[0].type,
          type: BIN_TYPE.pass_bin
        })
      } else if (singleGrnDetail.fail_bin?.length > 0) {
        setSelectedBin({
          id: singleGrnDetail.fail_bin[0].id,
          barcode: singleGrnDetail.fail_bin[0].barcode,
          container_type: singleGrnDetail.fail_bin[0].type,
          type: BIN_TYPE.fail_bin
        })
      }
    } else if (!singleGrnDetail) {
      dispatch(getSingleGrnDetail({ grn_id: grnDetails.id }))
    }
  }, [singleGrnDetail])

  useEffect(() => {
    if (has_barcode_mapped || pass_bin_id !== '' || fail_bin_id !== '') {
      dispatch(getSingleGrnDetail({ grn_id: grnDetails.id }))
      dispatch(setHasBarcodeMapped(false))
    }
  }, [has_barcode_mapped, pass_bin_id, fail_bin_id])

  return (
    <>
      {singleGrnDetail &&
      currentStep === RETURN_ORDER_PROCESSING_STEPS.QC.id ? (
          <div className="px-16px">
            <div className="d-flex gap-24px my-24px">
              {type === GRN_TYPES.STANDARD ? (
                <>
                  <div className="w-50">
                    <div className="text-dark-6">{t('Source')}</div>
                    <div className="text-dark">
                      {singleGrnDetail.entry_identification}
                    </div>
                  </div>
                  <div className="w-50">
                    <div className="text-dark-6">{`${singleGrnDetail.entry_identification} ${singleGrnDetail.identification_display_id ? 'ID' : 'Name'}`}</div>
                    <div className="text-dark">
                      {singleGrnDetail.identification_display_id || `${singleGrnDetail?.seller?.name} & ${singleGrnDetail?.supplier?.name}`}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-50">
                    <div className="text-dark-6">{t('Return Order ID')}</div>
                    {grnDetails?.return_order_detail?.display_order_id ? <div onClick={() => {
                      setReturnOrderDetailModalOpen(true)
                      setReturnOrderToShowDetail({
                        orderID: grnDetails?.return_order_detail?.oms_return_order_id,
                        return_order_id: grnDetails?.return_order_detail?.display_order_id
                      })

                    }} className="text-primary cursor-pointer">
                      {grnDetails?.return_order_detail?.display_order_id}
                    </div> :
                      <div>-</div>}
                  </div>
                  {grnDetails?.return_order_detail?.type !== OMS_RETURN_ORDER_TYPE.OFF_SYSTEM_RETURN.value && <div className="w-50">
                    <div className="text-dark-6">{t('Order ID')}</div>
                    {grnDetails?.return_order_detail?.seller_sales_channel_order_id ? <div onClick={() => setIsModalOpen(pre => !pre)} className="text-primary cursor-pointer">
                      {grnDetails?.return_order_detail?.seller_sales_channel_order_id}
                    </div> :
                      <div>-</div>}
                  </div>}
                  <OrderDetailsSideSlider sellerId={grnDetails?.return_order_detail?.oms_order_id} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
                  <ReturnOrderDetail
                    isReturnOrderDetailModalOpen={isReturnOrderDetailModalOpen}
                    handleReturnOrderDetailModal={handleReturnOrderDetailModal}
                    handleOrderDetailSidebar={handleOrderDetailSidebar}
                    returnOrderToShowDetail={returnOrderToShowDetail}
                  />
                </>
              )}
            </div>
            <div className="d-flex gap-16px">
              <div className="w-50">
                <BinDetailCard
                  key={BIN_TYPE.pass_bin.id}
                  bin_type={BIN_TYPE.pass_bin}
                  data={singleGrnDetail.pass_bin}
                  selectedBin={selectedBin}
                  setSelectedBin={setSelectedBin}
                  grnId={grnDetails.id}
                  singleGrnDetail={singleGrnDetail}
                />
              </div>
              <div className="w-50">
                <BinDetailCard
                  key={BIN_TYPE.fail_bin.id}
                  bin_type={BIN_TYPE.fail_bin}
                  data={singleGrnDetail.fail_bin}
                  selectedBin={selectedBin}
                  setSelectedBin={setSelectedBin}
                  grnId={grnDetails.id}
                  singleGrnDetail={singleGrnDetail}
                />
              </div>
            </div>
            <BinDetail selectedBin={selectedBin} />
          </div>
        ) : (
          <ComponentSpinner className='pt-3' />
        )}
    </>
  )
}

export default memo(GrnDetails)
