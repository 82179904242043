export const Countries = [
  {
    name: 'Afghanistan',
    flag: '🇦🇫',
    country_code: 'AF',
    calling_code: '+93',
    value: 'Afghanistan (AF) +93',
    label: 'Afghanistan (+93)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Åland Islands',
    flag: '🇦🇽',
    country_code: 'AX',
    calling_code: '+358',
    value: 'Åland Islands (AX) +358',
    label: 'Åland Islands (+358)',
    maxPhoneNumberLength: 12
  },
  {
    name: 'Albania',
    flag: '🇦🇱',
    country_code: 'AL',
    calling_code: '+355',
    value: 'Albania (AL) +355',
    label: 'Albania (+355)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Algeria',
    flag: '🇩🇿',
    country_code: 'DZ',
    calling_code: '+213',
    value: 'Algeria (DZ) +213',
    label: 'Algeria (+213)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'American Samoa',
    flag: '🇦🇸',
    country_code: 'AS',
    calling_code: '+1684',
    value: 'American Samoa (AS) +1684',
    label: 'American Samoa (+1684)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Andorra',
    flag: '🇦🇩',
    country_code: 'AD',
    calling_code: '+376',
    value: 'Andorra (AD) +376',
    label: 'Andorra (+376)',
    maxPhoneNumberLength: 6
  },
  {
    name: 'Angola',
    flag: '🇦🇴',
    country_code: 'AO',
    calling_code: '+244',
    value: 'Angola (AO) +244',
    label: 'Angola (+244)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Anguilla',
    flag: '🇦🇮',
    country_code: 'AI',
    calling_code: '+1264',
    value: 'Anguilla (AI) +1264',
    label: 'Anguilla (+1264)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Antarctica',
    flag: '🇦🇶',
    country_code: 'AQ',
    calling_code: '+672',
    value: 'Antarctica (AQ) +672',
    label: 'Antarctica (+672)',
    maxPhoneNumberLength: 6
  },
  {
    name: 'Antigua and Barbuda',
    flag: '🇦🇬',
    country_code: 'AG',
    calling_code: '+1268',
    value: 'Antigua and Barbuda (AG) +1268',
    label: 'Antigua and Barbuda (+1268)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Argentina',
    flag: '🇦🇷',
    country_code: 'AR',
    calling_code: '+54',
    value: 'Argentina (AR) +54',
    label: 'Argentina (+54)',
    maxPhoneNumberLength: 11
  },
  {
    name: 'Armenia',
    flag: '🇦🇲',
    country_code: 'AM',
    calling_code: '+374',
    value: 'Armenia (AM) +374',
    label: 'Armenia (+374)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Aruba',
    flag: '🇦🇼',
    country_code: 'AW',
    calling_code: '+297',
    value: 'Aruba (AW) +297',
    label: 'Aruba (+297)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Australia',
    flag: '🇦🇺',
    country_code: 'AU',
    calling_code: '+61',
    value: 'Australia (AU) +61',
    label: 'Australia (+61)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Austria',
    flag: '🇦🇹',
    country_code: 'AT',
    calling_code: '+43',
    value: 'Austria (AT) +43',
    label: 'Austria (+43)',
    maxPhoneNumberLength: 13
  },
  {
    name: 'Azerbaijan',
    flag: '🇦🇿',
    country_code: 'AZ',
    calling_code: '+994',
    value: 'Azerbaijan (AZ) +994',
    label: 'Azerbaijan (+994)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Bahamas',
    flag: '🇧🇸',
    country_code: 'BS',
    calling_code: '+1242',
    value: 'Bahamas (BS) +1242',
    label: 'Bahamas (+1242)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Bahrain',
    flag: '🇧🇭',
    country_code: 'BH',
    calling_code: '+973',
    value: 'Bahrain (BH) +973',
    label: 'Bahrain (+973)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Bangladesh',
    flag: '🇧🇩',
    country_code: 'BD',
    calling_code: '+880',
    value: 'Bangladesh (BD) +880',
    label: 'Bangladesh (+880)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Barbados',
    flag: '🇧🇧',
    country_code: 'BB',
    calling_code: '+1246',
    value: 'Barbados (BB) +1246',
    label: 'Barbados (+1246)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Belarus',
    flag: '🇧🇾',
    country_code: 'BY',
    calling_code: '+375',
    value: 'Belarus (BY) +375',
    label: 'Belarus (+375)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Belgium',
    flag: '🇧🇪',
    country_code: 'BE',
    calling_code: '+32',
    value: 'Belgium (BE) +32',
    label: 'Belgium (+32)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Belize',
    flag: '🇧🇿',
    country_code: 'BZ',
    calling_code: '+501',
    value: 'Belize (BZ) +501',
    label: 'Belize (+501)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Benin',
    flag: '🇧🇯',
    country_code: 'BJ',
    calling_code: '+229',
    value: 'Benin (BJ) +229',
    label: 'Benin (+229)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Bermuda',
    flag: '🇧🇲',
    country_code: 'BM',
    calling_code: '+1441',
    value: 'Bermuda (BM) +1441',
    label: 'Bermuda (+1441)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Bhutan',
    flag: '🇧🇹',
    country_code: 'BT',
    calling_code: '+975',
    value: 'Bhutan (BT) +975',
    label: 'Bhutan (+975)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Bolivia (Plurinational State of)',
    flag: '🇧🇴',
    country_code: 'BO',
    calling_code: '+591',
    value: 'Bolivia (Plurinational State of) (BO) +591',
    label: 'Bolivia (Plurinational State of) (+591)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    flag: '🇧🇶',
    country_code: 'BQ',
    calling_code: '+599',
    value: 'Bonaire, Sint Eustatius and Saba (BQ) +599',
    label: 'Bonaire, Sint Eustatius and Saba (+599)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Bosnia and Herzegovina',
    flag: '🇧🇦',
    country_code: 'BA',
    calling_code: '+387',
    value: 'Bosnia and Herzegovina (BA) +387',
    label: 'Bosnia and Herzegovina (+387)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Botswana',
    flag: '🇧🇼',
    country_code: 'BW',
    calling_code: '+267',
    value: 'Botswana (BW) +267',
    label: 'Botswana (+267)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Brazil',
    flag: '🇧🇷',
    country_code: 'BR',
    calling_code: '+55',
    value: 'Brazil (BR) +55',
    label: 'Brazil (+55)',
    maxPhoneNumberLength: 11
  },
  {
    name: 'British Indian Ocean Territory',
    flag: '🇮🇴',
    country_code: 'IO',
    calling_code: '+246',
    value: 'British Indian Ocean Territory (IO) +246',
    label: 'British Indian Ocean Territory (+246)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Brunei Darussalam',
    flag: '🇧🇳',
    country_code: 'BN',
    calling_code: '+673',
    value: 'Brunei Darussalam (BN) +673',
    label: 'Brunei Darussalam (+673)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Bulgaria',
    flag: '🇧🇬',
    country_code: 'BG',
    calling_code: '+359',
    value: 'Bulgaria (BG) +359',
    label: 'Bulgaria (+359)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Burkina Faso',
    flag: '🇧🇫',
    country_code: 'BF',
    calling_code: '+226',
    value: 'Burkina Faso (BF) +226',
    label: 'Burkina Faso (+226)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Burundi',
    flag: '🇧🇮',
    country_code: 'BI',
    calling_code: '+257',
    value: 'Burundi (BI) +257',
    label: 'Burundi (+257)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Cabo Verde',
    flag: '🇨🇻',
    country_code: 'CV',
    calling_code: '+238',
    value: 'Cabo Verde (CV) +238',
    label: 'Cabo Verde (+238)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Cambodia',
    flag: '🇰🇭',
    country_code: 'KH',
    calling_code: '+855',
    value: 'Cambodia (KH) +855',
    label: 'Cambodia (+855)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Cameroon',
    flag: '🇨🇲',
    country_code: 'CM',
    calling_code: '+237',
    value: 'Cameroon (CM) +237',
    label: 'Cameroon (+237)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Canada',
    flag: '🇨🇦',
    country_code: 'CA',
    calling_code: '+1',
    value: 'Canada (CA) +1',
    label: 'Canada (+1)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Cayman Islands',
    flag: '🇰🇾',
    country_code: 'KY',
    calling_code: '+1345',
    value: 'Cayman Islands (KY) +1345',
    label: 'Cayman Islands (+1345)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Central African Republic',
    flag: '🇨🇫',
    country_code: 'CF',
    calling_code: '+236',
    value: 'Central African Republic (CF) +236',
    label: 'Central African Republic (+236)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Chad',
    flag: '🇹🇩',
    country_code: 'TD',
    calling_code: '+235',
    value: 'Chad (TD) +235',
    label: 'Chad (+235)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Chile',
    flag: '🇨🇱',
    country_code: 'CL',
    calling_code: '+56',
    value: 'Chile (CL) +56',
    label: 'Chile (+56)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'China',
    flag: '🇨🇳',
    country_code: 'CN',
    calling_code: '+86',
    value: 'China (CN) +86',
    label: 'China (+86)',
    maxPhoneNumberLength: 11
  },
  {
    name: 'Christmas Island',
    flag: '🇨🇽',
    country_code: 'CX',
    calling_code: '+61',
    value: 'Christmas Island (CX) +61',
    label: 'Christmas Island (+61)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Cocos (Keeling) Islands',
    flag: '🇨🇨',
    country_code: 'CC',
    calling_code: '+61',
    value: 'Cocos (Keeling) Islands (CC) +61',
    label: 'Cocos (Keeling) Islands (+61)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Colombia',
    flag: '🇨🇴',
    country_code: 'CO',
    calling_code: '+57',
    value: 'Colombia (CO) +57',
    label: 'Colombia (+57)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Comoros',
    flag: '🇰🇲',
    country_code: 'KM',
    calling_code: '+269',
    value: 'Comoros (KM) +269',
    label: 'Comoros (+269)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Congo',
    flag: '🇨🇬',
    country_code: 'CG',
    calling_code: '+242',
    value: 'Congo (CG) +242',
    label: 'Congo (+242)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Congo (Democratic Republic of the)',
    flag: '🇨🇩',
    country_code: 'CD',
    calling_code: '+243',
    value: 'Congo (Democratic Republic of the) (CD) +243',
    label: 'Congo (Democratic Republic of the) (+243)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Cook Islands',
    flag: '🇨🇰',
    country_code: 'CK',
    calling_code: '+682',
    value: 'Cook Islands (CK) +682',
    label: 'Cook Islands (+682)',
    maxPhoneNumberLength: 5
  },
  {
    name: 'Costa Rica',
    flag: '🇨🇷',
    country_code: 'CR',
    calling_code: '+506',
    value: 'Costa Rica (CR) +506',
    label: 'Costa Rica (+506)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Côte d\'Ivoire',
    flag: '🇨🇮',
    country_code: 'CI',
    calling_code: '+225',
    value: 'Côte d\'Ivoire (CI) +225',
    label: 'Côte d\'Ivoire (+225)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Croatia',
    flag: '🇭🇷',
    country_code: 'HR',
    calling_code: '+385',
    value: 'Croatia (HR) +385',
    label: 'Croatia (+385)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Cuba',
    flag: '🇨🇺',
    country_code: 'CU',
    calling_code: '+53',
    value: 'Cuba (CU) +53',
    label: 'Cuba (+53)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Curaçao',
    flag: '🇨🇼',
    country_code: 'CW',
    calling_code: '+599',
    value: 'Curaçao (CW) +599',
    label: 'Curaçao (+599)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Cyprus',
    flag: '🇨🇾',
    country_code: 'CY',
    calling_code: '+357',
    value: 'Cyprus (CY) +357',
    label: 'Cyprus (+357)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Czech Republic',
    flag: '🇨🇿',
    country_code: 'CZ',
    calling_code: '+420',
    value: 'Czech Republic (CZ) +420',
    label: 'Czech Republic (+420)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Denmark',
    flag: '🇩🇰',
    country_code: 'DK',
    calling_code: '+45',
    value: 'Denmark (DK) +45',
    label: 'Denmark (+45)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Djibouti',
    flag: '🇩🇯',
    country_code: 'DJ',
    calling_code: '+253',
    value: 'Djibouti (DJ) +253',
    label: 'Djibouti (+253)',
    maxPhoneNumberLength: 6
  },
  {
    name: 'Dominica',
    flag: '🇩🇲',
    country_code: 'DM',
    calling_code: '+1767',
    value: 'Dominica (DM) +1767',
    label: 'Dominica (+1767)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Dominican Republic',
    flag: '🇩🇴',
    country_code: 'DO',
    calling_code: '+1809',
    value: 'Dominican Republic (DO) +1809',
    label: 'Dominican Republic (+1809)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Ecuador',
    flag: '🇪🇨',
    country_code: 'EC',
    calling_code: '+593',
    value: 'Ecuador (EC) +593',
    label: 'Ecuador (+593)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Egypt',
    flag: '🇪🇬',
    country_code: 'EG',
    calling_code: '+20',
    value: 'Egypt (EG) +20',
    label: 'Egypt (+20)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'El Salvador',
    flag: '🇸🇻',
    country_code: 'SV',
    calling_code: '+503',
    value: 'El Salvador (SV) +503',
    label: 'El Salvador (+503)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Equatorial Guinea',
    flag: '🇬🇶',
    country_code: 'GQ',
    calling_code: '+240',
    value: 'Equatorial Guinea (GQ) +240',
    label: 'Equatorial Guinea (+240)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Eritrea',
    flag: '🇪🇷',
    country_code: 'ER',
    calling_code: '+291',
    value: 'Eritrea (ER) +291',
    label: 'Eritrea (+291)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Estonia',
    flag: '🇪🇪',
    country_code: 'EE',
    calling_code: '+372',
    value: 'Estonia (EE) +372',
    label: 'Estonia (+372)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Ethiopia',
    flag: '🇪🇹',
    country_code: 'ET',
    calling_code: '+251',
    value: 'Ethiopia (ET) +251',
    label: 'Ethiopia (+251)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Falkland Islands (Malvinas)',
    flag: '🇫🇰',
    country_code: 'FK',
    calling_code: '+500',
    value: 'Falkland Islands (Malvinas) (FK) +500',
    label: 'Falkland Islands (Malvinas) (+500)',
    maxPhoneNumberLength: 5
  },
  {
    name: 'Faroe Islands',
    flag: '🇫🇴',
    country_code: 'FO',
    calling_code: '+298',
    value: 'Faroe Islands (FO) +298',
    label: 'Faroe Islands (+298)',
    maxPhoneNumberLength: 6
  },
  {
    name: 'Fiji',
    flag: '🇫🇯',
    country_code: 'FJ',
    calling_code: '+679',
    value: 'Fiji (FJ) +679',
    label: 'Fiji (+679)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Finland',
    flag: '🇫🇮',
    country_code: 'FI',
    calling_code: '+358',
    value: 'Finland (FI) +358',
    label: 'Finland (+358)',
    maxPhoneNumberLength: 12
  },
  {
    name: 'France',
    flag: '🇫🇷',
    country_code: 'FR',
    calling_code: '+33',
    value: 'France (FR) +33',
    label: 'France (+33)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'French Guiana',
    flag: '🇬🇫',
    country_code: 'GF',
    calling_code: '+594',
    value: 'French Guiana (GF) +594',
    label: 'French Guiana (+594)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'French Polynesia',
    flag: '🇵🇫',
    country_code: 'PF',
    calling_code: '+689',
    value: 'French Polynesia (PF) +689',
    label: 'French Polynesia (+689)',
    maxPhoneNumberLength: 6
  },
  {
    name: 'Gabon',
    flag: '🇬🇦',
    country_code: 'GA',
    calling_code: '+241',
    value: 'Gabon (GA) +241',
    label: 'Gabon (+241)',
    maxPhoneNumberLength: 6
  },
  {
    name: 'Gambia',
    flag: '🇬🇲',
    country_code: 'GM',
    calling_code: '+220',
    value: 'Gambia (GM) +220',
    label: 'Gambia (+220)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Georgia',
    flag: '🇬🇪',
    country_code: 'GE',
    calling_code: '+995',
    value: 'Georgia (GE) +995',
    label: 'Georgia (+995)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Germany',
    flag: '🇩🇪',
    country_code: 'DE',
    calling_code: '+49',
    value: 'Germany (DE) +49',
    label: 'Germany (+49)',
    maxPhoneNumberLength: 11
  },
  {
    name: 'Ghana',
    flag: '🇬🇭',
    country_code: 'GH',
    calling_code: '+233',
    value: 'Ghana (GH) +233',
    label: 'Ghana (+233)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Gibraltar',
    flag: '🇬🇮',
    country_code: 'GI',
    calling_code: '+350',
    value: 'Gibraltar (GI) +350',
    label: 'Gibraltar (+350)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Greece',
    flag: '🇬🇷',
    country_code: 'GR',
    calling_code: '+30',
    value: 'Greece (GR) +30',
    label: 'Greece (+30)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Greenland',
    flag: '🇬🇱',
    country_code: 'GL',
    calling_code: '+299',
    value: 'Greenland (GL) +299',
    label: 'Greenland (+299)',
    maxPhoneNumberLength: 6
  },
  {
    name: 'Grenada',
    flag: '🇬🇩',
    country_code: 'GD',
    calling_code: '+1473',
    value: 'Grenada (GD) +1473',
    label: 'Grenada (+1473)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Guadeloupe',
    flag: '🇬🇵',
    country_code: 'GP',
    calling_code: '+590',
    value: 'Guadeloupe (GP) +590',
    label: 'Guadeloupe (+590)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Guam',
    flag: '🇬🇺',
    country_code: 'GU',
    calling_code: '+1671',
    value: 'Guam (GU) +1671',
    label: 'Guam (+1671)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Guatemala',
    flag: '🇬🇹',
    country_code: 'GT',
    calling_code: '+502',
    value: 'Guatemala (GT) +502',
    label: 'Guatemala (+502)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Guernsey',
    flag: '🇬🇬',
    country_code: 'GG',
    calling_code: '+44',
    value: 'Guernsey (GG) +44',
    label: 'Guernsey (+44)',
    maxPhoneNumberLength: 11
  },
  {
    name: 'Guinea',
    flag: '🇬🇳',
    country_code: 'GN',
    calling_code: '+224',
    value: 'Guinea (GN) +224',
    label: 'Guinea (+224)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Guinea-Bissau',
    flag: '🇬🇼',
    country_code: 'GW',
    calling_code: '+245',
    value: 'Guinea-Bissau (GW) +245',
    label: 'Guinea-Bissau (+245)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Guyana',
    flag: '🇬🇾',
    country_code: 'GY',
    calling_code: '+592',
    value: 'Guyana (GY) +592',
    label: 'Guyana (+592)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Haiti',
    flag: '🇭🇹',
    country_code: 'HT',
    calling_code: '+509',
    value: 'Haiti (HT) +509',
    label: 'Haiti (+509)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Holy See',
    flag: '🇻🇦',
    country_code: 'VA',
    calling_code: '+379',
    value: 'Holy See (VA) +379',
    label: 'Holy See (+379)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Honduras',
    flag: '🇭🇳',
    country_code: 'HN',
    calling_code: '+504',
    value: 'Honduras (HN) +504',
    label: 'Honduras (+504)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Hong Kong',
    flag: '🇭🇰',
    country_code: 'HK',
    calling_code: '+852',
    value: 'Hong Kong (HK) +852',
    label: 'Hong Kong (+852)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Hungary',
    flag: '🇭🇺',
    country_code: 'HU',
    calling_code: '+36',
    value: 'Hungary (HU) +36',
    label: 'Hungary (+36)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Iceland',
    flag: '🇮🇸',
    country_code: 'IS',
    calling_code: '+354',
    value: 'Iceland (IS) +354',
    label: 'Iceland (+354)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'India',
    flag: '🇮🇳',
    country_code: 'IN',
    calling_code: '+91',
    value: 'India (IN) +91',
    label: 'India (+91)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Indonesia',
    flag: '🇮🇩',
    country_code: 'ID',
    calling_code: '+62',
    value: 'Indonesia (ID) +62',
    label: 'Indonesia (+62)',
    maxPhoneNumberLength: 11
  },
  {
    name: 'Iran (Islamic Republic of)',
    flag: '🇮🇷',
    country_code: 'IR',
    calling_code: '+98',
    value: 'Iran (Islamic Republic of) (IR) +98',
    label: 'Iran (Islamic Republic of) (+98)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Iraq',
    flag: '🇮🇶',
    country_code: 'IQ',
    calling_code: '+964',
    value: 'Iraq (IQ) +964',
    label: 'Iraq (+964)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Ireland',
    flag: '🇮🇪',
    country_code: 'IE',
    calling_code: '+353',
    value: 'Ireland (IE) +353',
    label: 'Ireland (+353)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Isle of Man',
    flag: '🇮🇲',
    country_code: 'IM',
    calling_code: '+44',
    value: 'Isle of Man (IM) +44',
    label: 'Isle of Man (+44)',
    maxPhoneNumberLength: 12
  },
  {
    name: 'Israel',
    flag: '🇮🇱',
    country_code: 'IL',
    calling_code: '+972',
    value: 'Israel (IL) +972',
    label: 'Israel (+972)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Italy',
    flag: '🇮🇹',
    country_code: 'IT',
    calling_code: '+39',
    value: 'Italy (IT) +39',
    label: 'Italy (+39)',
    maxPhoneNumberLength: 12,
    minPhoneNumberLength: 6
  },
  {
    name: 'Jamaica',
    flag: '🇯🇲',
    country_code: 'JM',
    calling_code: '+1876',
    value: 'Jamaica (JM) +1876',
    label: 'Jamaica (+1876)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Japan',
    flag: '🇯🇵',
    country_code: 'JP',
    calling_code: '+81',
    value: 'Japan (JP) +81',
    label: 'Japan (+81)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Jersey',
    flag: '🇯🇪',
    country_code: 'JE',
    calling_code: '+44',
    value: 'Jersey (JE) +44',
    label: 'Jersey (+44)',
    maxPhoneNumberLength: 12
  },
  {
    name: 'Jordan',
    flag: '🇯🇴',
    country_code: 'JO',
    calling_code: '+962',
    value: 'Jordan (JO) +962',
    label: 'Jordan (+962)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Kazakhstan',
    flag: '🇰🇿',
    country_code: 'KZ',
    calling_code: '+7',
    value: 'Kazakhstan (KZ) +7',
    label: 'Kazakhstan (+7)',
    maxPhoneNumberLength: 11
  },
  {
    name: 'Kenya',
    flag: '🇰🇪',
    country_code: 'KE',
    calling_code: '+254',
    value: 'Kenya (KE) +254',
    label: 'Kenya (+254)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Kiribati',
    flag: '🇰🇮',
    country_code: 'KI',
    calling_code: '+686',
    value: 'Kiribati (KI) +686',
    label: 'Kiribati (+686)',
    maxPhoneNumberLength: 5
  },
  {
    name: 'Korea (Democratic People\'s Republic of)',
    flag: '🇰🇵',
    country_code: 'KP',
    calling_code: '+850',
    value: 'Korea (Democratic People\'s Republic of) (KP) +850',
    label: 'Korea (Democratic People\'s Republic of) (+850)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Korea (Republic of)',
    flag: '🇰🇷',
    country_code: 'KR',
    calling_code: '+82',
    value: 'Korea (Republic of) (KR) +82',
    label: 'Korea (Republic of) (+82)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Kuwait',
    flag: '🇰🇼',
    country_code: 'KW',
    calling_code: '+965',
    value: 'Kuwait (KW) +965',
    label: 'Kuwait (+965)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Kyrgyzstan',
    flag: '🇰🇬',
    country_code: 'KG',
    calling_code: '+996',
    value: 'Kyrgyzstan (KG) +996',
    label: 'Kyrgyzstan (+996)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Lao People\'s Democratic Republic',
    flag: '🇱🇦',
    country_code: 'LA',
    calling_code: '+856',
    value: 'Lao People\'s Democratic Republic (LA) +856',
    label: 'Lao People\'s Democratic Republic (+856)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Latvia',
    flag: '🇱🇻',
    country_code: 'LV',
    calling_code: '+371',
    value: 'Latvia (LV) +371',
    label: 'Latvia (+371)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Lebanon',
    flag: '🇱🇧',
    country_code: 'LB',
    calling_code: '+961',
    value: 'Lebanon (LB) +961',
    label: 'Lebanon (+961)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Lesotho',
    flag: '🇱🇸',
    country_code: 'LS',
    calling_code: '+266',
    value: 'Lesotho (LS) +266',
    label: 'Lesotho (+266)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Liberia',
    flag: '🇱🇷',
    country_code: 'LR',
    calling_code: '+231',
    value: 'Liberia (LR) +231',
    label: 'Liberia (+231)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Libya',
    flag: '🇱🇾',
    country_code: 'LY',
    calling_code: '+218',
    value: 'Libya (LY) +218',
    label: 'Libya (+218)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Liechtenstein',
    flag: '🇱🇮',
    country_code: 'LI',
    calling_code: '+423',
    value: 'Liechtenstein (LI) +423',
    label: 'Liechtenstein (+423)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Lithuania',
    flag: '🇱🇹',
    country_code: 'LT',
    calling_code: '+370',
    value: 'Lithuania (LT) +370',
    label: 'Lithuania (+370)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Luxembourg',
    flag: '🇱🇺',
    country_code: 'LU',
    calling_code: '+352',
    value: 'Luxembourg (LU) +352',
    label: 'Luxembourg (+352)',
    maxPhoneNumberLength: 11
  },
  {
    name: 'Macao',
    flag: '🇲🇴',
    country_code: 'MO',
    calling_code: '+853',
    value: 'Macao (MO) +853',
    label: 'Macao (+853)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Macedonia (the former Yugoslav Republic of)',
    flag: '🇲🇰',
    country_code: 'MK',
    calling_code: '+389',
    value: 'Macedonia (the former Yugoslav Republic of) (MK) +389',
    label: 'Macedonia (the former Yugoslav Republic of) (+389)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Madagascar',
    flag: '🇲🇬',
    country_code: 'MG',
    calling_code: '+261',
    value: 'Madagascar (MG) +261',
    label: 'Madagascar (+261)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Malawi',
    flag: '🇲🇼',
    country_code: 'MW',
    calling_code: '+265',
    value: 'Malawi (MW) +265',
    label: 'Malawi (+265)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Malaysia',
    flag: '🇲🇾',
    country_code: 'MY',
    calling_code: '+60',
    value: 'Malaysia (MY) +60',
    label: 'Malaysia (+60)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Maldives',
    flag: '🇲🇻',
    country_code: 'MV',
    calling_code: '+960',
    value: 'Maldives (MV) +960',
    label: 'Maldives (+960)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Mali',
    flag: '🇲🇱',
    country_code: 'ML',
    calling_code: '+223',
    value: 'Mali (ML) +223',
    label: 'Mali (+223)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Malta',
    flag: '🇲🇹',
    country_code: 'MT',
    calling_code: '+356',
    value: 'Malta (MT) +356',
    label: 'Malta (+356)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Marshall Islands',
    flag: '🇲🇭',
    country_code: 'MH',
    calling_code: '+692',
    value: 'Marshall Islands (MH) +692',
    label: 'Marshall Islands (+692)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Martinique',
    flag: '🇲🇶',
    country_code: 'MQ',
    calling_code: '+596',
    value: 'Martinique (MQ) +596',
    label: 'Martinique (+596)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Mauritania',
    flag: '🇲🇷',
    country_code: 'MR',
    calling_code: '+222',
    value: 'Mauritania (MR) +222',
    label: 'Mauritania (+222)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Mauritius',
    flag: '🇲🇺',
    country_code: 'MU',
    calling_code: '+230',
    value: 'Mauritius (MU) +230',
    label: 'Mauritius (+230)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Mayotte',
    flag: '🇾🇹',
    country_code: 'YT',
    calling_code: '+262',
    value: 'Mayotte (YT) +262',
    label: 'Mayotte (+262)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Mexico',
    flag: '🇲🇽',
    country_code: 'MX',
    calling_code: '+52',
    value: 'Mexico (MX) +52',
    label: 'Mexico (+52)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Micronesia (Federated States of)',
    flag: '🇫🇲',
    country_code: 'FM',
    calling_code: '+691',
    value: 'Micronesia (Federated States of) (FM) +691',
    label: 'Micronesia (Federated States of) (+691)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Moldova (Republic of)',
    flag: '🇲🇩',
    country_code: 'MD',
    calling_code: '+373',
    value: 'Moldova (Republic of) (MD) +373',
    label: 'Moldova (Republic of) (+373)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Monaco',
    flag: '🇲🇨',
    country_code: 'MC',
    calling_code: '+377',
    value: 'Monaco (MC) +377',
    label: 'Monaco (+377)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Mongolia',
    flag: '🇲🇳',
    country_code: 'MN',
    calling_code: '+976',
    value: 'Mongolia (MN) +976',
    label: 'Mongolia (+976)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Montenegro',
    flag: '🇲🇪',
    country_code: 'ME',
    calling_code: '+382',
    value: 'Montenegro (ME) +382',
    label: 'Montenegro (+382)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Montserrat',
    flag: '🇲🇸',
    country_code: 'MS',
    calling_code: '+1664',
    value: 'Montserrat (MS) +1664',
    label: 'Montserrat (+1664)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Morocco',
    flag: '🇲🇦',
    country_code: 'MA',
    calling_code: '+212',
    value: 'Morocco (MA) +212',
    label: 'Morocco (+212)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Mozambique',
    flag: '🇲🇿',
    country_code: 'MZ',
    calling_code: '+258',
    value: 'Mozambique (MZ) +258',
    label: 'Mozambique (+258)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Myanmar',
    flag: '🇲🇲',
    country_code: 'MM',
    calling_code: '+95',
    value: 'Myanmar (MM) +95',
    label: 'Myanmar (+95)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Namibia',
    flag: '🇳🇦',
    country_code: 'NA',
    calling_code: '+264',
    value: 'Namibia (NA) +264',
    label: 'Namibia (+264)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Nauru',
    flag: '🇳🇷',
    country_code: 'NR',
    calling_code: '+674',
    value: 'Nauru (NR) +674',
    label: 'Nauru (+674)',
    maxPhoneNumberLength: 5
  },
  {
    name: 'Nepal',
    flag: '🇳🇵',
    country_code: 'NP',
    calling_code: '+977',
    value: 'Nepal (NP) +977',
    label: 'Nepal (+977)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Netherlands',
    flag: '🇳🇱',
    country_code: 'NL',
    calling_code: '+31',
    value: 'Netherlands (NL) +31',
    label: 'Netherlands (+31)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'New Caledonia',
    flag: '🇳🇨',
    country_code: 'NC',
    calling_code: '+687',
    value: 'New Caledonia (NC) +687',
    label: 'New Caledonia (+687)',
    maxPhoneNumberLength: 6
  },
  {
    name: 'New Zealand',
    flag: '🇳🇿',
    country_code: 'NZ',
    calling_code: '+64',
    value: 'New Zealand (NZ) +64',
    label: 'New Zealand (+64)',
    maxPhoneNumberLength: 11
  },
  {
    name: 'Nicaragua',
    flag: '🇳🇮',
    country_code: 'NI',
    calling_code: '+505',
    value: 'Nicaragua (NI) +505',
    label: 'Nicaragua (+505)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Niger',
    flag: '🇳🇪',
    country_code: 'NE',
    calling_code: '+227',
    value: 'Niger (NE) +227',
    label: 'Niger (+227)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Nigeria',
    flag: '🇳🇬',
    country_code: 'NG',
    calling_code: '+234',
    value: 'Nigeria (NG) +234',
    label: 'Nigeria (+234)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Niue',
    flag: '🇳🇺',
    country_code: 'NU',
    calling_code: '+683',
    value: 'Niue (NU) +683',
    label: 'Niue (+683)',
    maxPhoneNumberLength: 4
  },
  {
    name: 'Norfolk Island',
    flag: '🇳🇫',
    country_code: 'NF',
    calling_code: '+672',
    value: 'Norfolk Island (NF) +672',
    label: 'Norfolk Island (+672)',
    maxPhoneNumberLength: 6
  },
  {
    name: 'Northern Mariana Islands',
    flag: '🇲🇵',
    country_code: 'MP',
    calling_code: '+1670',
    value: 'Northern Mariana Islands (MP) +1670',
    label: 'Northern Mariana Islands (+1670)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Norway',
    flag: '🇳🇴',
    country_code: 'NO',
    calling_code: '+47',
    value: 'Norway (NO) +47',
    label: 'Norway (+47)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Oman',
    flag: '🇴🇲',
    country_code: 'OM',
    calling_code: '+968',
    value: 'Oman (OM) +968',
    label: 'Oman (+968)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Pakistan',
    flag: '🇵🇰',
    country_code: 'PK',
    calling_code: '+92',
    value: 'Pakistan (PK) +92',
    label: 'Pakistan (+92)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Palau',
    flag: '🇵🇼',
    country_code: 'PW',
    calling_code: '+680',
    value: 'Palau (PW) +680',
    label: 'Palau (+680)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Palestine, State of',
    flag: '🇵🇸',
    country_code: 'PS',
    calling_code: '+970',
    value: 'Palestine, State of (PS) +970',
    label: 'Palestine, State of (+970)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Panama',
    flag: '🇵🇦',
    country_code: 'PA',
    calling_code: '+507',
    value: 'Panama (PA) +507',
    label: 'Panama (+507)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Papua New Guinea',
    flag: '🇵🇬',
    country_code: 'PG',
    calling_code: '+675',
    value: 'Papua New Guinea (PG) +675',
    label: 'Papua New Guinea (+675)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Paraguay',
    flag: '🇵🇾',
    country_code: 'PY',
    calling_code: '+595',
    value: 'Paraguay (PY) +595',
    label: 'Paraguay (+595)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Peru',
    flag: '🇵🇪',
    country_code: 'PE',
    calling_code: '+51',
    value: 'Peru (PE) +51',
    label: 'Peru (+51)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Philippines',
    flag: '🇵🇭',
    country_code: 'PH',
    calling_code: '+63',
    value: 'Philippines (PH) +63',
    label: 'Philippines (+63)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Pitcairn',
    flag: '🇵🇳',
    country_code: 'PN',
    calling_code: '+64',
    value: 'Pitcairn (PN) +64',
    label: 'Pitcairn (+64)',
    maxPhoneNumberLength: 5
  },
  {
    name: 'Poland',
    flag: '🇵🇱',
    country_code: 'PL',
    calling_code: '+48',
    value: 'Poland (PL) +48',
    label: 'Poland (+48)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Portugal',
    flag: '🇵🇹',
    country_code: 'PT',
    calling_code: '+351',
    value: 'Portugal (PT) +351',
    label: 'Portugal (+351)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Puerto Rico',
    flag: '🇵🇷',
    country_code: 'PR',
    calling_code: '+1',
    value: 'Puerto Rico (PR) +1',
    label: 'Puerto Rico (+1)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Qatar',
    flag: '🇶🇦',
    country_code: 'QA',
    calling_code: '+974',
    value: 'Qatar (QA) +974',
    label: 'Qatar (+974)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Réunion',
    flag: '🇷🇪',
    country_code: 'RE',
    calling_code: '+262',
    value: 'Réunion (RE) +262',
    label: 'Réunion (+262)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Romania',
    flag: '🇷🇴',
    country_code: 'RO',
    calling_code: '+40',
    value: 'Romania (RO) +40',
    label: 'Romania (+40)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Russian Federation',
    flag: '🇷🇺',
    country_code: 'RU',
    calling_code: '+7',
    value: 'Russian Federation (RU) +7',
    label: 'Russian Federation (+7)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Rwanda',
    flag: '🇷🇼',
    country_code: 'RW',
    calling_code: '+250',
    value: 'Rwanda (RW) +250',
    label: 'Rwanda (+250)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Saint Barthélemy',
    flag: '🇧🇱',
    country_code: 'BL',
    calling_code: '+590',
    value: 'Saint Barthélemy (BL) +590',
    label: 'Saint Barthélemy (+590)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    flag: '🇸🇭',
    country_code: 'SH',
    calling_code: '+290',
    value: 'Saint Helena, Ascension and Tristan da Cunha (SH) +290',
    label: 'Saint Helena, Ascension and Tristan da Cunha (+290)',
    maxPhoneNumberLength: 4
  },
  {
    name: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
    country_code: 'KN',
    calling_code: '+1869',
    value: 'Saint Kitts and Nevis (KN) +1869',
    label: 'Saint Kitts and Nevis (+1869)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Saint Lucia',
    flag: '🇱🇨',
    country_code: 'LC',
    calling_code: '+1758',
    value: 'Saint Lucia (LC) +1758',
    label: 'Saint Lucia (+1758)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Saint Martin (French part)',
    flag: '🇲🇫',
    country_code: 'MF',
    calling_code: '+590',
    value: 'Saint Martin (French part) (MF) +590',
    label: 'Saint Martin (French part) (+590)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Saint Pierre and Miquelon',
    flag: '🇵🇲',
    country_code: 'PM',
    calling_code: '+508',
    value: 'Saint Pierre and Miquelon (PM) +508',
    label: 'Saint Pierre and Miquelon (+508)',
    maxPhoneNumberLength: 6
  },
  {
    name: 'Saint Vincent and the Grenadines',
    flag: '🇻🇨',
    country_code: 'VC',
    calling_code: '+1784',
    value: 'Saint Vincent and the Grenadines (VC) +1784',
    label: 'Saint Vincent and the Grenadines (+1784)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Samoa',
    flag: '🇼🇸',
    country_code: 'WS',
    calling_code: '+685',
    value: 'Samoa (WS) +685',
    label: 'Samoa (+685)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'San Marino',
    flag: '🇸🇲',
    country_code: 'SM',
    calling_code: '+378',
    value: 'San Marino (SM) +378',
    label: 'San Marino (+378)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Sao Tome and Principe',
    flag: '🇸🇹',
    country_code: 'ST',
    calling_code: '+239',
    value: 'Sao Tome and Principe (ST) +239',
    label: 'Sao Tome and Principe (+239)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Saudi Arabia',
    flag: '🇸🇦',
    country_code: 'SA',
    calling_code: '+966',
    value: 'Saudi Arabia (SA) +966',
    label: 'Saudi Arabia (+966)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Senegal',
    flag: '🇸🇳',
    country_code: 'SN',
    calling_code: '+221',
    value: 'Senegal (SN) +221',
    label: 'Senegal (+221)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Serbia',
    flag: '🇷🇸',
    country_code: 'RS',
    calling_code: '+381',
    value: 'Serbia (RS) +381',
    label: 'Serbia (+381)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Seychelles',
    flag: '🇸🇨',
    country_code: 'SC',
    calling_code: '+248',
    value: 'Seychelles (SC) +248',
    label: 'Seychelles (+248)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Sierra Leone',
    flag: '🇸🇱',
    country_code: 'SL',
    calling_code: '+232',
    value: 'Sierra Leone (SL) +232',
    label: 'Sierra Leone (+232)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Singapore',
    flag: '🇸🇬',
    country_code: 'SG',
    calling_code: '+65',
    value: 'Singapore (SG) +65',
    label: 'Singapore (+65)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Sint Maarten (Dutch part)',
    flag: '🇸🇽',
    country_code: 'SX',
    calling_code: '+1721',
    value: 'Sint Maarten (Dutch part) (SX) +1721',
    label: 'Sint Maarten (Dutch part) (+1721)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Slovakia',
    flag: '🇸🇰',
    country_code: 'SK',
    calling_code: '+421',
    value: 'Slovakia (SK) +421',
    label: 'Slovakia (+421)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Slovenia',
    flag: '🇸🇮',
    country_code: 'SI',
    calling_code: '+386',
    value: 'Slovenia (SI) +386',
    label: 'Slovenia (+386)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Solomon Islands',
    flag: '🇸🇧',
    country_code: 'SB',
    calling_code: '+677',
    value: 'Solomon Islands (SB) +677',
    label: 'Solomon Islands (+677)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Somalia',
    flag: '🇸🇴',
    country_code: 'SO',
    calling_code: '+252',
    value: 'Somalia (SO) +252',
    label: 'Somalia (+252)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'South Africa',
    flag: '🇿🇦',
    country_code: 'ZA',
    calling_code: '+27',
    value: 'South Africa (ZA) +27',
    label: 'South Africa (+27)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    flag: '🇬🇸',
    country_code: 'GS',
    calling_code: '+500',
    value: 'South Georgia and the South Sandwich Islands (GS) +500',
    label: 'South Georgia and the South Sandwich Islands (+500)',
    maxPhoneNumberLength: 5
  },
  {
    name: 'South Sudan',
    flag: '🇸🇸',
    country_code: 'SS',
    calling_code: '+211',
    value: 'South Sudan (SS) +211',
    label: 'South Sudan (+211)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Spain',
    flag: '🇪🇸',
    country_code: 'ES',
    calling_code: '+34',
    value: 'Spain (ES) +34',
    label: 'Spain (+34)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Sri Lanka',
    flag: '🇱🇰',
    country_code: 'LK',
    calling_code: '+94',
    value: 'Sri Lanka (LK) +94',
    label: 'Sri Lanka (+94)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Sudan',
    flag: '🇸🇩',
    country_code: 'SD',
    calling_code: '+249',
    value: 'Sudan (SD) +249',
    label: 'Sudan (+249)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Suriname',
    flag: '🇸🇷',
    country_code: 'SR',
    calling_code: '+597',
    value: 'Suriname (SR) +597',
    label: 'Suriname (+597)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Svalbard and Jan Mayen',
    flag: '🇸🇯',
    country_code: 'SJ',
    calling_code: '+47',
    value: 'Svalbard and Jan Mayen (SJ) +47',
    label: 'Svalbard and Jan Mayen (+47)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Swaziland',
    flag: '🇸🇿',
    country_code: 'SZ',
    calling_code: '+268',
    value: 'Swaziland (SZ) +268',
    label: 'Swaziland (+268)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Sweden',
    flag: '🇸🇪',
    country_code: 'SE',
    calling_code: '+46',
    value: 'Sweden (SE) +46',
    label: 'Sweden (+46)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Switzerland',
    flag: '🇨🇭',
    country_code: 'CH',
    calling_code: '+41',
    value: 'Switzerland (CH) +41',
    label: 'Switzerland (+41)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Syrian Arab Republic',
    flag: '🇸🇾',
    country_code: 'SY',
    calling_code: '+963',
    value: 'Syrian Arab Republic (SY) +963',
    label: 'Syrian Arab Republic (+963)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Taiwan, Province of China',
    flag: '🇹🇼',
    country_code: 'TW',
    calling_code: '+886',
    value: 'Taiwan, Province of China (TW) +886',
    label: 'Taiwan, Province of China (+886)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Tajikistan',
    flag: '🇹🇯',
    country_code: 'TJ',
    calling_code: '+992',
    value: 'Tajikistan (TJ) +992',
    label: 'Tajikistan (+992)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Tanzania, United Republic of',
    flag: '🇹🇿',
    country_code: 'TZ',
    calling_code: '+255',
    value: 'Tanzania, United Republic of (TZ) +255',
    label: 'Tanzania, United Republic of (+255)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Thailand',
    flag: '🇹🇭',
    country_code: 'TH',
    calling_code: '+66',
    value: 'Thailand (TH) +66',
    label: 'Thailand (+66)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Timor-Leste',
    flag: '🇹🇱',
    country_code: 'TL',
    calling_code: '+670',
    value: 'Timor-Leste (TL) +670',
    label: 'Timor-Leste (+670)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Togo',
    flag: '🇹🇬',
    country_code: 'TG',
    calling_code: '+228',
    value: 'Togo (TG) +228',
    label: 'Togo (+228)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Tokelau',
    flag: '🇹🇰',
    country_code: 'TK',
    calling_code: '+690',
    value: 'Tokelau (TK) +690',
    label: 'Tokelau (+690)',
    maxPhoneNumberLength: 4
  },
  {
    name: 'Tonga',
    flag: '🇹🇴',
    country_code: 'TO',
    calling_code: '+676',
    value: 'Tonga (TO) +676',
    label: 'Tonga (+676)',
    maxPhoneNumberLength: 5
  },
  {
    name: 'Trinidad and Tobago',
    flag: '🇹🇹',
    country_code: 'TT',
    calling_code: '+1868',
    value: 'Trinidad and Tobago (TT) +1868',
    label: 'Trinidad and Tobago (+1868)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Tunisia',
    flag: '🇹🇳',
    country_code: 'TN',
    calling_code: '+216',
    value: 'Tunisia (TN) +216',
    label: 'Tunisia (+216)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Turkey',
    flag: '🇹🇷',
    country_code: 'TR',
    calling_code: '+90',
    value: 'Turkey (TR) +90',
    label: 'Turkey (+90)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Turkmenistan',
    flag: '🇹🇲',
    country_code: 'TM',
    calling_code: '+993',
    value: 'Turkmenistan (TM) +993',
    label: 'Turkmenistan (+993)',
    maxPhoneNumberLength: 8
  },
  {
    name: 'Turks and Caicos Islands',
    flag: '🇹🇨',
    country_code: 'TC',
    calling_code: '+1649',
    value: 'Turks and Caicos Islands (TC) +1649',
    label: 'Turks and Caicos Islands (+1649)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Tuvalu',
    flag: '🇹🇻',
    country_code: 'TV',
    calling_code: '+688',
    value: 'Tuvalu (TV) +688',
    label: 'Tuvalu (+688)',
    maxPhoneNumberLength: 5
  },
  {
    name: 'Uganda',
    flag: '🇺🇬',
    country_code: 'UG',
    calling_code: '+256',
    value: 'Uganda (UG) +256',
    label: 'Uganda (+256)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Ukraine',
    flag: '🇺🇦',
    country_code: 'UA',
    calling_code: '+380',
    value: 'Ukraine (UA) +380',
    label: 'Ukraine (+380)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'United Arab Emirates',
    flag: '🇦🇪',
    country_code: 'AE',
    calling_code: '+971',
    value: 'United Arab Emirates (AE) +971',
    label: 'United Arab Emirates (+971)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'United Kingdom',
    flag: '🇬🇧',
    country_code: 'GB',
    calling_code: '+44',
    value: 'United Kingdom (GB) +44',
    label: 'United Kingdom (+44)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'United States of America',
    flag: '🇺🇸',
    country_code: 'US',
    calling_code: '+1',
    value: 'United States of America (US) +1',
    label: 'United States of America (+1)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Uruguay',
    flag: '🇺🇾',
    country_code: 'UY',
    calling_code: '+598',
    value: 'Uruguay (UY) +598',
    label: 'Uruguay (+598)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Uzbekistan',
    flag: '🇺🇿',
    country_code: 'UZ',
    calling_code: '+998',
    value: 'Uzbekistan (UZ) +998',
    label: 'Uzbekistan (+998)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Vanuatu',
    flag: '🇻🇺',
    country_code: 'VU',
    calling_code: '+678',
    value: 'Vanuatu (VU) +678',
    label: 'Vanuatu (+678)',
    maxPhoneNumberLength: 7
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    flag: '🇻🇪',
    country_code: 'VE',
    calling_code: '+58',
    value: 'Venezuela (Bolivarian Republic of) (VE) +58',
    label: 'Venezuela (Bolivarian Republic of) (+58)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Vietnam',
    flag: '🇻🇳',
    country_code: 'VN',
    calling_code: '+84',
    value: 'Vietnam (VN) +84',
    label: 'Vietnam (+84)',
    maxPhoneNumberLength: 10
  },
  {
    name: 'Wallis and Futuna',
    flag: '🇼🇫',
    country_code: 'WF',
    calling_code: '+681',
    value: 'Wallis and Futuna (WF) +681',
    label: 'Wallis and Futuna (+681)',
    maxPhoneNumberLength: 6
  },
  {
    name: 'Yemen',
    flag: '🇾🇪',
    country_code: 'YE',
    calling_code: '+967',
    value: 'Yemen (YE) +967',
    label: 'Yemen (+967)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Zambia',
    flag: '🇿🇲',
    country_code: 'ZM',
    calling_code: '+260',
    value: 'Zambia (ZM) +260',
    label: 'Zambia (+260)',
    maxPhoneNumberLength: 9
  },
  {
    name: 'Zimbabwe',
    flag: '🇿🇼',
    country_code: 'ZW',
    calling_code: '+263',
    value: 'Zimbabwe (ZW) +263',
    label: 'Zimbabwe (+263)',
    maxPhoneNumberLength: 9
  }
]