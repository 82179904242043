import defaultImg from '@src/assets/images/catalog/bundlesku/default-img.svg'
import error_img from '@src/assets/images/catalog/bundlesku/error_img.svg'
import { PACKAGE_TYPE } from '@src/views/Purchases/purchase.constants'
import { showSkuTypeTag } from '@src/views/sales/sales.utils'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { BUNDLE } from '../../../views/catalog/catalog.constants'
import SimpleSkuDetailModal from '../../../views/catalog/components/simple-sku-detail-modal/SimpleSkuDetailModal'
import SkuChildDetailModal from '../../../views/catalog/components/sku-child-detail-modal/SkuChildDetailModal'
import { cleanupBundleChildSku, cleanupSingleSku } from '../../../views/catalog/store'
import { ExpandableCustomTable } from '../globalTable/ExpandableCustomTable/index'
import SideSheet from '../sideSheet/index'
import SKUDetailsCol from '../sku-details'
import './styles.scss'

const BundleSkuDetailsSideSheet = ({
  bundleSkuForDetails,
  toggleBundleSkuModal,
  ...rest
}) => {
  const [skuForDetails, setSkuForDetails] = useState({})
  const [openSkuDetailsSidebar, setOpenSkuDetailsSidebar] = useState(false)
  const [openSkuChildDetailsSidebar, setOpenSkuChildDetailsSidebar] = useState(false)
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const handleSkuDetailsSidebar = (data) => {
    if (data) {
      setSkuForDetails(data)
      setOpenSkuDetailsSidebar(true)
    } else {
      setOpenSkuDetailsSidebar(false)
    }
  }

  const columns = [
    {
      id: 1,
      name: t('S. No.'),
      key: 'serial_no',
      width: '64px',
      reorder: false,
      cell: (row, index) => (
        <div className="w-100 text-center">{row.serialNumber || index + 1}</div>
      )
    },
    {
      id: 2,
      name: <span className="txt-body-md" title={t('SKU Details')}>{t('SKU Details')}</span>,
      minWidth: '30%',
      reorder: true,
      cell: (row) => {
        return (
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: row.sku?.seller_sku_id,
              image: {
                src: row.sku?.images?.[0]?.url,
                alt: row.sku?.name
              },
              details: {
                name: row.sku?.name,
                handleSkuDetails: () => handleSkuDetailsSidebar(row),
                skuCode: row.sku?.seller_sku_code,
                columnKey: 'sku_details'
              },
              customTag: {
                isCustomTagPresent: row.package_type === PACKAGE_TYPE.CASE_PACK || row.package_type === PACKAGE_TYPE.PALLET,
                className: classNames({
                  'bg-brown-light text-brown': row.package_type === PACKAGE_TYPE.PALLET,
                  'bg-info-light text-info': row.package_type === PACKAGE_TYPE.CASE_PACK
                }),
                title: showSkuTypeTag(row.packageType)
              }
            }}
          />
        )
      }
    },
    {
      id: 3,
      name: (
        <div className="txt-body-md w-100 text-end text-truncate" title={t('Qty Per Kit')}>
          {t('Qty Per Kit')}
        </div>
      ),
      key: 'qty_per_kit',
      minWidth: '120px',
      maxWidth: '120px',
      reorder: true,
      cell: (row) => {
        const { display_quantity_per_bundle } = row
        return (
          <div className="w-100">
            <div className="w-100 txt-sub-rg text-end text-truncation">
              {display_quantity_per_bundle}
            </div>
          </div>
        )
      }
    },
    {
      id: 4,
      name: (
        <div className="txt-body-md w-100 text-end text-truncate" title={t('Qty Per Order')}>
          {t('Qty Per Order')}
        </div>
      ),
      key: 'qty_per_order',
      minWidth: '120px',
      maxWidth: '120px',
      reorder: true,
      cell: (row) => {
        const { display_quantity } = row
        return (
          <div className="w-100">
            <div className="w-100 txt-sub-rg text-end text-truncation">
              {display_quantity}
            </div>
          </div>
        )
      }
    },
    {
      id: 5,
      name: (
        <div className="txt-body-md w-100 text-end text-truncate" title={t('Total Qty To Be Picked')}>
          {t('Total Qty To Be Picked')}
        </div>
      ),
      key: 'total_qty',
      minWidth: '130px',
      maxWidth: '130px',
      reorder: true,
      cell: (row) => {
        const { display_total_quantity } = row
        return (
          <div className="w-100">
            <div className="w-100 txt-sub-rg text-end text-truncation">
              {display_total_quantity}
            </div>
          </div>
        )
      }
    },
    {
      id: 6,
      name: (
        <div className="txt-body-md w-100 text-end text-truncate" title={t('Avl Qty')}>
          {t('Avl Qty')}
        </div>
      ),
      minWidth: '120px',
      maxWidth: '120px',
      reorder: true,
      cell: (row) => {
        const { display_available_quantity } = row
        return (
          <div className="w-100">
            <div className="w-100 txt-sub-rg text-end text-truncation">
              {display_available_quantity}
            </div>
          </div>
        )
      }
    }
  ]

  const onModalOpened = () => {}

  const onModalClosed = () => {
    dispatch(cleanupSingleSku())
    dispatch(cleanupBundleChildSku())
  }

  const handleBundleSkuDetailsSidebar = () => {
    setOpenSkuChildDetailsSidebar(!openSkuChildDetailsSidebar)
    setSkuForDetails(bundleSkuForDetails)
  }

  return (
    <SideSheet
      modalClassName="modal-slide-in bundle-sku-detail-modal"
      size="md"
      toggle={toggleBundleSkuModal}
      onOpened={onModalOpened}
      onClosed={onModalClosed}
      title="Kit Overview"
      {...rest}
    >
      <div className="m-24px overflow-auto">
        <div className="flex-center-start gap-24px">
          <div className="border rounded-8px">
            <img
              className="rounded-8px"
              src={
                bundleSkuForDetails.image || defaultImg
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = error_img
              }}
              alt="bundle-sku-image"
              width={99}
              height={99}
            />
          </div>
          <div className="kit-details-wrapper"> 
            <div className="text-primary txt-h3-md cursor-pointer text-wrap" onClick={handleBundleSkuDetailsSidebar}>
              {bundleSkuForDetails.name}
            </div> 
            <div className="txt-h3-rg dark-6 mt-12px">
              {bundleSkuForDetails.seller_sku_code}
            </div> 
          </div>
        </div>
        <div className="mt-30px">
          <ExpandableCustomTable
            data={bundleSkuForDetails?.child_skus || []} 
            columns={columns || []}
            showPagination={false}
            showColumnsTableHeader={false}
          />
        </div> 
      </div>       

      <SimpleSkuDetailModal
        isOpen={openSkuDetailsSidebar}
        skuForDetails={skuForDetails}
        toggleSimpleSkuModal={() => handleSkuDetailsSidebar(false)}
      />

      <SkuChildDetailModal
        skuForDetails={skuForDetails}
        toggle={handleBundleSkuDetailsSidebar}
        isOpen={openSkuChildDetailsSidebar}
        skuType={BUNDLE}
      />
    </SideSheet>
  )
}

export default BundleSkuDetailsSideSheet