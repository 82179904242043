import trackingImage from '@src/assets/images/orders/trackingOrder.svg'
import { memo } from 'react'
import { Card, CardTitle } from 'reactstrap'
import './detailsCard.scss'
const DetailsCard = (props) => {
  const { cardTitle, details } = props
  return (
    <Card className="p-0 m-0 border return-order-card-container bg-white rounded-4">
      <CardTitle className="card-title mb-0 txt-h3-md">
        {cardTitle}
      </CardTitle>
      <div className="card-body-container">
        {details.map((element) => (
          <div className="sto-row" key={element.key}>
            <div className="key">{element.key}</div>
            <span className="colon">:</span>
            <div className="value d-flex align-items-center gap-50">
              {element.url && <a href={element.url} target="_blank" rel="noreferrer"><img src={trackingImage} alt="tracking-image" /></a>}
              {element.value || '-'}
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
}
export default memo(DetailsCard)