import ImageActions from '@src/@core/components/image-preview-modal'
import SideSheet from '@src/@core/components/sideSheet'
import ComponentSpinner from '@src/@core/components/spinner/Loading-spinner'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import defaultImg from '@src/assets/images/catalog/bundlesku/default-img.svg'
import BarcodeListViewModal from '@src/components/barcode-view-modal'
import ability from '@src/configs/acl/ability'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ModalBody } from 'reactstrap'
import { BUNDLE } from '../../catalog.constants'
import { cleanupBundleChildSku, getBundleChildSku } from '../../store'
import SkuImageHandler from '../SkuImageHandler'
import SimpleSkuDetailModal from '../simple-sku-detail-modal/SimpleSkuDetailModal'
import SkuChildTable from './SkuChildTable'
import SkuDetail from './SkuDetail'
import './styles.scss'

const SkuChildDetailModal = ({
  skuForDetails,
  toggle,
  skuType,
  isArchive = false,
  ...rest
}) => {
  const [uploadedImages, setUploadedImages] = useState([])
  const [currentSelectedImage, setCurrentSelectedImage] = useState(0)
  const [singleChild, setSingleChild] = useState({})
  const [isSimpleSkuModalOpen, setIsSimpleSkuModalOpen] = useState(false)
  const [isImageActionModalOpen, setIsImageActionModalOpen] = useState(false)
  const [barcodeList, setBarcodeList] = useState([])
  const [barcodeModalOpen, setBarcodeModalOpen] = useState(false)
  const { t } = useTranslation()
  const imageRef = useRef(null)
  const dispatch = useDispatch()

  const bundleChildSkuDetails = useSelector((state) => state.catalog.bundleChildSku)
  const loading = useSelector((state) => state.catalog.skuLoading)
  // for pos
  const isPosEnabled = useSelector(store => store.auth.userData?.user_plan?.is_pos_enabled)

  const onModalOpened = () => {
    dispatch(getBundleChildSku({ params: {sellerId: skuForDetails.seller_id, sku_id: skuForDetails.id}, query: {is_archived: isArchive ? `${isArchive}` : false }, isPosEnabled }))
  }

  const onModalClosed = () => {
    dispatch(cleanupBundleChildSku())
    setUploadedImages([])
    setCurrentSelectedImage(0)
  }

  useEffect(() => {
    const mappedImages = skuForDetails.images?.map((ele) => {
      return { value: ele.default }
    }) || []
    setUploadedImages(mappedImages)

  }, [bundleChildSkuDetails.id])

  return (
    <div>
      <SideSheet
        modalClassName='modal-slide-in child-detail-sku-modal'
        size='md'
        onOpened={onModalOpened}
        onClosed={onModalClosed}
        backdrop={true}
        toggle={toggle}
        title={`${skuType === BUNDLE ? 'Kit' : 'Assembled Kit'} Details `}
        {...rest}
      >
        {loading ? <ComponentSpinner /> : 
          <ModalBody>
            <div className='sku-child-detail-modal'>
              <div className='d-flex flex-column gap-30px p-24px'>
                <div className='d-flex gap-2'>
                  <div>
                    <SkuImageHandler
                      uploadedImages={uploadedImages}
                      currentSelectedImage={currentSelectedImage}
                      imageRef={imageRef}
                      setCurrentSelectedImage={setCurrentSelectedImage}
                      setUploadedImages={setUploadedImages}
                      isView={true}
                    />
                  </div>
                  <SkuDetail sku={bundleChildSkuDetails} t={t} skuType={skuType} setBarcodeList={setBarcodeList} setBarcodeModalOpen={setBarcodeModalOpen} barcodeModalOpen={barcodeModalOpen} />
                </div>
 
                <div className='d-flex flex-column gap-2'>
                  <div>{t('Added SKUs')}</div>
                  <SkuChildTable
                    sku={bundleChildSkuDetails || skuForDetails}
                    t={t}
                    defaultImg={defaultImg}
                    setIsSimpleSkuModalOpen={setIsSimpleSkuModalOpen}
                    setSingleChild={setSingleChild}
                    skuType={skuType}
                    loading={loading}
                    isArchive={isArchive}
                  />
                </div>
 
                {ability.can(abilityMap.product.view_price.action, abilityMap.product.view_price.resource) && <div>
                  <div className='d-flex gap-5 align-items-center'>
                    <div className='d-flex gap-1 flex-column w-100'>
                      <p className='txt-body-md text-dark-6 text-end w-100 m-0'>{skuType === BUNDLE ? t('Kit Retail Price') : t('Assembled Kit Retail Price')}</p>
                      <p className='txt-h3-sb text-dark text-end m-0'>{skuType === BUNDLE ? t('Kit Selling Price') : t('Assembled Kit Selling Price')}</p>
 
                    </div>
                    <div className='d-flex gap-1 flex-column'>
                      <div className='d-flex gap-8px align-items-center justify-content-end'>
                        <p className='txt-body-md text-dark-6 text-end m-0'>
                          {skuForDetails.retail_price >= 0 ? `${skuForDetails.retail_price.toFixed(2)}` : '-'}
                        </p>
                        <p className='txt-body-md text-dark-6 text-end m-0'>
                          {skuForDetails.selling_price >= 0 ? bundleChildSkuDetails.currency : null}
                        </p>
                      </div>
                      <div className='d-flex gap-4px align-items-center justify-content-end'>
                        <p className='text-dark txt-h3-sb text-end m-0'>
                          {skuForDetails.selling_price >= 0 ? `${skuForDetails.selling_price.toFixed(2)}` : '-'}
                        </p>
                        <p className='text-dark txt-h3-sb text-end m-0'>
                          {skuForDetails.selling_price >= 0 ? bundleChildSkuDetails.currency  : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </ModalBody>
        }
      </SideSheet>
      <BarcodeListViewModal
        modalOpen={barcodeModalOpen}
        toggleSidebar={() => setBarcodeModalOpen(false)}
        data={barcodeList}
      />
      <SimpleSkuDetailModal
        isOpen={isSimpleSkuModalOpen}
        skuForDetails={singleChild}
        toggleSimpleSkuModal={() => setIsSimpleSkuModalOpen(false)}
      />
      <ImageActions
        isOpen={isImageActionModalOpen}
        toggle={() => setIsImageActionModalOpen(false)}
        currentInView={0}
        imgArray={uploadedImages}
        // imageUploadHandler={imageUploadHandler}
        setCurrentSelectedImage={setCurrentSelectedImage}
        currentSelectedImage={currentSelectedImage}
        uploadedImages={uploadedImages}
        // handleImageRemover={handleImageRemover}
        imageRef={imageRef}
        isView={true}
      />
    </div>
  )
}

export default SkuChildDetailModal
