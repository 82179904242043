import CustomTag from '@src/@core/components/badge/CustomTag'
import { API_ENDPOINTS } from '@src/api.urls'
import { API_KEYS, BULK_SHIP_TABS_DATA } from './bulk-ship.constants'

export const getClusterUrl = (clusterTab) => {
  switch (clusterTab) {
  case BULK_SHIP_TABS_DATA.SUGGESTIONS.id: return API_ENDPOINTS.BULK_SHIP.FETCH_SUGGESTED_CLUSTERS
  case BULK_SHIP_TABS_DATA.SAVED_SUGGESTIONS.id: return API_ENDPOINTS.BULK_SHIP.FETCH_DRAFT_CLUSTERS
  case BULK_SHIP_TABS_DATA.CLUSTERS.id: return API_ENDPOINTS.BULK_SHIP.FETCH_CLUSTERS
  default: return ''
  }
}

export const getClusterKey = (clusterTab) => {
  switch (clusterTab) {
  case BULK_SHIP_TABS_DATA.SUGGESTIONS.id: return API_KEYS.FETCH_SUGGESTED_CLUSTERS
  case BULK_SHIP_TABS_DATA.SAVED_SUGGESTIONS.id: return API_KEYS.FETCH_DRAFT_CLUSTERS
  case BULK_SHIP_TABS_DATA.CLUSTERS.id: return API_KEYS.FETCH_CLUSTERS
  default: return ''
  }
}

export const bulkShipmentStatus = (status) => {
  switch (status) {
  case 'in_picking':
    return <CustomTag title='In Picking'  className='text-warning bg-warning-light'/>
  case 'pending':
    return <CustomTag title='Pending' className='text-dark bg-dark-1'/>
  case 'shipped':
    return <CustomTag title='Shipped' className='text-success bg-success-light'/>
  case 'packed':
    return <CustomTag title='Packed' className='text-info bg-info-light'/>
  default:
    return '-'
  }
}