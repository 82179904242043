import BarcodeScanner from '@src/@core/components/barcodeScanner'
import CustomToast from '@src/@core/components/custom-toast/CustomToast'
import ToggleTabs from '@src/@core/components/toggleTabs'
import { KEYBOARD_KEYS } from '@src/App.constants'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { clearAddItemInGrnBinResponse, clearBinStatus, clearLastScannedBin, clearLastScannedSKUComplete, clearQcPrevData, clearScannedSkuDetails, getSkuDetails, setBinDisabledGrnTableData, setScannedSkuDetails, updateIsShelfLifeBreached } from '@src/views/inventory/store'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BATCH_STATUS, SEARCH_SKU_TYPES, SERIALISATION_OPTION } from '../constant'
import NoSkuScannedState from './NoSkuScannedState'
import SkuComponent from './SkuComponent'
import OverReceiveItemModal from './qc-component/overReceiveItemModal'
import SearchSkuByCode from './qc-component/searchSkuByCode'
import SkuNotPartOfOrderModal from './qc-component/skuNotPartOfOrderModal'

const SkuScan = forwardRef(({ control, reset, watch, setValue, register, searchBarcode, grnType }, ref) => {

  const { t } = useTranslation()
  const selectedGlobalHubID = useSelector(
    (store) => store.auth.selectedGlobalHubId
  )
  const skuScanLoading = useSelector((store) => store.inventory.loadingState.getSkuDetails)
  const skuScanFailed = useSelector((store) => store.inventory.errorState.getSkuDetails)
  const sku_details = useSelector(store => store.inventory.grn.sku_details?.data)
  const sku_details_loading = useSelector(store => store.inventory.grn.sku_details_loading)
  const grnResponse = useSelector(store => store.inventory.grn.grnResponse)
  const qcBinBarcodeRef = useRef(null)
  const configurations = useSelector(store => store.auth.selectedHubConfiguration)
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled
  const tableData = useSelector(store => store.inventory.binDisabledGrn.tableData)
  const dispatch = useDispatch()
  const [isBarcodeScanned, setIsBarcodeScanned] = useState(false)
  const [isOverReceiveModalOpen, setIsOverReceiveModalOpen] = useState(false)
  const [isSkuNotExistModalOpen, setIsSkuNotExistModalOpen] = useState(false)
  const displayGrnDetails = useSelector(store => store.inventory.grn.displayGrnDetails)
  const singleGrnDetail = useSelector(
    (state) => state.inventory.grn.singleGrnDetail
  )
  const entityID = singleGrnDetail?.is_stock_transfer_order ? singleGrnDetail.entity_id : singleGrnDetail?.is_purchase_order && !singleGrnDetail.is_po_over_receive ? singleGrnDetail.entity_id : undefined
  const entityType = singleGrnDetail?.is_stock_transfer_order ? singleGrnDetail.entity_type : singleGrnDetail?.is_purchase_order && !singleGrnDetail.is_po_over_receive ? singleGrnDetail.entity_type : undefined
  
  const {mutate: updateBinDisabledSkuDetails} = useApi({
    isMutation:true,
    apiKey:'update-Bin-Disabled-Sku-Details-by-barcode-non-serialised-skus',
    apiFn:({passQty, reasons}) => {
      const reasonsToSend = Array.isArray(reasons) && reasons?.length ? 
        reasons.map((reason) => ({
          ...reason,
          images:[],
          upload_ids:[]
        }))
        : []

      const skuInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id)

      const body = {
        pass_quantity: (parseInt(passQty) + 1),
        seller_sku_code: sku_details?.seller_sku_code,
        over_receive: isOverReceiveModalOpen || !!skuInTable?.allow_over_receive,
        return_order_id: undefined,
        reasons:reasonsToSend
      }
      return axiosInstance.post(`/api/v1/wms/hubs/${grnResponse?.hub_id}/grns/${grnResponse.id}`, body)
    },
    onSuccess:(data) => {
      if (data?.data?.is_over_receive || data?.data?.is_sku_not_exist) {
        if (data?.data?.is_over_receive) {
          setIsOverReceiveModalOpen(true)
        } else {
          setIsSkuNotExistModalOpen(true)
        }
      } else {  
        const isSkuPresentInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id)
        if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isSkuPresentInTable) {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              pass_qty: 1,
              reasons:[],
              scanned_qty: 1
            }
          }))
  
          const dataToPush = {
            id: sku_details?.id,
            name: sku_details?.name,
            code: sku_details?.seller_sku_code,
            image: sku_details?.images?.[0]?.url,
            is_serialised: sku_details?.sku_config?.is_serialisation_enable,
            seller_sku_id: sku_details?.seller_sku_id,
            pass_qty: 1,
            fail_qty: 0,
            total_qty: 0,
            scanned_qty: 1,
            purchase_order_id: sku_details?.purchase_order_id,
            seller_id: sku_details?.seller_id,
            reasons:[]
          }
          const updateTableData = [...tableData, dataToPush]
          dispatch(setBinDisabledGrnTableData(updateTableData))
        } else {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              pass_qty:sku_details?.pass_qty + 1,
              scanned_qty:sku_details?.scanned_qty + 1
            }
          }))

          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === sku_details?.seller_sku_id ? { ...item, pass_qty: item.pass_qty + 1, scanned_qty: item.scanned_qty + 1 } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        }
        CustomToast('1 Item passed successfully', { my_type: 'success', duration:2000 })
      }
    }
  })

  const { mutate: updateBinDisabledSkuDetailsFromModal, isPending:loadingState } = useApi({
    isMutation: true,
    apiKey: 'update-over-or-not-exist-Bin-Disabled-Sku-Details-by-barcode-non-serialised-skus',
    apiFn: () => {
      const reasonsToSend = Array.isArray(sku_details?.reasons) && sku_details?.reasons?.length ? 
        sku_details.reasons.map((reason) => ({
          ...reason,
          images:[],
          upload_ids:[]
        }))
        : []

      const body = {
        pass_quantity: (parseInt(sku_details?.pass_qty) + 1),
        seller_sku_code: sku_details?.seller_sku_code,
        over_receive: isOverReceiveModalOpen || isSkuNotExistModalOpen,
        return_order_id: undefined,
        reasons: reasonsToSend
      }
      return axiosInstance.post(`/api/v1/wms/hubs/${grnResponse?.hub_id}/grns/${grnResponse.id}`, body)
    },
    onSuccess: () => {
      if (isSkuNotExistModalOpen) {
        const isSkuPresentInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id) 
        if (isSkuPresentInTable) {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              pass_qty:sku_details?.pass_qty + 1,
              scanned_qty:sku_details?.scanned_qty + 1
            }
          }))
  
          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === sku_details?.seller_sku_id ? { ...item, pass_qty: item.pass_qty + 1, scanned_qty: item.scanned_qty + 1 } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        } else {
          dispatch(setScannedSkuDetails({
            data: {
              ...sku_details,
              pass_qty: 1,
              scanned_qty: 1,
              reasons:[]
            }
          }))
  
          const dataToPush = {
            id: sku_details?.id,
            name: sku_details?.name,
            code: sku_details?.seller_sku_code,
            image: sku_details?.images?.[0]?.url,
            is_serialised: sku_details?.sku_config?.is_serialisation_enable,
            seller_sku_id: sku_details?.seller_sku_id,
            pass_qty: 1,
            scanned_qty: 1,
            fail_qty: 0,
            total_qty: sku_details?.quantity || 0,
            purchase_order_id: sku_details?.purchase_order_id,
            seller_id: sku_details?.seller_id,
            reasons:[]
          }
          const updateTableData = [...tableData, dataToPush]
          dispatch(setBinDisabledGrnTableData(updateTableData))
        }
      }

      if (isOverReceiveModalOpen) {

        dispatch(setScannedSkuDetails({
          data: {
            ...sku_details,
            pass_qty:sku_details?.pass_qty + 1,
            scanned_qty:sku_details?.scanned_qty + 1
          }
        }))

        const updateTableData = tableData.map((item) => {
          return item.seller_sku_id === sku_details?.seller_sku_id ? { ...item, pass_qty: item.pass_qty + 1, scanned_qty: item.scanned_qty + 1, allow_over_receive: true } : item
        })
        dispatch(setBinDisabledGrnTableData(updateTableData))
      }
      
      CustomToast('1 Item passed successfully', { my_type: 'success', duration:2000 })
      setIsSkuNotExistModalOpen(false)
      setIsOverReceiveModalOpen(false)
    }
  })

  const {mutate:getUpdatedSkuDetails} = useApi({
    isMutation:true,
    apiKey:'get-Updated-Sku-Details-by-barcode-non-serialised-skus',
    apiFn:() => {
      return axiosInstance.get(`/api/v1/wms/hubs/${grnResponse?.hub_id}/grns/${grnResponse?.id}/skus/${sku_details?.seller_sku_id}`)
    },
    onSuccess:(data) => {
      if (sku_details?.sku_config?.is_batching_enable === false) {
        const previousSkuDetails = tableData?.find(item => item.seller_sku_id === sku_details?.seller_sku_id)
        dispatch(setScannedSkuDetails({
          data:{
            tenant_id: sku_details?.tenant_id,
            seller_id: sku_details?.seller_id,
            seller_sku_id: sku_details?.seller_sku_id,
            seller_sku_code: sku_details?.seller_sku_code,
            images: sku_details?.images,
            barcodes: sku_details?.barcodes || [],
            name: sku_details?.name,
            weight:sku_details?.weight,
            package_type: sku_details?.package_type,
            child_sku: sku_details?.package_level_skus?.[0],
            serialisation_status: sku_details?.sku_config?.is_serialisation_enable === null ? 'undefined' : sku_details?.sku_config?.is_serialisation_enable === true ? SERIALISATION_OPTION.serialised.id : SERIALISATION_OPTION.non_serialised.id,
            batch_status: sku_details?.sku_config?.is_batching_enable === null ? 'undefined' : BATCH_STATUS.NOT_BATCHED,
            is_configuration_editable: sku_details?.sku_config?.is_configuration_editable,
            dimensions: sku_details?.dimensions,
            pass_qty: previousSkuDetails ? previousSkuDetails.pass_qty : 0,
            fail_qty: previousSkuDetails ? previousSkuDetails.fail_qty : 0,
            reasons: previousSkuDetails ? previousSkuDetails.reasons : [],
            pass_barcodes: previousSkuDetails ? previousSkuDetails.pass_barcodes : [],
            fail_barcodes: previousSkuDetails ? previousSkuDetails.fail_barcodes : [],
            grn_details:data?.data?.grn_details,
            scanned_qty: previousSkuDetails ? (previousSkuDetails.pass_qty + previousSkuDetails.fail_qty) : 0
          }}
        ))
      } else if (sku_details?.sku_config?.is_batching_enable === true) {
        dispatch(setScannedSkuDetails({
          data: {
            tenant_id: sku_details?.tenant_id,
            seller_id: sku_details?.seller_id,
            seller_sku_id: sku_details?.seller_sku_id,
            seller_sku_code: sku_details?.seller_sku_code,
            images: sku_details?.images,
            barcodes: sku_details?.barcodes || [],
            name: sku_details?.name,
            weight:sku_details?.weight,
            package_type: sku_details?.package_type,
            child_sku: sku_details?.package_level_skus?.[0],
            serialisation_status: sku_details?.sku_config?.is_serialisation_enable === null ? 'undefined' : sku_details?.sku_config?.is_serialisation_enable === true ? SERIALISATION_OPTION.serialised.id : SERIALISATION_OPTION.non_serialised.id,
            batch_status: BATCH_STATUS.BATCHED,
            is_configuration_editable: sku_details?.sku_config?.is_configuration_editable,
            dimensions: sku_details?.dimensions,
            pass_qty: 0,
            fail_qty: 0,
            pass_barcodes:[],
            batch:{},
            fail_barcodes:[],
            grn_details:data?.data?.grn_details,
            scanned_qty: 0,
            min_shelf_life: sku_details?.sku_config?.min_shelf_life
          }}
        ))
      } else {
        dispatch(setScannedSkuDetails({
          data: {
            tenant_id: sku_details?.tenant_id,
            seller_id: sku_details?.seller_id,
            seller_sku_id: sku_details?.seller_sku_id,
            seller_sku_code: sku_details?.seller_sku_code,
            images: sku_details?.images,
            barcodes: sku_details?.barcodes || [],
            name: sku_details?.name,
            weight:sku_details?.weight,
            package_type: sku_details?.package_type,
            child_sku: sku_details?.package_level_skus?.[0],
            serialisation_status: undefined,
            batch_status: undefined,
            is_configuration_editable: sku_details?.sku_config?.is_configuration_editable,
            dimensions: sku_details?.dimensions,
            pass_qty: 0,
            fail_qty: 0,
            pass_barcodes:[],
            fail_barcodes:[],
            grn_details:[],
            scanned_qty: 0
          }}
        ))
      }

      if (sku_details?.sku_config?.is_batching_enable !== null && sku_details?.sku_config?.is_serialisation_enable !== null) {
        if (!sku_details?.sku_config?.is_batching_enable && !sku_details?.sku_config?.is_serialisation_enable) {
          updateBinDisabledSkuDetails({passQty:data?.data?.grn_details?.[0]?.pass_quantity || 0, reasons:data?.data?.grn_details?.[0]?.reasons})
        }
      }
    }
  })

  const [selectedTabId, setSelectedTabId] = useState(
    SEARCH_SKU_TYPES.sku_barcode.id
  )

  const tabChangeHandler = (tab) => {
    setSelectedTabId(tab.id)
    setValue('search_barcode', '')
    setValue('selected_batch', null)
    setValue('omniful-sku-barcode', '')
    dispatch(clearAddItemInGrnBinResponse())
    dispatch(clearBinStatus())
    dispatch(clearQcPrevData())
    dispatch(clearScannedSkuDetails())
    // setTotalItemQtyToScan(1)
    reset()
  }

  const handleSearchBarcode = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      if (e.target.value === '') {
        CustomToast(('Please enter a barcode'), { my_type: 'error' })
        return
      }
      dispatch(updateIsShelfLifeBreached(false))
      if (isBinDisabled) {
        setValue('selected_batch', null)
        dispatch(clearAddItemInGrnBinResponse())
        dispatch(clearLastScannedSKUComplete())
        setValue('omniful-sku-barcode', '')
        dispatch(clearScannedSkuDetails())
        dispatch(
          getSkuDetails({
            sku_barcode: e.target.value.trim(),
            seller_id: grnResponse?.seller?.id,
            hub_id: selectedGlobalHubID,
            grn_id: grnResponse?.id,
            entity_id: entityID,
            entity_type:entityType
          })
        )
        setIsBarcodeScanned(true)
      } else {
        const rawBarcode = e.target.value.trim();
        let decodedBarcode;
  
        try {
          decodedBarcode = decodeURIComponent(rawBarcode);
        } catch (error) {
          // can put a cutom toast to show user that barcode format is invalid but need to confirm with PM
          console.log(error.message, '- Invalid Barcode Format')
          return;
        }

        setValue('selected_batch', null)
        dispatch(clearAddItemInGrnBinResponse())
        dispatch(clearLastScannedSKUComplete())
        dispatch(clearBinStatus())
        // setTotalItemQtyToScan(1)
        setValue('omniful-sku-barcode', '')
        setValue('scanned-bin', '')
        dispatch(clearScannedSkuDetails())
        dispatch(
          getSkuDetails({
            sku_barcode: decodedBarcode,
            seller_id: grnResponse?.seller?.id,
            hub_id: selectedGlobalHubID,
            grn_id: grnResponse?.id,
            entity_id: entityID,
            entity_type:entityType
          })
        )
      }
    }
  }

  const handleClearSearchBarcode = () => {
    setValue('search_barcode', '')
    setValue('omniful-sku-barcode', undefined)
    setValue('scanned-bin', '')
    setValue('search_sku', '')
    setValue('selected_batch', null)
    // setTotalItemQtyToScan(1)
    dispatch(clearAddItemInGrnBinResponse())
    dispatch(clearBinStatus())
    dispatch(clearScannedSkuDetails())
  }

  const handleChangeQty = () => {
    setIsOverReceiveModalOpen(p => !p)
  }

  const handleSaveAnyway = () => {
    updateBinDisabledSkuDetailsFromModal()
  }

  const handleSkipSku = () => {
    setValue('search_sku', '')
    setValue('search_barcode', '')
    setValue('selected_batch', null)
    setIsSkuNotExistModalOpen(false)
    dispatch(clearScannedSkuDetails())
  }

  useEffect(() => {
    if (!isBinDisabled) {
      if (sku_details) {
        dispatch(clearLastScannedBin())
        qcBinBarcodeRef.current?.focus()
      }
      if (skuScanFailed) {
        setValue('search_barcode', '')
      }
    }
    if (isBinDisabled) {
      if (skuScanFailed) {
        setValue('search_barcode', '')
        ref.current?.focus()
      }
    }
  }, [sku_details, skuScanFailed])

  useEffect(() => {
    if (isBinDisabled) {
      if (sku_details?.seller_sku_id && isBarcodeScanned && !skuScanFailed) {
        setIsBarcodeScanned(false)
        getUpdatedSkuDetails()
        setValue('search_barcode', '')
        ref.current?.focus()
      }
    }
  }, [sku_details])

  return (
    <>
      <div className="d-flex flex-column gap-16px">
        <ToggleTabs
          tabDataList={Object.values(SEARCH_SKU_TYPES)}
          selectedTabId={selectedTabId}
          onTabChange={tabChangeHandler}
        />
        {SEARCH_SKU_TYPES.sku_barcode.id === selectedTabId && (
          <BarcodeScanner
            ref={ref}
            width="380px"
            startIcon={
              <img
                src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
                alt="QR Code"
                width="16px"
                height="16px"
              />
            }
            placeholder="Scan SKU Barcode"
            loading={skuScanLoading}
            control={control}
            name="search_barcode"
            onKeyDown={handleSearchBarcode}
            hasValue={watch('search_barcode')}
            handleClear={handleClearSearchBarcode}
          />
        )}
      
        {SEARCH_SKU_TYPES.search_sku.id === selectedTabId && (
          <SearchSkuByCode ref={ref} register={register} watch={watch} setValue={setValue} />
        )}

        {sku_details_loading ? <div className="d-flex justify-content-between rounded-8px bg-light-1 p-16px border border-success-light">
          <div className="d-flex flex-column gap-8px w-50">
            <div className="skeleton-effect height-20px rounded-30px skeleton-row-1"></div>
            <div className="skeleton-effect height-20px rounded-30px skeleton-row-2"></div>
            <div className="skeleton-effect height-20px rounded-30px skeleton-row-3"></div>
            <div className="skeleton-effect height-20px rounded-30px skeleton-row-4"></div>
          </div>
          <div className="skeleton-effect height-50px me-40px width-50 align-self-center rounded-8px"></div>
        </div> :
          sku_details ? <SkuComponent sku_details={sku_details} selectedTabId={selectedTabId} qcBinBarcodeRef={qcBinBarcodeRef} searchBarcode={searchBarcode} grnType={grnType}/>
            : (isBinDisabled ? <NoSkuScannedState /> : null)
        }
      </div>

      <OverReceiveItemModal
        isOpen={isOverReceiveModalOpen}
        toggle={() => setIsOverReceiveModalOpen(p => !p)}
        handleChangeQuantity={handleChangeQty}
        handleSaveAnyway={handleSaveAnyway}
        loading={loadingState}
      />

      <SkuNotPartOfOrderModal
        isOpen={isSkuNotExistModalOpen}
        toggle={() => setIsSkuNotExistModalOpen(p => !p)}
        handleSaveAnyway={handleSaveAnyway}
        handleSkipThisSku={handleSkipSku}
        loading={loadingState}
      />
    </>
  )
})

export default SkuScan

