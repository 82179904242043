import CustomLabel from '@src/@core/components/badge/CustomLabel'
import Button from '@src/@core/components/button'
import CopyOnClick from '@src/@core/components/click-and-copy'
import { ORDER_SOURCE, ORDER_TYPES, TENANT_TYPE } from '@src/App.constants'
import { EditPencil } from '@src/assets/data/assets'
import noSalesChannelImage from '@src/assets/images/omniful/no_sales_channel_image.svg'
import { INVENTORY_ROUTES } from '@src/views/inventory/inventory.constants'
import { DELIVERY_TYPE, ORDER_STATUS } from '@src/views/sales/constant/orders.constants'
import OrderTagsMapper from '@src/views/sales/live-orders/pages/packingOrderWise/StationPages/packingOrderTabsPages/OrderTagsMapper'
import AddFreeTags from '@src/views/sales/pending-actions/components/AddFreeTags/AddFreeTags'
import { PATH_NAME, REASONS_FOR_ON_HOLD } from '@src/views/sales/sales.constant'
import { OrderDetailsRow } from '@src/views/sales/sales.utils'
import classNames from 'classnames'
import { useMemo } from 'react'
import { Plus, RefreshCcw } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Card, UncontrolledTooltip } from 'reactstrap'

const BasicDetailsCard = ({ orderDetails, hasEditOrderDetailsPermission, toggleAddLocationAttributesModal, isOpenAddLocationAttributesModal, syncButtons, handleSyncPaymentStatus, handleNavigateToReturnOrders=()=>{}, handleOpenSplittedOrderDetailSideSheet, handleToggleAttachmentSidebar, hasEditAttachmentButton, handleShowSTODetail }) => {

  const { t } = useTranslation()

  const tenantType = useSelector(store => store.auth.userData.tenant.type)
  const sales_loading = useSelector((state) => state.sales.loading)

  // variables
  const currentPathname = window.location.pathname

  const deliveryType = useMemo(() => {
    return orderDetails?.delivery_type.toUpperCase()
  }, [orderDetails])

  const hide_parent_reason = useMemo(() => {
    const { reason } = orderDetails || {}
    return reason === REASONS_FOR_ON_HOLD.INVENTORY_NOT_PRESENT.label || reason === REASONS_FOR_ON_HOLD.SKU_NOT_VALID.label
  }, [orderDetails])

  const CustomIcon = () => {
    return <span className="text-primary">+{orderDetails?.tags?.length - 3}</span>
  }

  const RenderStoRequestId = () => {
    return (
      <CopyOnClick
        id={orderDetails.stock_transfer_request_details.id}
        handleDetails={currentPathname === PATH_NAME.stockTransferOrder || currentPathname === INVENTORY_ROUTES.STOCK_TRANSFER ? null : handleShowSTODetail}
        columnKey='sto_request_id'
      >
        {orderDetails.stock_transfer_request_details.display_id}
      </CopyOnClick>
    )
  }

  const RenderOrderTags = () => {
    return (
      <div className="d-flex flex-wrap gap-8px">
        <OrderTagsMapper customIcon={CustomIcon} tagsArray={orderDetails.tags} />
      </div>
    )
  }

  const RenderTags = () => {
    return (
      <div className="d-flex align-items-center flex-wrap gap-8px">
        {orderDetails.custom_labels?.length > 0 ?
          <>
            {hasEditOrderDetailsPermission && <div className='text-primary txt-sub-rg cursor-pointer bg-primary-lighter width-24px height-24px flex-center-center rounded-12px' onClick={toggleAddLocationAttributesModal}>
              <Plus size={16} />
            </div>}
            <OrderTagsMapper customIcon={() => <span className="text-primary">+{orderDetails.custom_labels?.length - 2}</span>} tagsArray={orderDetails.custom_labels} viewItems={2} />
          </>
          :
          <>
            {hasEditOrderDetailsPermission ?
              <Button className="p-0 shadow-none" ofStyle='noBackground' onClick={toggleAddLocationAttributesModal}>{t('Add Tag')}</Button>
              : '-'
            }
          </>
        }
        <AddFreeTags
          isOpen={isOpenAddLocationAttributesModal}
          toggle={toggleAddLocationAttributesModal}
          orderDetails={orderDetails}
        />
      </div>
    )
  }

  const RenderPaymentStatus = () => {
    return <div className="flex-center-start gap-12px">
      {orderDetails.payment_method} {syncButtons.sync_payment_status &&
        <>
          <Button
            id="sync_payment_status"
            className="p-4px rounded-4px"
            ofStyle="noBackground"
            onClick={handleSyncPaymentStatus}
            disabled={sales_loading.syncOrderPaymentStatus}
          >
            <RefreshCcw
              className={classNames({ 'sync-rotate-icon': sales_loading.syncOrderPaymentStatus })} size={16} color={sales_loading.syncOrderPaymentStatus ? 'var(--bs-primary-light)' : 'var(--bs-primary)'} />
          </Button>
          <UncontrolledTooltip offset={[0, 7]} autohide={false} target='sync_payment_status'>
            {t(`Syncing updates payment status and invoice details from ${orderDetails.sales_channel?.store_name}`)}
          </UncontrolledTooltip>
        </>}
    </div>
  }

  const RenderSalesChannel = () => {
    return (
      <span className='text-black'>
        <img
          width={60}
          height={20}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = noSalesChannelImage
          }}
          className="object-fit-contain rounded shadow-1"
          src={orderDetails.sales_channel?.logo_url || noSalesChannelImage}
          alt={orderDetails.sales_channel?.store_name}
        />
        <span className="ms-50">{orderDetails.sales_channel?.store_name}</span>
      </span>
    )
  }

  const RenderRefreshSalesChannel = () => {
    return (
      <div>
        <img
          width={60}
          height={20}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = noSalesChannelImage
          }}
          className="object-fit-contain rounded shadow-1"
          src={orderDetails.sales_channel.logo_url || noSalesChannelImage}
          alt={orderDetails.sales_channel.name}
        />
        <span className="ms-50">{orderDetails.sales_channel.name}</span>
      </div>
    )
  }

  const RenderAttachment = () => {
    return (
      orderDetails.attachments?.length > 0 ?
        <span className="text-primary cursor-pointer" onClick={handleToggleAttachmentSidebar}>
          {`${orderDetails.attachments.length} File${orderDetails.attachments.length > 1 ? 's' : ''}`} {hasEditOrderDetailsPermission && hasEditAttachmentButton && <EditPencil width={'14px'} height={'14px'} />}
        </span>
        :
        hasEditOrderDetailsPermission && hasEditAttachmentButton &&
        <Button className="p-0 shadow-none" ofStyle='noBackground' onClick={handleToggleAttachmentSidebar}>{t('Attach Files')}</Button>
    )
  }

  const basicDetailsData = [
    {
      id: 'sto_request_id',
      entityName: 'STO Request ID',
      entityValue: <RenderStoRequestId/>,
      omit: orderDetails.type !== ORDER_TYPES.STO.value || !orderDetails.stock_transfer_request_details.display_id
    },
    {
      id: 'order_tags',
      entityName: 'Order Tags',
      entityValue: <RenderOrderTags/>,
      omit: orderDetails.tags.length <= 0
    },
    {
      id: 'created_at',
      entityName: 'Created At',
      entityValue: orderDetails.order_created_at,
    },
    {
      id: 'tags',
      entityName: 'Tags',
      entityValue: <RenderTags/>,
      omit: orderDetails.type === ORDER_TYPES.RTS.value
    },
    {
      id: 'ordered_quantity',
      entityName: 'Ordered Quantity',
      entityValue: orderDetails.ordered_quantity,
    },
    {
      id: 'number_of_items',
      entityName: 'Number of Items',
      entityValue: orderDetails.total_item_count
    },
    {
      id: 'delivery_date',
      entityName: 'Delivery Date',
      entityValue: orderDetails.slot.display_date,
      omit: !orderDetails.slot?.display_date
    },
    {
      id: 'delivery_slot',
      entityName: 'Delivery Slot',
      entityValue: `${orderDetails.slot.display_start_time} - ${orderDetails.slot.display_end_time}`,
      omit: !orderDetails.slot?.display_date
    },
    {
      id: 'payment_status',
      entityName: 'Payment Status',
      entityValue: <RenderPaymentStatus/>,
      omit: !orderDetails.payment_method
    },
    {
      id: 'payment_mode',
      entityName: 'Payment Mode',
      entityValue: orderDetails.invoice.payment_mode,
      omit: !orderDetails.invoice.payment_mode
    },
    {
      id: 'hub_name',
      entityName: 'Hub Name',
      entityValue: orderDetails.status === ORDER_STATUS.ON_HOLD.name? orderDetails.assigned_hub?.name : orderDetails.hub?.name,
      omit: orderDetails.status === ORDER_STATUS.ON_HOLD.name && !orderDetails.assigned_hub?.name
    },
    {
      id: 'seller_name',
      entityName: 'Seller Name',
      entityValue: orderDetails.seller_name || '-',
      omit: tenantType === TENANT_TYPE.SELF_USAGE
    },
    {
      id: 'source',
      entityName: 'Source',
      entityValue: orderDetails.display_source,
    },
    {
      id: 'sales_channel',
      entityName: 'Sales Channel',
      entityValue: <RenderSalesChannel/>,
      omit: orderDetails.source === ORDER_SOURCE.MANUAL.label
    },
    {
      id: 'return_status',
      entityName: 'Return Status',
      entityValue: <CustomLabel title={orderDetails.return_status} className="order-status-tag" />,
      omit: orderDetails.status === ORDER_STATUS.ON_HOLD.name || !orderDetails.return_status
    },
    {
      id: 'return_order_count',
      entityName: 'Return Order Count',
      entityValue: (
        <span className='text-primary cursor-pointer' onClick={handleNavigateToReturnOrders}>
          {orderDetails.return_order_count} {t('Return(s)')}
        </span>
      ),
      omit: orderDetails.status === ORDER_STATUS.ON_HOLD.name || !orderDetails.return_order_count
    },
    {
      id: 'delivery_attempts',
      entityName: 'Delivery Attempts',
      entityValue: orderDetails.shipment.number_of_retries,
      omit: orderDetails.status === ORDER_STATUS.ON_HOLD.name || orderDetails.shipment.number_of_retries <= 0
    },
    {
      id: 'parent_reason',
      entityName: 'Reason',
      entityValue: orderDetails.reason,
      omit: !orderDetails.reason || hide_parent_reason || orderDetails.status !== ORDER_STATUS.ON_HOLD.name
    },
    {
      id: 'reason_for_on_hold',
      entityName: 'Reason for On Hold',
      entityValue: orderDetails.forceful_onhold_reason,
      omit: !orderDetails.forceful_onhold_reason || orderDetails.status !== ORDER_STATUS.ON_HOLD.name
    },
    {
      id: 'parent_order_id',
      entityName: 'Parent Order ID',
      entityValue: (
        <span className='text-primary cursor-pointer' onClick={handleOpenSplittedOrderDetailSideSheet}>
          {orderDetails.parent_order_details.display_id}
        </span>
      ),
      omit: !orderDetails.is_split_order
    },
    {
      id: 'order_note',
      entityName: 'Note',
      entityValue: orderDetails.order_note,
      omit: !orderDetails.order_note
    },
    {
      id: 'reference_sales_channel',
      entityName: 'Reference Sales Channel',
      entityValue: <RenderRefreshSalesChannel/>,
      omit: !(orderDetails.source === ORDER_SOURCE.MANUAL.label && orderDetails.sales_channel.name)
    },
    {
      id: 'shipping_method',
      entityName: 'Shipping Method',
      entityValue: `${orderDetails.ssc_order_details.shipping_company} (${t('Sales Channel')})`,
      omit: !orderDetails.ssc_order_details.shipping_company
    },
    {
      id: 'delivery_type',
      entityName: 'Delivery Type',
      entityValue: DELIVERY_TYPE[deliveryType]?.label,
      omit: !(orderDetails.delivery_type && orderDetails.delivery_type !== DELIVERY_TYPE.NORMAL_DELIVERY.name)
    },
    {
      id: 'partial_fulfillment',
      entityName: 'Partial Fulfillment',
      entityValue: t('Allowed'),
      omit: !orderDetails.is_partial_fulfillment_allowed
    },
    {
      id: 'attachment',
      entityName: 'Attachment',
      entityValue: <RenderAttachment/>,
      omit: !(hasEditOrderDetailsPermission && hasEditAttachmentButton)
    }
  ]

  return (
    <Card className="bg-white w-100 rounded-4 details-card mt-24px">
      <div className="card-header py-12px px-16px rounded-top-4 text-secondary txt-h3-md">
        <span>{t('Basic Details')}</span>
      </div>
      <div className="basic-details-row-wrapper">
        {
          basicDetailsData
            .filter((item) => !item.omit)
            .map((item) => (
              <OrderDetailsRow key={item.id} entityNameColSpan={4} entityValueColSpan={8} entityName={item.entityName} entityValue={item.entityValue || '-'} />
            ))
        }
      </div>
    </Card>
  )
}

export default BasicDetailsCard