import CustomToast from '@src/@core/components/custom-toast/CustomToast'
import DropdownWithTitle from '@src/@core/components/dropdown'
import { ExpandableCustomTable } from '@src/@core/components/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/globalTable/globalTable.constant'
import PageHeader from '@src/@core/components/page-header'
import { ADDITIONAL_FILTER_DROPDOWN_STYLE, TENANT_TYPE } from '@src/App.constants'
import { API_ENDPOINTS, getApiUrl } from '@src/api.urls'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import Inventory from '@src/assets/images/icons/primary-navbar/Inventory'
import FilterTabs from '@src/components/filterTabs'
import ability from '@src/configs/acl/ability'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { isObjEmpty } from '@src/utility/Utils'
import { useFilters, usePagination, useSearch, useSorting } from '@src/utility/hooks/useFilters'
import useClustersColumns from '@src/views/bulk-ship/hooks/use-clusters-columns'
import SimpleSkuDetailModal from '@src/views/catalog/components/simple-sku-detail-modal/SimpleSkuDetailModal'
import { getAllSellersAsync } from '@src/views/inventory/store'
import { SORT_ORDER } from '@src/views/sales/constant/orders.constants'
import SidebarContainer from '@src/views/sales/picking-wave/pages/sidebarContainer/SidebarContainer'
import { getPicklistsOfStatus } from '@src/views/sales/store/store'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import BundleSkuDetailsSideSheet from '../../../@core/components/bundle-sku-details'
import { API_KEYS, BULK_SHIP_TABS_DATA } from '../bulk-ship.constants'
import { setSelectedCluster } from '../store'
import { getClusterKey, getClusterUrl } from '../utils'
import ItemsListModal from './items-list-modal'

// !!TODO: open Single SKU details modal
// !!TODO: Seller Column key mapping
// !!TODO: Save Cluster API

const BulkShip = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const selectedGlobalHubId = useSelector(store => store.auth.selectedGlobalHubId)
  const tenantType = useSelector((state) => state.auth.userData.tenant.type)
  const picklistsOfStatus = useSelector((state) => state.sales.picklistsOfStatus)
  const selectedPicklistStatus = useSelector((state) => state.sales.selectedPicklistStatus)

  const [isWaveDetailsSidebarOpen, setIsWaveDetailsSidebarOpen] = useState(false)
  const [selectedWave, setSelectedWave] = useState(null)
  const [skuForDetails, setSkuForDetails] = useState({})
  const [openSkuDetailsSidebar, setOpenSkuDetailsSidebar] = useState(false)
  const [openBundleSkuDetailsSidebar, setOpenBundleSkuDetailsSidebar] = useState(false)
  const handleSkuDetailsSidebar = (data) => {
    if (data) {
      const formattedData = {
        ...data,
        sku_name: data.name,
        images: [{url: data.image, type: ''}]
      }
      setSkuForDetails(formattedData)
      setOpenSkuDetailsSidebar(true)
    } else {
      setOpenSkuDetailsSidebar(false)
    }
  }

  const handleBundleSkuDetailsSidebar = (data) => {
    if (data) {
      const modifiedData = {
        ...data,
        id: data.sku.seller_sku_id,
        name: data.skuName,
        seller_sku_code: data.skuCode,
        qty: data.display_quantity,
        avlQty: data.display_available_quantity,
        totalQtyToBePicked: data.display_total_quantity,
        child_skus: data.child_order_items
      }
      setSkuForDetails(modifiedData)
      setOpenBundleSkuDetailsSidebar(true)
    } else {
      setOpenBundleSkuDetailsSidebar(false)
    }
  }

  const pageHeaderProps = {
    breadcrumbIcon: Inventory,
    title: t('Bulk Ship'),
    breadcrumbsData: [{ title: 'Inventory' }, { title: 'Inventory Operations' }]
  }


  //***** Items List Modal Related */
  const [itemsListModalContent, setItemsListModalContent] = useState(null)
  //***** Items List Modal Related */

  const [searchParams, setSearchParams] = useSearchParams({ tab: BULK_SHIP_TABS_DATA.SUGGESTIONS.id })
  
  const bulkShipSearchCol = searchParams.get('tab') === BULK_SHIP_TABS_DATA.CLUSTERS.id  ? [{ id: 'name', name: 'Cluster ID' }] : [
    { id: 'name', name: 'SKU Name' }, 
    { id: 'seller_sku_code', name: 'SKU Code' }
  ]
  const { filter, setFilter } = useFilters({})
  const {sort, setSort} = useSorting({})
  const { searchQuery, setSearchQuery } = useSearch({ column: bulkShipSearchCol[0] })
  const { pagination, setPagination } = usePagination({page: 1, per_page: 20 })
  
  //***** API Related ^ */

  const API_KEY = getClusterKey(searchParams.get('tab'))
  const {
    data: clusters,
    isFetching,
    isError,
    isSuccess,
    refetch
  } = useApi({
    apiKey: [API_KEY, { tab: searchParams.get('tab'), pagination }],
    apiFn: () => {
      const params = {
        ...pagination, 
        seller_id: filter.seller_id?.value, 
        search_column:searchQuery.query ? searchQuery.column.id : null,
        search_query:searchQuery.query
      }
      if (!isObjEmpty(sort)) {
        params.sort = `${sort.field}_${sort.order}`
      }
      const apiUrl = getClusterUrl(searchParams.get('tab'))
      return axiosInstance.get(getApiUrl(apiUrl, { hubId: selectedGlobalHubId }), {params})
    },
    isSerializedData: true
  })
  //***** API Related $ */

  //***** Save Cluster API Related ^ */
  const {
    mutate: saveCluster
  } = useApi({
    isMutation: true,
    apiKey: [API_KEYS.SAVE_CLUSTER, { tab: searchParams.get('tab'), pagination }],
    apiFn: ({ order_hash }) => {
      const body = { order_hash }
      return axiosInstance.post(getApiUrl(API_ENDPOINTS.BULK_SHIP.SAVE_CLUSTER, { hubId: selectedGlobalHubId }), body)
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [API_KEYS.FETCH_DRAFT_CLUSTERS] })
      queryClient.refetchQueries({ queryKey: [API_KEYS.FETCH_SUGGESTED_CLUSTERS] })
      
      CustomToast('Suggestion saved successfully.', { my_type: 'success' })
    }
  })
  
  const {
    mutate: removeSavedCluster
  } = useApi({
    isMutation: true,
    apiKey: [API_KEYS.SAVE_CLUSTER, { tab: searchParams.get('tab'), pagination }],
    apiFn: ({ order_hash }) => {
      return axiosInstance.delete(getApiUrl(API_ENDPOINTS.BULK_SHIP.REMOVE_SAVED_CLUSTER, { hubId: selectedGlobalHubId, clusterId: order_hash }))
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [API_KEYS.FETCH_DRAFT_CLUSTERS] })
      queryClient.refetchQueries({ queryKey: [API_KEYS.FETCH_SUGGESTED_CLUSTERS] })
      CustomToast('Saved cluster removed successfully.', { my_type: 'success' })
    }
  })
  //***** API Related $ */

  //***** Table Related */

  const handleWaveDetailsSideSheet = (data) => {
    setSelectedWave(data)
    dispatch(getPicklistsOfStatus({ hubId: selectedGlobalHubId,  waveId: data.wave.id }))
  }
  
  const onNavigateToProcessCluster = selectedCluster => {
    dispatch(setSelectedCluster(selectedCluster))
  }
  
  const columns = useClustersColumns({ tab: searchParams.get('tab'), t, openItemsListModal: (data) => setItemsListModalContent({...data, isOpen: true}), onNavigateToProcessCluster, saveCluster, removeSavedCluster, handleWaveDetailsSideSheet, handleSkuDetailsSidebar, handleBundleSkuDetailsSidebar})

  const handleSort = (sortField) => {
    setSort(sortField)
  }
  
  const handleSearchItem = ({ searchVal, selectedColumn = null }) => {
    const searchQuery = searchVal ? { column: selectedColumn, query: searchVal } : { column: bulkShipSearchCol[0] }
    setSearchQuery(searchQuery)
  }
  
  const handlePagination = (page) => {
    const pagination = { page: page.selected, per_page: page.per_page ? page.per_page : 10 }
    setPagination(pagination)
  }

  const filterHandler = ({ filterKey, filterValue }) => {
    if (!filterValue) {
      delete filter[filterKey]
      setFilter(filter)
      return
    }
    if (filterKey === 'status') {
      setFilter({[filterKey]: filterValue})
      return
    }
    setFilter({ ...filter, [filterKey]: filterValue })
  }
  
  //***** Table Related */

  useEffect(() => {
    if (picklistsOfStatus.success && !isWaveDetailsSidebarOpen) {
      setIsWaveDetailsSidebarOpen(true)
    }
  }, [picklistsOfStatus.success])

  const defaultSortColumn = columns.find((column) => column.key === sort.field) || {}

  return (
    <>
      <PageHeader {...pageHeaderProps} />
      <FilterTabs
        tabDataList={Object.values(BULK_SHIP_TABS_DATA)}
        selectedTabId={searchParams.get('tab')}
        onTabChange={(changedTab) => {
          setSearchParams({ tab: changedTab.id  })
        }}
      />
      <div key={searchParams.get('tab')} className=''>
        <div className='p-16px d-flex flex-column gap-16px'>  
          {((ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource) && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE)) && <div>
            <DropdownWithTitle
              isAsync 
              isClearable={!!filter.seller_id} 
              value={!filter.seller_id ? {label: 'All', value: ''} : filter.seller_id}
              title="Sellers"
              loadOptionsHandler={getAllSellersAsync} 
              selectOptionHandler={(value) => filterHandler({filterKey: 'seller_id', filterValue: value})} 
              externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
            />
          </div>}
          <ExpandableCustomTable
            loading={isFetching}
            error={isError}
            success={isSuccess}
            isFilterOrSearchApplied={!!(Object.values(filter)?.length || Object.values(searchQuery)?.length > 1)}
            data={clusters?.data || []}
            columns={columns || []}
            columnName={ALL_COLUMN_NAME_MAPPING.BULK_SHIP_SUGGESTED_CLUSTERS}
            searchcolumnsList={bulkShipSearchCol}
            search_column_query={searchQuery}
            handleQueryParams={handleSearchItem}
            handlePagination={handlePagination}
            meta={clusters?.meta || {}}
            useReactPaginate={false}
            handleRefreshTable={refetch}
            showColumnsTableHeader={true}
            handleSort={handleSort}
            defaultSortFieldId={defaultSortColumn.id}
            defaultSortAsc={sort.order ? sort.order === SORT_ORDER.ASC : true}
          />
        </div>
      </div>
      <ItemsListModal t={t} isOpen={itemsListModalContent?.isOpen} items={itemsListModalContent?.order_items || itemsListModalContent?.cluster_items} toggle={setItemsListModalContent} handleSkuDetailsSidebar={handleSkuDetailsSidebar} handleBundleSkuDetailsSidebar={handleBundleSkuDetailsSidebar} />
      <SidebarContainer
        status={selectedPicklistStatus?.value}
        waveDetails={{wave: {
          waveId: selectedWave?.wave?.id,
          name: selectedWave?.wave?.name,
          time: selectedWave?.wave?.created_at
        }}}
        is_sidebar_open={isWaveDetailsSidebarOpen}
        set_is_sidebar_open={setIsWaveDetailsSidebarOpen}
        hub_id={selectedGlobalHubId}
      />
      <SimpleSkuDetailModal
        isOpen={openSkuDetailsSidebar} 
        skuForDetails={skuForDetails}
        toggleSimpleSkuModal={() => handleSkuDetailsSidebar(false)}
      />

      <BundleSkuDetailsSideSheet
        isOpen={openBundleSkuDetailsSidebar}
        bundleSkuForDetails={skuForDetails}
        toggleBundleSkuModal={() => handleBundleSkuDetailsSidebar(false)}
      />
    </>
  )
}

export default BulkShip
