import Button from '@src/@core/components/button'
import SidesheetFooter from '@src/@core/components/sidesheet-footer'
import { FILE_TYPES } from '@src/App.constants'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useState } from 'react'
import { Download } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import FileIcon from './file-icon'
import Preview from './preview/preview'

const PreviewAttachments = (props) => {
  const {addedAttachments} = props

  const {t} = useTranslation()
  const [isDownloading, setDownloading] = useState(false)
  const [previewingAttachment, setPreviewingAttachment] = useState(null)

  const handleSingleDownload = (item) => {
    fetch(item.file_url)
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(blob => {
        saveAs(blob, item.name)
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  const handleDownloadAll = async() => {
    setDownloading(true)
    if (addedAttachments.length < 6) {
      addedAttachments.forEach(item => {
        handleSingleDownload(item)
      })
      return
    }
    const zip = new JSZip();
    for (const item of addedAttachments) {
      try {
        const response = await fetch(item.file_url)
        const blob = await response.blob()
        zip.file(item.name, blob);
      } catch (error) {
        console.error(`Failed to fetch file: ${item.name}`, error);
      }
    }
    zip.generateAsync({ type: 'blob' })
      .then((content) => {
      // Use FileSaver.js to download the ZIP file
        saveAs(content, 'attachments.zip');
      })
      .catch((error) => {
        console.error('Failed to generate ZIP file', error)
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  const handleTogglePreview = (attachment) => {
    if (previewingAttachment) {
      setPreviewingAttachment(null)
    } else {
      setPreviewingAttachment(attachment)
    }
  }

  return (
    <div className="attachments-main-div w-100 d-flex flex-column gap-20px"> 
      <div className='d-flex flex-column gap-24px'>
        {
          addedAttachments?.map((item, index) => {
            const fileType = item.mime_type.split('/')[0] // Get the file type (image, video, text, etc.)
            const isPreviewAble = item.type.startsWith('image/') || item.type === FILE_TYPES.PDF.type
            const fileName = item.name
            const fileUrl = item.file_url
            return (
              <div className="d-flex align-items-center justify-content-center flex-column file_main_div min-width-300" key={index}>
                <div className="d-flex align-items-center justify-content-between upload_container_div gap-20px">
                  <div className="w-100 d-flex flex-column justify-content-between upload_file_details text-truncate gap-16px ms-0">
                    <div className={'flex-center-start gap-16px'}>
                      <div className={'h-100 flex-center-start'}>
                        <FileIcon fileType={fileType}/>
                      </div>
                      <div className='d-flex flex-column gap-8px w-100'>
                        <div className="file_title w-75 text-truncate txt-body-md" title={fileName} 
                          onClick={() => {
                            const properties = { name: item.name, type: item.type, file_url: fileUrl }
                            if (isPreviewAble) {
                              handleTogglePreview(properties)
                            } else {
                              handleSingleDownload(properties)
                            }
                          }}
                        >
                          {fileName}
                        </div>
                        {item.description && <div className="w-75 txt-sub-rg txt-dark-5 file_description text-wrap">
                          {item.description}
                        </div>}
                      </div>
                    </div>
                  </div>
                  <Download 
                    size={16} color="var(--bs-primary)"
                    onClick={() => handleSingleDownload(item)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            )
          })
        }
      </div>   
      <SidesheetFooter>
        <div className="flex-center-end gap-12px">
          {<Button ofStyle="outlined" onClick={handleDownloadAll} disabled={isDownloading} icon={isDownloading ? Spinner : null} iconSize="sm">
            {t('Download All')}
          </Button>}
        </div>
      </SidesheetFooter>  
      <Preview 
        isOpen={!!previewingAttachment} 
        toggle={handleTogglePreview} 
        previewingAttachment={previewingAttachment}
      />
    </div>
  )
}

export default PreviewAttachments