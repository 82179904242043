import Button from '@src/@core/components/button'
import CustomToast from '@src/@core/components/custom-toast/CustomToast'
import FloatingDropDown from '@src/@core/components/floating-dropdown'
import InputField from '@src/@core/components/input-field'
import RadioButton from '@src/@core/components/radio-button'
import { MANUAL_COURIER_PARTNER } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import PrintManualAwb from '@src/components/PrintManualAwb'
import { printMultipleAwb } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import classNames from 'classnames'
import { format, parse } from 'date-fns'
import { useContext, useEffect, useRef, useState } from 'react'
import { ArrowRight, Edit2, Info } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { components } from 'react-select'
import { Col, Form, Row, Spinner } from 'reactstrap'
import MultipleAwbSideSheet from '../components/MultipleAwbSideSheet/MultipleAwbSideSheet'
import OrderDetails from '../components/OrderDetails'
import ShippingPartner from '../components/ShippingPartner'
import ShippingDetails from '../components/shippingDetails'
import { CREATE_SHIPMENT_STATUS, PRINT_BUTTON_FOR_MANUAL_SHIPMENT, SHIPPING_ENTITY, SHIPPING_OPTION } from '../constant/orders.constants'
import PrintAwb from '../pending-actions/pages/orderDetails/orderDetailsTabPages/PrintAwb/PrintAwb'
import PrintDeliveryNote from '../pending-actions/pages/orderDetails/orderDetailsTabPages/PrintDeliveryNote'
import { EDIT_MODAL_TYPE, LIVE_ORDERS_TABS, SHIPPING_PARTNERS_TAG_NAMES } from '../sales.constant'
import { clearGetPrioritisedShippingPartners, createShipment, createShipmentOrderShipment, fetchAWB, getAWBDetail, getAsyncParcelShop, getCouriersPartners, getShippingPartnersAccounts, getSingleOrderDetails, updatePrefilledNumberOfPackages, updatePrefilledWeight } from '../store/store'
import CustomCourierPartnerOption from './customCourierPartnerOption'


const CustomDetailsOption = (props) => {
  const { data: { name, address } } = props
  const { Option } = components
  const {t} = useTranslation()
  return (
    <Option key={address} {...props}>
      <div className="pb-6px">
        <div className="fs-14px txt-body-md fw-bolder">{t(name)}</div>
        <div className='fs-11px txt-asst-rg mt-4px text-wrap'>{t(address)}</div>
      </div>
    </Option>
  )
}

const CreateShipmentComponents = (props) => {

  const {orderID, shippingDetailsData, sellerSalesChannelId,  omnifulGeneratedShipmentType, setOmnifulGeneratedShipmentType, handleEditDetailsModal, entity_name = '' } = props

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)
  const printManualAwbRef = useRef(null)
  const printDeliveryNoteRef = useRef(null)
  const [searchParams] = useSearchParams({})
  const [shippingPartnerOptions, setShippingPartnerOptions] = useState([{}])
  const { shipmentDetails, loading } = useSelector((store) => store.sales)
  const { singleOrderDetailsData } = useSelector((store) => store.sales)
  const orderDetails = singleOrderDetailsData?.data
  const tenantId = useSelector(store => store.auth.userData.id)
  const getPrioritisedShippingPartnersResonse = useSelector((state) => state.sales.getPrioritisedShippingPartnersRes)
  const {success} = useSelector((store) => store.sales)
  const [shipmentCreated, setShipmentCreated] = useState(false)
  const [printAwbLoading, setPrintAwbLoading] = useState(false)
  const [DBOrderId, setDBOrderId] = useState()
  const [manualPrintLoadingState, setManualPrintLoadingState] = useState('')
  const [isRemarkDisabled, setIsRemarkDisabled] = useState(false)
  const [multipleAwbModalOpen, setMultipleAwbModalOpen] = useState(false)
  const [multipleAwbList, setMultipleAwbList] = useState([])
  const [packageData, setPackageData] = useState([])

  const [isCityEditing, setIsCityEditing] = useState(false)

  const [createShipmentStatus, setCreateShipmentStatus] = useState(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_UNSELECTED)
  const defaultCountry = { value: shippingDetailsData.entity_data.hub.address.country_id, label: shippingDetailsData.entity_data.hub.address.country }
  const defaultCity = { value: shippingDetailsData.entity_data.hub.address.city_id, label: shippingDetailsData.entity_data.hub.address.city }
  const omnifulCountry = { value: shippingDetailsData.entity_data.shipping_address.omniful_country_id, label: shippingDetailsData.entity_data.shipping_address.omniful_country }
  const omnifulCity = { value: shippingDetailsData.entity_data.shipping_address.omniful_city_id, label: shippingDetailsData.entity_data.shipping_address.omniful_city}
  const preFilledWeight = useSelector(state => state.sales.preFilledWeight)
  const preFilledNumberOfPackages = useSelector(state => state.sales.preFilledNumberOfPackages)
  const weightFromShippingDetailsData = shippingDetailsData.entity_data.weight > 0 ? parseFloat(shippingDetailsData.entity_data.weight) : 1
  const numberOfBoxesFromShippingDetailsData = shippingDetailsData.entity_data.number_of_packages ? parseInt(shippingDetailsData.entity_data.number_of_packages) : 1
  const initialWeight = typeof preFilledWeight === 'number' && preFilledWeight > 0 ? preFilledWeight : weightFromShippingDetailsData
  const initialNumberOfBoxes = preFilledNumberOfPackages || numberOfBoxesFromShippingDetailsData

  const handleAwb = async () => {
    setPrintAwbLoading(prev => !prev)
    if (shipmentDetails?.data?.metadata?.shipping_awb_label) {
      printMultipleAwb(shipmentDetails.data.metadata.shipping_awb_label)
    } else {
      if (entity_name === SHIPPING_ENTITY.SHIPMENT_ORDER.value) {
        const params = {
          entity_id: shipmentDetails?.data?.oms_order_id,
          entity_type: SHIPPING_ENTITY.SHIPMENT_ORDER.value
        }
        const res = await fetchAWB({ params })
        if (res?.is_success && res?.data?.awb_label) {
          printMultipleAwb(res.data.awb_label)
        }
      } else {
        const res = await getAWBDetail(shipmentDetails?.data?.oms_order_id)
        if (res?.is_success && res?.data?.awb_label) {
          printMultipleAwb(res.data.awb_label)
        }
      }
    }
    setPrintAwbLoading(prev => !prev)
  }

  const { handleSubmit, register, formState: { errors }, watch, setValue, control, clearErrors, trigger } = useForm({
    defaultValues: {
      awb: '',
      weight: initialWeight,
      remarks: '',
      shipment_type: '',
      number_of_boxes: initialNumberOfBoxes,
      city: {},
      country: {},
      city_id_from: {},
      country_id_from: {},
      shipping_partner: null,
      shipping_reference:'',
      suggested_shipping_account:null
    },
    mode: 'onChange'
  })

  const allFields = watch()
  const { shipping_partner: shippingPartner, shipment_type } = allFields
  const city_Id = typeof allFields.city?.value === 'string' ? +allFields.city?.value?.split('-')?.[0] : allFields.city?.value
  const shippingPartnerObj = shippingDetailsData.shipping_options.find(option => option.key === watch('shipment_type'))
  const isCourierPartnerForShipping = shippingPartner?.shipping_partner?.fetch_courier_partners
  const shipping_partner_tag = shippingPartner?.shipping_partner?.tag || shippingPartnerObj?.shipping_partners?.[0]?.tag
  const { loading: loadingFetchDimensions } = useSelector(state => state.shipping.shippingPartnerMethods)
  const isCityMappingRequired = watch('shipping_partner')?.shipping_partner.details?.is_city_mapping_required
  const dropCity = watch('shipping_partner')?.shipping_partner.mapped_shipping_partner_cities?.drop_city
  const isFetch_multi_dimensions = allFields?.shipping_partner?.shipping_partner.details?.fetch_multi_dimensions

  const isCreateShipmentDisabled = !!((isCityMappingRequired && !dropCity) || isCityEditing)

  useEffect(() => {
    if (shipmentDetails) {
      setShipmentCreated(true)
      if (shipmentDetails.data.oms_order_id) setDBOrderId(shipmentDetails.data.oms_order_id)
    }
  }, [shipmentDetails])

  useEffect(() => {
    setShipmentCreated(false)
    setValue('country_id_from', defaultCountry)
    setValue('city_id_from', defaultCity)
    return () => { 
      setManualPrintLoadingState('')
      dispatch(updatePrefilledNumberOfPackages(null))
      dispatch(updatePrefilledWeight(null))
    }
  }, [])

  useEffect(() => {
    if (shipment_type === SHIPPING_OPTION.MANUAL_DISPATCH.name || shipment_type === SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name) {
      setValue('courier_partner', {label: 'Manual', value: 'manual'})
    }
  }, [shipment_type])

  useEffect(() => {
    if (success.createShipment || success.createShipmentOrderShipment) {
      setCreateShipmentStatus(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_UNSELECTED)
    }
  }, [success])

  useEffect(() => {
    if (getPrioritisedShippingPartnersResonse.is_success) {
      setCreateShipmentStatus(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED)
      const shippingPartnerOption = getPrioritisedShippingPartnersResonse.data.data.shipping_accounts.map((ele) => {
        const storedObj = {
          ...ele,
          label: `${ele.account_name} (${ele.shipping_partner.name})`,
          value: ele.id,
          logo: ele.shipping_partner.logo
        }
        return storedObj
      })
      setShippingPartnerOptions(shippingPartnerOption)

      const actionValue = getPrioritisedShippingPartnersResonse.data.data.action_value

      if (shippingPartnerOption[0]) {
        setValue('shipping_partner', shippingPartnerOption[0])
        setValue('suggested_shipping_account', shippingPartnerOption[0])
        const serviceTypes = shippingPartnerOption[0]?.shipping_partner?.details?.service_types
        const productType = shippingPartnerOption[0]?.shipping_partner?.product_type
        const productTypeOptions = productType?.value
        const detailsProductType = shippingPartnerOption[0]?.details?.[productType?.key_name]
        const defaultServiceType = serviceTypes?.find(item => item.value === shippingPartnerOption[0]?.details?.service_type)
        setValue('service_type', defaultServiceType || null)
        if (productType?.key_name && productTypeOptions?.length) {
          const defaultProductType = actionValue?.product_type?.find(item => item.id === shippingPartnerOption[0].id)
          const defaultDetailsProductType = productTypeOptions?.find(item => item.value === detailsProductType)
          const defaultValue = defaultProductType?.value || defaultDetailsProductType || null
          setValue(productType.key_name, defaultValue)
        }
      }
    }
  }, [getPrioritisedShippingPartnersResonse])

  const handleOrderDetailsData = () => {
    dispatch(getSingleOrderDetails(DBOrderId))
  }
  const handleGetOrderDataForManualPrint = (printButtonDetail) => {
    setManualPrintLoadingState(printButtonDetail)
    dispatch(getSingleOrderDetails(DBOrderId))
  }

  const handleOrderFulfillment = (e) => {
    setOmnifulGeneratedShipmentType(e.target.value)
    clearErrors()
    setValue('shipping_partner', null)
    setValue('suggested_shipping_account', null)
    setValue('courier_partner', null)
    setValue('number_of_boxes', initialNumberOfBoxes)
    setValue('weight', initialWeight)
    setValue('awb', '')
    setValue('remarks', '')
    setCreateShipmentStatus(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_UNSELECTED)
    setValue('country', omnifulCountry.value ? omnifulCountry : '')
    setValue('city', omnifulCity.value ? omnifulCity : '')
    setIsRemarkDisabled(false)
  }

  const handleEditOrderDetails = () => {
    dispatch(clearGetPrioritisedShippingPartners())
    setCreateShipmentStatus(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_UNSELECTED)
    setIsRemarkDisabled(false)
    setValue('shipping_partner', null)
    setValue('suggested_shipping_account', null)
    setValue('shipping_reference', null)
    clearErrors('remarks')
    setIsCityEditing(false)
  }

  const onSubmit = (data) => {
    let shipmentData
    // The exact same struct is sent
    const fromVillage = data.from_village || {}
    const toVillage = data.to_village || {}
    delete fromVillage.value
    delete fromVillage.label
    delete toVillage.value
    delete toVillage.label

    if (omnifulGeneratedShipmentType === SHIPPING_OPTION.SHIPPING_PARTNER.name) {
      shipmentData = {
        shipment_type: omnifulGeneratedShipmentType,
        shipping_partner_tag: data.shipping_partner?.shipping_partner?.tag,
        order_address: {
          to: {
            city_id: String(data.city.value),
            city: data.city.label,
            country_id: String(data.country.value),
            country: data.country.label
          },
          village_details: {
            from: fromVillage,
            to: toVillage
          }
        },
        order_details: {
          number_of_boxes: parseInt(data.number_of_boxes),
          weight: parseFloat(data.weight),
          remarks: data.remarks
        },
        shipping_account : {
          id: data.shipping_partner?.id,
          name:data.shipping_partner?.account_name
        },
        suggested_shipping_account : {
          id: data.suggested_shipping_account?.id,
          name:data.suggested_shipping_account?.account_name,
          shipping_partner_name:data.suggested_shipping_account?.shipping_partner?.name
        },
        omniful_rule_id:getPrioritisedShippingPartnersResonse?.data?.data?.rule_id,
        shipping_reference: data.shipping_reference
      }
      if (data.length && data.breadth && data.height) {
        shipmentData.order_details.shipment_packages = isFetch_multi_dimensions ? packageData : 
          [
            {
              length: parseInt(data.length),
              breadth: parseInt(data.breadth),
              height: parseInt(data.height)
            }
          ]
      }
    }

    if (omnifulGeneratedShipmentType === SHIPPING_OPTION.MANUAL_DISPATCH.name || omnifulGeneratedShipmentType === SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name) {
      shipmentData = {
        shipment_type: omnifulGeneratedShipmentType,
        awb_number: data.awb,
        tracking_url: data.tracking_url,
        order_details: {
          number_of_boxes: parseInt(data.number_of_boxes),
          weight: parseFloat(data.weight),
          remarks: data.remarks
        },
        order_address: (data.country?.value || data.city?.value) && {
          to: {
            city_id:data.city?.value && String(data.city.value),
            city: data.city?.label,
            country_id: data.country?.value &&  String(data.country.value),
            country: data.country?.label
          }
        }
      }
    }

    if (data.courier_partner && data.courier_partner.value) {
      shipmentData.courier_partner = {
        id: data.courier_partner.value.id,
        name: data.courier_partner.value.name,
        tag: data.courier_partner.value.tag,
        logo: data.courier_partner.value.logo
      }
      if (data.courier_partner.value.id === MANUAL_COURIER_PARTNER.value) {
        delete shipmentData.courier_partner.id
      }
    }
    if (watch('shipping_partner')?.shipping_partner.details?.show_delivery_slot) {
      const parsedDate = parse(data.delivery_date.value, 'dd-MM-yyyy', new Date());
      shipmentData.order_details.slot = {
        start_time :data.delivery_start_time,
        end_time : data.delivery_end_time, 
        delivery_date:format(parsedDate, 'ddMMyyyy')
      }
    }
    if (watch('parcel_shop')?.value) {
      shipmentData.pre_shipment_details = {
        parcel_shop_id: watch('parcel_shop')?.value
      }
    }
    if (watch('nearby_points_details')?.value) {
      shipmentData.pre_shipment_details = {
        point_id: watch('nearby_points_details')?.value
      }
    }

    if (data.service_type) {
      shipmentData.service_type = data.service_type.value
    }
    const productType = data?.shipping_partner?.shipping_partner?.product_type
    if (productType?.key_name && data[productType?.key_name]?.value) {
      shipmentData.pre_shipment_details = {
        ...shipmentData.pre_shipment_details,
        [productType.key_name]: data[productType.key_name].value
      }
    }
    if (entity_name === SHIPPING_ENTITY.SHIPMENT_ORDER.value) {
      dispatch(createShipmentOrderShipment({ orderID, shipmentData }))
    } else {
      dispatch(createShipment({ orderID, shipmentData }))
    }
  }

  const orderDetailsFieldConfigurations = [
    {
      name: 'number_of_boxes',
      label: 'No of Packages',
      validationSchema: {
        required: 'Packages Quantity is required',
        min: {
          value: 1,
          message: t('Please enter a valid number')
        },
        validate: {
          'less-than-100': (fieldValue) => {
            return +fieldValue <= 100 || 'Packages Quantity cannot be more than 100'
          },
          'is-whole-number': (fieldValue) => {
            return /^[0-9]+$/.test(fieldValue) || 'Packages Quantity must be a whole number'
          }
        }
      },
      isClearable: true,
      isRequired: true,
      type: 'number',
      disabled: createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED
    },
    {
      name: 'weight',
      label: 'Weight in KG',
      validationSchema: {
        required: 'Weight is required',
        min: {
          value: 0.1,
          message: t('Please enter a valid number')
        },
        validate: (fieldValue) => {
          if (omnifulGeneratedShipmentType === SHIPPING_OPTION.MANUAL_DISPATCH.name) {
            return null
          } else {
            return +fieldValue <= 1000 || t('Weight cannot be more than 1000')
          }
        }
      },
      isClearable: true,
      isRequired: true,
      type: 'number',
      disabled: createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED
    },
    {
      name: 'remarks',
      label: 'Enter Remarks',
      type: 'textarea',
      // isRequired: isCourierPartnerForShipping,
      // validationSchema: {
      //     required: 'Remarks is required'
      // },
      rows: '3',
      disabled:isRemarkDisabled
    }
  ]

  // courier partner dropdown field
  const newConfigForCourierPartner = {
    name: 'courier_partner',
    title: t('Courier Partner'),
    type: 'select',
    isAsync: true,
    control,
    loadOptions: getCouriersPartners,
    isRequired: true,
    isClearable: true,
    value: watch('courier_partner'),
    defaultValue: watch('courier_partner'),
    validationSchema: { courier_partner: { required: t('Courier Partner is required') } },
    isDisabled: ((omnifulGeneratedShipmentType === SHIPPING_OPTION.SHIPPING_PARTNER.name && !allFields.city?.value) || allFields.weight === ''),
    errors,
    cacheUniqs: [watch('city')],
    additional: {
      tenant_id: tenantId,
      shipping_partner_tag,
      seller_id: shippingDetailsData.entity_data.seller_id,
      city_id: city_Id,
      weight: parseFloat(allFields.weight),
      number_of_boxes: parseInt(allFields.number_of_boxes),
      payment_type: shippingDetailsData.entity_data.payment_method,
      hub: shippingDetailsData.entity_data.hub,
      total_cost: shippingDetailsData.entity_data.invoice_total
    },
    additionalComponents : shippingPartner?.shipping_partner.tag === SHIPPING_PARTNERS_TAG_NAMES.TOROD
      ? { Option: CustomCourierPartnerOption }
      : {}
  }

  const configForParcelShops = {
    name: 'parcel_shop',
    title: t('Select Parcel Shop'),
    type: 'select',
    isAsync: true,
    control,
    loadOptions: getAsyncParcelShop,
    isClearable: true,
    errors,
    cacheUniqs: [watch('shipping_partner')?.id],
    additionalComponents:{Option: CustomDetailsOption},
    additional: {
      shipping_partner_tag,
      seller_id: shippingDetailsData.entity_data.seller_id,
      city: watch('city')?.label,
      country_code: shippingDetailsData.entity_data.shipping_address.country_code,
      pincode: shippingDetailsData.entity_data.shipping_address.postal_code,
      page: 1
    }
  }

  const handleGetShippingPartnerOrder = async () => {
    try {
      const isValidated = await trigger()
      if (!watch('country') && !watch('city')) {
        CustomToast('City and Country are required', {my_type:'error'})
      } else if (!watch('country')) {
        CustomToast('Country is required', {my_type:'error'})
      } else if (!watch('city')) {
        CustomToast('City is required', {my_type:'error'})
      } else if (isValidated) {
        // setCreateShipmentStatus(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED)
        const formValues = watch()
        const body = {
          order_details: {},
          order_address: {
            to: {},
            from: {
              city_id: `${defaultCity.value}`,
              city: defaultCity.label,
              country_id: `${defaultCountry.value}`,
              country: defaultCountry.label
            }
          },
          entity_id: '',
          entity_type: ''
        }
        body.shipment_type = formValues.shipment_type
        body.order_address.to.city_id = `${formValues.city.value}`
        body.order_address.to.city = formValues.city.label
        body.order_address.to.country_id = `${formValues.country.value}`
        body.order_address.to.country = formValues.country.label
        body.order_details.number_of_boxes = +formValues.number_of_boxes
        body.order_details.weight = +formValues.weight
        body.order_details.remarks = formValues.remarks
        body.entity_id = orderID
        if (entity_name === SHIPPING_ENTITY.SHIPMENT_ORDER.value) {
          body.entity_type = SHIPPING_ENTITY.SHIPMENT_ORDER.value
          dispatch(getShippingPartnersAccounts({body}))
        } else {
          body.entity_type = SHIPPING_ENTITY.FORWARD_ORDER.value
          dispatch(getShippingPartnersAccounts({body}))
        }
      }
    } catch (error) {
      console.error(error)
    }
  }


  const togglemultipleAwbModal = (awbData) => {
    setMultipleAwbList(
      awbData ? awbData : []
    )
    setMultipleAwbModalOpen((p) => !p)
  }

  useEffect(() => {
    setValue('country', omnifulCountry.value ? omnifulCountry : '')
    setValue('city', omnifulCity.value ? omnifulCity : '')
    handleEditOrderDetails()
  }, [shippingDetailsData])

  useEffect(() => {
    if (createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED) {
      setIsRemarkDisabled(true)
    } else {
      setIsRemarkDisabled(false)
    }
  }, [isCourierPartnerForShipping, createShipmentStatus])

  useEffect(() => {
    if (omnifulGeneratedShipmentType !== watch('shipment_type')) {
      setValue('shipment_type', omnifulGeneratedShipmentType)
    }
  }, [omnifulGeneratedShipmentType])

  return (
    <>
      <div className="orderFulFillment mx-1 mb-3 mt-2 border rounded-4">
        <h5 className="py-1 px-1 fw-bolder text-secondary mb-0 flex-center-between rounded-top-4 txt-h3-md shipment-details-heading">
          {t('Shipment Details')}
        </h5>
        {!shipmentCreated ? (
          <div className="bg-white px-1">
            <div className="w-w-auto d-flex px-8px mt-16px txt-body-rg border border-primary-lighter rounded-4px width-fit-content">
              <span className='py-8px'>{t('Shipping From')}</span>
              <span className='border-end mx-8px'></span>
              <span className='py-8px text-dark'>
                {shippingDetailsData.entity_data.hub.name}
                {defaultCity.label && `, ${defaultCity.label}`}
                {defaultCountry.label && `, ${defaultCountry.label}`}
              </span>
            </div>
            <p className="txt-body-md mt-16px text-dark">{t('Order Fulfilment by')}</p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex w-75 justify-content-between">
                {shippingDetailsData.shipping_options.map((option) => (
                  <div className="w-50 d-flex gap-1" key={option.key}>
                    <RadioButton
                      id={`formRadio-${option.key}`}
                      name={option.key}
                      label={option.display_name}
                      {...register('shipment_type')}
                      value={option.key}
                      checked={omnifulGeneratedShipmentType === option.key}
                      onClick={handleOrderFulfillment}
                      disabled={createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED}
                    />
                  </div>
                ))}
              </div>
              <div className='mt-24px'>
                <h6 className="d-flex align-items-center gap-20px txt-body-md text-dark cursor-pointer">
                  <span className="">{t('Shipping Address')}</span>
                  {(ability.can(
                    abilityMap.order.edit_details.action,
                    abilityMap.order.edit_details.resource
                  ) || ability.can(
                    abilityMap.shipment_order.edit_details.action,
                    abilityMap.shipment_order.edit_details.resource
                  )) && handleEditDetailsModal && <div className='d-flex gap-8px align-items-center'
                    onClick={() => { handleEditDetailsModal(EDIT_MODAL_TYPE.SHIPPING_ADDRESS.key) }}>
                    <Edit2 size={16} color = 'var(--bs-primary)'/>
                    <span className='txt-body-rg' style = {{color:'var(--bs-primary)'}}>{t('Edit')}</span>
                  </div>}
                </h6>
                <div className="py-1">
                  <Row className='mb-1 mx-0'>
                    <div className='text-dark py-8px txt-sub-rg flex-center-start rounded-4px' style = {{backgroundColor: 'var(--bs-primary-lighter'}}>
                      <div className='flex-center-start'>
                        <div className='flex-center-start gap-50'>
                          <span className='flex-center-center'><Info size={14} /></span>
                          <span>{t('In the absence of recipient details in the shipping address, customer information (name, email, phone) will be utilized for delivery.')}</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <ShippingDetails shippingDetailsData={shippingDetailsData} isFieldRequired={SHIPPING_OPTION.SHIPPING_PARTNER.name === omnifulGeneratedShipmentType && !shipmentCreated} />
                </div>
              </div>

              <div className='mt-30px d-flex gap-32px'>
                <div className='w-50'>
                  <OrderDetails
                    register={register}
                    errors={errors}
                    inputFieldConfigurations={orderDetailsFieldConfigurations}
                  />
                </div>
                <div className='w-50'>
                  {(omnifulGeneratedShipmentType === SHIPPING_OPTION.MANUAL_DISPATCH.name || omnifulGeneratedShipmentType === SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name) && <div>
                    <h6 className="txt-body-md mb-2">{t('Shipping Partner Details')}</h6>
                    <div className='d-grid gap-24px'>
                      <div className='w-100'>
                        <FloatingDropDown key={newConfigForCourierPartner.name}  {...newConfigForCourierPartner} />
                      </div>
                      <div className='w-100'>
                        <InputField
                          name='awb'
                          label='Enter AWB'
                          errors={errors}
                          isClearable={true}
                          {...register('awb')}
                        />
                      </div>
                      {
                        (omnifulGeneratedShipmentType === SHIPPING_OPTION.MANUAL_DISPATCH.name || omnifulGeneratedShipmentType === SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name) &&
                        <div className='w-100'>
                          <InputField
                            name='tracking_url'
                            label='Enter Tracking URL'
                            errors={errors}
                            isClearable={true}
                            {...register('tracking_url')}
                          />
                        </div>
                      }
                    </div>
                  </div>}

                  {omnifulGeneratedShipmentType === SHIPPING_OPTION.SHIPPING_PARTNER.name && createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED &&
                    <ShippingPartner
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      control={control}
                      sellerSalesChannelId={sellerSalesChannelId}
                      watch={watch}
                      createShipmentStatus={createShipmentStatus}
                      isCourierPartnerForShipping={isCourierPartnerForShipping}
                      newConfigForCourierPartner={newConfigForCourierPartner}
                      shippingPartnerOptions={shippingPartnerOptions}
                      omnifulCity={omnifulCity}
                      isCityEditing={isCityEditing}
                      setIsCityEditing={setIsCityEditing}
                      configForParcelShops={configForParcelShops}
                      packageData={packageData} 
                      setPackageData={setPackageData}
                      shippingDetailsData={shippingDetailsData}
                    />
                  }
                </div>
              </div>
              {createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_UNSELECTED && (watch('shipment_type') !== SHIPPING_OPTION.MANUAL_DISPATCH.name && watch('shipment_type') !== SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name) &&
                <div className="d-flex justify-content-end mt-36px mb-2">
                  <Button
                    type="button"
                    disabled={getPrioritisedShippingPartnersResonse.loading}
                    onClick={handleGetShippingPartnerOrder}
                  >
                    {getPrioritisedShippingPartnersResonse.loading && <Spinner size="sm" />}
                    <span className={classNames({ 'ms-50': getPrioritisedShippingPartnersResonse.loading })}>{t('Get Shipping Details')} <ArrowRight size={16} />
                    </span>
                  </Button>
                </div>
              }

              {(createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED || (watch('shipment_type') === SHIPPING_OPTION.MANUAL_DISPATCH.name || watch('shipment_type') === SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name)) &&
                <div className="d-flex justify-content-end mt-36px mb-2 gap-16px">
                  {omnifulGeneratedShipmentType === SHIPPING_OPTION.SHIPPING_PARTNER.name && <Button ofStyle='outlined' onClick={handleEditOrderDetails}>{t('Edit Details')}</Button>}
                  <Button type="submit" disabled={loading.createShipment || loading.createShipmentOrderShipment || loadingFetchDimensions || (createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED && !watch('shipping_partner')) || isCreateShipmentDisabled || Object.entries(errors).length}>
                    {(loading.createShipment || loading.createShipmentOrderShipment) && <Spinner size="sm" />}
                    <span className={classNames({ 'ms-50': loading.createShipment || loading.createShipmentOrderShipment })}>{t('Create Shipment')}</span>
                  </Button>
                </div>
              }
            </Form>
          </div>
        ) : (
          <>
            {omnifulGeneratedShipmentType === SHIPPING_OPTION.SHIPPING_PARTNER.name && (
              <div>
                <div className="bg-white px-1 py-1">
                  <ShippingDetails shippingDetailsData={shippingDetailsData} />
                  <Row className="my-1">
                    <Col className="col-3">{t('Order Status')}:</Col>
                    <Col className="text-secondary col-9">
                      {(shipmentDetails && shipmentDetails.data.status) || '-'}
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col className="col-3">{t('Shipping Partner Order Id')}:</Col>
                    <Col className="text-secondary col-9">
                      {(shipmentDetails && shipmentDetails.data.shipping_partner_order_id) || '-'}
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col className="col-3">{t('AWB Number')}:</Col>
                    <Col className="text-secondary col-9">
                      {(shipmentDetails && shipmentDetails.data.awb_number) || '-'}
                      {shipmentDetails?.data.metadata?.tracking_info?.length > 1 &&
                        <span
                          onClick={() => togglemultipleAwbModal(shipmentDetails.data.metadata.tracking_info)}
                          className={'text-primary ms-12px cursor-pointer'}
                        >
                          {`+${shipmentDetails.data.metadata.tracking_info.length - 1}`}
                        </span>}
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col className="col-3">{t('Shipping Partner Status')}:</Col>
                    <Col className="text-secondary col-9">
                      {(shipmentDetails && shipmentDetails.data.shipping_partner_status) || '-'}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-3">{t('Shipping Partner Name')}:</Col>
                    <Col className="text-secondary col-9">
                      {(shipmentDetails && shipmentDetails.data.shipping_partner_name) || '-'}
                    </Col>
                  </Row>
                </div>
                {(searchParams.get('currentTab') !== LIVE_ORDERS_TABS.PACKING.value) && <div className="d-flex justify-content-end gap-2 bg-white pb-2 px-2">
                  {shipmentDetails && shipmentDetails.data.print_awb_allowed &&
                    <Button disabled={printAwbLoading} onClick={handleAwb}>
                      {printAwbLoading && <Spinner size="sm" />}
                      <span className={classNames({ 'ms-50': printAwbLoading })}>{t('Print AWB')}</span>
                    </Button>}
                  {shipmentDetails && shipmentDetails.data.print_delivery_note_allowed && (!orderDetails ?
                    <Button disabled={loading.getSingleOrderDetails} onClick={handleOrderDetailsData}>{loading.getSingleOrderDetails && <Spinner size="sm" />}
                      <span className={classNames({ 'ms-50': loading.getSingleOrderDetails })}>{t('Print Delivery Note')}</span>
                    </Button> : <PrintDeliveryNote printDeliveryNoteRef={printDeliveryNoteRef} buttonClicked={true} orderDetails={orderDetails} />)}
                </div>}
              </div>
            )}

            {(omnifulGeneratedShipmentType === SHIPPING_OPTION.MANUAL_DISPATCH.name || omnifulGeneratedShipmentType === SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name) && (
              <div>
                <div className="bg-white px-1 py-1">
                  <ShippingDetails shippingDetailsData={shippingDetailsData}/>
                  <Row className="my-1">
                    <Col className="col-3">{t('Order Status')}:</Col>
                    <Col className="text-secondary col-9">
                      {(shipmentDetails && shipmentDetails.data.status) || '-'}
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col className="col-3">{t('AWB Number')}:</Col>
                    <Col className="text-secondary col-9">
                      {(shipmentDetails && shipmentDetails.data.awb_number) || '-'}
                      {shipmentDetails?.data.metadata?.tracking_info?.length > 1 &&
                        <span
                          onClick={() => togglemultipleAwbModal(shipmentDetails.data.metadata.tracking_info)}
                          className={'text-primary ms-12px cursor-pointer'}
                        >
                          {`+${shipmentDetails.data.metadata.tracking_info.length - 1}`}
                        </span>}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-3">{t('Shipping Partner Status')}:</Col>
                    <Col className="text-secondary col-9">
                      {(shipmentDetails && shipmentDetails.data.shipping_partner_status) || '-'}
                    </Col>
                  </Row>
                  {(searchParams.get('currentTab') !== LIVE_ORDERS_TABS.PACKING.value) && <div className="d-flex justify-content-end gap-2 bg-white pb-2 mt-3">
                    {shipmentDetails && shipmentDetails.data.print_manual_awb_allowed && entity_name === SHIPPING_ENTITY.SHIPMENT_ORDER.value ? <PrintManualAwb entity_id={shipmentDetails.data.oms_order_id} entity_type={SHIPPING_ENTITY.SHIPMENT_ORDER.value} /> :
                      (!orderDetails ?
                        <Button disabled={manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_AWB} onClick={() => { handleGetOrderDataForManualPrint(PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_AWB) }}>{(manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_AWB) && <Spinner size="sm" />}
                          <span className={classNames({ 'ms-50': (manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_AWB) })}>{t('Print AWB')}</span>
                        </Button> : <PrintAwb buttonClicked={manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_AWB} orderDetails={orderDetails} printManualAwbRef={printManualAwbRef} />)
                    }

                    {shipmentDetails && shipmentDetails.data.print_delivery_note_allowed && (!orderDetails ?
                      <Button disabled={isCityEditing || manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_DELIVERY_NOTE} onClick={() => { handleGetOrderDataForManualPrint(PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_DELIVERY_NOTE) }}>{(manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_DELIVERY_NOTE) && <Spinner size="sm" />}
                        <span className={classNames({ 'ms-50': (manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_DELIVERY_NOTE) })}>{t('Print Delivery Note')}</span>
                      </Button> : <PrintDeliveryNote printDeliveryNoteRef={printDeliveryNoteRef}
                        buttonClicked={manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_DELIVERY_NOTE} orderDetails={orderDetails} />)}

                  </div>}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <MultipleAwbSideSheet modalOpen={multipleAwbModalOpen} data={multipleAwbList} toggleSidebar={togglemultipleAwbModal} />
    </>
  )
}

export default CreateShipmentComponents