import ComponentSpinner from '@src/@core/components/spinner/Loading-spinner'
import Hub from '@src/assets/images/icons/primary-navbar/Hub'
import ClockIcon from '@src/assets/images/svg/hubs/ClockIcon'
import MapIcon from '@src/assets/images/svg/hubs/MapIcon'
import { DELIVERY_AREA_TYPES } from '@src/views/hubs/constants.hubs'
import { setViewDeliveryZonesScreenData } from '@src/views/hubs/store'
import { convertTo12HourFormat, convertTo24HourFormatFromMilitaryTime } from '@src/views/hubs/utils/functions'
import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import { Calendar, Circle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { API_KEYS, fetchSingleDeliveryZone } from '../../HubDeliveryZone/hubDeliveryZone.constant'
const DeliveryZoneCard = (props) => {
  const {deliveryZone, isDeliveryZonesFetching} = props
  const {
    id,
    name, 
    hub_name, 
    hub_id,
    display_end_time, 
    display_start_time, 
    type, 
    is_active, 
    is_serviceable_24_hours, 
    meta_data
  } = deliveryZone

  const dispatch = useDispatch()
  const {t} = useTranslation()
  const viewDeliveryZonesScreen = useSelector(state => state.revampedHubs.viewDeliveryZonesScreenData)
  const {selectedDeliveryZones, selectedHubs} = viewDeliveryZonesScreen
  const minutes = meta_data?.find((ele) => ele.Key === 'minutes')
  const displayType = Object.values(DELIVERY_AREA_TYPES).find((delArea) => delArea.key === type).type
  const isSelected = !!selectedDeliveryZones[deliveryZone.id]
  const { mutate: getSingleDeliveryZoneData, isPending: isSingleDeliveryZoneFetching } = useMutation({ 
    mutationKey: [API_KEYS.FETCH_SINGLE_DELIVERY_ZONE], 
    mutationFn: () => {
      const params = {
        hubId: hub_id,
        deliveryZoneId: id
      }
      return fetchSingleDeliveryZone({params})
    },
    onSuccess: ({ data }) => {
      const prevSelectedDeliveryZones = {...selectedDeliveryZones}
      prevSelectedDeliveryZones[data.data.id] = data.data
      prevSelectedDeliveryZones[data.data.id].display_start_time = convertTo12HourFormat(convertTo24HourFormatFromMilitaryTime(data.data.start_time))
      prevSelectedDeliveryZones[data.data.id].display_end_time = convertTo12HourFormat(convertTo24HourFormatFromMilitaryTime(data.data.end_time))
      dispatch(setViewDeliveryZonesScreenData({variableName: 'selectedDeliveryZones', value: prevSelectedDeliveryZones}))
    }
  }) 

  const handleSelectZone = (zone) => {
    if (isSingleDeliveryZoneFetching || isDeliveryZonesFetching) return
    const prevSelectedDeliveryZones = {...selectedDeliveryZones}
    if (prevSelectedDeliveryZones[zone.id]) {
      delete prevSelectedDeliveryZones[zone.id]
      dispatch(setViewDeliveryZonesScreenData({variableName: 'selectedDeliveryZones', value: prevSelectedDeliveryZones}))
    } else {
      getSingleDeliveryZoneData()   
    }
  }
  const styles = isSelected ? {
    borderColor: `${selectedHubs[hub_id]?.color}`,
    background: `${selectedHubs[hub_id]?.color}1F`
  } : {}
  return (
    <div className="position-relative delivery-zone-card-container" id={id}>
      <div className={classNames('delivery-zone-card', {selected: isSelected, disabled: (isSingleDeliveryZoneFetching || isDeliveryZonesFetching)})} onClick={() => handleSelectZone(deliveryZone)}
        style={styles}
      >
        <div className="flex-center-between text-dark mb-16px">
          <div className="flex-center-start gap-12px">
            <div className="txt-body-md">{name}</div>
            <div className="flex-shrink-0">
              <Circle size={6} fill={is_active ? 'var(--bs-success)' : 'var(--bs-dark-5)'} stroke={is_active ? 'var(--bs-success)' : 'var(--bs-dark-5)'} strokeWidth={1}/>
            </div>
          </div>
          {/* <UncontrolledDropdown>
            <DropdownToggle tag="span">
              <MoreVertical size={16} strokeWidth={1} fill="var(--bs-dark)"/>
            </DropdownToggle>
            <DropdownMenu end container="body">
              <DropdownItem className="w-100 text-dark flex-center-start txt-asst-rg gap-6px" onClick={() => {}}>
                <Edit2 size={12} />
                <span>{t("Edit")}</span>
              </DropdownItem>
              <DropdownItem className="w-100 text-dark flex-center-start txt-asst-rg gap-6px" onClick={() => {}}>
                {is_active ? <Slash size={12} /> : <Check size={12}/>}
                <span>{is_active ? t("Mark as Inactive") : t("Mark as Active")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
        </div>
        <div className="delivery-zone-config">
          <div className="d-flex align-items-baseline gap-8px text-truncate" title={hub_name}>
            <div className="flex-shrink-0"><Hub width={16} height={16} color="var(--bs-dark-6)"/></div>
            <div className="txt-asst-rg text-dark-6 text-truncate">{hub_name}</div>
          </div>
          <div className="d-flex align-items-baseline gap-8px">
            <div className="flex-shrink-0"><Calendar size={16} color="var(--bs-dark-6)" strokeWidth="1.5"/></div>
            <div className="txt-asst-rg text-dark-6">
              {is_serviceable_24_hours ? t('24 hours') : `${display_start_time} - ${display_end_time}`}
            </div>
          </div>
          <div className="d-flex align-items-baseline gap-8px">
            <div className="flex-shrink-0"><MapIcon width={16} height={16} color="var(--bs-dark-6)"/></div>
            <div className="txt-asst-rg text-dark-6">{displayType}</div>
          </div>
          {type === DELIVERY_AREA_TYPES.SYSTEM_GENERATED.key && <div className="d-flex align-items-baseline gap-8px">
            <div className="flex-shrink-0"><ClockIcon width={16} height={16} color="var(--bs-dark-6)"/></div>
            <div className="txt-asst-rg text-dark-6">{minutes.Value} mins</div>
          </div>}
        </div>
      </div>
      {(isSingleDeliveryZoneFetching || isDeliveryZonesFetching) && <div className="position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center z-10"><ComponentSpinner className={'spinner-sm'}/></div>}
    </div>
  )
}

export default DeliveryZoneCard