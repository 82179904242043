import Button from '@src/@core/components/button'
import { GRN_TYPES } from '@src/views/inventory/inventory.constants'
import React, { useRef } from 'react'
import { AlertTriangle, X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, Spinner } from 'reactstrap'

const CompleteGRNModal = ({ isOpen, toggle, handleCompleteGRN, type, isQuantityRemaining, isCompleteGrnPending }) => {
  const { t } = useTranslation()

  const primaryCtaRef = useRef()

  const onOpened = () => {
    if (primaryCtaRef.current) primaryCtaRef.current?.focus()
  }

  return (
    <Modal
      isOpen={isOpen}
      centered
      toggle={toggle}
      onOpened={onOpened}
      contentClassName='rounded-12px'
    >
      <ModalBody className='p-0'>
        <div className='px-24px pt-24px pb-12px'>
          <div className="d-flex gap-8px justify-content-center mb-24px position-relative">
            <X onClick={toggle} size={16} className="text-dark cursor-pointer position-absolute end-0" />
            <AlertTriangle style={{ strokeWidth: 1.5, color: '#FF991F' }} size={120} fill="currentColor" strokeColor="white" stroke="white" />
          </div>
          <div className='d-flex flex-column gap-12px'>
            <div className='txt-h3-sb text-dark'>
              {type === GRN_TYPES.RETURN ? t('Are you sure you want to complete the return order processing?') : t('Are you sure you want to complete the GRN?')}
            </div>
            <div className='txt-body-md text-dark-6'>
              {isQuantityRemaining ? t('A new GRN will be required to process the remaining items.') : t('Once completed, no further changes can be made to this GRN.') }</div>
          </div>
        </div>
        <div className='flex-center-end p-24px gap-16px'>
          <Button
            ref={primaryCtaRef}
            onClick={toggle}
            ofStyle="outlined"
            disabled={isCompleteGrnPending}
          >
            {type === GRN_TYPES.RETURN ? t('Keep doing QC') : t('Cancel')}
          </Button>
          <Button
            onClick={handleCompleteGRN}
            disabled={isCompleteGrnPending}
          >
            {isCompleteGrnPending ? <Spinner size="sm" /> : null}
            <span className="align-middle ms-25">{type === GRN_TYPES.RETURN ? t('Complete Process') : t('Mark as complete')}</span>

          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CompleteGRNModal