import { memo, useCallback, useContext, useEffect, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ModalBody, Spinner } from 'reactstrap'
import ConfirmationPopup from '../../../components/popup/confirmationPopup'
import { ADDITIONAL_FILTER_DROPDOWN_STYLE, ALL_RETURN_STATUS_COLUMN, ALL_RETURN_STATUS_FILTER_OPTIONS, FILTER_DATA, RETURN_ORDER_COLUMNS_LIST, RETURN_ORDER_TABS, RETURN_STATUS, RETURN_TABLE_COLUMN_HEADER, STATUS_FILTER_DROPDOWN_STYLE } from '../../../returns.constants'
import { cancelReturnOrder, getReturnOrderDetail, getReturnOrders, markOrdersAsReturnedTohHub, resetSuccess } from '../../../store'
import ReturnOrderDetail from '../return-order-details'
// import ReverseShipment from "../create-reverse-shipment"
import CustomFilter from '@src/@core/components/customTableFilterComponent'
import DropdownWithTitle from '@src/@core/components/dropdown'
import CustomDropdownOption from '@src/@core/components/dropdown/customDropdownOption'
import { ExpandableCustomTable } from '@src/@core/components/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/globalTable/globalTable.constant'
import { COLLAPSED_CONTAINER_LIST, HUBS_CONFIGURATION, TENANT_TYPE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { AbilityContext } from '@src/utility/context/Can'
import { useFilters, usePagination, useSearch } from '@src/utility/hooks/useFilters'
import OrderDetailsSideSlider from '@src/views/sales/OrderDetailsSideSlider'
import { getAsyncSellerSalesChannel, getCourierPartnerAsyncData, getSellersAsync } from '@src/views/sales/store/store'
import { useTranslation } from 'react-i18next'
import TypeOfReturnsInformation from '../../../components/TypeOfReturnsInformation/index'
import CreateReturnOrderProcessing from '../../return-order-processing/create-return-order-processing'
import RefundDetail from '../../return-order-processing/refund-detail'

const PopupBody = (props) => {
  const {t} = useTranslation()
  const {handlePopupToggle, handleCancelReturnOrder, popupIcon, popupName, loading} = props
  return (
    <ModalBody>
      <div className="d-flex justify-content-center icon-container">
        {popupIcon}
      </div>
      <div className="d-flex flex-column align-items-start return-confirmation-popup-body">
        <div className="paragraph-1">{t(`${popupName} Return Order?`)}</div>
        <div className="paragraph-2">
          <div>You are going to <span className="fw-bolder bold-text">{popupName.toLowerCase()}</span> this order. This action cannot be reverted.</div>
        </div>
        <div className="paragraph-3">
          {t('Are you sure you want to continue?')}
        </div>
      </div>
      <div className="confirmation-modal-footer">
        <Button className="w-50" color="primary action-button" outline disabled={loading.cancelReturnOrder} onClick={handlePopupToggle}>{t('Take me back')}</Button>
        <Button className="w-50" color="primary action-button" onClick={handleCancelReturnOrder} disabled={loading.cancelReturnOrder}>{loading.cancelReturnOrder && <Spinner size="sm" className="me-50"/>}{t(`Yes, ${popupName}`)}</Button>
      </div>
    </ModalBody>
  )
}

const OffSystemReturn = (props) => {
  const {t} = useTranslation()
  const {handleCreateReverseShipmentModal, activeTab} = props
  const ability = useContext(AbilityContext)
  const tenantType = useSelector((state) => state.auth.userData.tenant.type)
  const selfTenantSellerId = useSelector((state) => state.auth.userData.sellers[0]?.seller_id)
  const dispatch = useDispatch()
  const loading = useSelector((store) => store.returns.loading)
  const success = useSelector((store) => store.returns.success)
  const error = useSelector((store) => store.returns.error)
  const returnOrders = useSelector((store) => store.returns.returnOrders)
  const selectedHubConfiguration = useSelector(state => state.auth.selectedHubConfiguration)  
  const isLocationInventoryEnabled = selectedHubConfiguration?.find((config) => config.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)?.configuration_values?.enabled
  const [tableData, setTableData] = useState([])
  const { filter, setFilter } = useFilters({})
  const { searchQuery, setSearchQuery } = useSearch({})
  const { pagination, setPagination } = usePagination()
  const isSellerViewAllowed = ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource)
  // const [isCreateReverseShipmentModalOpen, setCreateReverseShipmentModalOpen] = useState(false)
  const [isReturnOrderDetailModalOpen, setReturnOrderDetailModalOpen] = useState(false)
  const [isOrderDetailSidebarOpen, setOrderDetailSidebarOpen] = useState(false)
  const [returnOrderToShowDetail, setReturnOrderToShowDetail] = useState(null)
  const [isCancelReturnOrderPopupOpen, setCancelReturnOrderPopupOpen] = useState(false)
  const [returnOrderIdToCancel] = useState(null)
  const [isCreateReturnOrderProcessingModalOpen, setCreateReturnOrderProcessingModalOpen] = useState(false)
  const [isRefundDetailModalOpen, setRefundDetailModalOpen] = useState(false)
  const [selectedData, setSelectedData] = useState([])

  const handleReturnOrderDetailModal = useCallback(() => {
    setReturnOrderDetailModalOpen(prev => !prev)
  }, [isOrderDetailSidebarOpen])

  const handleOrderDetailSidebar = useCallback(() => {
    setOrderDetailSidebarOpen((prev) => !prev)
  }, [isOrderDetailSidebarOpen])

  const handleSetOrderToShowOrderDetail = useCallback((order) => {
    setReturnOrderToShowDetail(order)
    handleOrderDetailSidebar()
  }, [returnOrderToShowDetail])

  const handleSetReturnOrderToShowDetail = useCallback((order) => {
    setReturnOrderToShowDetail(order)
    handleReturnOrderDetailModal()
  }, [returnOrderToShowDetail])

  const handleCreateReturnOrderProcessingModal = useCallback((params) => {
    if (params?.orderID) {
      dispatch(getReturnOrderDetail(params?.orderID))
    }
    setCreateReturnOrderProcessingModalOpen(prev => !prev)
  }, [isCreateReturnOrderProcessingModalOpen])

  const getAllAppliedFilters = (appliedFilters) => {
    setFilter(appliedFilters)
  }

  const filterHandler = ({ filterKey, filterValue }) => {
    if (!filterValue) {
      delete filter[filterKey]
      setFilter(filter)
      return
    }
    if (filterKey === 'status') {
      setFilter({[filterKey]: filterValue})
      return
    }
    setFilter({ ...filter, [filterKey]: filterValue })
  }

  const handleSearch = ({searchVal, selectedColumn = null}) => {
    const searchQuery = searchVal ? {column: selectedColumn, query: searchVal} : null
    setSearchQuery(searchQuery)
  }

  // const handleCreateReverseShipmentModal = () => {
  //   setCreateReverseShipmentModalOpen(prev => !prev)
  // }

  const handlePagination = page => {
    const pagination = {page: page.selected, per_page: page.per_page ? page.per_page : 20 }
    setPagination(pagination)
  }

  const handleCancelReturnOrderPopup = () => {
    setCancelReturnOrderPopupOpen(prev => !prev)
  }

  const handleCancelReturnOrder = () => {
    dispatch(cancelReturnOrder({orderID: returnOrderIdToCancel}))
  }

  // const handleSetReturnOrderToCancel = (orderID) => {
  //   setReturnOrderIdToCancel(orderID)
  //   handleCancelReturnOrderPopup()
  // }

  const handleRefundDetailModal = (params) => {
    if (params?.orderID) {
      dispatch(getReturnOrderDetail(params.orderID))
    }
    setRefundDetailModalOpen(prev => !prev)
  }

  const getReturnOrderWithFilter = () => {
    const filterParams = {
      status: filter.status?.value,
      seller_id: filter.seller_id?.value,
      seller_sales_channel_id: filter.seller_sales_channel_id?.value,
      shipping_partner_tag: filter.shipping_partner_tag?.value,
      courier_partner_tag: filter.courier_partner_tag?.value
    }
    const searchParams = searchQuery.query ? {[searchQuery.column.id]: searchQuery.query} : {}
    dispatch(getReturnOrders({params: {...filterParams, ...searchParams, ...pagination, types: activeTab.key}}))
  }

  const handleSelectedData = ({ selectedRows }) => {
    setSelectedData(selectedRows)
  }

  const handleMarkOrderArrivedAtHub = () => {
    const body = {
      is_reverse: activeTab.key === RETURN_ORDER_TABS.CUSTOMER_RETURN.key,
      shipment_updates: selectedData.map((order) => {
        return {
          order_id: order.orderID,
          awb_number: order.awb_number,
          shipping_partner_tag: order.shipping_partner_tag
        }
      }),
      status: RETURN_STATUS.REACHED_AT_HUB.key
    }
    dispatch(markOrdersAsReturnedTohHub({ body }))
  }

  useEffect(() => {
    if (success.cancelReturnOrder) {
      handleCancelReturnOrderPopup()
    }
    if (success.createReverseShipment || success.markOrdersAsReturnedTohHub || success.completeReturnOrderProcessing || success.completeRefundProcess || success.cancelReturnOrder) {
      getReturnOrderWithFilter()
      dispatch(resetSuccess())
      if (isReturnOrderDetailModalOpen) {
        handleReturnOrderDetailModal()
      }
    }
  }, [success])

  useEffect(() => {
    getReturnOrderWithFilter()
  }, [])

  useEffect(() => {
    if (returnOrders?.data) {
      const mappedData = returnOrders.data.map((data, index) => {
        return {
          ...data,
          serialNo: ((returnOrders.meta?.current_page - 1) * returnOrders.meta?.per_page) + index + 1,
          orderID: data.id,
          created_order_id: data.order_id,
          awb_number: data.shipment.awb_number,
          shipping_partner_name: data.shipment.shipping_partner_name,
          shipping_partner_tag: data.shipment.shipping_partner_tag,
          courier_partner: data.shipment.courier_partner,
          total_price: data.total,
          approved_by: data.approved_by.name,
          created_by: data.created_by.name,
          qc_processed_by: data.qc_processed_by?.name
        }
      })
      setTableData(mappedData)
    }
  }, [returnOrders])
  return (
    <div className="return-order">
      <div className="p-1 pb-0 d-flex gap-1">
        <DropdownWithTitle
          value={!filter.status ? {label: 'All', value: ''} : filter.status}
          isClearable={!!filter.status}
          title="Status"
          options={ALL_RETURN_STATUS_FILTER_OPTIONS(isLocationInventoryEnabled).filter((option) => {
            if (option.value === RETURN_STATUS.INITIATED.key) {
              return false
            }
            if (option.value === RETURN_STATUS.ITEMS_PICKED_UP.key) {
              return false
            }
            return true
          })}
          selectOptionHandler={(value) => filterHandler({ filterKey: 'status', filterValue: value })}
          externalStyles={STATUS_FILTER_DROPDOWN_STYLE}
        />
      </div>
      <div className="p-1 d-flex gap-1">
        <CustomFilter
          filterData={FILTER_DATA}
          defaultSelectedOption={filter}
          getAllAppliedFilters={getAllAppliedFilters}
        />
        {((ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource) && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE)) && <div>
          <DropdownWithTitle 
            isAsync 
            isClearable={!!filter.seller_id} 
            value={!filter.seller_id ? {label: 'All', value: ''} : filter.seller_id}
            title="Sellers"
            loadOptionsHandler={getSellersAsync} 
            selectOptionHandler={(value) => filterHandler({filterKey: 'seller_id', filterValue: value})} 
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
          />
        </div>}
        {ability.can(abilityMap.sales_channel_app.view.action, abilityMap.sales_channel_app.view.resource) && <div>
          <DropdownWithTitle 
            isAsync 
            isClearable={!!filter.seller_sales_channel_id} 
            value={!filter.seller_sales_channel_id ? {label: 'All', value: ''} : filter.seller_sales_channel_id}
            title="Sales Channel"
            loadOptionsHandler={getAsyncSellerSalesChannel}
            additional={{
              page: 1,
              seller: filter.seller_id?.value || selfTenantSellerId
            }}
            selectOptionHandler={(value) => filterHandler({filterKey: 'seller_sales_channel_id', filterValue: value})} 
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
            isDisabled={!filter.seller_id && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && isSellerViewAllowed}
            tooltipId={!filter.seller_id && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && isSellerViewAllowed && 'seller_sales_channel_id'}
            tooltipText={!filter.seller_id && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && 'First select the seller'}
            additionalComponents={{Option: CustomDropdownOption}}
          />
        </div>}
        {ability.can(abilityMap.shipping_app.view.action, abilityMap.shipping_app.view.resource) && <div>
          <DropdownWithTitle 
            isAsync 
            isClearable={!!filter.courier_partner_tag} 
            value={!filter.courier_partner_tag ? {label: t('All'), value: ''} : filter.courier_partner_tag}
            title={t('Couriers Partners')}
            loadOptionsHandler={getCourierPartnerAsyncData} 
            selectOptionHandler={(value) => filterHandler({filterKey: 'courier_partner_tag', filterValue: value})} 
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
            additionalComponents={{Option: CustomDropdownOption}}
          />
        </div>}
      </div>
      {selectedData.length > 0 && (
        <div className="w-100 d-flex align-items-stretch gap-1 selected-row-summary">
          <div className="selected-row-batch">
            {t('Order Selected')}: {selectedData.length}
          </div>
          <div className="vertcal-row"></div>
          <button
            onClick={handleMarkOrderArrivedAtHub}
            disabled={loading.markOrdersAsReturnedTohHub}
            className="border border-primary text-primary rounded"
          >
            {loading.markOrdersAsReturnedTohHub && (
              <Spinner size="sm" className="me-50" />
            )}
            {t('Mark Order(s) as Return To Origin')}
          </button>
        </div>
      )}

      <div className='mb-1 mx-1'>
        <TypeOfReturnsInformation returnInfo={COLLAPSED_CONTAINER_LIST.OFF_SYSTEM_RETURN}/>
      </div>
      <div className="return-order-table px-1 pb-1">
        <ExpandableCustomTable
          loading={loading.getReturnOrders}
          success={success.getReturnOrders}
          error={error.getReturnOrders}
          isFilterOrSearchApplied = {!!(Object.values(filter).length || Object.values(searchQuery).length  > 1)}
          data={tableData}
          columns={ALL_RETURN_STATUS_COLUMN(handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType).filter((column) => column.name !== RETURN_TABLE_COLUMN_HEADER.ORDER_ID.name)}
          columnName={ALL_COLUMN_NAME_MAPPING.OMS_ALL_RETURN_ORDER}
          searchcolumnsList={RETURN_ORDER_COLUMNS_LIST.filter((column) => column.id !== RETURN_ORDER_COLUMNS_LIST[0].id)}
          search_column_query={searchQuery}
          handleQueryParams={handleSearch}
          handlePagination={handlePagination}
          meta={returnOrders?.meta || {}}
          useReactPaginate={false}
          selectable={(filter.status?.value === RETURN_STATUS.REVERSE_SHIPMENT_CREATED.key || filter.status?.value === RETURN_STATUS.INITIATED.key) && ability.can(abilityMap.return.edit_order.action, abilityMap.return.edit_order.resource)}
          handleSelectedRowsChange={handleSelectedData}
          showSelected={ability.can(abilityMap.return.edit_order.action, abilityMap.return.edit_order.resource)}
          showColumnsTableHeader={true}
        />
      </div>
      {/* <ReverseShipment isCreateReverseShipmentModalOpen={isCreateReverseShipmentModalOpen} handleCreateReverseShipmentModal={handleCreateReverseShipmentModal}/> */}
      <ReturnOrderDetail isReturnOrderDetailModalOpen={isReturnOrderDetailModalOpen} handleReturnOrderDetailModal={handleReturnOrderDetailModal} handleCreateReverseShipmentModal={handleCreateReverseShipmentModal} handleOrderDetailSidebar={handleOrderDetailSidebar} returnOrderToShowDetail={returnOrderToShowDetail}/>
      <ConfirmationPopup
        isConfirmationPopupOpen={isCancelReturnOrderPopupOpen} 
        handlePopupToggle={handleCancelReturnOrderPopup} 
        isToggle={false}
        popupBody={
          <PopupBody 
            handlePopupToggle={handleCancelReturnOrderPopup}
            handleCancelReturnOrder={handleCancelReturnOrder}
            loading={loading}
            popupIcon = {<AlertTriangle style={{ strokeWidth: 1.5, color: '#FF991F' }} size={64} fill="currentColor" strokeColor="white" stroke="white" />}
            popupName= {('Cancel')}
          />}
      />
      <OrderDetailsSideSlider
        sellerId={returnOrderToShowDetail?.created_order_id}
        isModalOpen={isOrderDetailSidebarOpen}
        setIsModalOpen={setOrderDetailSidebarOpen}
      />
      <CreateReturnOrderProcessing isCreateReturnOrderProcessingModalOpen={isCreateReturnOrderProcessingModalOpen} handleCreateReturnOrderProcessingModal={handleCreateReturnOrderProcessingModal}/>
      <RefundDetail isRefundDetailModalOpen={isRefundDetailModalOpen} handleRefundDetailModal={handleRefundDetailModal}/>
    </div>
  )
}
export default memo(OffSystemReturn)