import ComponentSpinner from '@src/@core/components/spinner/Loading-spinner'
import CancelledImage from '@src/assets/images/orders/orderLogs/Cancelled.svg'
import ConsolidatedImage from '@src/assets/images/orders/orderLogs/Consolidated.svg'
import DeliveredImage from '@src/assets/images/orders/orderLogs/Delivered.svg'
import Hub_Assigned from '@src/assets/images/orders/orderLogs/Hub_Assigned.svg'
import Packing from '@src/assets/images/orders/orderLogs/In_Packing.svg'
import In_Picking from '@src/assets/images/orders/orderLogs/In_Picking.svg'
import New_Order from '@src/assets/images/orders/orderLogs/New_Order.svg'
import Packing_Completed from '@src/assets/images/orders/orderLogs/Packing_Completed.svg'
import Picking_Completed from '@src/assets/images/orders/orderLogs/Picking_Completed.svg'
import Problem_Queue from '@src/assets/images/orders/orderLogs/Problem_Queue.svg'
import shipmentCancelled from '@src/assets/images/orders/orderLogs/Shipment_Cancelled.svg'
import Shipment_Created from '@src/assets/images/orders/orderLogs/Shipment_Created.svg'
import ShippedImage from '@src/assets/images/orders/orderLogs/Shipped.svg'
import ApprovedImage from '@src/assets/images/orders/orderLogs/approved.svg'
import AutoShipmentCreationFail from '@src/assets/images/orders/orderLogs/auto_shipment_creation_fail.svg'
import CreatedImage from '@src/assets/images/orders/orderLogs/created.svg'
import hubReassigned from '@src/assets/images/orders/orderLogs/hubReassigned.svg'
import invoiceUpdated from '@src/assets/images/orders/orderLogs/invoice_updated.svg'
import onHold from '@src/assets/images/orders/orderLogs/onhold.svg'
import orderUpdated from '@src/assets/images/orders/orderLogs/orderUpdated.svg'
import orderSynced from '@src/assets/images/orders/orderLogs/order_synced.svg'
import partialReturn from '@src/assets/images/orders/orderLogs/partialReturn.svg'
import partiallyDelivered from '@src/assets/images/orders/orderLogs/partially_delivered.svg'
import PickListGenerated from '@src/assets/images/orders/orderLogs/picklist_generated.svg'
import readyForHandover from '@src/assets/images/orders/orderLogs/readyForHandover.svg'
import Return_In_Progress from '@src/assets/images/orders/orderLogs/return_in_progress.svg'
import Return_To_Origin from '@src/assets/images/orders/orderLogs/return_to_origin.svg'
import returnedLog from '@src/assets/images/orders/orderLogs/returnedLog.svg'
import shipmentCreatedLog from '@src/assets/images/orders/orderLogs/shipment_created_log.svg'
import { FallBack } from '@src/views/home/ErrorBoundary'
import SidebarContainer from '@src/views/sales/picking-wave/pages/sidebarContainer/SidebarContainer'
import { ORDER_LOG_EVENT_BY } from '@src/views/sales/sales.constant'
import { clearOrderLogs, getOrderLogs, getPicklistsOfStatus } from '@src/views/sales/store/store'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import './OrderLog.scss'

const logsLogo = {
  'New Order': New_Order,
  'Hub Assigned': Hub_Assigned,
  'On Hold': onHold,
  'Problem Queue': Problem_Queue,
  'Order Updated': orderUpdated,
  Approved: ApprovedImage,
  Created: CreatedImage,
  'In Picking': In_Picking,
  Picked: Picking_Completed,
  Consolidated: ConsolidatedImage,
  // "Ready To Pack": ,
  'In Packing': Packing,
  'Ready To Ship': Shipment_Created,
  Shipped: ShippedImage,
  // "Dispatched From Hub": ,
  Packed: Packing_Completed,
  'Shipment Cancelled': shipmentCancelled,
  Cancelled: CancelledImage,
  Delivered: DeliveredImage,
  'Return To Origin': Return_To_Origin,
  'Return In Progress': Return_In_Progress,
  'Partial Return': partialReturn,
  Returned: returnedLog,
  'Ready For Handover': readyForHandover,
  'Invoice Updated': invoiceUpdated,
  'Picklist Generated': PickListGenerated,
  'Order Synced': orderSynced,
  'Automatic Shipment Creation Failed': AutoShipmentCreationFail,
  'Partially Delivered': partiallyDelivered,
  'Shipment Created': shipmentCreatedLog,
  'Hub Reassigned': hubReassigned
}


const OrderLog = ({sellerId}) => {

  const dispatch = useDispatch()
  const {t} = useTranslation()

  const orderLogs = useSelector((store) => store.sales.orderLogs)
  const loading = useSelector((store) => store.sales.loading)
  const picklistsOfStatus = useSelector((store) => store.sales.picklistsOfStatus)
  const selectedPicklistStatus = useSelector((store) => store.sales.selectedPicklistStatus)

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [selectedWave, setSelectedWave] = useState(null)
  

  const handleFetchOrderLogs = () => {
    dispatch(getOrderLogs(sellerId))
  }
  
  const handleWaveDetails = ({item, index}) => {
    if (item.event_updated_by_user_type === ORDER_LOG_EVENT_BY.WAVE.key && !picklistsOfStatus.loading) {
      setSelectedWave({waveId:item.event_updated_by_id, index})
      dispatch(getPicklistsOfStatus({ hub_id:orderLogs.hub_id, waveId: item.event_updated_by_id }))
    }
  }

  useEffect(() => {
    if (!orderLogs) {
      handleFetchOrderLogs()
    }
    return () => {
      dispatch(clearOrderLogs())
    }
  }, [])

  useEffect(() => {
    if (picklistsOfStatus.success && isSidebarOpen === false) {
      // need to persist the details when table data refresh (same api is used)
      setIsSidebarOpen(true)
      const wave = {
        name: picklistsOfStatus.data.data[0].wave.number,
        time: picklistsOfStatus.data.data[0].wave.start_time
      }
      setSelectedWave((prev) => ({...wave, waveId:prev.waveId}))
    }
  }, [picklistsOfStatus])
  
  return (
    <>
      {
        (loading.getOrderLogs)
          ? <ComponentSpinner className="mt-5"/>
          : <PerfectScrollbar>
            {orderLogs?.log?.length > 0
              ? <div className='orderLogsMain pb-2'>
                {orderLogs.log.map((item, index) => <div key={index} className="d-flex flex-column">
                  <div className="d-flex gap-2">
                    <div className='logo-container d-flex flex-column align-items-center gap-1'>
                      {logsLogo[item?.event] ? <div className='d-flex justify-content-center align-items-center mt-1'><img src={logsLogo[item.event]} alt={item.event} height={24} width={24}/></div> : <div className='showCircle d-flex justify-content-center align-items-center mt-1'>
                      </div>}
                      {index < orderLogs.log.length - 1 && <div className={'styleVerticalLine'}></div>}
                    </div>
                    <div className='pt-1'>
                      <h4 className="processHeading txt-body-md">{t(item?.event)}</h4>
                      <p
                        className={classNames(
                          'orderSubHeading',
                          {
                            'text-primary cursor-pointer': item.event_updated_by_user_type === ORDER_LOG_EVENT_BY.WAVE.key,
                            'text-primary-light cursor-not-allowed': item.event_updated_by_user_type === ORDER_LOG_EVENT_BY.WAVE.key && picklistsOfStatus.loading && !isSidebarOpen
                          }
                        )}
                        onClick={() => { handleWaveDetails({item, index}) }}
                      >
                        {item.event_updated_by_user_type !== ORDER_LOG_EVENT_BY.WAVE.key ? <span className='me-4px'>{t('By')}</span> : (selectedWave?.index === index && !isSidebarOpen && picklistsOfStatus.loading) && <Spinner size='sm' className='me-8px' />}
                        {item.event_updated_by}
                      </p>
                      <p className='orderDate'>{item.event_updated_at}</p>
                      <p className=''>{item.note}</p>
                    </div>
                  </div>
                </div>)}
              </div>
              : <FallBack handleRetryFunc={handleFetchOrderLogs} message={t('Please try again later')}/>
            } 
          </PerfectScrollbar>
      }
      <SidebarContainer
        is_sidebar_open={isSidebarOpen}
        set_is_sidebar_open={setIsSidebarOpen}
        waveDetails={{wave:selectedWave}}
        hub_id={orderLogs?.hub_id}
        status={selectedPicklistStatus?.value}
      />
    </>
  )
}

export default OrderLog