import Button from '@src/@core/components/button'
import InputField from '@src/@core/components/input-field'
import no_image from '@src/assets/images/omniful/no_image.svg'
import omnifulLogoWithText from '@src/assets/images/omniful/omniful_logo_with_text.svg'
import { Info, RefreshCcw } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  useSelector
} from 'react-redux'
import { Form } from 'reactstrap'

const SalesChannelShipment = ({ craeteShipmentLoading, shippingDetailsData, handleCreateShipmentWithSalesChannel }) => {

  const { t } = useTranslation()
  const tenant = useSelector(store => store.auth.userData.tenant)
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      remarks: '',
      number_of_boxes:shippingDetailsData?.entity_data.number_of_packages || 1
    },
    mode: 'onChange'
  })

  return (
    <>
      <div className='text-dark p-8px txt-sub-rg flex-center-start rounded-4px shipment-type-info px-1px gap-50 mt-2'>
        <div>
          <Info size='14' />
        </div>
        <div className='ml-1'>
          {t('The shipment for the order will be generated at the sales channel.')}
        </div>
      </div>
      <Form onSubmit={handleSubmit(handleCreateShipmentWithSalesChannel)} className="border rounded-4 mt-2">
        <h5 className="py-1 px-1 fw-bolder bg-primary-lighter text-secondary mb-0 rounded-top-4 txt-h3-md">
          {t('Package Details')}
        </h5>
        {craeteShipmentLoading ? <div className="createShipmentWithSalesChannelConatainer my-2 text-center">
          <div className="d-flex align-items-center justify-content-center h-100">
            <div>
              <div className="parentLogoConatainer d-flex align-items-center justify-content-center gap-1">
                <div className="logoConatainer">
                  <img
                    src={tenant.logo || omnifulLogoWithText}
                    alt="Omniful Logo"
                    className="w-100 h-100 object-fit-contain"
                  />
                </div>
                <div className="spinnerMainContainer fw-bolder d-flex align-items-center justify-content-center gap-50">
                  <div>------</div>
                  <div className="runningSpinnerContainer d-flex align-items-center justify-content-center">
                    <RefreshCcw
                      strokeWidth={3}
                      className={'runningSpinner text-primary'}
                      size={15}
                    />
                  </div>
                  <div>------</div>
                </div>
                <div className="logoConatainer">
                  <img
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null
                      currentTarget.src = no_image
                    }}
                    src={shippingDetailsData.entity_data.sales_channel.logo_url || no_image}
                    alt="Omniful Logo"
                    className="w-100 h-100"
                  />
                </div>
              </div>
              <div className="progressMessage mb-75">
                {shippingDetailsData.entity_data.heading}
              </div>
              <div className="progressMessage2">
                {shippingDetailsData.entity_data.sub_heading}
              </div>
            </div>
          </div>
        </div>
          :
          <div className="px-1 py-1">
            <div className="row mt-2">
              <div className="col-6">
                <InputField
                  isClearable
                  onWheel={(e) => e.target.blur()}
                  type='number'
                  label={t('No of Packages')}
                  errors={errors}
                  {...register('number_of_boxes', {
                    required: t('This field is required'),
                    min: {
                      value: 1,
                      message: t('Please enter a valid number')
                    }
                  })}
                  isRequired
                  disabled={craeteShipmentLoading}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <InputField
                  type="textarea"
                  isClearable
                  onWheel={(e) => e.target.blur()}
                  label={t('Remarks')}
                  errors={errors}
                  {...register('remarks')}
                  disabled={craeteShipmentLoading}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end mt-2">
              <Button type="submit" disabled={craeteShipmentLoading}>
                {t('Proceed')}
              </Button>
            </div>
          </div>
        }
      </Form>
    </>
  )
}

export default SalesChannelShipment