import { ExpandableCustomTable } from '@src/@core/components/globalTable/ExpandableCustomTable'
import RowExpanderWrapper from '@src/@core/components/row-expander-wrapper'
import ComponentSpinner from '@src/@core/components/spinner/Loading-spinner'
import './OrderItemsChildTable.scss'
const OrderItemsChildTable = (props) => {
  const { data, childTableColumns, customStyles, childDataKey, ...rest } = props
  console.log('data in child table', data)

  let childData;
  if (childDataKey.includes('.')) {
    childData=childDataKey.split('.').reduce((acc, key) => acc[key], data);
  } else {
    childData = data[childDataKey];
  }

  return (
    data.loading ?
      <div className='w-100 h-100 flex-center-center flex-column'>
        <ComponentSpinner />
      </div> :
      <div id="order-items-child-table-container">
        <RowExpanderWrapper data={data}>
          <ExpandableCustomTable
            columns={childTableColumns}
            data={childData}
            striped={false}
            responsive
            customStyles={customStyles}
            showPagination={false}
            useReactPaginate={false}
            showColumnsTableHeader={false}
            {...rest}
          />
        </RowExpanderWrapper>
      </div>

  )
}
export default OrderItemsChildTable
