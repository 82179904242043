import Button from '@src/@core/components/button'
import Toggle from '@src/@core/components/toggle-button'
import { PRINT_PAGE_STYLE } from '@src/App.constants'
import { useRef, useState } from 'react'
import Barcode from 'react-barcode'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { Modal, ModalBody } from 'reactstrap'
import { SERIALISATION_OPTION } from '../../constant'

const BatchBarcodePreview = ({ isOpen, toggle, barcodeValue, batchNum, expDate, handlefocusOmnifulSKUBarcode, handlefocusQCBinBarcode }) => {

  const {t} = useTranslation()
  const sku_details = useSelector(store => store.inventory.grn.sku_details?.data)
  const [isBatchDetailsRemove, setIsBatchDetailRemove] = useState(false)
  const handleShowBatchDetails = () => {
    setIsBatchDetailRemove(pre => !pre)

  }
  const singleBarcodeRef = useRef()
  const handlePrintSingleBarcode = useReactToPrint({
    content: () => singleBarcodeRef.current,
    pageStyle: `@page {size: 378px ${!isBatchDetailsRemove ? '270px' : '220px'}; margin: auto 0}`,
    onAfterPrint: () => {
      if (sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
        handlefocusOmnifulSKUBarcode()
      } else {
        handlefocusQCBinBarcode()
      }
    }
  })
   
  return (
    <Modal
      isOpen={isOpen}
      centered
      toggle={toggle}
      contentClassName='rounded-12px bg-white'
    >
      <ModalBody className="d-flex flex-column py-16px px-20px ">
        <div className="d-flex justify-content-end ">
          <X onClick={toggle} size={16} className="text-dark" />
        </div>
        <div ref={singleBarcodeRef} className='flex-center-center flex-column mb-24px'>
          <style>{ PRINT_PAGE_STYLE }</style>
          <Barcode  value={barcodeValue} />
          {!isBatchDetailsRemove && <>
            <div className='text-dark-6 txt-asst-rg'>{t('Batch No')}: {batchNum}</div>
            <div className='text-dark-6 txt-asst-rg'>{t('Expiry Date')}: {expDate}</div>
          </>}
        </div>
        <div className='d-flex justify-content-between mb-16px'>
          <div className='text-dark'>{t('Remove Batch Details')}</div>
          <Toggle ofStyle='success' ofSize='compressed' onClick={handleShowBatchDetails} checked={isBatchDetailsRemove}  activeText='Yes' inactiveText='No' inactiveColor='var(--bs-dark-2)'/>
        </div>
        <div className='d-flex'>
          <Button onClick={() => {
            handlePrintSingleBarcode()
            toggle()
          }}
          className='flex-grow-1 justify-content-center'>{t('Print Barcode')}</Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default BatchBarcodePreview