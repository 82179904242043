import ClosedCasepack from './ClosedCasepack'
import OpenedCasepack from './OpenedCasepack'
import SkuDetailsAndQc from './SkuDetailsAndQc'

const CasePack = ({ sku_details, maxQty, selectedTabId, qcBinBarcodeRef, searchBarcode, grnType }) => {

  if (!sku_details.child_data || (sku_details.child_data && (sku_details.child_data.openedAssignedQty + sku_details.child_data.closedAssignedQty) >= sku_details.child_data.maxQty)) {
    return <SkuDetailsAndQc sku_details={sku_details} maxQty={maxQty} selectedTabId={selectedTabId} qcBinBarcodeRef={qcBinBarcodeRef} searchBarcode={searchBarcode} grnType={grnType}/>
  } else {
    const maxQtyCase = sku_details.openedQty * sku_details.child_sku?.quantity
    return <div>
      {!!sku_details.closedQty && sku_details.closedAssignedQty < sku_details.closedQty && <ClosedCasepack sku_details={sku_details} selectedTabId={selectedTabId} qcBinBarcodeRef={qcBinBarcodeRef} searchBarcode={searchBarcode} />}
      {!!sku_details.openedQty && sku_details.openedAssignedQty < sku_details.openedQty && <OpenedCasepack sku_details={sku_details} maxQty={maxQtyCase} child_data={sku_details.child_data} selectedTabId={selectedTabId} qcBinBarcodeRef={qcBinBarcodeRef} searchBarcode={searchBarcode} />}
    </div>
  }
}

export default CasePack