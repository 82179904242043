import { ExpandableCustomTable } from '@src/@core/components/globalTable/ExpandableCustomTable'
import SKUDetailsCol from '@src/@core/components/sku-details'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import SimpleSkuDetailModal from '@src/views/catalog/components/simple-sku-detail-modal/SimpleSkuDetailModal'
import { setBinDisabledGrnTableData, setBinDisabledReturnOrderId } from '@src/views/inventory/store'
import ReturnOrderDetail from '@src/views/returns/pages/return-order/return-order-details'
import OrderDetailsSideSlider from '@src/views/sales/OrderDetailsSideSlider'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

const BinDisabledOverview = ({ grnDetails, isReturnOrder, toggle, isProcessNextOrder }) => {
  const selectedGlobalHubId = useSelector(store => store.auth.selectedGlobalHubId)
  const po_id = grnDetails.purchase_order_id
  const sto_id = grnDetails.sto_wms_id
  const dispatch = useDispatch()
  const tableData = useSelector(store => store.inventory.binDisabledGrn.tableData)
  const [seeSkuDetails, setSeeSkuDetails] = useState(false)
  const [skuForDetails, setSkuForDetails] = useState({
    seller_id: '',
    seller_sku_id: ''
  })
  const singleReturnGrnOrderDetail = useSelector((state) => state.returns.singleReturnGrnOrderDetail)
  const oms_return_order_id = singleReturnGrnOrderDetail?.oms_return_order_id
  const omitColumnQty = isReturnOrder ? false : (!grnDetails?.is_purchase_order && !grnDetails?.is_sto)
  const singleGrnDetail = useSelector(
    (state) => state.inventory.grn.singleGrnDetail
  )
  const grnResponse = useSelector(store => store.inventory.grn.grnResponse)
  const configurations = useSelector(store => store.auth.selectedHubConfiguration)
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isReturnOrderDetailModalOpen, setReturnOrderDetailModalOpen] = useState(false)
  const [returnOrderToShowDetail, setReturnOrderToShowDetail] = useState(null)

  const { mutate: getShipmentsOfSinglePo, isPending: shipmentsOfPoDataPending } = useApi({
    isMutation: true,
    apiKey: 'get-Shipments-Of-Single-Po',
    apiFn: () => {
      const params = {
        awb: grnDetails.awb
      }
      return axiosInstance.get(`/api/v1/wms/hubs/${selectedGlobalHubId}/purchase_orders/${po_id}/shipments`, { params })
    },
    onSuccess: (data) => {
      const mappedData = data?.data?.purchase_order_items?.map((item) => ({
        id: item.id,
        name: item.sku?.name,
        code: item.sku?.seller_sku_code,
        image: item.sku?.images?.[0]?.url,
        is_serialised: item.sku?.is_serialised,
        seller_sku_id: item.sku?.seller_sku_id,
        pass_qty: 0,
        fail_qty: 0,
        total_qty: item.display_ordered_quantity,
        purchase_order_id: item.purchase_order_id,
        seller_id: data?.data?.seller?.id
      }))
      dispatch(setBinDisabledGrnTableData(mappedData))
    }
  })

  const { mutate: getSinglePurchaseOrderList, isPending: singlepurchaseOrderDataPending } = useApi({
    isMutation: true,
    apiKey: 'get-Single-Purchase-Order-List',
    apiFn: () => {
      return axiosInstance.get(`/api/v1/wms/hubs/${selectedGlobalHubId}/purchase_order/${po_id}`)
    },
    onSuccess: (data) => {
      const mappedData = data?.data?.purchase_order_items?.map((item) => ({
        id: item.id,
        name: item.sku?.name,
        code: item.sku?.seller_sku_code,
        image: item.sku?.images?.[0]?.url,
        is_serialised: item.sku?.is_serialised,
        seller_sku_id: item.sku?.seller_sku_id,
        pass_qty: 0,
        fail_qty: 0,
        total_qty: item.display_quantity,
        purchase_order_id: item.purchase_order_id,
        seller_id: item.seller_id
      }))
      dispatch(setBinDisabledGrnTableData(mappedData))
    }
  })

  const { mutate: getStoItems, isPending: stoDetailPending } = useApi({
    isMutation: true,
    apiKey: 'get-Sto-Items',
    apiFn: () => {
      return axiosInstance.get(`/api/v1/wms/hubs/${selectedGlobalHubId}/stock_transfer_order/${sto_id}`)
    },
    onSuccess: (data) => {
      const mappedData = data?.data?.stock_transfer_order_items?.map((item) => ({
        id: item.id,
        name: item.sku?.name,
        code: item.sku?.seller_sku_code,
        image: item.sku?.images?.[0]?.url,
        is_serialised: item.sku?.is_serialised,
        seller_sku_id: item.sku?.seller_sku_id,
        pass_qty: 0,
        fail_qty: 0,
        total_qty: item.quantity,
        seller_id: item.sku?.seller_id
      }))
      dispatch(setBinDisabledGrnTableData(mappedData))
    }
  })

  const { mutate: getReturnDetails } = useApi({
    isMutation: true,
    apiKey: 'get-Return-Details-bin-disabled',
    apiFn: () => axiosInstance.get(`/api/v1/oms/returns/orders/${oms_return_order_id}`),
    onSuccess: (data) => {
      dispatch(setBinDisabledReturnOrderId(data?.data?.return_order_id))
      const mappedData = data?.data?.order_items?.map((item) => ({
        id: item.id,
        name: item.seller_sku?.name,
        code: item.seller_sku_code,
        image: item.seller_sku?.images?.[0]?.default,
        is_serialised: item.sku?.is_serialised,
        seller_sku_id: item.seller_sku?.id,
        pass_qty: 0,
        fail_qty: 0,
        total_qty: item.return_quantity,
        seller_id: item.seller_sku?.seller_id
      }))
      dispatch(setBinDisabledGrnTableData(mappedData))
      if (isProcessNextOrder) {
        toggle()
      }
    }
  })

  useEffect(() => {
    if (
      grnDetails?.is_purchase_order &&
      !tableData?.length
    ) {
      if (grnDetails?.awb) {
        getShipmentsOfSinglePo()
      } else {
        getSinglePurchaseOrderList()
      }
    } else if (grnDetails?.is_sto && !tableData?.length) {
      getStoItems()
    } else if (singleReturnGrnOrderDetail && isReturnOrder && !tableData?.length) {
      getReturnDetails()
    }
  }, [singleReturnGrnOrderDetail])

  const handleSkuDetails = ({ seller_id, seller_sku_id }) => {
    setSkuForDetails({ seller_id, seller_sku_id })
    if (seller_id && seller_sku_id) {
      setSeeSkuDetails(p => !p)
    }
  }

  const handleReturnOrderDetailModal = () => {
    setReturnOrderDetailModalOpen((prev) => !prev)
  }

  const handleOrderDetailSidebar = () => {
    setIsModalOpen(pre => !pre)
  }

  const columns = [
    {
      id: 1,
      name: t('SKU Details'),
      minWidth: '200px',
      cell: (row) => {
        return  <SKUDetailsCol
          skuDetailData={{
            isValidSku: true,
            id: row.id,
            image: {
              src: row.image,
              alt: row.name
            },
            details: {
              name: row.name,
              handleSkuDetails: () => (row.name ? handleSkuDetails({ seller_id: row.seller_id, seller_sku_id: row.seller_sku_id }) : null),
              skuCode: row.code,
              columnKey: 'sku_details'
            },
          }}
        />
      }
    },
    {
      id: 2,
      name: <div className="flex-center-end w-100">{isReturnOrder ? t('Returned Qty') : t('Ordered Qty')}</div>,
      minWidth: '120px',
      omit: omitColumnQty,
      cell: (row) => (<div className="flex-center-end w-100">{row.total_qty}</div>)
    },
    {
      id: 3,
      name: <div className="flex-center-end w-100">{t('Passed Qty')}</div>,
      minWidth: '100px',
      cell: (row) => (<div className="flex-center-end w-100 text-success">{row.pass_qty}</div>)
    },
    {
      id: 4,
      name: <div className="flex-center-end w-100">{t('Failed Qty')}</div>,
      minWidth: '100px',
      cell: (row) => (<div className="flex-center-end w-100 text-danger">{row.fail_qty}</div>)
    },
    {
      id: 5,
      name: t('Batch Details'),
      minWidth: '120px',
      cell: (row) => (row.batch?.label ? <div className="d-flex flex-column">
        <span className="txt-sub-rg text-dark">{row.batch?.label}</span>
        <span className="txt-asst-rg text-dark-6">Exp: {row.batch?.expiry_date}</span>
      </div> : '-')
    },
    {
      id: 6,
      omit: isReturnOrder,
      name: t('Barcode Setting'),
      minWidth: '120px',
      cell: (row) => (row.is_serialised ? 'Serialised' : 'Non-Serialised')
    }
  ]

  return (
    <>
      <section className="px-20px pt-20px w-100 d-flex flex-column gap-20px">

        <div className="flex-center-start gap-16px txt-sub-rg">
          {!isReturnOrder && <div className="min-width-150">
            <div className="text-dark-6">{t('Source')}</div>
            <div className="text-dark">
              {singleGrnDetail?.entry_identification || '-'}
            </div>
          </div>}

          {!isReturnOrder && singleGrnDetail?.entry_identification ? <div className="min-width-150">
            <div className="text-dark-6">{`${singleGrnDetail?.entry_identification} ${singleGrnDetail?.identification_display_id ? 'ID' : 'Name'}`}</div>
            <div className="text-dark">{singleGrnDetail?.identification_display_id || `${singleGrnDetail?.seller?.name} & ${singleGrnDetail?.supplier?.name}`}</div>
          </div> : null}

          {!isReturnOrder && singleGrnDetail?.entry_identification === 'Gate Entry' && (singleGrnDetail?.is_purchase_order || singleGrnDetail?.is_stock_transfer_order) ?  <div className="min-width-150">
            <div className="text-dark-6">{singleGrnDetail?.is_purchase_order ? 'PO ID' : 'STO ID'}</div>
            <div className="text-dark">{singleGrnDetail?.source_display_id || '-'}</div>
          </div> : null}

          {!isReturnOrder && singleGrnDetail?.remarks ? <div className="min-width-150 max-width-250 text-truncate">
            <div className="text-dark-6">{t('Remark')}</div>
            <div className="text-dark" title={singleGrnDetail?.remarks}>{singleGrnDetail?.remarks}{singleGrnDetail?.remarks?.length > 42 ? '...' : ''}</div>
          </div> : null}

          {isReturnOrder && <div className="min-width-150">
            <div className="text-dark-6">{t('Return Order ID')}</div>
            <div className="text-primary cursor-pointer"
              onClick={() => {
                setReturnOrderDetailModalOpen(true)
                setReturnOrderToShowDetail({
                  orderID: grnDetails?.return_order_detail?.oms_return_order_id,
                  return_order_id: grnDetails?.return_order_detail?.display_order_id
                })

              }}
            >{grnDetails?.return_order_detail?.display_order_id || '-'}</div>
          </div>}

          {isReturnOrder && <div className="min-width-150">
            <div className="text-dark-6">{t('Order ID')}</div>
            <div className="text-primary cursor-pointer"
              onClick={() => setIsModalOpen(pre => !pre)}
            >
              {grnDetails?.return_order_detail?.seller_sales_channel_order_id || '-'}
            </div>
          </div>}
        </div>

        {/* <div className="bg-warning-light rounded-8px p-8px flex-center-start gap-8px">
          <Info size={16} color="var(--bs-warning)" />
          <span className="txt-sub-rg text-dark">{t('By default, all the scanned items will be marked as pass.')}</span>
        </div> */}

        <ExpandableCustomTable
          loading={shipmentsOfPoDataPending || singlepurchaseOrderDataPending || stoDetailPending}
          data={tableData || []}
          columns={columns}
          meta={{}}
          handlePagination={() => { }}
          showPagination={false}
          showColumnsTableHeader={false}
          showColumnsDropdown={false}
        />
      </section>

      <SimpleSkuDetailModal
        skuForDetails={skuForDetails}
        toggleSimpleSkuModal={() => setSeeSkuDetails(p => !p)}
        isOpen={seeSkuDetails}
      />

      <OrderDetailsSideSlider sellerId={grnDetails?.return_order_detail?.oms_order_id} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <ReturnOrderDetail
        isReturnOrderDetailModalOpen={isReturnOrderDetailModalOpen}
        handleReturnOrderDetailModal={handleReturnOrderDetailModal}
        handleOrderDetailSidebar={handleOrderDetailSidebar}
        returnOrderToShowDetail={returnOrderToShowDetail}
      />
    </>
  )
}

export default BinDisabledOverview