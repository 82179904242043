import BarcodeScanner from '@src/@core/components/barcodeScanner'
import FloatingDropDown from '@src/@core/components/floating-dropdown'
import ToggleTabs from '@src/@core/components/toggleTabs'
import { KEYBOARD_KEYS } from '@src/App.constants'
import {
  clearGrnResponse,
  createNewGrnForGateEntry,
  getAsyncGateEntries,
  setSellerId
} from '@src/views/inventory/store'
import { clearSingleReturnOrderDetail } from '@src/views/returns/store'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { GATE_ENTRY_OPTIONS } from '../constant'
import GrnWithOffTypeReturns from './grnWithOffTypeReturns'

const GrnWithGateEntry = ({ isBarcodePrint, isNextOrderProcess, setIsNextOrderProcess }) => {
  const [selectedTabId, setSelectedTabId] = useState(
    GATE_ENTRY_OPTIONS.gate_entry.id
  )
  const configurations = useSelector(store => store.auth.selectedHubConfiguration)
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    setValue
  } = useForm()
  const dispatch = useDispatch()
  const selectedGlobalHubID = useSelector(
    (store) => store.auth.selectedGlobalHubId
  )
  const tabChangeHandler = (tab) => {
    setSelectedTabId(tab.id)
    dispatch(clearGrnResponse())
    reset()
    // dispatch(clearCreateReturnGrnResponse())
    dispatch(clearSingleReturnOrderDetail())
  }

  const handleSelectedGateEntryForGRN = (data) => {
    const body = {
      entity_id: data.gate_entry_id.value,
      hub_id: selectedGlobalHubID
    }

    // setting seller id in store
    if (data.gate_entry_id.seller) dispatch(setSellerId(data.gate_entry_id.seller.id))

    dispatch(createNewGrnForGateEntry(body))
  }

  const handleSelectedCartonBarcodeForGRN = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      const body = {
        ['carton_barcode']: e.target.value.toString().trim(),
        ['hub_id']: selectedGlobalHubID
      }
  
      dispatch(createNewGrnForGateEntry(body))
    }
  }
  
  const handleGateEntryChange = (e) => {
    if (e) {
      handleSubmit(handleSelectedGateEntryForGRN)()
    } else {
      dispatch(clearGrnResponse())
    }
  }

  useEffect(() => {
    if (!isBinDisabled && (!watch('carton_barcode') || !watch('gate_entry_id'))) {
      dispatch(clearGrnResponse())
    }
  }, [watch('carton_barcode'), watch('gate_entry_id')])

  const handleCartonBarcodeClear = () => {
    setValue('carton_barcode', '')
  }

  useEffect(() => {
    if (isNextOrderProcess) setSelectedTabId(GATE_ENTRY_OPTIONS.off_system_returns.id)
  }, [])

  return (
    <div className="d-flex flex-column gap-24px">
      <ToggleTabs
        tabDataList={Object.values(GATE_ENTRY_OPTIONS)}
        selectedTabId={selectedTabId}
        onTabChange={tabChangeHandler}
        isDisabled={isNextOrderProcess || isBarcodePrint}
      />
      {selectedTabId === GATE_ENTRY_OPTIONS.gate_entry.id && (
        <FloatingDropDown
          name="gate_entry_id"
          control={control}
          errors={errors}
          loadOptions={getAsyncGateEntries}
          validationSchema={{
            gate_entry_id: { required: 'This field is required' }
          }}
          isAsync
          isRequired
          onChangeFunc={handleGateEntryChange}
          isClearable
          title="Gate Entry ID"
          additional={{
            page: 1,
            hub_id: selectedGlobalHubID
          }}
          openMenuOnFocus={false}
          closeMenuOnSelect={true}
          blurInputOnSelect={true}
        />
      )}
      {selectedTabId === GATE_ENTRY_OPTIONS.carton_barcode.id && (
        <BarcodeScanner
          width="380px"
          startIcon={
            <img
              src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
              alt="QR Code"
              width="16px"
              height="16px"
            />
          }
          placeholder="Inward Carton Barcode"
          control={control}
          name="carton_barcode"
          onKeyDown={handleSelectedCartonBarcodeForGRN}
          hasValue={watch('carton_barcode')}
          handleClear={handleCartonBarcodeClear}
        />
      )}
      {selectedTabId === GATE_ENTRY_OPTIONS.off_system_returns.id && <GrnWithOffTypeReturns isNextOrderProcess={isNextOrderProcess} setIsNextOrderProcess={setIsNextOrderProcess} />}
    </div>
  )
}

export default GrnWithGateEntry
