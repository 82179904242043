import BarcodeScanner from '@src/@core/components/barcodeScanner'
import CustomToast from '@src/@core/components/custom-toast/CustomToast'
import FloatingDropDown from '@src/@core/components/floating-dropdown'
import ToggleTabs from '@src/@core/components/toggleTabs'
import { KEYBOARD_KEYS } from '@src/App.constants'
import {
  clearAwbDetailsResponse,
  clearGrnResponse,
  clearGrnStore,
  createNewGrnForPo,
  getAsyncPurchaseOrderList,
  getAsyncStoList,
  getAwbDetailsData,
  setSellerId
} from '@src/views/inventory/store'
import { clearSingleReturnOrderDetail } from '@src/views/returns/store'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { PO_AND_STO_ENABLED_OPTIONS, PO_AND_STO_ENTITY_TYPE, PO_ENABLED_OPTIONS, STO_ENABLED_OPTIONS } from '../constant'
import GrnWithOffTypeReturns from './grnWithOffTypeReturns'
import GrnWithSellerAndSupplier from './grnWithSellerAndSupplier'

const GrnWithPOAndSto = ({ grnConfig, isNextOrderProcess, setIsNextOrderProcess, isBarcodePrint }) => {
  const [selectedTabId, setSelectedTabId] = useState(grnConfig.purchase_order ? PO_AND_STO_ENABLED_OPTIONS.purchase_order_id.id : PO_AND_STO_ENABLED_OPTIONS.stock_transfer_order_id.id)
  const selectedGlobalHubID = useSelector(
    (store) => store.auth.selectedGlobalHubId
  )
  // const grnResponse = useSelector(store => store.inventory.grn.grnResponse)
  const awbDetails = useSelector(store => store.inventory.awbDetails)
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    reset
  } = useForm({
    mode: 'onBlur'
  })
  const dispatch = useDispatch()


  const tabChangeHandler = (tab) => {
    setSelectedTabId(tab.id)
    dispatch(clearGrnResponse())
    dispatch(clearAwbDetailsResponse())
    reset()
    // dispatch(clearCreateReturnGrnResponse())
    dispatch(clearSingleReturnOrderDetail())
  }

  const handleScanAWBBarcodeNumber = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      if (e.target.value === '') {
        CustomToast('Please enter a AWB number', { my_type: 'error' })
        return
      }
      if (e.target.value.trim()) clearErrors('awb_number')
      dispatch(getAwbDetailsData({hubId: selectedGlobalHubID, awbNumber: e.target.value.trim()}))
    }
  }
  const handleSelectedPOAndSTOForGRN = (data, type) => {
    const body = {
      entity_id: PO_AND_STO_ENTITY_TYPE.PO === type ?  data.purchase_order_id.value.toString() : data.sto_id.value.toString(),
      entity_type: type,
      hub_id: selectedGlobalHubID
    }
    if (watch('awb_number')) {
      body.awb = watch('awb_number')
    }
    // setting seller id in store
    if (data.purchase_order_id?.seller && PO_AND_STO_ENTITY_TYPE.PO === type) { 
      dispatch(setSellerId(data.purchase_order_id.seller.id)) 
    }
    if (data.sto_id?.seller && PO_AND_STO_ENTITY_TYPE.STO === type) {
      dispatch(setSellerId(data.sto_id.seller.id)) 
    }

    dispatch(createNewGrnForPo(body))
  }

  const handlePOAndSTOChange = (e, type) => {
    if (type === PO_AND_STO_ENTITY_TYPE.PO && e?.type === 'shipment') {
          
      if (watch('awb_number')) {
        handleSubmit((data) => handleSelectedPOAndSTOForGRN(data, type))()
      } else {
        setError('awb_number', { type: 'custom', message: 'You need to scan AWB for this GRN' })
        dispatch(clearGrnStore())
      }
    } else {
      if (e) {
        handleSubmit((data) => handleSelectedPOAndSTOForGRN(data, type))()
      } else {
        dispatch(clearGrnResponse())
      }
    }
    
  }
  
  useEffect(() => {
    if (awbDetails?.is_success) {
      if (awbDetails.data.entity_type === 'po') {
        setValue('purchase_order_id', {label: awbDetails.data.display_id, value:awbDetails.data.entity_id})
        handlePOAndSTOChange({label: awbDetails.data.display_id, value:awbDetails.data.entity_id}, PO_AND_STO_ENTITY_TYPE.PO)
        dispatch(setSellerId(awbDetails.data.seller_id))
      }
      if (awbDetails.data.entity_type === 'sto') {
        setValue('sto_id', {label: awbDetails.data.display_id, value:awbDetails.data.entity_id})
        handlePOAndSTOChange({label: awbDetails.data.display_id, value:awbDetails.data.entity_id}, PO_AND_STO_ENTITY_TYPE.STO)
        dispatch(setSellerId(awbDetails.data.seller_id))
      }
    }
  }, [awbDetails])

  useEffect(() => {
    if (isNextOrderProcess) setSelectedTabId(PO_AND_STO_ENABLED_OPTIONS.off_system_returns.id)
    return () => { 
      setValue('purchase_order_id', null) 
    }
  }, [])

  const handleClearAWBNumber = () => {
    setValue('purchase_order_id', null)
    setValue('sto_id', null)
    setValue('awb_number', '')
    dispatch(clearGrnResponse())
    dispatch(clearAwbDetailsResponse())
  }

  return grnConfig.purchase_order && grnConfig.stock_transfer_order ? (
    <div className="d-flex flex-column gap-24px">
      <ToggleTabs
        tabDataList={Object.values(PO_AND_STO_ENABLED_OPTIONS)}
        selectedTabId={selectedTabId}
        onTabChange={tabChangeHandler}
        isDisabled={isNextOrderProcess || isBarcodePrint}
      />
      
      {selectedTabId === PO_AND_STO_ENABLED_OPTIONS.purchase_order_id.id && (
        <>
          <BarcodeScanner
            startIcon={
              <img
                src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
                alt="QR Code"
                width="16px"
                height="16px"
              />
            }
            placeholder="Scan AWB Number"
            errors={errors}
            control={control}
            name="awb_number"
            // disabled={grnResponse.id}
            onKeyDown={handleScanAWBBarcodeNumber}
            hasValue={watch('awb_number')}
            handleClear={handleClearAWBNumber}
          />
          <FloatingDropDown
            control={control}
            errors={errors}
            name="purchase_order_id"
            loadOptions={getAsyncPurchaseOrderList}
            isRequired
            isClearable
            value={watch('purchase_order_id')}
            validationSchema={{
              purchase_order_id: { required: 'This field is required' }
            }}
            isDisabled={awbDetails?.is_success}
            isAsync={true}
            onChangeFunc={(e) => handlePOAndSTOChange(e, PO_AND_STO_ENTITY_TYPE.PO)}
            title="Select Purchase Order ID"
            additional={{
              page: 1,
              hub_id: selectedGlobalHubID
            }}
            openMenuOnFocus={false}
            closeMenuOnSelect={true}
            blurInputOnSelect={true}
          />
        </>
      )}
      {selectedTabId ===
        PO_AND_STO_ENABLED_OPTIONS.stock_transfer_order_id.id && (
        <>
          <BarcodeScanner
            startIcon={
              <img
                src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
                alt="QR Code"
                width="16px"
                height="16px"
              />
            }
            placeholder="Scan AWB Number"
            errors={errors}
            control={control}
            name="awb_number"
            // disabled={grnResponse.id}
            onKeyDown={handleScanAWBBarcodeNumber}
            hasValue={watch('awb_number')}
            handleClear={handleClearAWBNumber}
          />
      
          <FloatingDropDown
            control={control}
            errors={errors}
            name="sto_id"
            loadOptions={getAsyncStoList}
            isAsync={true}
            isRequired
            value={watch('sto_id')}
            isClearable
            isDisabled={awbDetails?.is_success}
            onChangeFunc={(e) => handlePOAndSTOChange(e, PO_AND_STO_ENTITY_TYPE.STO)}
            validationSchema={{ sto_id: { required: 'This field is required' } }}
            title="Select Stock Transfer Order ID"
            additional={{
              page: 1,
              hub_id: selectedGlobalHubID,
              status_not_in: 'cancelled'
            }}
            openMenuOnFocus={false}
            closeMenuOnSelect={true}
            blurInputOnSelect={true}
          />
        </>
      )}
      {selectedTabId === PO_AND_STO_ENABLED_OPTIONS.off_system_returns.id && <GrnWithOffTypeReturns isNextOrderProcess={isNextOrderProcess} setIsNextOrderProcess={setIsNextOrderProcess}/>}
    </div>
  ) : (
    <div className="mt-16px d-flex flex-column gap-28px">
      {grnConfig.purchase_order && (
        <>
          <ToggleTabs
            tabDataList={Object.values(PO_ENABLED_OPTIONS)}
            selectedTabId={selectedTabId}
            onTabChange={tabChangeHandler}
            isDisabled={isNextOrderProcess || isBarcodePrint}
          />
          {selectedTabId === PO_ENABLED_OPTIONS.purchase_order_id.id && <>
            <BarcodeScanner
              startIcon={
                <img
                  src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
                  alt="QR Code"
                  width="16px"
                  height="16px"
                />
              }
              placeholder="Scan AWB Number"
              errors={errors}
              control={control}
              name="awb_number"
              onKeyDown={handleScanAWBBarcodeNumber}
              hasValue={watch('awb_number')}
              handleClear={handleClearAWBNumber}
            />
            <FloatingDropDown
              control={control}
              errors={errors}
              name="purchase_order_id"
              loadOptions={getAsyncPurchaseOrderList}
              value={watch('purchase_order_id')}
              isAsync={true}
              isRequired
              isClearable
              isDisabled={awbDetails?.is_success}
              onChangeFunc={(e) => handlePOAndSTOChange(e, PO_AND_STO_ENTITY_TYPE.PO)}
              validationSchema={{
                purchase_order_id: { required: 'This field is required' }
              }}
              title="Select Purchase Order ID"
              additional={{
                page: 1,
                hub_id: selectedGlobalHubID
              }}
              openMenuOnFocus={false}
              closeMenuOnSelect={true}
              blurInputOnSelect={true}
            />
          </>}
          {selectedTabId === PO_ENABLED_OPTIONS.off_system_returns.id && <GrnWithOffTypeReturns isNextOrderProcess={isNextOrderProcess} setIsNextOrderProcess={setIsNextOrderProcess}/>}
        </>
      )}
      
      {grnConfig.stock_transfer_order && (
        <>
          <ToggleTabs
            tabDataList={Object.values(STO_ENABLED_OPTIONS)}
            selectedTabId={selectedTabId}
            onTabChange={tabChangeHandler}
            isDisabled={isNextOrderProcess || isBarcodePrint}
          />
          {selectedTabId === STO_ENABLED_OPTIONS.stock_transfer_order_id.id &&
          <>
            <BarcodeScanner
              startIcon={
                <img
                  src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
                  alt="QR Code"
                  width="16px"
                  height="16px"
                />
              }
              placeholder="Scan AWB Number"
              errors={errors}
              control={control}
              name="awb_number"
              onKeyDown={handleScanAWBBarcodeNumber}
              hasValue={watch('awb_number')}
              handleClear={handleClearAWBNumber}
            />

            <FloatingDropDown
              control={control}
              errors={errors}
              name="sto_id"
              loadOptions={getAsyncStoList}
              isAsync={true}
              isRequired
              value={watch('sto_id')}
              isClearable
              isDisabled={awbDetails?.is_success}
              onChangeFunc={(e) => handlePOAndSTOChange(e, PO_AND_STO_ENTITY_TYPE.STO)}
              validationSchema={{ sto_id: { required: 'This field is required' } }}
              title="Select Stock Transfer Order ID"
              additional={{
                page: 1,
                hub_id: selectedGlobalHubID,
                status_not_in: 'cancelled'
              }}
              openMenuOnFocus={false}
              closeMenuOnSelect={true}
              blurInputOnSelect={true}
            />
          </>
          }
          {
            selectedTabId === STO_ENABLED_OPTIONS.seller_supplier.id && 
            <GrnWithSellerAndSupplier 
              control={control} 
              errors={errors} 
              setValue={setValue} 
              watch={watch} 
              reset={reset} 
              isBarcodePrint={isBarcodePrint} 
              isNextOrderProcess={isNextOrderProcess} 
              setIsNextOrderProcess={setIsNextOrderProcess}
              isUsedInPoSto={true}
            />
          }
          {selectedTabId === PO_AND_STO_ENABLED_OPTIONS.off_system_returns.id && <GrnWithOffTypeReturns isNextOrderProcess={isNextOrderProcess} setIsNextOrderProcess={setIsNextOrderProcess}/>}
        </>
      )}
    </div>
  )
}

export default GrnWithPOAndSto
