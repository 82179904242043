import Button from '@src/@core/components/button'
import RadioButton from '@src/@core/components/radio-button'
import { GRN_CONTAINER_TYPES } from '@src/views/inventory/inventory.constants'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Modal } from 'reactstrap'

const SelectContainerQcModal = ({ isOpen, toggle, binType, containerType, setContainerType, handleAddBinModal }) => {

  const { t } = useTranslation()

  const handleOpenAddBinModal = () => {
    handleAddBinModal(binType)
    toggle()
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      size='sm'
      contentClassName='rounded-12px'
    >
      <div className='py-16px ps-20px pe-16px'>
        <div className='flex-center-between text-dark'>
          <div className='txt-h3-md'>
            {t('Select Container Type')}
          </div>
          <div className='cursor-pointer'>
            <X size={16} onClick={toggle} />
          </div>
        </div>
        <div className='mt-24px mb-16px d-flex flex-column gap-16px'>
          <RadioButton
            id='radio-bin'
            name='container-type-radio'
            label='Bin'
            value={GRN_CONTAINER_TYPES.BIN}
            checked={containerType === GRN_CONTAINER_TYPES.BIN}
            onChange={(e) => setContainerType(e.target.value)}
          />
          <RadioButton
            id='pallet-bin'
            name='container-type-radio'
            label='Pallet'
            value={GRN_CONTAINER_TYPES.PALLET}
            checked={containerType === GRN_CONTAINER_TYPES.PALLET}
            onChange={(e) => setContainerType(e.target.value)}
          />
        </div>
        <div className='mt-16px flex-center-end'>
          <Button onClick={handleOpenAddBinModal}>{t('Proceed')}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default SelectContainerQcModal