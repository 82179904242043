import PropTypes from 'prop-types'
import { memo } from 'react'
import FloatingDropdown from './FloatingDropdown'
import './styles.scss'

/**
 * Select component to render different types of select inputs.
 * @param {object} props - The component props.
 * @param {string} props.variant - The type of select input to render.
 * @param {string} [props.key] - The key for the component.
 * @param {string} props.name - The name of the select input.
 * @param {string} [props.label] - The label for the select input.
 * @param {string} [props.width] - The width of the select input.
 * @param {boolean} [props.isRequired] - Whether the select input is required.
 * @param {string} [props.infoText] - Additional information text.
 * @param {object} [props.errors] - Error messages for the select input.
 * @param {function} [props.loadOptions] - Function to load options.
 * @param {function} [props.onChange] - Callback function for change event.
 * @param {boolean} [props.isMulti] - Whether the select input allows multiple selections.
 * @param {boolean} [props.isDisabled] - Whether the select input is disabled.
 * @param {Array} [props.cacheUniqs] - Cache unique values.
 * @param {object} [props.modifiedClassNames] - Modified class names.
 * @param {object} [props.customComponents] - Custom components.
 * @param {boolean} [props.menuShouldBlockScroll] - Whether the menu should block scroll.
 * @param {boolean} [props.isLoading] - Whether the select input is in a loading state.
 * @param {function} [props.isOptionDisabled] - Function to determine if an option is disabled.
 * @param {string} [props.menuPlacement] - The placement of the menu (e.g., 'auto', 'top', 'bottom').
 * @param {HTMLElement} [props.menuPortalTarget] - The target element for the menu portal.
 * @param {string} [props.loadingMessage] - The message to display while options are loading.
 * @param {string} [props.noOptionsMessage] - The message to display when no options are available.
 * @param {boolean} [props.closeMenuOnSelect] - Whether to close the menu when an option is selected.
 * @param {boolean} [props.openMenuOnFocus] - Whether to open the menu when the input is focused.
 * @param {function} [props.openMenuOnClick] - Whether to open the menu when the select is clicked.
 * @param {function} [props.menuPosition] - The position of the menu (e.g., 'fixed', 'absolute').
 * @returns {JSX.Element} The rendered component.
 */
const Select = ({ variant, ...rest }) => {
  const variants = {
    floatingDropdown: <FloatingDropdown {...rest} />
  }

  const RenderedComponent = variants[variant]
  return <div id='select-component'>{RenderedComponent}</div>
}

Select.propTypes = {
  variant: PropTypes.oneOf(['floatingDropdown']).isRequired,
  key: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.string,
  isRequired: PropTypes.bool,
  infoText: PropTypes.string,
  errors: PropTypes.object,
  loadOptions: PropTypes.func,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  cacheUniqs: PropTypes.array,
  modifiedClassNames: PropTypes.object,
  customComponents: PropTypes.object,
  menuShouldBlockScroll: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  menuPlacement: PropTypes.string,
  menuPortalTarget: PropTypes.element,
  loadingMessage: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  openMenuOnFocus: PropTypes.bool,
  openMenuOnClick: PropTypes.bool,
  menuPosition: PropTypes.string,
  cacheOptions: PropTypes.bool
}

export default memo(Select)
