import CustomToast from '@components/custom-toast/CustomToast'
import Button from '@src/@core/components/button'
import InputField from '@src/@core/components/input-field'
import { axiosInstance } from '@src/network/AxiosInstance'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Modal, Spinner } from 'reactstrap'

const AddSkuLabelModal = ({ isOpen, toggle, label, setLabel, labelId, isEdit, isButtonDisabled, setIsLabelSuccess }) => {
  const { t } = useTranslation()

  const { mutate: updateSkuLabel, isPending: isUpdateSkuLabelPending } = useMutation({
    mutationKey: ['update-sku-tag'],
    mutationFn: ({ body, labelId }) => axiosInstance.put(`/api/v1/products/labels/${labelId}`, body),
    onSuccess: () => {
      CustomToast('Sku tag updated successfully', { my_type: 'success' })
      setIsLabelSuccess(true)
      toggle()
    }
  })

  const handleSubmit = () => {
    const body = {
      name: label.trim()
    }
    if (isEdit) {
      updateSkuLabel({ body, labelId })
    }
  }

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} onClosed={() => {
      setLabel('')
      setIsLabelSuccess(false)
    }}>
      <form className='p-24px d-flex flex-column gap-24px' onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        handleSubmit()
      }}>
        <div className="d-flex flex-column gap-16px">
          <h1 className='txt-h1-sb m-0 p-0'>{t('Edit Sku Tag')}</h1>
          <span className="txt-body-rg text-dark">{t('Editing this SKU tag will update it on all SKUs where it is currently applied.')}</span>
        </div>
        <div className="d-flex flex-column gap-28px">
          <InputField isRequired label="Label" value={label} onChange={(e) => setLabel(e.target.value)} />
          <div className="d-flex gap-12px align-items-center justify-content-end w-100">
            <Button type='button' ofStyle='noBackground' disabled={isUpdateSkuLabelPending} onClick={toggle}>{t('Cancel')}</Button>
            <Button className="flex-center-center" type='submit'
              disabled={!label.trim() || isUpdateSkuLabelPending || isButtonDisabled}
            >
              {(isUpdateSkuLabelPending) && <Spinner size="sm" className="text-white" />}
              {t('Done')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default AddSkuLabelModal