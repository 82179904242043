import { clearDataAfterSKUScanned, setLastScannedBin } from '@src/views/inventory/store'
import QualityCheck from '@src/views/returns/pages/return-grn-order-processing/revamp-create-return-order-processing/components/qualityCheck'
import { getReturnOrderDetail } from '@src/views/returns/store'
import { resetSuccess } from '@src/views/sales-channel/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SERIALISATION_OPTION } from '../constant'

const OffTypeSystemReturnQc = (props) => {
  const {control, watch, setValue, errors, reset, register, setError, clearErrors} = props
  const [qty, setQty] = useState(1)
  const [totalItemQtyToScan, setTotalItemQtyToScan] = useState(1)
  const configurations = useSelector(store => store.auth.selectedHubConfiguration)
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled

  const sku_details = useSelector(
    (store) => store.inventory.grn.sku_details?.data
  )
  const addSkuInGrnBinResponse = useSelector(
    (store) => store.inventory.grn.addSkuInGrnBinResponse
  )
  const processNextReturnOrderResponse = useSelector(store => store.returns.processNextReturnOrderResponse)
  const processNextReturnOrderSuccess = useSelector((state) => state.returns.success.processNextReturnOrder)
  const returnGrnResponse = useSelector((state) => state.returns.createReturnGrnResponse?.data)
  const dispatch = useDispatch()

  useEffect(() => {
    if (addSkuInGrnBinResponse.is_success) {
      if (
        !(
          addSkuInGrnBinResponse.data.is_sku_not_exist ||
          addSkuInGrnBinResponse.data.is_over_receive
        )
      ) {
        if (
          !(
            sku_details?.serialisation_status ===
              SERIALISATION_OPTION.serialised.id && qty >= 2
          )
        ) {
          dispatch(setLastScannedBin(watch('scanned-bin')))
          dispatch(clearDataAfterSKUScanned())
          setTotalItemQtyToScan(1)
          reset()
          setValue('search_barcode', '')
          setValue('search_sku', '')
        }
      }
    }
  }, [addSkuInGrnBinResponse])
 

  useEffect(() => {
    if (processNextReturnOrderSuccess && !isBinDisabled) {
      dispatch(getReturnOrderDetail(processNextReturnOrderResponse.oms_return_order_id))
      dispatch(resetSuccess())
    }
  }, [processNextReturnOrderSuccess])

  return (
    <QualityCheck
      returnGrnResponse={returnGrnResponse}
      control={control}
      setValue={setValue}
      watch={watch}
      errors={errors}
      qty={qty}
      setQty={setQty}
      totalItemQtyToScan={totalItemQtyToScan}
      setTotalItemQtyToScan={setTotalItemQtyToScan}
      register={register}
      setError={setError}
      clearErrors={clearErrors}
    />
  )
}
export default OffTypeSystemReturnQc