import { API_ENDPOINTS, getApiUrl } from '@src/api.urls'
import { axiosInstance } from '@src/network/AxiosInstance'

export const POS_API_KEYS = {
  POS: 'pos',
  GET_ALL_REGISTERS: 'getAllRegisters',
  CREATE_REGISTER: 'createRegister',
  CONTINUE_REGISTER: 'continueRegister',
  OPEN_REGISTER: 'openRegister',
  CREATE_CART: 'createCart',
  ADD_ITEM_TO_CART: 'addItemToCart',
  GET_ALL_CUSTOMERS: 'getAllCustomers',
  DELETE_CART_ITEM: 'deleteCartItem',
  UPDATE_CART_STATUS: 'updateCartStatus',
  ADD_CUSTOMER_TO_CART: 'addCustomerToCart',
  APPLY_DISCOUNT: 'applyDiscount',
  GET_PAUSED_SALES: 'getPausedSales',
  TRANSACT_AMOUNT: 'transactAmount',
  DELETE_PAUSED_CART: 'deletePausedCart',
  ADD_REMARK: 'addRemark',
  COMPLETE_PAYMENT: 'completePayment',
  CART_DELETE: 'cartDelete',
  CART_PAUSED: 'cartPaused',
  CONTINUE_SALE: 'continueSale',
  REMOVE_DISCOUNT: 'removeDiscount'
}

export const fetchRegisters = ({params}) => {
  const url = getApiUrl(API_ENDPOINTS.POS.REGISTERS)
  const response = axiosInstance.get(url, {params})
  return response
}

export const createRegister = ({body}) => {
  const url = getApiUrl(API_ENDPOINTS.POS.REGISTERS)
  const response = axiosInstance.post(url, body)
  return response
}

export const openRegister = ({id, body}) => {
  const url = getApiUrl(API_ENDPOINTS.POS.OPEN_REGISTER, {id})
  const response = axiosInstance.put(url, body)
  return response
}

export const closeRegister = ({id, body}) => {
  const url = getApiUrl(API_ENDPOINTS.POS.CLOSE_REGISTER, {id})
  const response = axiosInstance.put(url, body)
  return response
}

export const getInvoiceSettings = {
  apiKey: ['get-pos-invoice-settings'],
  url: API_ENDPOINTS.ORDERS.INVOICE_SETTINGS
}

export const updateInvoiceSettings = {
  apiKey: ['update-pos-invoice-settings'],
  url: API_ENDPOINTS.ORDERS.INVOICE_SETTINGS
}

export const createInvoiceSettings = {
  apiKey: ['create-pos-invoice-settings'],
  url: API_ENDPOINTS.ORDERS.INVOICE_SETTINGS
}

export const getTenantConfigurations = {
  apiKey: ['get-tenant-config'],
  url: API_ENDPOINTS.COMMON.TENANT_CONFIG
}

export const updateTenantConfigurations = {
  apiKey: ['update-tenant-config'],
  url: API_ENDPOINTS.COMMON.TENANT_CONFIG
}

export const getRegistersAsyncData = async (search, loadedOptions, { page, hub_id }) => {
  try {
    const response = await axiosInstance.get(`/api/v1/pos/registers?hub_id=${hub_id}`, { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hub_id
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching registers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        hub_id
      }
    }
  }
}
export const createCart = ({params}) => {
  const {hub_id, register_id} = params
  const url = getApiUrl(API_ENDPOINTS.POS.CREATE_CART, {hub_id, register_id})
  const response = axiosInstance.post(url)
  return response
}

export const addItemToCart = async({params, skuToSendBackend, skuToShowOnFrontend}) => {
  const {hub_id, register_id, cart_id} = params
  const url = getApiUrl(API_ENDPOINTS.POS.ADD_ITEM_TO_CART, {hub_id, register_id, cart_id})
  const response = await axiosInstance.put(url, skuToSendBackend)
  if (response?.data?.data) {
    response.data.skuToShowOnFrontend = skuToShowOnFrontend
  }
  return response
}

export const deleteItemToCart = ({params}) => {
  const {hub_id, register_id, cart_id, item_id} = params
  const url = getApiUrl(API_ENDPOINTS.POS.DELETE_CART_ITEM, {hub_id, register_id, cart_id, item_id})
  const response = axiosInstance.delete(url)
  return response
}

export const fetchCustomers = ({seller_id, ...params}) => {
  const url = getApiUrl(API_ENDPOINTS.ORDERS.GET_CUSTOMER_DETAILS_OR_CREATE_CUSTOMER, {sellerId: seller_id})
  const response = axiosInstance.get(url, { params })
  return response
}

export const updateCartStatus = ({params, body}) => {
  const {hub_id, register_id, cart_id} = params
  const url = getApiUrl(API_ENDPOINTS.POS.UPDATE_CART_STATUS, {hub_id, register_id, cart_id})
  const response = axiosInstance.put(url, body)
  return response
}

export const addCustomerToCart = ({params, body}) => {
  const {hub_id, register_id, cart_id} = params
  const url = getApiUrl(API_ENDPOINTS.POS.ADD_CUSTOMER_TO_CART, {hub_id, register_id, cart_id})
  const response = axiosInstance.post(url, body)
  return response
}

export const applyDiscount = ({params, body}) => {
  const {hub_id, register_id, cart_id} = params
  const url = getApiUrl(API_ENDPOINTS.POS.APPLY_DISCOUNT, {hub_id, register_id, cart_id})
  const response = axiosInstance.post(url, body)
  return response
}

export const removeDiscount = ({params}) => {
  const {hub_id, register_id, cart_id} = params
  const url = getApiUrl(API_ENDPOINTS.POS.REMOVE_DISCOUNT, {hub_id, register_id, cart_id})
  const response = axiosInstance.post(url)
  return response
}

export const getPausedSales = ({params}) => {
  const {hub_id, register_id, ...rest} = params
  const url = getApiUrl(API_ENDPOINTS.POS.GET_PAUSED_SALES, {hub_id, register_id})
  const response = axiosInstance.get(url, {params: rest})
  return response 
}

export const transactAmount = ({params, body}) => {
  const {hub_id, register_id} = params
  const url = getApiUrl(API_ENDPOINTS.POS.TRANSACT_AMOUNT, {hub_id, register_id})
  const response = axiosInstance.post(url, body)
  return response
}

export const completePayment = ({params, body}) => {
  const {hub_id, register_id, cart_id} = params
  const url = getApiUrl(API_ENDPOINTS.POS.COMPLETE_PAYMENT, {hub_id, register_id, cart_id})
  const response = axiosInstance.post(url, body)
  return response
}

export const continueSale = ({params}) => {
  const {hub_id, register_id, cart_id} = params
  const url = getApiUrl(API_ENDPOINTS.POS.CONTINUE_SALE, {hub_id, register_id, cart_id})
  const response = axiosInstance.post(url)
  return response
}

export const continueRegister = ({params}) => {
  const {register_id} = params
  const url = getApiUrl(API_ENDPOINTS.POS.CONTINUE_REGISTER, {register_id})
  const response = axiosInstance.put(url)
  return response
}

export const getTaxCategoriesData = async (search, loadedOptions, { page, seller_id }) => {
  try {
    const response = await axiosInstance.get('/api/v1/products/tax_categories', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        seller_id
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching registers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        seller_id
      }
    }
  }
}

export const getSingleOrderDetailsApi = {
  apiKey: ['get-single-order-details'],
  url: API_ENDPOINTS.ORDERS.GET_ORDER_DETAILS
}