import { lottieLazyLoader } from '@src/utility/Utils'
import className from 'classnames'
import {
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  Settings,
  X
} from 'react-feather'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import './customToast.scss'
const NotificationAnimation = lottieLazyLoader(() => import('../../../assets/animations/notification.json'))

const CustomToast = (message, options, content = '') => {
  const alertStyle = {
    info: {
      iconColor: 'var(--bs-omniful-primary)',
      icon: <AlertCircle color="var(--bs-white)" size={15} />
    },
    in_progress: {
      iconColor: 'var(--bs-omniful-primary)',
      icon: <AlertCircle color="var(--bs-white)" size={15} />
    },
    error: {
      iconColor: 'var(--bs-danger)',
      icon: <AlertOctagon color="var(--bs-white)" size={15} />
    },
    warning: {
      iconColor: 'var(--bs-warning)',
      icon: <AlertTriangle color="var(--bs-white)" size={15} />
    },
    success: {
      iconColor: 'var(--bs-success)',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          fill="var(--bs-white)"
          className="bi bi-check-circle"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
        </svg>
      )
    },
    setting: {
      iconColor: 'var(--bs-purple)',
      icon: <Settings color="var(--bs-white)" size={15} />
    },
    notification: {
      iconColor: 'var(--bs-white)',
      icon: <NotificationAnimation />
    }
  }
  const toastStyles = (colorTheme) => ({
    padding: 0,
    margin: '0 !important',
    border: `1px solid ${colorTheme}`,
    borderRadius: '6px',
    zIndex: '999999999'
  })
  
  return toast(
    (msg) => {
      const alertType = options?.my_type || msg.type
      const colorTheme = alertStyle[alertType].iconColor
      const { t } = useTranslation()
      
      return (
        <div className="custom-toast-wrapper w-100 d-flex position-relative m-0 p-0">
          <div
            style={{
              backgroundColor: `${colorTheme}`,
              border: `1px solid ${colorTheme}`
            }}
            className={className('d-flex align-items-center h-100 px-14px toast-icon-type', {'ps-4px pe-0':alertType === 'notification'})}
          >
            {alertStyle[alertType].icon}
          </div>
          <div className={className('pt-1 ps-1 pb-1 bg-white w-100', {'ps-4px':alertType === 'notification'})}>
            <div className="txt-body-md">{t(message)}</div>
            {content && (
              <p className="text-dark-6 toast-content">{t(content)}</p>
            )}
          </div>
          {content && <div className="empty-div"></div>}
          <div
            className={className('rounded-end bg-white cross-icon', {
              'position-absolute': content,
              'p-12px': !content
            })}
          >
            <div
              onClick={options?.handleCloseToast ? () => options.handleCloseToast() : () => toast.dismiss(msg.id)}
              className={className('cursor-pointer', {
                'pt-2px': !content,
                'pt-0': content
              })}
            >
              <X size={18} />
            </div>
          </div>
        </div>
      )
    },
    {
      duration: options?.duration || 10000,
      ...options,
      className: 'm-0',
      style: toastStyles(alertStyle[options?.my_type]?.iconColor)
    }
  )
}

export default CustomToast
