import { createRoot } from 'react-dom/client'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
// import logo from "@src/assets/images/omniful/omniful-logo.svg"
import details_img from '@src/assets/images/purchases/details.svg'
import phone_img from '@src/assets/images/purchases/phone.svg'
import location_img from '@src/assets/images/purchases/location.svg'
import { formatDateToLongMonthFormat } from '@src/utility/Utils'
import { useEffect } from 'react'
import { DeliveryNote } from './DeliveryNoteTemplate'
import { ORDER_TYPE } from '@src/views/sales/constant/orders.constants'
import Button from '@src/@core/components/button'


const PrintDeliveryNoteLayout = ({orderDetails}) => {
  const { t } = useTranslation()
  const hasBatches = orderDetails.order_items.some(item => item.batches.length > 0)
  let globalSerialNumber = 0
    
  const TableRow = ({ serialNumber, item, batch }) => (
    <tr className='table_row'>
      <td className='row_serial_number text-center'>{serialNumber}</td>
      <td className='row_seller_sku_name'>{item.seller_sku.name}</td>
      <td className='row_seller_sku_code'>{item.seller_sku_code}</td>
      {hasBatches && <td className='row_batch_number'>{batch ? <>
        <div>{batch.number}</div>
        <div>{batch.expiry_date}</div>
      </> : '-'}
      </td>}
      <td className='row_ordered_quantity text-end'>{batch ? batch.packed_quantity : item.packed_quantity}</td>
      <td className='row_unit_price text-end'>{item.unit_price} {orderDetails.invoice.currency}</td>
      <td className='row_total text-end'>{item.total} {orderDetails.invoice.currency}</td>
    </tr>
  )
  const renderTableRows = (item) => {
    if (item.packed_quantity !== 0 && item.batches.length === 0) {
      globalSerialNumber += 1
      return <TableRow key={globalSerialNumber} serialNumber={globalSerialNumber} item={item} />
    } else {
      const batchRows = item.batches.map((batch, batchIndex) => {
        globalSerialNumber += 1
        return <TableRow key={`${globalSerialNumber}_${batchIndex}`} serialNumber={globalSerialNumber} item={item} batch={batch} />
      })
      return batchRows
    }
  }
  return (
    <div className='main-container-purchase-order w-100 print-purchase-order'>
      <div className='purchase-order-content'>
        <div>
          <div className='text-inside text-center fw-bolder title'>{t('Delivery Note')}</div>
        </div>
        <div className='section_1 horizontal_divider'>
          <Row>
            <Col className='text-break'>
              <span className='deliverly_note_attribute paddings'>{t('Order ID:')} </span>
              <span className='deliverly_note_value'>{orderDetails.seller_sales_channel_order_id}</span>
            </Col>
          </Row>
          <Row>
            <Col className='text-break mt-2'>
              <span className='deliverly_note_attribute paddings'>{t('Created DateTime:')} </span>
              <span className='deliverly_note_value'>{orderDetails.order_created_at}</span>
            </Col>
          </Row>
          <Row>
            <Col className='text-break mt-2'>
              <span className='deliverly_note_attribute paddings'>{t('Date')}: </span>
              <span className='deliverly_note_value'>{formatDateToLongMonthFormat()}</span>
            </Col>
          </Row>
          <Row>
            <Col className='mt-2 text-break'>
              <span className='deliverly_note_attribute paddings'>{t('Total Weight:')} </span>
              <span className='deliverly_note_value'>{orderDetails.shipment.display_weight || orderDetails.shipment.weight}</span>
            </Col>
          </Row>
          <Row>
            <Col className='mt-2 text-break'>
              <span className='deliverly_note_attribute paddings'>{t('Shipping Partner:')} </span>
              <span className='deliverly_note_value'>{orderDetails.shipment.shipping_partner_name}</span>
            </Col>
          </Row>
        </div>
                

        <div className="d-flex horizontal_divider">
          {orderDetails.type === ORDER_TYPE.STO ? <div className="w-50">
            <div className="heading">{t('Seller Details')}</div>
            <div className="section_2">
              <Row>
                <Col className='d-flex text-break'>
                  <span className='deliverly_note_attribute paddings align-self-center'><img src={details_img} height="14" width="14" /></span>
                  <span className='deliverly_note_value'>{orderDetails.seller.name}</span>
                </Col>
              </Row>
              {orderDetails.seller.phone && <Row>
                <Col className='d-flex text-break margins'>
                  <span className='deliverly_note_attribute paddings align-self-center'><img src={phone_img} height="14" width="14" /></span>
                  <span className='deliverly_note_value'>{orderDetails.seller.phone}</span>
                </Col>
              </Row>}
              <Row>
                <Col className='d-flex text-break'>
                  <span className='deliverly_note_attribute paddings align-self-center'><img src={location_img} height="14" width="14" /></span>
                  <span className='deliverly_note_value'>{orderDetails.seller.address.address_line1} {orderDetails.seller.address.address_line2}, {orderDetails.seller.address.city.name}, {orderDetails.seller.address.state.name}, {orderDetails.seller.address.country.name}, {orderDetails.seller.address.postal_code}</span>
                </Col>
              </Row>
            </div>
          </div> :
            <div className="w-50">
              <div className="heading">{t('Customer Details')}</div>
              <div className="section_2">
                <Row>
                  <Col className='d-flex text-break'>
                    <span className='deliverly_note_attribute paddings align-self-center'><img src={details_img} height="14" width="14" /></span>
                    <span className='deliverly_note_value'>{orderDetails.customer.customer_name}</span>
                  </Col>
                </Row>
                {orderDetails.customer.mobile && <Row>
                  <Col className='d-flex text-break margins'>
                    <span className='deliverly_note_attribute paddings align-self-center'><img src={phone_img} height="14" width="14" /></span>
                    <span className='deliverly_note_value'>{orderDetails.customer.mobile}</span>
                  </Col>
                </Row>}
                {orderDetails.customer.email && <Row>
                  <Col className='d-flex text-break'>
                    <span className='deliverly_note_attribute paddings align-self-center'><img src={location_img} height="14" width="14" /></span>
                    <span className='deliverly_note_value'>{orderDetails.customer.email}</span>
                  </Col>
                </Row>}
              </div>
            </div>}
          <div className="w-50 ">
            <div className="heading">{t('Delivery Address')}</div>
            <div className="section_3">
              <Row>
                <Col className='d-flex'>
                  <div>
                    <span className='deliverly_note_attribute paddings align-self-center'><img src={location_img} height="14" width="14" /></span>
                  </div>
                  <div>
                    <div className='text-break'>
                      <span className='deliverly_note_value'>{orderDetails.shipping_address.address1}</span>
                      <span className='deliverly_note_value'>{orderDetails.shipping_address.address2}  </span>
                    </div>
                    <div className='text-break margins'>
                      <span className='deliverly_note_value'>{orderDetails.shipping_address.city}</span>
                      <span className='deliverly_note_value'>{orderDetails.shipping_address.state}</span>
                    </div>
                    <div className='text-break'>
                      <span className='deliverly_note_value'>{orderDetails.shipping_address.country} </span>
                      <span className='deliverly_note_value'>{orderDetails.shipping_address.zip}</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
                   
        </div>
                

        <table className='w-100'>
          <tr className='table-title'>
            <td colSpan={hasBatches ? '7' : '6'}>
              <div className='text-inside text-center table-title'>{t('Items Details')}</div>
            </td>
          </tr>
                   
          <tr>
            <th className="text-center">{t('S. No.')}</th>
            <th>{t('SKU Name')}</th>
            <th>{t('SKU Code')}</th>
            {hasBatches && <th>{t('Batch No.')}</th>}
            <th className="text-end">{t('Qty')}</th>
            <th className="text-end">{t('Unit Cost')}</th>
            <th className="text-end">{t('Total Cost')}</th>
          </tr>
          {orderDetails.order_items.map(renderTableRows)}
          <tr>
            <th colSpan={hasBatches ? '6' : '5'} className='amount_section'>{t('Shipping Cost')}</th>
            <td className="amount">{orderDetails.invoice.shipping_price} {orderDetails.invoice.currency}</td>
          </tr>
          <tr>
            <th colSpan={hasBatches ? '6' : '5'} className='amount_section'>{t('Discount')}</th>
            <td className="amount">{orderDetails.invoice.discount} {orderDetails.invoice.currency}</td>
          </tr>
          <tr>
            <th colSpan={hasBatches ? '6' : '5'} className='amount_section'>{t('Order Total')}</th>
            <td className="total amount">{orderDetails.invoice.total} {orderDetails.invoice.currency}</td>
          </tr>
        </table>
        <div className="section_4 authorization_section">
          <Row>
            <Col>
              <div className='heading'>{t('Goods Received By:')}</div>
              <div className='d-flex'>
                <div className='deliverly_note_value w-50  margins'>{t('Print Name:')}</div>
                <div className='deliverly_note_value w-50 margins'>{t('Date')}:</div>
              </div>
                                
              <div className='deliverly_note_value'>{t('Signature')}: ......................................</div>
            </Col>
                            
                                
          </Row>
        </div>
      </div>
    </div>
  )
}

const PrintDeliveryNote = ({printDeliveryNoteRef, orderDetails, buttonClicked = false}) => {
  // debugger
  useEffect(() => {
    if (buttonClicked) {
      printDeliveryNoteRef.current.click()
    }
  }, [buttonClicked])
  const { t } = useTranslation()
  const handleClick = () => {
    const newWindow = window.open('', '_blank', 'width=1100,height=700')
    if (newWindow && newWindow.document) {
      newWindow.document.write(DeliveryNote)
      const container = newWindow.document.getElementById('root')
      const root = createRoot(container)
      root.render(<PrintDeliveryNoteLayout orderDetails={orderDetails} />)
      setTimeout(() => {
        newWindow.print()
      }, 200)
    }
  }
  return (
    <div className='d-flex gap-1'>
      <Button ref={printDeliveryNoteRef} onClick={handleClick}>{t('Print Delivery Note')}</Button>
    </div>
  )
}

export default PrintDeliveryNote