import BulkUpload from '@src/@core/components/bulk-upload'
import Button from '@src/@core/components/button'
import SideSheet from '@src/@core/components/sideSheet'
import bulkUploadImage from '@src/assets/images/orders/BulkUpload.svg'
import fileLogo from '@src/assets/images/orders/fileLogo.svg'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { initRealTime } from '@src/network/RealTime'
import { uploadFileOnS3 } from '@src/redux/authentication'
import { bulkUploadSerializeBarcode, clearBulkUploadSerialiseBarcodeResponse, setBinDisabledGrnTableData, setScannedSkuDetails } from '@src/views/inventory/store'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import { BATCH_STATUS, BIN_DISABLED_GRN_SAMPLE_FILE, BULK_UPLOAD_GRN_SAMPLE_FILE, SERIALISATION_OPTION } from '../../constant'

const BulkUploadSerialisedSkus = (props) => {
  const {
    setBulkUploadSkuSidesheetState,
    bulkUploadSkuSidesheetState,
    watch,
    sku_details
  } = props

  const { t } = useTranslation()
  const [uploadProgress, setUploadProgress] = useState(0)
  const [skusUploadId, setskusUploadId] = useState('')
  const dispatch = useDispatch()
  const configurations = useSelector(store => store.auth.selectedHubConfiguration)
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled

  const selectedGlobalHubID = useSelector(
    (store) => store.auth.selectedGlobalHubId
  )
  const pusherLoading = useSelector(state => state.auth.pusherLoading)
  const grnResponse = useSelector(store => store.inventory.grn.grnResponse)
  const bulkUploadSerialiseBarcodeResponse = useSelector(store => store.inventory.bulkUploadSerialiseBarcodeResponse)
  const tableData = useSelector(store => store.inventory.binDisabledGrn.tableData)
  const pusherResponse = useSelector(store => store.auth.pusherResponse)

  const fileChangeHandler = async (e) => {
    const upload_id = await uploadFileOnS3({
      service: 'grnBulkBarcode',
      usecase: 'grn_bulk_barcode_upload',
      file: e?.[0]
    })

    if (upload_id) {
      setskusUploadId(upload_id)
      setUploadProgress(100)
    }
  }

  const handleBulkUpload = () => {
    const body = {
      upload_id: skusUploadId,
      seller_sku_code : sku_details.seller_sku_code, //string type
      parent_id: sku_details?.parent_id || 0
    }
    if (sku_details.batch_status === BATCH_STATUS.BATCHED) {
      body.batch_id = watch('selected_batch').id
    }
        
    initRealTime(() => {
      dispatch(bulkUploadSerializeBarcode({ body, hubId:selectedGlobalHubID, grnId:grnResponse.id }))
    })
  }

  const toggleSideSheet = () => {
    setBulkUploadSkuSidesheetState(false)
  }
    
  const onClosedSideSheet = () => {
    //clear data plus other operations
    setskusUploadId('')
    setUploadProgress(0)
    dispatch(clearBulkUploadSerialiseBarcodeResponse())
  }

  const { mutate: getUpdatedSkuDetailsAfterBulkUpload } = useApi({
    isMutation: true,
    apiKey: 'get-Updated-Sku-Details-by-barcode-non-serialised-skus-after-bulk-upload',
    apiFn: () => {
      return axiosInstance.get(`/api/v1/wms/hubs/${grnResponse?.hub_id}/grns/${grnResponse?.id}/skus/${sku_details?.seller_sku_id}`)
    },
    onSuccess: (data) => {
      if (data?.data?.grn_details?.length) {
        if (sku_details?.batch_status === BATCH_STATUS.BATCHED) {
          const updatedTableData = data.data.grn_details.reduce((acc, grnDetail) => {
            const existingBatch = acc.find(td => (td.batch?.id ? td.batch.id === grnDetail.batch?.id : td.seller_sku_id === data.data.sku?.seller_sku_id))

            if (existingBatch) {
              if (existingBatch.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...sku_details,
                    pass_qty: (grnDetail.pass_quantity || 0),
                    pass_barcodes: grnDetail.barcodes || [],
                    batch: {
                      id: grnDetail.batch?.id,
                      label: grnDetail.batch?.external_batch_id,
                      expiry_date: grnDetail.batch?.expiry_date
                    }
                  }
                }))

                const newTableData = acc.map((item) => {
                  return item.seller_sku_id === data.data.sku?.seller_sku_id && item.batch?.id === grnDetail.batch?.id ? {
                    ...item,
                    pass_qty: (grnDetail.pass_quantity || 0),
                    pass_barcodes: grnDetail.barcodes || [],
                    batch: {
                      id: grnDetail.batch?.id,
                      label: grnDetail.batch?.external_batch_id,
                      expiry_date: grnDetail.batch?.expiry_date
                    }
                  } : item
                })
                return newTableData
              } else if (!existingBatch.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...sku_details,
                    pass_qty: (grnDetail.pass_quantity || 0),
                    pass_barcodes: grnDetail.barcodes || [],
                    batch: {
                      id: grnDetail.batch?.id,
                      label: grnDetail.batch?.external_batch_id,
                      expiry_date: grnDetail.batch?.expiry_date
                    }
                  }
                }))

                const newTableData = tableData.map((item) => {
                  return item.seller_sku_id === data.data.sku?.seller_sku_id ? {
                    ...item,
                    pass_qty: (grnDetail.pass_quantity || 0),
                    pass_barcodes: grnDetail.barcodes || [],
                    batch: {
                      id: grnDetail.batch?.id,
                      label: grnDetail.batch?.external_batch_id,
                      expiry_date: grnDetail.batch?.expiry_date
                    }
                  } : item
                })
                return newTableData
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...sku_details,
                  pass_qty: grnDetail.pass_quantity || 0,
                  pass_barcodes: grnDetail.barcodes || [],
                  batch: {
                    id: grnDetail.batch?.id,
                    label: grnDetail.batch?.external_batch_id,
                    expiry_date: grnDetail.batch?.expiry_date
                  },
                  fail_barcodes: []
                }
              }))
                  
              const dataToPush = {
                id: sku_details?.seller_sku_id,
                name: sku_details?.name,
                code: sku_details?.seller_sku_code,
                image: sku_details?.images?.[0]?.url,
                is_serialised: sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id,
                seller_sku_id: sku_details?.seller_sku_id,
                pass_qty: grnDetail.pass_quantity || 0,
                fail_qty: 0,
                total_qty: sku_details?.quantity || 0,
                purchase_order_id: sku_details?.purchase_order_id,
                seller_id: sku_details?.seller_id,
                pass_barcodes: grnDetail.barcodes || [],
                fail_barcodes: [],
                batch: {
                  id: grnDetail.batch?.id,
                  label: grnDetail.batch?.external_batch_id,
                  expiry_date: grnDetail.batch?.expiry_date
                }
              }
              return [...acc, dataToPush] 
            }

            return acc
          }, [...tableData])

          dispatch(setBinDisabledGrnTableData(updatedTableData))
        } else {
          const isPresentInTable = tableData.find(item => item.seller_sku_id === sku_details?.seller_sku_id)

          if (isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...sku_details,
                pass_qty: (data?.data?.grn_details?.[0]?.pass_quantity || 0),
                pass_barcodes: data?.data?.grn_details?.[0]?.barcodes || []
              }
            }))

            const updateTableData = tableData.map((item) => {
              return item.seller_sku_id === data.data.sku?.seller_sku_id ? {
                ...item,
                pass_qty: (data?.data?.grn_details?.[0]?.pass_quantity || 0),
                pass_barcodes: data?.data?.grn_details?.[0]?.barcodes || []
              } : item
            })
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...sku_details,
                pass_qty: (data?.data?.grn_details?.[0]?.pass_quantity || 0),
                pass_barcodes: data?.data?.grn_details?.[0]?.barcodes || [],
                fail_barcodes: []
              }
            }))

            const dataToPush = {
              id: sku_details?.seller_sku_id,
              name: sku_details?.name,
              code: sku_details?.seller_sku_code,
              image: sku_details?.images?.[0]?.url,
              is_serialised: sku_details?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: sku_details?.seller_sku_id,
              pass_qty: data?.data?.grn_details?.[0]?.pass_quantity || 0,
              fail_qty: 0,
              total_qty: sku_details?.quantity || 0,
              purchase_order_id: sku_details?.purchase_order_id,
              seller_id: sku_details?.seller_id,
              pass_barcodes: data?.data?.grn_details?.[0]?.barcodes || [],
              fail_barcodes: []
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        }
      }
    }
  })

  useEffect(() => {
    if (bulkUploadSerialiseBarcodeResponse.is_success) {
      // if (isBinDisabled && sku_details?.seller_sku_id) {
      //     getUpdatedSkuDetailsAfterBulkUpload()
      // }
      setBulkUploadSkuSidesheetState(false)
      dispatch(clearBulkUploadSerialiseBarcodeResponse())
    }
  }, [bulkUploadSerialiseBarcodeResponse])

  useEffect(() => {
    if (pusherResponse.grn_serialisation_barcode_upload === 'success' && isBinDisabled && sku_details?.seller_sku_id) {
      getUpdatedSkuDetailsAfterBulkUpload()
    }
  }, [pusherResponse])

  return (
    <SideSheet
      isOpen={bulkUploadSkuSidesheetState}
      modalClassName='modal-slide-in'
      onClosed={onClosedSideSheet}
      toggle={toggleSideSheet}
      title={t('Bulk Upload')}
      size='sm'
    >
      <p className='txt-body-sb text-dark px-24px pt-24px'>{
        isBinDisabled ? t('To perform bulk assignment on serialised SKUs, follow these steps') : t('To perform bulk assignment on serialised SKUs to bins, follow these steps')
      }:</p>

      <ul className='bg-primary-lighter py-16px mx-24px mt-4px br-8 d-flex flex-column gap-12px text-dark'>
        <li className='ms-12px pe-12px'>
          <span className='txt-sub-md'>{t('Download the CSV Template')} -</span>
          <span className='txt-sub-rg'> {t('Click the link to download the CSV template.')} </span>
          <a href={isBinDisabled ? BIN_DISABLED_GRN_SAMPLE_FILE : BULK_UPLOAD_GRN_SAMPLE_FILE}
            className='txt-sub-rg text-primary cursor-pointer'>{t('Download Template(.csv)')}</a>
        </li>
        <li className='ms-12px pe-12px'>
          <span className='txt-sub-md'>{t('Populate the CSV')} -</span>
          <span className='txt-sub-rg'> {
            isBinDisabled ? t('Open the file and enter the Serialised SKU barcodes. Ensure that the Serialised SKU barcodes are correctly formatted. Only 5000 rows can be added in a single sheet.') : t('Open the file and enter the SKU barcodes and bin barcodes. Ensure that the bin barcodes are correctly formatted. Only 5000 rows can be added in a single sheet.')
          }</span>
        </li>
        <li className='ms-12px pe-12px'>
          <span className='txt-sub-md'>{t('Save the File')} -</span>
          <span className='txt-sub-rg'> {t('Save the file with the data you\'ve entered.')} </span>
        </li>
        <li className='ms-12px pe-12px'>
          <span className='txt-sub-md'>{t('Upload the CSV File')} -</span>
          <span className='txt-sub-rg'> {t('Use the \'Upload File\' button below to upload your file.')} </span>
        </li>
      </ul>

      <section className='pt-12px px-24px'>
        <BulkUpload
          fileChangeHandler={fileChangeHandler}
          upload_illustration={bulkUploadImage}
          setUploadProgress={setUploadProgress}
          setUploadId={setskusUploadId}
          progress={uploadProgress}
          uploadId={skusUploadId}
          fileLogo={fileLogo}
          url={
            isBinDisabled ? BIN_DISABLED_GRN_SAMPLE_FILE : BULK_UPLOAD_GRN_SAMPLE_FILE
          }
        />
      </section>

      {skusUploadId ? <Button onClick={handleBulkUpload} disabled={pusherLoading} className='mx-auto mt-40px'>
        {pusherLoading && <Spinner size="sm"/>}
        {t('Save and Continue')}
      </Button> : null}

    </SideSheet>
  )
}

export default BulkUploadSerialisedSkus