import { Headphones, Image, Youtube } from 'react-feather'

const FileLogo = ({size = 36, color = 'var(--bs-dark-6)'}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} viewBox="0 0 256 256">
      <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z"></path>
    </svg>
  )
}

const FileIcon = (props) => {
  const { fileType, size = 36 } = props
  switch (fileType) {
  case 'image':
    return <Image size={size} color="var(--bs-dark-6)" strokeWidth={1.5}/>
  case 'video':
    return <Youtube size={size} color="var(--bs-dark-6)"/>
  case 'audio':
    return <Headphones size={size} color="var(--bs-dark-6)"/>
  case 'text':
  case 'pdf':
  case 'csv':
  case 'tsv':
  case 'doc':
  case 'docx':
  case 'odt':
    return <FileLogo size={size} color="var(--bs-dark-6)"/> // Generic file icon for OpenDocument Text
  default:
    return <FileLogo size={size} color="var(--bs-dark-6)" /> // Default generic file icon
  }
}
export default FileIcon