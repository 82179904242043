import Button from '@src/@core/components/button'
import CustomToast from '@src/@core/components/custom-toast/CustomToast'
import ComponentSpinner from '@src/@core/components/spinner/Loading-spinner'
import { AD_HOC_USAGE_ADDED_IN } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import FilterTabs from '@src/components/filterTabs'
import { AbilityContext } from '@src/utility/context/Can'
import { GRN_TYPES } from '@src/views/inventory/inventory.constants'
import { clearScannedSkuDetails, setBinDisabledGrnTableData, updateSingleGrnDetailForNextOrder } from '@src/views/inventory/store'
import ReturnOrderDetail from '@src/views/returns/pages/return-grn-order-processing/revamp-create-return-order-processing/components/ReturnOrderDetail'
import { RETURN_ORDER_PROCESSING_STEPS } from '@src/views/returns/returns.constants'
import { resetSuccess } from '@src/views/returns/store'
import UpdateAdHocUsage from '@src/views/settings/billing/ad-hoc-activities/components/update-ad-hoc-usage'
import classNames from 'classnames'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import { GRN_STEPS } from '../../constant'
import BinDisabledProcessNextOrder from '../binDisabledProcessNextOrder'
import { AdHocActivityDetail } from './adHocActivityDetails'
import BinDisabledOverview from './bin-disabled-overview'
import GrnDetails from './grnDetails'
import './grnDetails.scss'
import PoStoDetail from './poStoDetail'
import UpdateItemQty from './updateItemQty'

function NewGrnDetails({ grnDetails, type, currentStep = false, setIsNextOrderProcess, isNextOrderProcess = false, getGrnDetails, isPending, isReturnOrderProcessing = false }) {
  const { t } = useTranslation()
  const [tabs, setTabs] = useState(null)
  const [activeTab, setActiveTab] = useState({ id: 1, name: 'GRN Details' })
  const [openUpdateQtyModal, setOpenUpdateQtyModal] = useState(false)
  const [openAddAdHocUsageModal, setOpenAddAdHocUsageModal] = useState(false)
  const [singleAdHocData, setSingleAdHocData] = useState({})
  const skuQtyUpdated = useSelector(store => store.inventory.grn.skuQtyUpdated)
  const success = useSelector((state) => state.returns.success)
  const returnOrderDetail = useSelector(store => store.returns.returnOrderDetail)
  const dispatch = useDispatch()
  const ability = useContext(AbilityContext)
  const singleGrnDetail = useSelector(
    (state) => state.inventory.grn.singleGrnDetail
  )
  const grnData = useSelector(
    (state) => state.inventory?.grn?.grnResponse
  )
  const singleReturnGrnOrderDetail = useSelector((state) => state.returns.singleReturnGrnOrderDetail)
  const configurations = useSelector(store => store.auth.selectedHubConfiguration)
  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled
  const primaryCtaRef = useRef()
  const grnResponse = useSelector(store => store.inventory.grn.grnResponse)
  const returnGrnResponse = useSelector((state) => state.returns.createReturnGrnResponse?.data)
  const [isProcessNextOrder, setIsProcessNextOrder] = useState(false)

  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }
  useEffect(() => {
    if (type === GRN_TYPES.STANDARD) {
      if (grnDetails?.id) {
        const is_po = grnDetails.is_purchase_order
        const is_sto = grnDetails.is_sto
        let tabs
        if (is_po || is_sto) {
          tabs = [
            { id: 1, name: 'GRN Details' },
            {
              id: 2,
              name: is_po ? 'Purchase Order Details' : is_sto ? 'STO Details' : ''
            },
            {
              id: 3,
              name: 'Ad Hoc Activities'
            }
          ]
        } else {
          tabs = [{ id: 1, name: 'GRN Details' }, { id: 3, name: 'Ad Hoc Activities' }]
        }
        setTabs(tabs)
      }
    }
    if (type === GRN_TYPES.RETURN) {
      
      let tabs
      if (isBinDisabled) {
        tabs = [{ id: 1, name: 'Overview' }, { id: 2, name: 'Ad Hoc Activities' }]
      } else {
        if (currentStep === RETURN_ORDER_PROCESSING_STEPS.RETURN_GRN_DETAILS.id) {
          tabs = [{ id: 1, name: 'Return Order Details' }]
        } else {
          tabs = [
            { id: 1, name: 'Return Order Details' },
            {
              id: 2,
              name: 'QC Details'
            },
            {
              id: 3,
              name: 'Ad Hoc Activities'
            }
          ]
          handleTabChange({
            id: 2,
            name: 'QC Details'
  
          })
        }
      }

      setTabs(tabs)
    }
    if (type === GRN_TYPES.BIN_DISABLED) {
      const tabs = [
        {
          id: 1,
          name: 'Overview'
        },
        {
          id: 2,
          name: 'Ad Hoc Activities'
        }
      ]
      setTabs(tabs)
    }
  }, [grnDetails, currentStep])

  const handleUpdateQtyModal = () => {
    setOpenUpdateQtyModal((prev) => !prev)
  }

  const handleAddAdHocUsage = () => {
    if (ability.can(abilityMap.adhoc_usage.create.action, abilityMap.adhoc_usage.create.resource)) {
      setOpenAddAdHocUsageModal((prev) => !prev)
    } else {
      CustomToast('You are not authorised to perfom this operation', { my_type: 'error' })
    }
  }

  const handleUpdateAdHocUsageToggle = () => {
    setOpenAddAdHocUsageModal((prev) => !prev)
    setSingleAdHocData()
  }

  const handleNextOrderProcessing = () => {
    if (isBinDisabled) {
      setIsProcessNextOrder(p => !p)
      dispatch(updateSingleGrnDetailForNextOrder())
      dispatch(clearScannedSkuDetails())
      dispatch(resetSuccess())
      dispatch(setBinDisabledGrnTableData([]))
    } else {
      dispatch(updateSingleGrnDetailForNextOrder())
      setTabs([{ id: 2, name: 'QC Details' }])
      handleTabChange({ id: 2, name: 'QC Details' })
      setIsNextOrderProcess(true)
      dispatch(clearScannedSkuDetails())
      dispatch(resetSuccess())
    }
  }

  useEffect(() => {
    if (skuQtyUpdated.key) {
      handleUpdateQtyModal()
    }
  }, [skuQtyUpdated])

  useEffect(() => {
    if (primaryCtaRef) {
      primaryCtaRef.current?.focus()
    }
  }, [])
  useEffect(() => {
    if (success.getReturnOrderDetail && isNextOrderProcess) {
      setIsNextOrderProcess(false)
      dispatch(updateSingleGrnDetailForNextOrder({ return_order_id: returnOrderDetail.return_order_id, seller_sales_channel_order_id: returnOrderDetail.seller_sales_channel_order_id, oms_order_id: returnOrderDetail.order_id }))
      dispatch(resetSuccess())
      if (!isBinDisabled) {
        setTabs([
          { id: 1, name: 'Return Order Details' },
          {
            id: 2,
            name: 'QC Details'
          },
          {
            id: 3,
            name: 'Ad Hoc Activities'
          }
        ])
        handleTabChange({
          id: 2,
          name: 'QC Details'
        })
      } 

      if (isBinDisabled) {
        setTabs([{ id: 1, name: 'Overview' }])

        handleTabChange({
          id: 1,
          name: 'Overview'
        })
      }
    }
  }, [success])

  return (
    <div className="new-grn-details">
      {tabs ? (
        <>
          <div className={classNames('d-flex justify-content-end gap-16px', { 'gap-16px': type === GRN_TYPES.RETURN })}>

            {(type === GRN_TYPES.STANDARD || type === GRN_TYPES.BIN_DISABLED || (type === GRN_TYPES.RETURN && currentStep === RETURN_ORDER_PROCESSING_STEPS.QC.id && !isNextOrderProcess)) &&
              <div className="d-flex gap-1">
                {
                  (type === GRN_TYPES.STANDARD || type === GRN_TYPES.BIN_DISABLED || type === GRN_TYPES.RETURN) &&
                  <Button className={classNames({ 'border-0': type === GRN_TYPES.RETURN })} ofStyle="outlined" onClick={handleAddAdHocUsage}
                  >
                    {t('Add Ad Hoc Usage')}
                  </Button>
                }
                {!isBinDisabled && <Button className={classNames({ 'border-0': type === GRN_TYPES.RETURN })} ofStyle="outlined" onClick={handleUpdateQtyModal}>
                  {t('Remove Items')}
                </Button>}
              </div>
            }

            {(type === GRN_TYPES.RETURN && currentStep === RETURN_ORDER_PROCESSING_STEPS.QC.id && !isNextOrderProcess) && <Button ofStyle="outlined" onClick={handleNextOrderProcessing}>
              {t('Process Next Order')}
            </Button>}

            {(grnResponse.id || returnGrnResponse?.grn_id) && currentStep === GRN_STEPS.GRN_QC && isBinDisabled &&
              <Button disabled={isPending} onClick={getGrnDetails} className='w-auto'>
                {isPending ? <Spinner size='sm'/> : null} 
                <span>{isReturnOrderProcessing ? t('Complete Process') : t('Complete GRN')}</span>
              </Button>}
          </div>
          <div className={classNames('tab-container', {
            'tab-container-': type === GRN_TYPES.RETURN
          })}>
            <FilterTabs
              tabDataList={tabs}
              selectedTabId={activeTab.id}
              onTabChange={handleTabChange}
              customBackgroundColor={true}
            />
            {((activeTab.id === 1 && type === GRN_TYPES.STANDARD) || (activeTab.id === 2 && type === GRN_TYPES.RETURN)) && !isBinDisabled && <GrnDetails grnDetails={grnDetails} type={type} currentStep={currentStep} />}
            {((activeTab.id === 2 && type === GRN_TYPES.STANDARD) || (activeTab.id === 1 && type === GRN_TYPES.RETURN)) && (type === GRN_TYPES.STANDARD ? <PoStoDetail grnDetails={grnDetails} setTabs={setTabs} /> : isBinDisabled ? <BinDisabledOverview grnDetails={grnDetails} isReturnOrder={true} isProcessNextOrder={isProcessNextOrder} toggle={() => setIsProcessNextOrder(p => !p)} /> : <ReturnOrderDetail />)}
            {((activeTab.id === 3 && (type === GRN_TYPES.STANDARD || type === GRN_TYPES.RETURN)) || (activeTab.id === 2 && (type === GRN_TYPES.BIN_DISABLED || isBinDisabled))) && <AdHocActivityDetail adHocActivityTabIn={AD_HOC_USAGE_ADDED_IN.GRN} />}
            {(activeTab.id === 1 && type === GRN_TYPES.BIN_DISABLED) && <BinDisabledOverview grnDetails={grnDetails}/>}
          </div>
          <UpdateItemQty
            isOpen={openUpdateQtyModal}
            toggle={handleUpdateQtyModal}
            grnResponse={grnDetails}
            grnType={type}
          />
          <UpdateAdHocUsage
            isOpen={openAddAdHocUsageModal}
            toggle={handleUpdateAdHocUsageToggle}
            singleAdHocData={singleAdHocData}
            sellerObj={singleGrnDetail?.seller || singleReturnGrnOrderDetail?.seller}
            name={grnData?.grn_id || returnGrnResponse?.grn_id}
            type={AD_HOC_USAGE_ADDED_IN.GRN}
            id={grnData?.id || returnGrnResponse?.id}
          />

          <BinDisabledProcessNextOrder
            isOpen={isProcessNextOrder}
            toggle={() => setIsProcessNextOrder(p => !p)}
          />
        </>
      ) : (
        <ComponentSpinner />
      )}
    </div>
  )
}

export default NewGrnDetails
