import Button from '@src/@core/components/button'
import CustomToast from '@src/@core/components/custom-toast/CustomToast'
import SidesheetFooter from '@src/@core/components/sidesheet-footer'
import { FILE_TYPES, MAX_FILE_SIZE_ALLOWED_IN_BYTES, MIN_FILE_SIZE_ALLOWED_IN_BYTES } from '@src/App.constants'
import { BulkUploadSvg } from '@src/assets/data/assets'
import { ATTACHMENTS_SIDEBAR_MODE } from '@src/views/sales/constant/orders.constants'
import classNames from 'classnames'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { Fragment, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Download, Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import AddDescription from './addDescription'
import FileIcon from './file-icon'
import Preview from './preview/preview'
const UploadAttachments = ({
  handleUploadFiles,
  isUploading = false,
  addedFiles,
  setAddedFiles,
  addedAttachments,
  mode,
  minFileSizeInBytes = MIN_FILE_SIZE_ALLOWED_IN_BYTES,
  maxFileSizeInBytes = MAX_FILE_SIZE_ALLOWED_IN_BYTES,
}) => {
  const { t } = useTranslation()
  const [isDownloading, setDownloading] = useState(false)
  const [isDescriptionPopupOpen, setDescriptionPopupOpen] = useState(false)
  const [previewingAttachment, setPreviewingAttachment] = useState(null)
  const [selectedAttachment, setSelectedAttachment] = useState(null)

  const acceptedFiles = [FILE_TYPES.CSV, FILE_TYPES.XLS, FILE_TYPES.XLSX, FILE_TYPES.PDF, FILE_TYPES.IMG, FILE_TYPES.DOC, FILE_TYPES.DOCX, FILE_TYPES.TXT, FILE_TYPES.ODS]
  const fileFormat = acceptedFiles.map(file => file.extensions).join(',')
  const acceptedFileTypes = acceptedFiles.map(file => file.type).join(',')
  const isEditMode = mode === ATTACHMENTS_SIDEBAR_MODE.EDIT.id

  const handleValidateFiles = (files) => {
    return files.every(file => {
      const type = file.type
      const subType = type ? type.split('/')[1] : ''
      const isAcceptable = type ? (acceptedFileTypes.includes(type) || fileFormat.includes(subType)) : false
      return isAcceptable
    })
  } 

  const handleUpload = (files) => {
    if (files.length && handleValidateFiles(files)) {
      setAddedFiles(prev => [...files.map(file => Object.assign(file)), ...prev])
    } else {
      CustomToast(
        ('Please upload files in a supported format.'), { my_type: 'error' }
      )
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileFormat,
    multiple: true,
    disabled: isUploading,
    minSize:minFileSizeInBytes,
    maxSize:maxFileSizeInBytes,
    onDrop: files => {
      handleUpload(files)
    }
  })

  const handleRemoveFiles = (removeItemIndex) => {
    if (isUploading) return
    const filteredItem = addedFiles.filter((item, index) => index !== removeItemIndex)
    setAddedFiles(filteredItem)
  }

  const handleToggleDescription = (item, index) => {
    if (isDescriptionPopupOpen) {
      setDescriptionPopupOpen(prev => !prev)
    } else {
      item.index = index
      setSelectedAttachment(item)
      setDescriptionPopupOpen(prev => !prev)
    }
  }

  const handleAddDescription = (index, description) => {
    const newUploadedFiles = [...addedFiles]
    newUploadedFiles[index].description = description
    setAddedFiles(newUploadedFiles)
  }

  const handleRemoveDescription = (index) => {
    const newUploadedFiles = [...addedFiles]
    newUploadedFiles[index].description = ''
    setAddedFiles(newUploadedFiles)
  }

  const handleSingleDownload = async (item) => {
    try {
      const res = await fetch(item.file_url)
      if (res.status >= 400) {
        throw new Error('Not allowed to download this file.');
      } else if (!res.ok) {
        throw new Error(`Failed to download file: ${res.statusText}`);
      }
      const blob = await res.blob() // Get the response and return it as a blob
      saveAs(blob, item.name) // Save the file using FileSaver.js
    } catch (error) {
      console.error('Download error:', error); // Log the error for debugging
    } finally {
      setDownloading(false); // Reset the downloading state
    }
  }

  const handleDownloadAll = async() => {
    setDownloading(true)
    if (addedAttachments.length < 6) {
      addedAttachments.forEach(item => {
        handleSingleDownload(item)
      })
      return
    }
    const zip = new JSZip();
    for (const item of addedAttachments) {
      try {
        const response = await fetch(item.file_url)
        const blob = await response.blob()
        zip.file(item.name, blob);
      } catch (error) {
        console.error(`Failed to fetch file: ${item.name}`, error);
      }
    }
    zip.generateAsync({ type: 'blob' })
      .then((content) => {
      // Use FileSaver.js to download the ZIP file
        saveAs(content, 'attachments.zip');
      })
      .catch((error) => {
        console.error('Failed to generate ZIP file', error)
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  const handleTogglePreview = (attachment) => {
    if (previewingAttachment) {
      setPreviewingAttachment(null)
    } else {
      setPreviewingAttachment(attachment)
    }
  }

  return (
    <div className="attachments-main-div w-100 d-flex flex-column gap-20px">
      <Fragment>
        <Row {...getRootProps({className: 'bulk-upload'})}>
          <Col sm='12'>
            <Card>
              <CardBody className="d-flex flex-column container-border"
              >
                <div className="mb-24px">
                  <BulkUploadSvg primary="var(--bs-primary)" width={140} height={124} />
                </div>
                <Row>
                  <Col sm='12'>
                    <div className='dropzone'>
                      <input {...getInputProps()} />
                      <div className="sample-upload d-flex flex-column align-items-center">
                        <h2 className="txt-h2-md m-0">
                          {t('Drag Your File Here Or')}{' '}
                          <a href='/' onClick={e => e.preventDefault()} className={classNames('text-primary', { 'opacity-50': isUploading, 'cursor-pointer': !isUploading })}>
                            {t('Browse Files')}
                          </a>
                        </h2>
                        <p className="txt-sub-rg m-0 text-dark-5">
                          {`(${t('Acceptable file are documents and images')}).`}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment> 
      {addedFiles.length > 0 && <div>{t('Uploaded')} {addedFiles.length} {t('Files')}</div>}
      {addedFiles.length ? (
        <div className='d-flex flex-column gap-24px'>
          {
            addedFiles.map((item, index) => {
              const fileType = item.type.split('/')[0] // Get the file type (image, video, text, etc.)
              const fileName = item.name
              const isPreviewAble = item.type.startsWith('image/') || item.type === FILE_TYPES.PDF.type
              const fileUrl = (item.preview_url || item.file_url) || URL.createObjectURL(item)
              return (
                <div className="d-flex align-items-center justify-content-center flex-column file_main_div min-width-300" key={index}>
                  <div className="d-flex align-items-center justify-content-between upload_container_div">
                    <div className="w-100 d-flex flex-column justify-content-between upload_file_details text-truncate gap-12px ms-0">
                      <div className="d-flex gap-16px align-items-center">
                        <div className="h-100 d-flex align-items-center">
                          <FileIcon fileType={fileType} size={36} />
                        </div>
                        <div className="d-flex flex-column gap-8px w-100">
                          <div className="file_title w-75 text-truncate txt-body-md" title={fileName} 
                            onClick={() => {
                              const properties = { name: item.name, type: item.type, file_url: fileUrl }
                              if (isPreviewAble) {
                                handleTogglePreview(properties)
                              } else {
                                handleSingleDownload(properties)
                              }
                            }}
                          >
                            {fileName}
                          </div>
                          {item.description && <div className="w-75 txt-sub-rg txt-dark-6 file_description text-wrap">
                            {item.description}
                          </div>}
                          <div className="flex-center-start gap-16px">
                            <Button ofType="compressed" ofStyle="noBackground" className="txt-sub-md p-0 shadow-none" onClick={() => handleToggleDescription(item, index)}>
                              {item.description ? t('Edit') : t('Add Description')}
                            </Button>
                            {item.description && <Button ofType="compressed" ofStyle="noBackground" className="txt-sub-md p-0 shadow-none" onClick={() => handleRemoveDescription(index)}>
                              {t('Remove')}
                            </Button>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-center-end gap-16px flex-shrink-0">
                      {isEditMode && <Download 
                        size={16} color="var(--bs-primary)"
                        onClick={() => handleSingleDownload(item)}
                        className="cursor-pointer"
                      />}
                      <Trash2 
                        size={16} color="var(--bs-danger)" 
                        className={classNames({'opacity-50': isUploading, 'cursor-pointer': !isUploading })} 
                        onClick={() => handleRemoveFiles(index)}
                      />
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      ) : null}
      <SidesheetFooter>
        <div className="flex-center-end gap-12px">
          {isEditMode && <Button ofStyle="outlined" onClick={handleDownloadAll} disabled={isUploading || isDownloading} icon={isDownloading ? Spinner : null} iconSize="sm">
            {t('Download All')}
          </Button>}
          <Button onClick={handleUploadFiles} disabled={isUploading || !(addedFiles?.length || addedAttachments?.length)} icon={isUploading ? Spinner : null} iconSize="sm">
            {t('Save')}
          </Button>
        </div>
      </SidesheetFooter>
      <Preview 
        isOpen={!!previewingAttachment} 
        toggle={handleTogglePreview} 
        previewingAttachment={previewingAttachment}
      />
      <AddDescription 
        isOpen={isDescriptionPopupOpen}
        toggle={handleToggleDescription}
        index={selectedAttachment?.index}
        description={selectedAttachment?.description}
        handleDescription={handleAddDescription} 
      />
    </div>
  )
}

export default UploadAttachments