import CustomCollapseContainer from '@src/@core/components/custom-collapse-container';
import { HUBS_CONFIGURATION } from '@src/App.constants';
import { RootState } from '@src/redux/store';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

interface TypeOfReturnsInformationProps {
  returnInfo:  string;
}

const TypeOfReturnsInformation = ({ returnInfo }: TypeOfReturnsInformationProps) => {
  const { t } = useTranslation();
  const {
    tenantName, selectedHubConfiguration
  } = useSelector((state: RootState) => ({
    //@ts-expect-error FIXME
    tenantName: state.auth.userData.tenant.name,
    selectedHubConfiguration: state.auth.selectedHubConfiguration
  }));
  //@ts-expect-error FIXME
  const isLocationInventoryEnabled = selectedHubConfiguration?.find((config) => config.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)?.configuration_values?.enabled; 
  
  return ( 
    <CustomCollapseContainer title='Types of Returns' returnInfo={returnInfo}
    >
      <Row>
        <Col xs="3" className="term txt-sub-md">
          <p>
            <span className='me-6px'>1.</span>
            {t('Customer Return')}
          </p>
        </Col>
        <Col className="meaning txt-sub-rg d-flex gap-10px">
          <span>:</span>
          <span>
            {t('This type of return oversees the process following successful delivery, addressing issues like receiving incorrect or damaged items, dissatisfaction, or customer-initiated returns')}.
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs="3" className="term txt-sub-md">
          <p>
            <span className='me-6px'>2.</span>
            {t('Courier Partner Return')}
          </p>
        </Col>
        <Col className="meaning txt-sub-rg d-flex gap-10px">
          <span>:</span>
          <span>
            {t('This handles situations where orders cannot be delivered successfully, initiating return orders due to reasons such as customer cancellation pre-delivery or unavailability at the delivery address')}.
          </span>
        </Col>
      </Row>
      {isLocationInventoryEnabled && <Row>
        <Col xs="3" className="term txt-sub-md">
          <p>
            <span className='me-6px'>3.</span>
            {t('Off-System Order')}
          </p>
        </Col>
        <Col className="meaning txt-sub-rg d-flex gap-10px">
          <span>:</span>
          <span>
            {`Off-system order return refers to processing return orders outside the regular system, used when there's no existing record of the order in ${tenantName}'s system.`}
          </span>
        </Col>
      </Row>}
    </CustomCollapseContainer>
  );
};

export default memo(TypeOfReturnsInformation);
