import { SKU_TYPE } from '@src/views/inventory/inventory.constants'
import Casepack from './Casepack'
import Pallet from './Pallet'
import SkuDetailsAndQc from './SkuDetailsAndQc'

const SkuComponent = ({ sku_details, selectedTabId, qcBinBarcodeRef, searchBarcode, grnType }) => {
  switch (sku_details.package_type) {
  case SKU_TYPE.PALLET.value:
    return <Pallet sku_details={sku_details} selectedTabId={selectedTabId} qcBinBarcodeRef={qcBinBarcodeRef} searchBarcode={searchBarcode} grnType={grnType}/>
  case SKU_TYPE.CASE_PACK.value:
    return <Casepack sku_details={sku_details} selectedTabId={selectedTabId} qcBinBarcodeRef={qcBinBarcodeRef} searchBarcode={searchBarcode} grnType={grnType}/>
  default:
    return <SkuDetailsAndQc sku_details={sku_details} selectedTabId={selectedTabId} qcBinBarcodeRef={qcBinBarcodeRef} searchBarcode={searchBarcode} grnType={grnType}/>
  }
}

export default SkuComponent