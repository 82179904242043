import Button from '@src/@core/components/button'
import { printMultipleAwb } from '@src/utility/Utils'
import classNames from 'classnames'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useSelector
} from 'react-redux'
import { Col, Row, Spinner } from 'reactstrap'
import PrintDeliveryNote from '../../pending-actions/pages/orderDetails/orderDetailsTabPages/PrintDeliveryNote'
import { getAWBDetail } from '../../store/store'

const ShipmentDetail = ({ shipmentDetails, handleOrderDetailsData }) => {

  const { t } = useTranslation()
  const printDeliveryNoteRef = useRef(null)

  // store
  const singleOrderDetailsData = useSelector((store) => store.sales.singleOrderDetailsData)
  const loading = useSelector((store) => store.sales.loading)
  const orderDetails = singleOrderDetailsData?.data

  // states
  const [printAwbLoading, setPrintAwbLoading] = useState(false)

  // functions
  const handleAwb = async () => {
    setPrintAwbLoading(true)
    if (shipmentDetails?.awb_label) {
      printMultipleAwb(shipmentDetails.awb_label)
    } else {
      const res = await getAWBDetail(shipmentDetails.oms_order_id)
      if (res?.is_success && res?.data?.awb_label) {
        printMultipleAwb(res.data.awb_label)
      }
    }
    setPrintAwbLoading(false)
  }

  return (
    <div>
      <div className="mt-2 border rounded-4">
        <h5 className="py-1 px-1 fw-bolder bg-primary-lighter text-secondary mb-0 flex-center-between rounded-top-4 txt-h3-md">
          {t('Shipment Details')}
        </h5>

        <div>
          <div className="px-1 py-1">
            <Row className="mb-1">
              <Col className="col-3">{t('Order Status')}:</Col>
              <Col className="text-secondary col-9">
                {(shipmentDetails && shipmentDetails.status) || '-'}
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="col-3">{t('Shipping Partner Order Id')}:</Col>
              <Col className="text-secondary col-9">
                {(shipmentDetails && shipmentDetails.shipping_partner_order_id) || '-'}
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="col-3">{t('AWB Number')}:</Col>
              <Col className="text-secondary col-9">
                {(shipmentDetails && shipmentDetails.awb_number) || '-'}
              </Col>
            </Row>
            <Row className="mb-1">
              <Col className="col-3">{t('Shipping Partner Status')}:</Col>
              <Col className="text-secondary col-9">
                {(shipmentDetails && shipmentDetails.shipping_partner_status) || '-'}
              </Col>
            </Row>
            <Row>
              <Col className="col-3">{t('Shipping Partner Name')}:</Col>
              <Col className="text-secondary col-9">
                {(shipmentDetails && shipmentDetails.shipping_partner_name) || '-'}
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end gap-2 mt-2">
        {shipmentDetails && shipmentDetails.print_awb_allowed &&
          <Button disabled={printAwbLoading} onClick={handleAwb}>
            {printAwbLoading && <Spinner size="sm" />}
            <span className={classNames({ 'ms-50': printAwbLoading })}>{t('Print AWB')}</span>
          </Button>
        }
        {shipmentDetails && shipmentDetails.print_delivery_note_allowed &&
          (!orderDetails ?
            <Button disabled={loading.getSingleOrderDetails} onClick={handleOrderDetailsData}>{loading.getSingleOrderDetails && <Spinner size="sm" />}
              <span className={classNames({ 'ms-50': loading.getSingleOrderDetails })}>{t('Print Delivery Note')}</span>
            </Button> : <PrintDeliveryNote printDeliveryNoteRef={printDeliveryNoteRef} buttonClicked={true} orderDetails={orderDetails} />)
        }
      </div>
    </div>
  )
}

export default ShipmentDetail