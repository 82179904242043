import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import Button from '../../button'
import NewCheckbox from '../../new-checkbox'


const CheckBoxDropdownOption = (props) => {
  const {t} = useTranslation()
  const { filterKey, isSelected, buttonConfig: {apply, cancel, disabled}, data: { label, color, logo, value } } = props
  const { Option } = components
  const customKey = `${filterKey}-${value}`
  const labelStyle = { color: color || 'text-dark', background: color ? `${color}1A` : 'transparent' }
  return (
    <Option key={customKey} {...props}>
      {label === 'button' 
        ? <div  className="position-fixed top-100 w-100 start-0 end-0 bg-white flex-center-center gap-12px p-12px rounded-bottom" style={{boxShadow: '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)'}}>
          <Button ofType="compressed" onClick={cancel} disabled={disabled} ofStyle="outlined" className="w-50 flex-center-center">{t('Cancel')}</Button>
          <Button ofType="compressed" onClick={apply} className="w-50 flex-center-center">{t('Apply')}</Button>
        </div> 
        : <div className="d-flex align-items-center text-truncate gap-8px">
          <NewCheckbox id={customKey} name={value} defaultChecked={isSelected}/>
          <div title={label} className={classNames('txt-sub-rg rounded-[10px] cursor-pointer text-truncate', { 'custom-option-label-extra-padding': color })} style={labelStyle} >{label}</div>
          {logo && <div className=''><img className='' src={logo} width={32} height={12} /></div>}
        </div>
      }
    </Option>
  )
}
export default CheckBoxDropdownOption