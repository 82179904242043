import Button from '@src/@core/components/button'
import CustomToast from '@src/@core/components/custom-toast/CustomToast'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import ability from '@src/configs/acl/ability'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Paperclip } from 'react-feather'
import { ATTACHMENTS_SIDEBAR_MODE, EDIT_ORDER_ENTITY_TYPES } from '../constant/orders.constants'
import Attachments from '../pending-actions/pages/createOrder/attachments'
import { getOrdersApi } from '../sales.apis'
import { editOrder } from '../store/store'

const AttachmentButtonBadge = (props) => {
  const {attachments, omsOrderId, handleGetParentData, hasAttachmentButton } = props

  const hasEditOrderDetailsPermission = ability.can(abilityMap.order.edit_details.action, abilityMap.order.edit_details.resource)

  const [attachmentSidebar, setAttachmentSidebar] = useState({
    isOpen: false,
    mode: ATTACHMENTS_SIDEBAR_MODE.EDIT.id
  })
  const [addedAttachments, setAddedAttachments] = useState([])
  const queryClient = useQueryClient()

  const handleToggleAttachmentSidebar = () => {
    const mode = hasEditOrderDetailsPermission && hasAttachmentButton ? ATTACHMENTS_SIDEBAR_MODE.EDIT.id : ATTACHMENTS_SIDEBAR_MODE.VIEW.id
    setAttachmentSidebar(prev => ({ ...prev, isOpen: !prev.isOpen, mode }))
  }
  
  const handleWhenAttachmentEdited = () => {
    const message = addedAttachments.length ? 'Files Updated Successfully' : 'File Added Successfully'
    CustomToast(message, {my_type: 'success'} )
    handleToggleAttachmentSidebar()
    if (handleGetParentData) {
      handleGetParentData()
    } else {
      queryClient.invalidateQueries({ queryKey:[getOrdersApi.apiKey] })
    }
  }

  const { mutate: editAttachment, isPending: isEditAttachmentPending} = editOrder({
    onSuccess: handleWhenAttachmentEdited
  })

  const onSaveAttachments = (files) => {
    const attachments = files.map((file) => {
      return {
        file_url: file.file_url,
        name: file.name.split('.')[0],
        description: file.description,
        mime_type: file.type,
        should_upload: Boolean(file.should_upload)
      }
    })
    const body = {
      attachments,
      edit_entity_type: EDIT_ORDER_ENTITY_TYPES.ORDER_ATTACHMENTS
    }
    editAttachment({body, order_id: omsOrderId})
  }

  useEffect(() => {
    const attachmentsMap = attachments.map(attachment => ({...attachment, type: attachment.mime_type, should_upload: false}))
    setAddedAttachments(attachmentsMap)
  }, [attachments])

  return (
    <>
      <Button 
        icon={Paperclip} 
        iconSize={14} 
        ofStyle="noBackground" 
        ofType="compressed"
        onClick={handleToggleAttachmentSidebar}
        className="text-nowrap txt-sub-md p-0 shadow-none"
      >
        {`${attachments.length} File${attachments.length > 1 ? 's' : ''}`}
      </Button>
      <Attachments 
        isOpen={attachmentSidebar.isOpen}
        toggle={handleToggleAttachmentSidebar}
        addedAttachments={addedAttachments}
        setAddedAttachments={setAddedAttachments}
        mode={attachmentSidebar.mode}
        onSaveAttachments={onSaveAttachments}
        isLoading={isEditAttachmentPending}
      />
    </>
  )
}

export default AttachmentButtonBadge