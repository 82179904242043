import ErrorIcon from '@src/assets/images/catalog/bundlesku/is_error_icon.svg'
import IsRequiredIcon from '@src/assets/images/catalog/bundlesku/is_required_icon.svg'
import classNames from 'classnames'
import { useRef } from 'react'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const MultiValueInput = ({ valueArray, valueRemoveHandler, label, isRequired, errors = {}, disabled, name, ...props }) => {
  const inputRef = useRef(null)
  const { t } = useTranslation()
  let isNested = false
  let nestedError
  if (name?.includes('.')) {
    isNested = true
    nestedError = errors
    for (const key of name.split('.')) {
      nestedError = nestedError?.[key]
    }
  }
  if (valueArray.length && !props.value) {
    props.value = ' '
  }
  if (errors.name && errors.name === name) {
    inputRef.current.focus()
  }
  return (
    <div className='omniful-chip-input-wrapper'>
      <div className={classNames('d-flex gap-8px flex-1 w-100 main-container flex-wrap position-relative', {
        'invalid-input': nestedError ? nestedError : errors[name] && !disabled
      })} onClick={() => inputRef.current.focus()}>
        {
          valueArray.map((ele, index) => {
            return <div key={index} className='d-flex align-items-center bg-dark-1 rounded-4px p-0 m-0'>
              <div className='ps-6px m-0 txt-asst-rg text-dark pe-4px'>{ele.barcode}</div>
              {ele.isEditable && <div className='p-0 me-4px rounded-2px d-flex align-items-center text-dark multi-value-remove'>
                <X color='var(--bs-dark-6)' className='cursor-pointer' size={14} onClick={() => valueRemoveHandler(index)} />
              </div>
              }
            </div>
          })
        }
        <input
          id={`${Date.now()}`}
          name={name}
          className='txt-sub-rg p-0'
          placeholder=''
          ref={inputRef}
          {...props}
        />
        <label htmlFor={inputRef.current} className={classNames('textarea-label')}>
          {t(label)}
          {isRequired && (
            <img className='required-icon' src={IsRequiredIcon} />
          )}
        </label>

      </div>
      {((errors?.[name]) || nestedError) && !disabled && (
        <div className="d-flex align-items-center gap-8px error-state ms-12px">
          <img className="info-icon" src={ErrorIcon} />
          <span className="error-text">
            {isNested ? nestedError.message : errors?.[name]?.message}
          </span>
        </div>
      )}
    </div>
  )
}

export default MultiValueInput
