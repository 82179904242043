import Button from '@src/@core/components/button'
import { WarningLogo } from '@src/assets/images/omniful/warning'
import { SHIPMENT_STATUS_UPDATE_ENTITY } from '@src/views/sales/sales.constant'
import { useMemo } from 'react'
import { Info, X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, Spinner } from 'reactstrap'

/**
 * Renders a modal for updating the shipment status of selected shipments or orders.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - {boolean} isOpen: Whether the modal is open or not.
 *   - {function} setIsOpen: Function to set the isOpen state.
 *   - {function} handleConfirm: Function to handle the confirmation of shipment status update.
 *   - {Array} selectedData: The selected shipments or orders.
 *   - {string} shipmentStatus: The new shipment status.
 *   - {string} entity: The type of entity (shipment or order).
 * @return {JSX.Element} The modal component.
 */
const ShipmentStatusUpdateModal = ({
  isOpen,
  setIsOpen,
  handleConfirm,
  selectedData,
  shipmentStatus,
  entity
}) => {

  const { t } = useTranslation()
  const loading = useSelector(state => state.sales.loading)
  const returnLoading = useSelector((store) => store.returns.loading)

  const handleClose = () => {
    setIsOpen({ isOpen: false, status: null })
  }

  const getShippingPartnerTag = (order) => {
    if (entity.key === SHIPMENT_STATUS_UPDATE_ENTITY.ORDER.key) {
      return order.shippingPartnerTag;
    }
    if (entity.key === SHIPMENT_STATUS_UPDATE_ENTITY.SHIPMENT_ORDER.key) {
      return order.shipment_details.shipping_partner.tag;
    }
    return order.shipping_partner_tag;
  };

  const { manualDispatchCount, shippingIntegrationCount } = useMemo(() => {
    return selectedData.reduce((acc, order) => {
      const shippingPartnerTag = getShippingPartnerTag(order);

      if (shippingPartnerTag === 'manual') {
        acc.manualDispatchCount++;
      } else {
        acc.shippingIntegrationCount++;
      }

      return acc;
    }, { shippingIntegrationCount: 0, manualDispatchCount: 0 });
  }, [selectedData]);

  return (
    <Modal
      isOpen={isOpen}
      className="shipment-status-update-modal rounded-24px width-600"
      size="lg"
      centered
      toggle={handleClose}
    >
      {isOpen && <ModalBody className="m-0 p-0 w-100">
        <div className="p-24px pb-16px d-flex flex-column gap-24px">
          <div className="d-flex justify-content-between">
            <div className="col-4"></div>
            <div className="col-4 text-center">
              <WarningLogo size='164px' />
            </div>
            <div className="col-4 d-flex justify-content-end">
              <X className="cursor-pointer text-dark" color="var(--bs-dark)" strokeWidth={2} size={20} onClick={handleClose} />
            </div>
          </div>
          <div className="d-flex flex-column gap-16px">
            <div className="text-dark txt-h1-sb">
              {`Confirm ${entity.label} Update`}
            </div>
            <div className="d-flex flex-column gap-8px text-dark-6 txt-sub-md">
              <div>
                {`Are you sure you want to mark the selected ${selectedData.length === 1 ? entity.value : `${entity.value}s`} as ${shipmentStatus.toLowerCase()}?`}
              </div>
              <div className="d-flex flex-column gap-2px">
                {manualDispatchCount > 0 ? <div className="d-flex gap-4px">
                  <span className="txt-sub-rg"> {`${entity.label}s via Manual dispatched`}:</span>
                  <span className="text-dark">{manualDispatchCount}</span>
                </div> : null}
                {shippingIntegrationCount > 0 ? <div className="d-flex gap-4px">
                  <span className="txt-sub-rg"> {`${entity.label}s via Shipping Partner Integration`}:</span>
                  <span className="text-dark">{shippingIntegrationCount}</span>
                </div> : null}
              </div>
            </div>
          </div>
          {shippingIntegrationCount > 0 ? <div className="d-flex bg-primary-lighter-global align-items-start gap-10px p-12px rounded-8px">
            <span>
              <Info fill='var(--bs-primary)' color="white" size={20} />
            </span>
            <span className="text-dark txt-sub-md">
              {`By marking these ${entity.value}s as ${shipmentStatus.toLowerCase()}, the ${entity.value}s via Shipping Partner Integration will not be automatically updated in their system`}.
            </span>
          </div> : null}
        </div>
        <div className="footer p-24px flex-center-end gap-16px">
          <Button ofStyle='noBackground' onClick={handleClose}>{t('Close')}</Button>
          <Button
            onClick={handleConfirm}
            icon={(loading.updateShipmentStatus || loading.changeOrderStatus || returnLoading.markOrdersAsReturnedTohHub) && Spinner}
            iconSize='sm'
            disabled={!!(loading.updateShipmentStatus || loading.changeOrderStatus || returnLoading.markOrdersAsReturnedTohHub)}
          >
            {t('Confirm')}
          </Button>
        </div>
      </ModalBody>}
    </Modal>
  )
}

export default ShipmentStatusUpdateModal
