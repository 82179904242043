import CustomLabel from '@src/@core/components/badge/CustomLabel'
import Button from '@src/@core/components/button'
import { ExpandableCustomTable } from '@src/@core/components/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/globalTable/globalTable.constant'
import SideSheetCloseButton from '@src/@core/components/page-header/sideSheetCloseButton'
import SideSheet from '@src/@core/components/sideSheet'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import ability from '@src/configs/acl/ability'
import { getPicklistsOfStatus } from '@src/views/sales/store/store'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'
import PicklistStatusFilter from '../../ongoingPicklist/components/sidebarTableFilters/picklistStatusFilter'
import ZoneFilter from '../../ongoingPicklist/components/sidebarTableFilters/zoneFilter'

const AllPicklistsOfStatus = ({
  // dropdownOptions (goes down here)
  toggleSidebar,
  isSidebarOpen,
  wave,
  time,
  columns,
  status,
  //  nextSidebarToggler,
  tableData,
  handleSidebarClosed,
  hub_id
}) => {
  // states
  const [lastUpdatedTime, setLastUpdated] = useState(null)
  const [currentTime, setCurrentTime] = useState(new Date())
  const loading = useSelector(state => state.sales.picklistsOfStatus.loading)
  const success = useSelector(state => state.sales.picklistsOfStatus.success)
  const error = useSelector(state => state.sales.picklistsOfStatus.error)
  const showHeading = useSelector((state) => state?.sales?.completedWaveState)
  const { t } = useTranslation()
  // const picklistsInZone = useSelector(state => state.sales.picklistsInZone)

  const timer = useRef()

  const onSidebarClosed = () => {
    if (timer.current) {
      setLastUpdated(null)
      clearInterval(timer.current)
    }
    handleSidebarClosed()
  }

  useEffect(() => {
    if (lastUpdatedTime) {
      const interval = setInterval(() => {
        setCurrentTime(new Date())
      }, 5000) // Update every second
      return () => clearInterval(interval)
    }
  }, [lastUpdatedTime])

  // Calculate the difference between the current time and last updated time
  const calculateTimeDifference = () => {
    if (lastUpdatedTime && currentTime) {
      const difference = Math.abs(currentTime - lastUpdatedTime)
      const minutes = Math.floor(difference / 1000 / 60)
      //   const seconds = Math.floor((difference / 1000) % 60)
      //   ${seconds} seconds
      return `${minutes} mins`
    }
  }
  const calculateSecondDifference = () => {
    const difference = Math.abs(currentTime - lastUpdatedTime)
    const seconds = Math.floor(difference / 1000)
    return seconds
  }

  const dispatch = useDispatch()
  const handleRefresh = () => {
    // Make API call here
    if (calculateSecondDifference() > 30) {
      setLastUpdated(new Date())
      setCurrentTime(new Date())
      dispatch(getPicklistsOfStatus({ type: status, waveId: wave?.waveId, hub_id }))
    }
  }

  // const newTableData = tableData?.map(row => ({ ...row, nextSidebarToggler }))
  return (
    <SideSheet
      isOpen={isSidebarOpen}
      onClosed={onSidebarClosed}
      toggle={toggleSidebar}
      contentClassName="p-0"
      modalClassName="modal-slide-in modal-slide-out picklist_wave_sidebar"
      size="md"
    >
      {/* TODO: Remove hardcoding Everywhere in this */}
      <div className="modal_header mb-50 p-24px pb-12px align-items-start">
        <div>
          <h2 className="modal_header_title txt-h1-md">{wave?.name}</h2>
          <div className="d-flex align-items-center gap-12px">
            <span className="txt-body-md text-dark-5">{t('Start time')}: {time}</span>
            {showHeading && true && (
              <CustomLabel title="Ongoing Wave" className="text-info bg-info-light" />
            )}
          </div>
          {/* TODO: Remove hardcoding */}
          <div className="d-flex align-items-center gap-1 last-updated-time-container">
            {calculateTimeDifference() && (
              <span
                className="text-secondary "
              >
                {t('Last updated')} : {calculateTimeDifference()} {t('ago')}
              </span>
            )}
            <Button
              id="sidebarRefresh"
              ofStyle='noBackground'
              className={classNames('p-2px bg-transparent', {'text-primary': calculateSecondDifference() <= 25})}
              onClick={handleRefresh}
            >
              {t('Refresh')}
            </Button>
            {calculateSecondDifference() <= 25 && (
              <UncontrolledTooltip target="sidebarRefresh">
                {`Refresh After: ${30 - calculateSecondDifference()} seconds`}
              </UncontrolledTooltip>
            )}
          </div>
        </div>

        <SideSheetCloseButton clearButtonHandler={toggleSidebar}/>
      </div>

      <div className="d-flex align-items-center ms-1 gap-50 my-2">
        {ability.can(abilityMap.hub_location.view.action, abilityMap.hub_location.view.resource) && <ZoneFilter wave={wave} status={status} hub_id={hub_id}/>}
        <PicklistStatusFilter wave={wave} status={status} hub_id={hub_id}/>
      </div>
      <div className="mx-1"> 
        <ExpandableCustomTable
          loading={loading}
          error={error}
          success={success}
          data={tableData}
          showPagination={false}
          columns={columns}
          columnName={ALL_COLUMN_NAME_MAPPING.ORDER_PICKLIST_STATUS}
          showColumnsTableHeader={false}
        />
      </div>
    </SideSheet>
  )
}

export default AllPicklistsOfStatus
