import Button from '@src/@core/components/button'
import warningImage from '@src/assets/images/catalog/bundlesku/warning-icon.svg'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

const DifferentCurrencyWarningModal = ({toggle, ...rest}) => {
  const { t } = useTranslation()

  return (
    <div>
      <Modal
        className='seller-change-warning-modal-for-kits'
        modalClassName='warning-seller-change-modal-classname'
        centered
        toggle={toggle}
        {...rest}
      >
        <div className='warning-modal-header'>
          <div className='modal-title m-auto'>
            <img src={warningImage} height={164} width={164}/>
          </div>
          <span
            className='bg-white cursor-pointer'
            onClick={toggle}
          >
            <X size={18} className='text-dark-6' />
          </span>
        </div>
        <ModalBody className='flex-center flex-column gap-16px mt-2 mb-2 color-black'>
          <div className='txt-h1-sb text-dark'>
            {t('Attention! SKUs added to the kit have different currencies.')}
          </div>
          <div className='text-dark txt-body-rg'>
            {t(
              'The selected SKUs in this kit have different currencies. To ensure accuracy, please convert all prices to a single currency for the kit\'s final total. Update the prices accordingly before proceeding.'
            )}
          </div>
        </ModalBody>
        <ModalFooter className='flex-center-end gap-1'>
          <Button onClick={toggle}>{t('Okay, Got it')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default DifferentCurrencyWarningModal
