import BarcodeScanner from '@src/@core/components/barcodeScanner'
import Button from '@src/@core/components/button'
import { changeSkuWeight, clearSkuDetailUpdateStatus, updateWeightForSku } from '@src/views/inventory/store'
import { useEffect, useRef } from 'react'
import { X } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, Spinner } from 'reactstrap'

const AddSkuBarcodeModal = ({isOpen, toggle, skuDetails}) => {

  const { control, reset, watch, handleSubmit } = useForm()

  const enterBarcodeRef = useRef()

  const loadingState = useSelector(store => store.inventory.loadingState)
  const isSkuDetailUpdated = useSelector(store => store.inventory.isSkuDetailUpdated)
  const latestSkuDetails = useSelector(state => state.inventory.grn.sku_details?.data)

  const dispatch = useDispatch()
  const onSubmit = (data) => {
    const body = {
      barcodes: [...skuDetails.barcodes, data.sku_barcode],
      sku_upsert_source_details: {
        source: 'manual'
      }
    }
    dispatch(changeSkuWeight({ seller_id: skuDetails.seller_id, seller_sku_id:skuDetails.seller_sku_id, body, mode: 'barcode' }))
  }
  useEffect(() => {
    if (isSkuDetailUpdated && isSkuDetailUpdated.mode === 'barcode' && isSkuDetailUpdated.skuId === skuDetails.seller_sku_id) {
      dispatch(updateWeightForSku({ prevState: latestSkuDetails, skuId: isSkuDetailUpdated.skuId, body: isSkuDetailUpdated.body, mode: isSkuDetailUpdated.mode }))
      toggle()
      dispatch(clearSkuDetailUpdateStatus())
    }
  }, [isSkuDetailUpdated])

  const onOpened = () => {
    if (enterBarcodeRef.current) enterBarcodeRef.current?.focus()
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={isOpen}
      centered
      onOpened={onOpened}
      onClosed={onClosed}
      toggle={toggle}
      contentClassName="rounded-12px"
    >
      <ModalBody className="d-flex flex-column gap-24px py-16px px-20px ">
        <div className="d-flex justify-content-between align-items-center">
          <div className="txt-body-md text-dark">Add SKU Barcode</div>
          <X onClick={toggle} size={16} className="text-dark cursor-pointer" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-column mb-24px">
            <BarcodeScanner
              ref={enterBarcodeRef}
              startIcon={
                <img
                  src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
                  alt="QR Code"
                  width="16px"
                  height="16px"
                />
              }
              placeholder="Enter Barcode"
              control={control}
              name="sku_barcode"
            />
          </div>
          <Button
            type='submit'
            className="w-100 justify-content-center"
            disabled={loadingState.changeSkuWeight || !watch('sku_barcode')}
          >
            {loadingState.changeSkuWeight && <Spinner size="sm" />}
            <span className="align-middle ms-25">Save And Continue</span>
          </Button>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default AddSkuBarcodeModal