import Button from '@src/@core/components/button'
import { ExpandableCustomTable } from '@src/@core/components/globalTable/ExpandableCustomTable'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import ability from '@src/configs/acl/ability'
import { SORT_ORDER } from '@src/views/sales/constant/orders.constants'
import { useEffect, useState } from 'react'
import { Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { HUB_SORT_ORDER } from '../../constants.hubs'
import { getHubs, setSelectedHubId } from '../../store'
import SortHeaderColumn from '@src/@core/components/table-header-col'

const TabularView = ({ hubsTableData, meta, navigate, hubLoading, success, error, TableColumnsHeader, setSearchQuery, searchQuery, sort, setSort, filter, handlePagination, pagination }) => {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const [hubTableData, setHubTableData] = useState([])
  const toHubDetails = (hubId) => {
    dispatch(setSelectedHubId(hubId))
    if (ability.can(abilityMap.hub.view.action, abilityMap.hub.view.resource)) {
      navigate(`/hubs/${hubId}/overview`)
    } else if (ability.can(abilityMap.hub_location.view_bin.action, abilityMap.hub_location.view_bin.resource)) {
      navigate(`/hubs/${hubId}/bins`)
    }
  }
  const handleSearchItem = ({ searchVal, selectedColumn = null }) => {
    const searchQuery = searchVal
      ? { column: selectedColumn, query: searchVal }
      : null
    setSearchQuery(searchQuery)
  }

  const columns = [
    {
      id: 1,
      name: <div className="w-100 text-center">{t('S. No.')}</div>,
      key:'serial_no',
      width: '80px',
      cell: (row) => {
        return (
          <div className="w-100 text-center">
            {row.serialNumber}
          </div>
        )
      }
    },
    {
      id: 2,
      name: <SortHeaderColumn title="Hub Code"/>,
      key:'hub_code',
      sortable:true,
      minWidth: '40px',
      reorder:true,
      selector: (row) => {
        return (
          <div>
            {row.hubCode}
          </div>
        )
      }
    },
    {
      id: 3,
      name: t('Hub Name'),
      key:'hub_name',
      reorder:true,
      minWidth:'180px',
      cell: (row) => {
        return (
          <div className='d-flex gap-2px flex-column'>
            <span onClick={() => toHubDetails(row.id)} className="cursor-pointer text-primary">
              {row.hubName}
            </span>
            <div className='font-small-2'>
              {row.hubAddress || '-'}
            </div>
          </div>
        )
      }
    },
    {
      id: 4,
      name: t('Email ID'),
      key:'hub_email',
      minWidth: '40px',
      reorder:true,
      selector: row => row.hubEmail
    },
    {
      id: 5,
      name: t('Contact Number'),
      key:'hub_contact',
      minWidth: '40px',
      reorder:true,
      selector: row => row.contactNumber
    },
    {
      id: 6,
      name: t('City'),
      key:'hub_city',
      minWidth: '40px',
      reorder:true,
      selector: row => row.hubCity || '-'
    },
    {
      id: 7,
      name: t('Country'),
      key:'hub_country',
      minWidth: '40px',
      reorder:true,
      selector: row => row.hubCountry
    }
  ]

  const handleGetTableRefreshedData = () => {
    const searchParams = searchQuery.query ? { search_column: searchQuery.column.id, search_query: searchQuery.query } : {}
    const sortParams = sort.order ? { sort: sort.order === SORT_ORDER.ASC ? HUB_SORT_ORDER.ASC : HUB_SORT_ORDER.DESC } : {}
    const filterParams = filter.city_id ? {
      city_id: filter.city_id.value,
      city_name: filter.city_id.label
    } : {}
    if (hubLoading) return
    const params = {
      ...searchParams,
      ...sortParams,
      ...filterParams,
      ...pagination
    }
    dispatch(getHubs(params))
  }

  const toCreateHub = () => {
    navigate('/hubs', { state: { hubCreate: true } })
  }

  const handleSort = (sortField) => {
    setSort(sortField)
  }

  const defaultSortColumn = columns.find((column) => column.key === sort.field) || {}

  useEffect(() => {
    if (hubsTableData) {
      const data = hubsTableData.map((row, index) => {
        return {
          ...row,
          serialNumber: ((meta?.current_page - 1) * meta?.per_page) + index + 1
        }
      })
      setHubTableData(data)
    } else {
      setHubTableData([])
    }
  }, [hubsTableData])

  return (
    <>
      <ExpandableCustomTable
        loading={hubLoading}
        success={success}
        error={error}
        showColumnsTableHeader
        columns={columns}
        data={hubTableData}
        useReactPaginate={false}
        meta={meta || {}}
        handlePagination={handlePagination}
        TableHeaderComponent={
          ability.can(abilityMap.hub.create.action, abilityMap.hub.create.resource) &&
          <div className="d-flex gap-1 ps-14px border-start border-primary-lighter">
            <Button onClick={toCreateHub}>
              <Plus size={16}/>
              {t('Create Hub')}
            </Button> 
          </div>    
        }
        searchcolumnsList={TableColumnsHeader}
        handleRefreshTable={handleGetTableRefreshedData}
        search_column_query={searchQuery}
        handleQueryParams={handleSearchItem}
        isFilterOrSearchApplied={!!(Object.values(filter)?.length || Object.values(searchQuery)?.length > 1)}
        handleSort={handleSort}
        defaultSortFieldId={defaultSortColumn.id}
        defaultSortAsc= {sort.order === SORT_ORDER.ASC}
      />
    </>
  )
}

export default TabularView
