import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { AlertCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { UncontrolledTooltip } from 'reactstrap'
import './styles.scss'

const CheckBox = React.forwardRef(({ id, name, label, size, className = '', disabled, partialChecked, isLabelBeforeInput, tooltipId, tooltipText, isTranslationEnabled = true, ...props }, ref) => {
  const { t } = useTranslation()
  const inputId = id || `onminiful_checkbox_${name}`
  return (
    <div className='omniful-checkbox-component-wrapper d-flex'>
      <label className={classNames('omniful-checkbox-component', { 'disabled ': disabled, 'partialChecked ': partialChecked })} htmlFor={`${name}_${inputId}`}>
        <div className={classNames('d-flex align-items-center p-4px', {'flex-row-reverse': isLabelBeforeInput, 'gap-8px': label, 'gap-0': !label})}>
          <div className='checkbox'>
            <input
              id={`${name}_${inputId}`}
              name={name}
              type='checkbox'
              className={classNames('cursor-pointer', { 'cursor-default': disabled, 'partialChecked ': partialChecked })}
              disabled={disabled}
              {...props}
              ref={ref}
            />
            <div className={classNames(`checkmark checkmark-${size}`, { 'partialChecked ': partialChecked, [`partialChecked-${size}`]: partialChecked})}></div>
          </div>
          <label htmlFor={`${name}_${inputId}`} className={classNames(`checkbox-label cursor-pointer ${className}`, { 'cursor-default': disabled })}>
            {(typeof label === 'string' && isTranslationEnabled) ? t(label) : label}
          </label>
        </div>
      </label>
      {tooltipId && <div className='flex-center-center'> <AlertCircle  size={16} id={tooltipId}/>
        <UncontrolledTooltip offset={[0, 5]} target={tooltipId}>
          {t(tooltipText || label)}
        </UncontrolledTooltip>
      </div>}
    </div>
  )
})

CheckBox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  partialChecked: PropTypes.bool,
  isLabelBeforeInput: PropTypes.bool,
  isTranslationEnabled: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg'])
}

CheckBox.defaultProps = {
  size: 'lg'
}

export default memo(CheckBox)