
import CopyOnClick from '@src/@core/components/click-and-copy'
import { ENVIRONMENT } from '@src/App.constants'
import { QRCodeSVG } from 'qrcode.react'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody } from 'reactstrap'
import './ModalQRCode.scss'

const QrcodeModal = ({ modalOpen, toggle, qrData}) => {
  const { t } = useTranslation()

  let envVar = import.meta.env.VITE_ENVIRONMENT === ENVIRONMENT.PRODUCTION? 0 : 1;

  const currentUrl = `${window.location.origin}/app-download.html?app-type=${qrData.appURL}&env=${envVar}`

  return (
    <Modal
      centered
      isOpen={modalOpen}
      toggle={()=>toggle()}
      contentClassName="width-600 barcode-print-modal-hubs"
    >
      <div className="barcode-modal-header">
        <div className="modal-title txt-h1-sb">{t(`Download ${qrData.appName}`)}</div>
        <span className="bg-white cursor-pointer" onClick={()=>toggle()}>
          <X size={18} className="x-mark" color="var(--bs-dark)" />
        </span>
      </div>
      <ModalBody className="width-600 flex-center-center flex-column modal-body" >
        <div className="generated-qr-code-box">
          <QRCodeSVG value={currentUrl} className="generated-qr-code" size={300} level='H'
            imageSettings={{
              src: `${qrData.icon}`,
              height: `${qrData.iconHeight}`,
              width: `${qrData.iconWidth}`,
              opacity: 1,
              excavate: true,
            }}/>
        </div>
        <p className="txt-body-md body-text">{t(`To download the ${qrData.appName} scan the QR code or open the link below on your ${qrData.availability} device`)}</p>
        <div className="flex-start download-copy-box">
          <p className="txt-body-md download-url m-0">{t('App Download URL')}</p>
          <div className="qr-copy-area flex-start">
            <CopyOnClick isCopyIconVisibleAlways={true} additionalClasses="qr-code" title={currentUrl}>{currentUrl}</CopyOnClick>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default QrcodeModal