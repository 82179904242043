import Button from '@src/@core/components/button'
import Toggle from '@src/@core/components/toggle-button'
import ToggleTabs from '@src/@core/components/toggleTabs'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import infoFilled from '../../../../../../../assets/images/inventory/icons/infoFilled.svg'
import { SERIALISATION_OPTION } from '../../constant'
import InfoPopupModal from './InfoPopupModal'
import ConfirmConfigModal from './confirmConfigModal'

const BatchAndSerialisationSetting = ({ skuDetails, currentSkuConfig, setCurrentSkuConfig = () => {} }) => {
  const { register, watch } = useForm({
    defaultValues: {
      batch_status: skuDetails.is_configuration_editable && currentSkuConfig ? currentSkuConfig.batch_status === 'batched' : false
    }
  })
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [selectedSerilisationOption, setSelectedSerilisationOption] = useState(null)
  const {t} = useTranslation()

  const tabChangeHandler = (tab) => {
    setSelectedSerilisationOption(tab.id)
  }

  const handleInfoModal = () => {
    setIsInfoModalOpen((pre) => !pre)
  }
  
  const toggleConfirmModal = () => {
    setIsConfirmModalOpen(pre => !pre)
  }

  const handleSetGrnConfig = () => {
    setIsConfirmModalOpen(true)
    
  }

  useEffect(() => {
    if (skuDetails.is_configuration_editable && currentSkuConfig) {
      setSelectedSerilisationOption(currentSkuConfig.serialisation_status)
    } else {
      setSelectedSerilisationOption(SERIALISATION_OPTION.non_serialised.id) 
    }
  }, [])

  return (
    <div className="d-flex flex-column gap-16px px-16px pt-12px pb-16px bg-light-3 rounded-8px">
      <div className="d-flex flex-column gap-16px border-bottom border-dark-2 pb-16px">
        <div className="d-flex flex-column gap-8px">
          <div className="txt-body-md text-dark">{t('Batch Setting')}</div>
          <div className="txt-sub-rg">
            {t('Do you want to assign this SKU to a batch?')}
          </div>
        </div>
        <div className="width-fit-content">
          <Toggle ofStyle='success' ofSize='compressed' {...register('batch_status')}  checked={watch('batch_status')} name="batch_status" activeText='Yes' inactiveText='No' inactiveColor='var(--bs-dark-2)'/>
        </div>
        <div className="d-flex gap-8px align-items-center">
          <img src={infoFilled} alt="infoImg" />
          <div className="txt-asst-rg text-dark-6">
            {t('Making this SKU a part of a batch will let you know when a SKU expires.')}
          </div>
          <Button
            onClick={handleInfoModal}
            className="p-4px bg-light-3 txt-asst-rg"
            ofStyle="noBackground"
          >
            {t('Read More')}
          </Button>
          <InfoPopupModal isOpen={isInfoModalOpen} toggle={handleInfoModal} />
        </div>
      </div>
      <div className="d-flex flex-column gap-16px">
        <div className="txt-body-md text-dark">{t('Barcode Setting')}</div>
        <ToggleTabs
          tabDataList={Object.values(SERIALISATION_OPTION)}
          selectedTabId={selectedSerilisationOption}
          onTabChange={tabChangeHandler}
        />
        <div className="d-flex flex-column gap-8px">
          <div className="d-flex gap-8px align-items-center">
            <img src={infoFilled} alt="infoImg" />
            <div className="txt-asst-rg text-dark-6">
              {t('In Serialisation, barcodes will be unique for every Item.')}
            </div>
          </div>
          <div className="d-flex gap-8px align-items-center">
            <img src={infoFilled} alt="infoImg" />
            <div className="txt-asst-rg text-dark-6">
              {t('In Non-Serialisation, all generated barcode will be same.')}
            </div>
          </div>
        </div>
        <Button onClick={handleSetGrnConfig}>{t('Continue')}</Button>
        <ConfirmConfigModal skuDetails={skuDetails} isOpen={isConfirmModalOpen} toggle={toggleConfirmModal} batchStatus = {watch('batch_status')} serialisationStatus={selectedSerilisationOption} setCurrentSkuConfig={setCurrentSkuConfig} />
      </div>
    </div>
  )
}

export default BatchAndSerialisationSetting
