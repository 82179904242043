import Button from '@src/@core/components/button'
import CustomDataTable from '@src/@core/components/custom-data-table'
import FloatingDropDown from '@src/@core/components/floating-dropdown'
import InputField from '@src/@core/components/input-field'
import ComponentSpinner from '@src/@core/components/spinner/Loading-spinner'
import { API_ENDPOINTS } from '@src/api.urls'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getApiUrl } from '@src/utility/Utils'
import CreateBoxes from '@src/views/hubs/pages/HubBoxes/CreateBoxes'
import { getBoxesAsyncData } from '@src/views/sales/store/store'
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ArrowDown, Check, Plus, Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { components } from 'react-select'
import { API_KEYS } from '../bulk-ship.constants'

const PackagingPreference = ({
  orderItems = [],
  setBoxes,
  orderWeight = 0,
  setOrderWeight,
  setNoOfPackages,
  waveId,
  setIsSavePackagingDetailsApi,
  packagingDetailsItemsLoading,
  noOfPackages,
  boxes,
  hasPackagingPreference,
  isEditingPackagePreference,
  setIsEditingPackagePreference,
  hasViewBinPermission
}) => {
  const { t } = useTranslation()
  const selectedGlobalHubId = useSelector(
    (store) => store.auth.selectedGlobalHubId
  )

  const packagePrefViewOnly = hasPackagingPreference && !isEditingPackagePreference

  const boxRows = Object.keys(boxes).length ? [...Object.values(boxes)] : []
  const queryClient = useQueryClient()

  const [openCreateBoxModal, setOpenCreateBoxModal] = useState(false)

  const MenuList = (props) => {
    // const optionsLength = getLength(props.options)
    const { MenuList } = components
    return (
      <div className="bg-white rounded box-shadow-1 overflow-auto">
        <MenuList {...props}>{props.children}</MenuList>
        <div className="">
          <Button
            onClick={() => setOpenCreateBoxModal(true)}
            ofStyle="noBackground"
            icon={Plus}
            className="w-100 justify-content-start box-shadow-1 rounded-0"
          >
            {t('Add Box')}
          </Button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    setNoOfPackages(Object.keys(boxes).filter((k) => k).length)
  }, [boxes])

  const allocatedOrderItems = useMemo(() => {
    const allocatedorderItems = orderItems.reduce((acc, curVal) => {
      const totalAllocation = Object.values(boxes).reduce(
        (allocationSum, curbox) => {
          const currentBoxSkusObj =
            curbox.sku_details?.[curVal?.sku?.seller_sku_id] || {}
          return (
            allocationSum +
            (+currentBoxSkusObj?.quantity ? currentBoxSkusObj.quantity : 0)
          )
        },
        0
      )

      return {
        ...acc,
        [curVal?.sku?.seller_sku_id]: {
          ...acc[curVal?.sku?.seller_sku_id],
          allocated: totalAllocation,
          total: curVal?.quantity
        }
      }
    }, {})

    return allocatedorderItems
  }, [boxes, orderItems])

  const getItemColumn = (item) => {
    const orderItem = {
      id: item.sku.seller_sku_id,
      skuName: item.sku?.name,
      skuCode: item.sku?.seller_sku_code,
      image: item.sku?.images?.[0]?.url,
      qty: item.display_quantity
    }
    return {
      id: orderItem.id,
      name: (() => {
        const { image, skuName, skuCode } = orderItem
        return (
          <div className={classNames('d-flex w-100', {
            'min-width-400': !packagePrefViewOnly, 
            'min-width-300': packagePrefViewOnly
          })}>
            <div className="flex-center-start gap-16px w-50">
              <img
                src={image || no_sku_image}
                height={32}
                width={32}
                className="border rounded-4px p-4px border-dark-2"
                style={{ objectFit: 'contain' }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = no_sku_image
                }}
              />
              <div className="d-flex flex-column gap-4px text-truncation">
                <p
                  id={`order-item-${orderItem.id}`}
                  className="txt-sub-rg text-truncation cursor-pointer m-0"
                  title={skuName}
                >
                  <span className="d-none " />
                  {skuName}
                </p>
                <p className="txt-asst-rg text-truncation text-dark-6 m-0">
                  {t('SKU Code')}: {skuCode}
                </p>
              </div>
            </div>
            {packagePrefViewOnly ? null : <div
              className={classNames(
                'w-50 flex-center-end gap-6px txt-asst-md',
                {
                  'text-danger':
                    allocatedOrderItems[orderItem.id]?.allocated <
                    allocatedOrderItems[orderItem.id]?.total,
                  'text-dark-5':
                    allocatedOrderItems[orderItem.id]?.allocated ===
                    allocatedOrderItems[orderItem.id]?.total
                }
              )}
            >
              {allocatedOrderItems[orderItem.id]?.allocated <
              allocatedOrderItems[orderItem.id]?.total ? (
                  <ArrowDown size={16} />
                ) : null}
              {allocatedOrderItems[orderItem.id]?.allocated >=
              allocatedOrderItems[orderItem.id]?.total ? (
                  <span
                    className={classNames(
                      'border rounded-circle min-height-16px height-16px min-width-16px width-16px flex-center-center',
                      {
                        'border-danger':
                        allocatedOrderItems[orderItem.id]?.allocated <
                        allocatedOrderItems[orderItem.id]?.total,
                        'border-dark-5':
                        allocatedOrderItems[orderItem.id]?.allocated ===
                        allocatedOrderItems[orderItem.id]?.total
                      }
                    )}
                  >
                    <Check size={12} />
                  </span>
                ) : null}
              {allocatedOrderItems[orderItem.id]?.allocated}/
              {allocatedOrderItems[orderItem.id]?.total} Items Allocated
            </div>}
          </div>
        )
      })(),
      cell: (box) => {
        if (packagePrefViewOnly) {
          return <div className="w-100 text-start">
            {boxes[box.refId]?.sku_details?.[item.sku.seller_sku_id]?.quantity}
          </div>
        }
        const inputValue =
          boxes[box.refId]?.sku_details?.[item.sku.seller_sku_id]?.quantity
        const onChange = (e) => {
          const valueToChange = +e.target.value

          const remainingOrderItemsAllocatedSum = Object.keys(boxes).reduce(
            (sum, key) => {
              if (`${key}` === `${box.refId}`) return sum
              return (
                sum + (+boxes[key].sku_details?.[orderItem.id].quantity || 0)
              )
            },
            0
          )

          const totalAllocatableSum = allocatedOrderItems[orderItem?.id].total

          const maxValueCanBeEntered =
            Object.keys(boxes).length === 1
              ? totalAllocatableSum
              : totalAllocatableSum - remainingOrderItemsAllocatedSum

          const quantity = Math.min(
            maxValueCanBeEntered,
            Math.max(valueToChange, 0)
          )

          setBoxes((p) => ({
            ...p,
            [box.refId]: {
              ...p[box.refId],
              sku_details: {
                ...p[box.refId].sku_details,
                [item.sku.seller_sku_id]: {
                  ...p[box.refId].sku_details?.[item.sku.seller_sku_id],
                  quantity
                }
              }
            }
          }))
        }

        return (
          <input
            key={`${box.refId}${orderItem.id}`}
            className={classNames(
              'w-100 d-block height-28px border border-dark-2 rounded-4px px-8px outline-none',
              {
                'border-danger': Boolean(
                  box.value &&
                    Object.values(boxes[box.refId]?.sku_details || {}).every(
                      (sku) => !sku.quantity
                    )
                )
              }
            )}
            value={inputValue}
            onChange={onChange}
            type="number"
            onWheel={(e) => e.target.blur()}
            disabled={!box.refId}
          />
        )
      }
    }
  }

  const isAllAllocated = useMemo(
    () => !Object.values(allocatedOrderItems).some(
      (el) => +el.allocated < +el.total
    ),
    [allocatedOrderItems]
  )
  const hasEmptyRow = useMemo(
    () => boxRows.some((box) => Object.values(box?.sku_details || {}).every((sku) => !sku.quantity)
    ),
    [boxRows]
  )
  const isAddRowDisabled = isAllAllocated || hasEmptyRow

  const handleBoxChange = useCallback(
    (selOption, prevValue) => {
      if (prevValue.refId) {
        const p = { ...boxes }
        const newBoxes = {
          ...p,
          [prevValue.refId]: { ...p[prevValue.refId], ...selOption }
        }
        setBoxes(newBoxes)
        return
      }

      const boxId = Date.now()

      const newBoxDetails = {
        ...boxes,
        [boxId]: {
          ...selOption,
          refId: boxId
        }
      }

      newBoxDetails[boxId].sku_details = orderItems.reduce((acc, curItem) => {
        const quantity = +(
          allocatedOrderItems[curItem.sku?.seller_sku_id]?.total -
          allocatedOrderItems[curItem.sku?.seller_sku_id]?.allocated
        )
        return {
          ...acc,
          [curItem.sku?.seller_sku_id]: {
            seller_sku_id: curItem.sku?.seller_sku_id,
            quantity
          }
        }
      }, {})
      delete newBoxDetails['']
      setBoxes(newBoxDetails)
    },
    [boxes]
  )

  const handleDeleteRow = (box) => {
    setBoxes((p) => {
      const newBoxes = { ...p }
      if (Object.keys(newBoxes).length === 1) return newBoxes
      delete newBoxes[box.refId]
      return newBoxes
    })
  }

  const handleAddRow = () => {
    if (isAddRowDisabled) {
      return
    }
    setBoxes((p) => ({
      ...p,
      '': {}
    }))
  }

  const columns = useMemo(() => {
    const columns = [
      {
        id: 1,
        minWidth: '56px',
        maxWidth: '56px',
        name: '',
        cell: (box) => {
          return (
            <button
              onClick={() => handleDeleteRow(box)}
              disabled={Object.keys(boxes).length === 1 || !box.value}
              className="border bg-transparent outline-none cursor-not-allowed rounded min-height-24px min-width-24px p-0 flex-center-center"
            >
              <Trash2
                size={16}
                color={
                  Object.keys(boxes).length === 1 || !box.value
                    ? 'var(--bs-dark-4)'
                    : 'var(--bs-danger)'
                }
              />
            </button>
          )
        }
      },
      {
        id: 2,
        minWidth: '280px',
        maxWidth: '280px',
        name: t('Box'),
        cell: (box) => {
          return (() => {
            if (packagePrefViewOnly) return <div className="">{box.refId ? box.label : '-'}</div>

            return (
              <FloatingDropDown
                key={box.refId ? `${box.refId}` : 'empty-box'}
                isAsync
                title=""
                placeholder={!box.refId ? 'Choose Box' : ''}
                value={box.refId ? box : null}
                onChangeFunc={(selectedOpt) => handleBoxChange(selectedOpt, box)
                }
                width="100%"
                additionalComponents={{ MenuList }}
                classNames={{
                  control: () => {
                    return 'max-height-28px min-height-28px p-0 rounded-4px'
                  },
                  valueContainer: () => {
                    return 'px-4px py-0'
                  }
                }}
                loadOptions={
                  selectedGlobalHubId ? getBoxesAsyncData : undefined
                }
                menuPortalTarget={document.body}
                additional={{ hubId: selectedGlobalHubId }}
                cacheUniqs={[openCreateBoxModal]}
                disabled={!hasViewBinPermission}
              />
            )
          })()
        }
      }
    ]
      .concat(...orderItems.map((orderItem) => getItemColumn(orderItem)))
      .filter((col) => (packagePrefViewOnly ? col.id !== 1 : true))
    return columns
  }, [orderItems, handleBoxChange, openCreateBoxModal, packagePrefViewOnly])

  const { mutate: savePackagingDetails, isPending: savePackagingDetailsLoading } = useApi({
    isMutation: true,
    apiKey: [API_KEYS.SAVE_PACKAGING_DETAILS],
    apiFn: () => {
      const body = {
        no_of_packages: +noOfPackages,
        order_weight: +orderWeight,
        box_details: Object.values(boxes).map((box) => ({
          box_id: +box.value,
          sku_details: Object.values(box.sku_details).map(
            ({ seller_sku_id, quantity }) => ({ seller_sku_id, quantity })
          )
        }))
      }

      return axiosInstance.post(
        getApiUrl(API_ENDPOINTS.BULK_SHIP.SAVE_PACKAGING_DETAILS, {
          waveId,
          hubId: selectedGlobalHubId
        }),
        body
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_KEYS.FETCH_PICKING_WAVE]
      })
      setIsEditingPackagePreference(false)
      setIsSavePackagingDetailsApi(false)
    }
  })

  const isSavePackagingDisabled = !isAllAllocated || !orderWeight || savePackagingDetailsLoading


  return (
    <>
      <div className="d-flex flex-column gap-32px">
        <div className="w-100 border border-1 rounded-12px overflow-hidden">
          {packagingDetailsItemsLoading ? (
            <ComponentSpinner />
          ) : (
            <div className="my-custom-table-changed">
              <CustomDataTable
                columns={columns}
                data={boxRows.sort((a, b) => a.refId - b.refId)}
                striped
                responsive
              />
            </div>
          )}
          {packagePrefViewOnly ? null : (
            <div className="p-16px py-6px bg-light-2 border-top text-primary">
              <Button
                icon={Plus}
                ofType="compressed"
                ofStyle="noBackground"
                className="bg-transparent px-4px"
                onClick={handleAddRow}
                disabled={isAddRowDisabled}
              >
                {t('Add Row')}
              </Button>
            </div>
          )}
        </div>

        <div className="text-dark d-flex flex-column gap-28px">
          <div className="d-flex flex-column gap-18px">
            <p className="m-0 txt-body-md ">{t('Packaging Details')}</p>
            {packagePrefViewOnly ?
              <div className="d-flex gap-16px p-16px bg-light-2 width-fit-content rounded-16px">
                <div className="d-flex flex-column gap-8px">
                  <div className="width-150 txt-body-rg text-dark-6">{ t('Order Weight') } :</div>
                  <div className="txt-h3-sb">{ orderWeight || 0 } Kg</div>
                </div>
                <div className="d-flex flex-column gap-8px">
                  <div className="width-150 txt-body-rg text-dark-6">{ t('No of Packages') } :</div>
                  <div className="txt-h3-sb">{ noOfPackages || 0 }</div>
                </div>
              </div> :
              <div className="d-flex gap-16px">
                <InputField
                  label="No Of Packages"
                  isRequired
                  disabled
                  value={noOfPackages}
                />
                <InputField
                  label="Weight In Kg"
                  isRequired
                  value={orderWeight}
                  onChange={(e) => {
                    const value = e.target.value
                    const regex = /^\d*\.?\d{0,3}$/
                    if (regex.test(value)) {
                      setOrderWeight(value)
                    }
                  }}
                />
              </div>
            }
          </div>
          {packagePrefViewOnly ? null : <>
            <hr className="m-0 p-0 text-dark-2" />
            <Button
              onClick={() => {
                setIsSavePackagingDetailsApi(true)
                savePackagingDetails()
              }}
              disabled={isSavePackagingDisabled}
            >
              {t('Save & Continue')}
            </Button>
          </>}
        </div>
      </div>
      <CreateBoxes
        openBoxModal={openCreateBoxModal}
        setOpenBoxModal={setOpenCreateBoxModal}
        hubId={selectedGlobalHubId}
      />
    </>
  )
}

export default PackagingPreference
