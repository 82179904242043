import Button from '@src/@core/components/button'
import InputField from '@src/@core/components/input-field'
import { X } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Modal } from 'reactstrap'
/**
 * Renders a modal component for adding a description.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - isOpen {boolean}: Indicates whether the modal is open or closed.
 *   - toggle {function}: A function to toggle the visibility of the modal.
 *   - index {number}: The index of the remark.
 *   - description {string}: The description of the remark.
 *   - handleDescription {function}: A function to handle the description change.
 * @return {JSX.Element} The rendered modal component.
 */
const AddDescription = (props) => {
  const { isOpen, toggle, index, description, handleDescription} = props
  const { t } = useTranslation()
  const {
    watch,
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { isDirty }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      description: description || ''
    }
  })

  const handleOnModalOpened = () => {
    setValue('description', description)
  }

  const onSubmit = (data) => {
    handleDescription(index, data.description)
    toggle()
  }

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      onOpened={handleOnModalOpened}
      className="max-width-600"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex-center-between w-100 p-24px text-dark'>
          <div className="txt-h1-sb">{description ? t('Edit Description') : t('Add Description')}</div>
          <X size={24} onClick={toggle} className="cursor-pointer"/>
        </div>
        <section className="border-top border-bottom border-dark-2 p-24px">
          <div className="d-flex flex-column gap-24px">
            <InputField 
              placeholder="Enter description"
              type="textarea"
              {...register('description')}
            />
          </div>
        </section>
        <div className="p-24px gap-16px flex-center-between">
          <Button
            ofStyle="outlined"
            type="button"
            onClick={toggle}
            className="w-50 flex-center-center"
          >
            {t('Cancel')}
          </Button>
          <Button 
            type="submit" 
            className="w-50 flex-center-center"
            iconSize='sm'
            disabled={!isDirty}
          >
            {t('Save')}
          </Button>
        </div>      
      </form>
    </Modal>
  )
}
export default AddDescription
