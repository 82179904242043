import FloatingDropDown from '@src/@core/components/floating-dropdown'
import InputField from '@src/@core/components/input-field'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const OrderDetails = (props) => {
  const { register, errors, inputFieldConfigurations } = props
  const { t } = useTranslation()

  return (
    <div>
      <h6 className="txt-body-md mb-2">{t('Package Details')}</h6>
      <div className='orderdetails-container'>
        {inputFieldConfigurations && inputFieldConfigurations.map(config => (
          <div className='orderdetails-field'>
            {config.type === 'select' ? <FloatingDropDown key={config.name}  {...config} /> :
              <InputField
                key={config.name}
                errors={errors}
                {...register(config.name, {
                  ...config.validationSchema,
                  required: config.isRequired ? t(config.validationSchema.required) : undefined
                })}
                {...config}
              />
            }
          </div>
        ))}
      </div>

    </div>
  )
}

export default OrderDetails