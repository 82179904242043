import CustomToast from '@src/@core/components/custom-toast/CustomToast'
import { MAX_FILE_SIZE_ALLOWED_IN_BYTES, ONE_MB_IN_BYTES, URLS_TO_EXCLUDE_FROM_GLOBAL_HUB_SCOPE, URLS_TO_INCULDE_FROM_GLOBAL_HUB_SCOPE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import new_order_notification_audio from '@src/assets/sound/new_order_notification.mp3'
import ability from '@src/configs/acl/ability'
import { queryClient } from '@src/configs/react-query/react-query-provider'
import { disconnectFromPusher } from '@src/network/RealTime'
import { handlePupupBlocked, handlePusherResponse } from '@src/redux/authentication'
import { store } from '@src/redux/store'
import { getOrdersApi } from '@src/views/sales/sales.apis'
import { useEffect, useRef } from 'react'
import { toast } from 'react-hot-toast'
import { DefaultRoute } from '../router/routes'
import { retry } from './retry'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('auth')
export const getUserData = () => JSON.parse(localStorage.getItem('auth'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'default') return DefaultRoute
  if (userRole === 'client') return '/access-control'
  return '/login'
}

/**
 ** Returns the date in DD-MM-YYYY Format
 * @param {Date} date date to format
 */
export const getDmyDateFormat = (date) => {
  let day = date.getDate()
  day = day > 9 ? day : `0${day}`

  const year = date.getFullYear()

  let month = (date.getMonth() + 1)
  month = month > 9 ? month : `0${month}`

  return `${day}-${month}-${year}`
}

export const convertToAMPM = (time24) => {
  // Split the time into hours and minutes
  const [hrs, minutes] = time24.split(':').map(Number)
  let hours = hrs

  // Determine AM or PM
  let period = 'AM'
  if (hours >= 12) {
    period = 'PM'
    if (hours > 12) {
      hours -= 12
    }
  }

  // Format hours and minutes with zero-padding
  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')

  // Combine and format as "hh : mm AM/PM"
  return `${formattedHours}:${formattedMinutes} ${period}`
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--bs-primary)', // for selected option bg-color
    primary25: 'var(--bs-primary-lighter)', // for option hover bg-color
    primary50: 'var(--bs-primary-lighter)',
    neutral10: 'var(--bs-primary)' // for tags bg-color
    // primary50: '#EEF0FE' 
    // neutral20: '#ededed', // for input border-color
    // neutral30: '#ededed' // for input hover border-color
  }
})

export const selectCustomStyle = {
  singleValue: provided => ({
    ...provided,
    color: 'black',
    fontWeight: '400'
  }),
  control: provided => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    width: '120px',
    fontSize: '14px',
    textAlign: 'start'
  }),
  option: provided => ({
    ...provided,
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '11px',
    textOverflow: 'ellipsis',
    textAlign: 'start'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: state.hasValue || state.selectProps.inputValue ? '-50%' : '15%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
    backgroundColor: 'white'
  }),
  menu: provided => ({
    ...provided,
    height: 'auto',
    width: '100%'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#222222',
    '&:hover': {
      color: 'black'
    }
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  })
}

export const colorPalette = {
  text_primary: '#222222',
  text_secondary: '#666666',
  text_tertiary: '#999999',
  omniful_primary: '#5468FA',
  primary_border: '#d8d6de',
  primary_focus: '#CCD2FD',
  red: '#c21808',
  white: '#ffffff',
  whiteSmoke: '#fefefe',
  light_gray: '#dedede'
}

// get todays date in 'dd-mmy-yyyy' format
export const getTodaysDate = () => {
  const today = new Date()
  const day = String(today.getDate()).padStart(2, '0')
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const year = today.getFullYear()
  const todayFormatted = `${year}-${month}-${day}`
  return todayFormatted
}

export const formatDateToLongMonthFormat = () => {
  const currentDate = new Date()
  const options = { day: 'numeric', month: 'long', year: 'numeric' }
  return new Intl.DateTimeFormat('en-US', options).format(currentDate)
}

// convert date from 'dd-mm-yyyy' format to unix timestamp format
export const convertToUnixTime = dateString => {
  // Parse the date string into a JavaScript Date object
  const dateParts = dateString.split('-')
  const year = parseInt(dateParts[2], 10)
  const month = parseInt(dateParts[1], 10) - 1 // month is 0-indexed
  const day = parseInt(dateParts[0], 10)
  const dateObject = new Date(year, month, day)
  // Convert the Date object to Unix time
  const unixTime = Math.floor(dateObject.getTime() / 1000)
  return unixTime
}

// Debouncing implemented using a closure concept
export const debounceAction = (() => {
  let timeoutId
  return (callback, delay) => {
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(() => {
        callback(...args)
      }, delay)
    }
  }
})()

export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const hexToRGBA = (hex, alpha) => {
  // Remove the '#' character if it exists
  if (hex) {
    hex = hex.replace('#', '')

    // Extract the individual color components
    const red = parseInt(hex.substring(0, 2), 16)
    const green = parseInt(hex.substring(2, 4), 16)
    const blue = parseInt(hex.substring(4, 6), 16)

    // Return the RGBA value
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`
  }
}

// react select custom color styles
export const customSelectColorStyles = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    borderColor: '#d8d6de',
    minHeight: '45px',
    // maxHeight: '36px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'var(--bs-primary)',
      cursor: 'pointer'
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible',
    width: '100%',
    padding: '8px 8px'
  }),
  option: (provided, { data }) => ({
    ...provided,
    color: data?.color,
    cursor: 'pointer'
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      color: data.color,
      backgroundColor: hexToRGBA(data.color, 0.2),
      borderRadius: '10px'
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
      borderRadius: '10px'
    }
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: state.selectProps.inputValue
      ? '-72%'
      : state.hasValue
        ? '-60%'
        : '20%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
    backgroundColor: state.isDisabled ? 'transparent' : 'white'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    paddingRight: 0
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'lightgray',
    paddingLeft: 0,
    marginInlineEnd: '10px',
    width: '20px',
    '&:hover': {
      color: 'gray'
    }
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  menuPortal: (base) => ({ ...base, zIndex: 999999 })
}

export const downloadFile = (fileLink) => {
  const anchor = document.createElement('a')
  anchor.href = fileLink
  anchor.download = true
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

let toastId
export const pusherCallback = (response) => {
  const { event_name, event_type, is_last_response, data, actions } = response
  const messageSeparator = data?.message?.content.split('|') || []
  const messageContent = messageSeparator[0]
  const messageDesc = messageSeparator.length > 1 ? messageSeparator[messageSeparator.length - 1] : ''
  const messageType = data?.message?.type

  // deciding and executing the ui interaction depending on type
  switch (event_type) {
  case 'TOAST':
    store.dispatch(handlePusherResponse({ [event_name]: messageType }))
    CustomToast(messageContent, { my_type: messageType === 'failure' ? 'error' : messageType }, messageDesc ? messageDesc : '')
    break
  case 'DOWNLOAD_LINK':
    store.dispatch(handlePusherResponse({ [event_name]: messageType }))
    downloadFile(data.link)
    if (data.message) CustomToast(messageContent, { my_type: messageType === 'failure' ? 'error' : messageType }, messageDesc ? messageDesc : '')
    break
  case 'CUSTOM':
    actions.forEach((el) => {
      const sequence = el.sequence
      const selectedAction = actions[sequence - 1]
      const actionType = selectedAction.action_type
      const url = selectedAction.data.url
      const content = selectedAction.data.message.content
      const contentType = selectedAction.data.message.content_type
      switch (actionType) {
      case 'TOAST':
        store.dispatch(handlePusherResponse({ [event_name]: contentType }))
        toastId = CustomToast(content, { my_type: contentType === 'failure' ? 'error' : contentType, id: toastId }, messageDesc ? messageDesc : '')
        break
      case 'DOWNLOAD':
        store.dispatch(handlePusherResponse({ [event_name]: contentType }))
        toastId = CustomToast(content, { my_type: contentType === 'failure' ? 'error' : contentType, id: toastId }, messageDesc ? messageDesc : '')
        downloadFile(url)
        break
      case 'INFO': 
        store.dispatch(handlePusherResponse({ [event_name]: contentType }))
        toastId =  CustomToast(content, { my_type: contentType === 'failure' ? 'error' : contentType, id: toastId }, messageDesc ? messageDesc : '')
        break
      default:
      }
    })
    break
  default:
    // do nothing
  }

  // closing connection if this is the last response
  if (is_last_response) {
    disconnectFromPusher()
  }
}

export const customStylesAsyncpaginate = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    borderColor: '#d8d6de',
    minHeight: '45px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#5468FA',
      cursor: 'pointer'
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible',
    width: '100%'
  }),
  option: provided => ({
    ...provided,
    cursor: 'pointer'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: state.hasValue || state.selectProps.inputValue ? '-65%' : '15%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
    backgroundColor: state.isDisabled ? 'transparent' : 'white'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    paddingRight: 0
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'lightgray',
    paddingLeft: 0,
    marginInlineEnd: '10px',
    width: '20px',
    '&:hover': {
      color: 'gray'
    }
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999, color: '#222222' }),
  menuPortal: (base) => ({ ...base, zIndex: 999999 })
}

export const printMultipleAwb = (awbUrls) => {
  awbUrls = awbUrls.split(',')
  let popupWindow
  try {
    for (const url of awbUrls) {
      popupWindow = window.open(url)
    }
    popupWindow.focus()
  } catch (e) {
    console.log('pupupBlocked', 'testing', e)
    store.dispatch(handlePupupBlocked({ isPopupBlocked: true }))

  }

}

const hideGlobalHubForTabs = ({ location }) => {
  
  const isInculde = Object.values(URLS_TO_INCULDE_FROM_GLOBAL_HUB_SCOPE).find((url) => location.pathname.includes(url))
  if (!isInculde) return false
  const params = new URLSearchParams(location.search)
  const currentTab = params.get('currentTab')
  const isContainAll = currentTab === 'all'
  return isContainAll

}

export const shouldNotDisplayGlobalHubDropdown = ({location}) => {
  const isHome = location.pathname === '/'
  const shouldHide = (!!URLS_TO_EXCLUDE_FROM_GLOBAL_HUB_SCOPE.find((url) => location.pathname.includes(url)) || hideGlobalHubForTabs({location}))
  if (!ability.can(abilityMap.hub.view.action, abilityMap.hub.view.resource)) return true
  return isHome || shouldHide
}

export const basicSelectStyles = {
  control: provided => {
    return {
      ...provided,
      width: '100%',
      borderColor: '#d8d6de',
      minHeight: '45px',
      borderRadius: '8px',
      color: '#666',
      boxShadow: 'none',
      fontSize: '12px',
      '&:hover': {
        borderColor: '#5468FA'
      },
      cursor: 'pointer'
    }
  },
  valueContainer: provided => ({
    ...provided,
    overflow: 'visible',
    width: '100%'
  }),
  option: provided => ({
    ...provided,
    cursor: 'pointer',
    color: '#222'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    color: '#666',
    top: state.hasValue || state.selectProps.inputValue ? '-50%' : '15%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
    backgroundColor: state.isDisabled ? 'transparent' : 'white'
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingRight: 0
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingLeft: 0,
    marginInlineEnd: '5px',
    width: '25px',
    color: '#666',
    '&:hover': {
      color: '#666'
    }
  }),
  indicatorContainer: provided => ({
    ...provided,
    padding: '2px',
    border: '1px solid red !important',
    backgroundColor: 'red'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  })
}
// currency options 
export const currencyOption = [
  { label: 'SAR', value: 'SAR', name: 'Saudi Riyal' }, {
    label: 'USD', value: 'USD', name: 'US Dollar'
  }
]

export const currencyOptionHub = [
  { label: 'SAR (Saudi Riyal)', value: 'SAR', name: 'Saudi Riyal' }, {
    label: 'USD (US Dollar)', value: 'USD', name: 'US Dollar'
  }
]

export const lottieLazyLoader = (lottieImportAnimation, loop = true) => {
  return function LottieComponent() {
    const animationRef = useRef(null)
    const animationInstance = useRef(null)

    useEffect(() => {
      const loadAnimation = async () => {
        try {
          const lottieAnimationModule = await retry(lottieImportAnimation)
          const lottieAnimation = { ...lottieAnimationModule }
          const lottie = await retry(() => import('lottie-web'))
          animationInstance.current = lottie.loadAnimation({
            container: animationRef.current,
            animationData: lottieAnimation,
            loop,
            autoplay: true
          })
        } catch (error) {
          console.error('Failed to load animation in lazy retries and refresh:', error)
        }
      }

      loadAnimation()

      return () => {
        if (animationInstance.current) {
          animationInstance.current.destroy()
        }
      }
    }, [])

    return <div ref={animationRef} />
  }
}

export const getApiUrl = (url, params) => {
  for (const key in params) {
    url = url.replace(`:${key}`, params[key])
  }
  return url
}


// This function serializes an object into a query string by joining its key-value pairs with '&' separators.
export const paramsSerializer = (params) => {
  const parts = []
  for (const key in params) {
    if (Array.isArray(params[key])) {
      for (const value of params[key]) {
        if (value || typeof value === 'boolean') {
          parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        }
      }
    } else {
      if (params[key] || typeof params[key] === 'boolean') {
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      }
    }
  }
  const searchParams = parts.join('&')
  return searchParams
}

export const QueryParamsSerializerWithBrackets = (params) => {
  const queryString = []

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key]

      if (value !== null && value !== undefined) {
        if (Array.isArray(value) && value.length > 0) {
          for (const item of value) {
            queryString.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`)
          }
        } else if (!Array.isArray(value)) {
          queryString.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        }
      }
    }
  }
  return queryString.join('&')
}

export const isFileTypeAndSizeValid = (selectedFile, fileTypes, maxFileSizeAllowed) => {
  if (!maxFileSizeAllowed) maxFileSizeAllowed = MAX_FILE_SIZE_ALLOWED_IN_BYTES
  if (selectedFile && selectedFile.size > maxFileSizeAllowed) {
    CustomToast(`Please upload a file of less then ${maxFileSizeAllowed / ONE_MB_IN_BYTES} Mb.`, { my_type: 'error' })
    return false
  }
  if (selectedFile && !fileTypes.includes(selectedFile.type)) {
    CustomToast('Please upload a file in a supported format.', { my_type: 'error' })
    return false
  }
  return true
}

export const formattedLongDate = (date) => {
  const newDate = new Date(date)
  // , hour: 'numeric', minute: 'numeric', hour12: true
  const dateOptions = { month: 'long', day: 'numeric', year: 'numeric' }
  const formattedDate = newDate?.toLocaleString('en-US', dateOptions)
  return formattedDate
}

export const receiveNotificationsFromServiceWorker = () => {

  let newOrderToastId = ''
  let messageCount = 0
  let timeOutId = ''
  let audio
  let refreshTabTimeOutId = null
  let latestMessageConfig = {}
  let soundIntervalId = null
  const defaultSoundDuration = 120000

  const destroyCustomToast = () => {
    if (timeOutId) clearTimeout(timeOutId)
    if (soundIntervalId) clearTimeout(soundIntervalId)
    toast.dismiss(newOrderToastId)
    messageCount = 0
    newOrderToastId = ''
  }

  navigator.serviceWorker.addEventListener('message', event => {

    const selectedGlobalHubId = JSON.parse(localStorage.getItem('selectedGlobalHubId'))

    const { is_meta_exist, messages, meta } = event.data.payload.data.notification
    const notification_name = is_meta_exist && meta.notification_name
    const hub_id = is_meta_exist && meta.hub_id

    const isNotificationVisible = ability.can(abilityMap.order.view.action, abilityMap.order.view.resource) && (selectedGlobalHubId === hub_id)

    const currentURL = window.location.href
    const url = new URL(currentURL)
    const route = url.pathname
    const queryParams = Object.fromEntries(url.searchParams.entries())
    const isTriggerRefresh = route.endsWith('/sales/live-orders') && (queryParams.currentTab === 'new_orders' || queryParams.currentTab === undefined)

    console.log({ isNotificationVisible, isTriggerRefresh, notification_name })

    if (soundIntervalId) clearInterval(soundIntervalId)
    switch (notification_name) {
    case 'new-order-notification':
      if (isNotificationVisible) {
        messageCount = messageCount + 1
        messages.forEach((message) => {
          latestMessageConfig = message.config
          const latestMessageSoundDuration = latestMessageConfig.sound_duration ? (latestMessageConfig.sound_duration * 1000) : defaultSoundDuration
          newOrderToastId = CustomToast(messageCount > 1 ? `${messageCount} New orders received` : 'New order received', {
            id: newOrderToastId ? newOrderToastId : undefined,
            my_type: message.message_type,
            duration: latestMessageConfig.play_continuously ? Infinity : latestMessageSoundDuration,
            handleCloseToast: destroyCustomToast
          })

          if (latestMessageConfig.play_sound) {
            if (audio && !audio.paused) {
              audio.pause()
            }
            audio = new Audio(new_order_notification_audio)
            audio.play().catch(error => {
              console.error('Failed to play audio:', error)
            })
          }
          if (isTriggerRefresh) {
            clearTimeout(refreshTabTimeOutId)
            refreshTabTimeOutId = setTimeout(() => {
              queryClient.invalidateQueries({ queryKey: [getOrdersApi.apiKey] })
            }, 1000)
          }
        })  
          
        if (latestMessageConfig.play_sound && isNotificationVisible) {
          let currentTime = 0
          soundIntervalId = setInterval(() => {
            audio.currentTime = 0
            audio.play().catch(error => {
              console.error('Failed to play audio:', error)
            })
            currentTime += 3
            if (!latestMessageConfig.play_continuously) {          
              if (currentTime >= latestMessageConfig.sound_duration) {
                clearInterval(soundIntervalId)
              }
            }
          }, 3000)
        }

        if (timeOutId) {
          clearTimeout(timeOutId)
        }
        if (!latestMessageConfig.play_continuously && latestMessageConfig.sound_duration) {
          timeOutId = setTimeout(() => {
            destroyCustomToast()
          }, latestMessageConfig.sound_duration * 1000)
        }
      }
      break
    default:
    }
  })
}

export const checkNotificationPermission = () => {
  return new Promise((resolve, reject) => {
    if (!('Notification' in window)) {
      // Browser does not support notifications
      reject('Browser does not support notifications')
    }

    if (Notification.permission === 'granted') {
      // Notification permission has been granted
      resolve({ isAllowed: true, msg: 'Notification permission has been granted' })
    } else if (Notification.permission === 'denied') {
      // Notification permission has been denied
      resolve({ isAllowed: false, msg: 'Notification permission has been denied' })
    } else {
      // Notification permission has not been requested yet
      // const notification = new Notification("Notification permission request")
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission has been granted')
          resolve({ isAllowed: true, msg: 'Notification permission has been granted' })
        } else if (permission === 'denied') {
          console.log('Notification permission has been denied')
          resolve({ isAllowed: false, msg: 'Notification permission has been denied' })
        } else {
          resolve({ isAllowed: false, msg: 'Notification permission has not been granted nor denied' })
        }
      })
    }
  })
}


export const commaSeparateIndian = (num) => {
  if (isNaN(num)) return null

  // Convert number to string and split into integer and decimal parts
  const [integerPart, decimalPart] = num.toString().split('.')

  // Comma separate the integer part according to Indian numbering system
  const commaSeparatedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // Combine integer part and decimal part (if any)
  const formattedNumber = decimalPart ? `${commaSeparatedInteger}.${decimalPart}` : commaSeparatedInteger

  return formattedNumber
}

export const commaSeparateInternational = (num, requireIntegerFormat = false ) => {
  if (isNaN(num)) return null

  if(requireIntegerFormat) {
    // Round the number to the nearest integer
    const roundedNum = Math.round(num);

    // Comma separate the integer part according to international numbering system
    const formattedNumber = roundedNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedNumber
  } else{
    // Convert number to string and split into integer and decimal parts
    const [integerPart, decimalPart] = num.toString().split('.')

    // Comma separate the integer part according to international numbering system
    const commaSeparatedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    // Combine integer part and decimal part (if any)
    const formattedNumber = decimalPart ? `${commaSeparatedInteger}.${decimalPart}` : commaSeparatedInteger

    return formattedNumber
  }
}

// Format the date with more control over the AM/PM
export const formatDateAmPm = (dateStr) => {
  const dateObj = new Date(dateStr)
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  const dateFormatter = new Intl.DateTimeFormat('en-US', options)
  const datePart = dateFormatter.format(dateObj)

  let hours = dateObj.getHours()
  const minutes = dateObj.getMinutes().toString().padStart(2, '0')
  const amPm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'

  return `${datePart}, ${hours}:${minutes} ${amPm}`
}

export const customPermissionsWithChecks = [
  {
    customPermission: { action: 'view', subject: 'custom_app' },
    checkPermissions: [{ ...abilityMap.external_wms.view }, { ...abilityMap.sales_channel_app.view }, { ...abilityMap.reports_api.edit }]
  },
  {
    customPermission: { action: 'view', subject: 'analytics' },
    checkPermissions: [{ ...abilityMap.analytics_hub_dashboard.view }, { ...abilityMap.analytics_seller_dashboard.view }]
  }
]

export const insertCustomPermission = ({userPermissions, customPermissionsWithChecks}) => {
  // Function to check if a permission exists in a list of permissions
  const permissionExists = (permission, permissionsList) => {
    return permissionsList.some(p => p.action === permission.action && p.subject === permission.subject)
  }

  // Iterate through each custom permission with its corresponding check permissions
  customPermissionsWithChecks.forEach(item => {
    const { customPermission, checkPermissions } = item
    const mappedCheckPermissions = checkPermissions.map(check => ({
      action: check.action,
      subject: check.resource
    }))

    // Check if any of the check permissions exist in user permissions
    if (mappedCheckPermissions.some(check => permissionExists(check, userPermissions))) {
      // If the custom permission does not already exist in user permissions, add it
      if (!permissionExists(customPermission, userPermissions)) {
        userPermissions.push(customPermission)
      }
    }
  })

  return userPermissions
}

export const getNameInitials = (name) => {
  // Trim whitespace and split the name into words
  if (!name) {
    return ''; // Return empty if name is empty
  }
  
  const words = name.trim().split(/\s+/);

  // Check if there is at least one word
  if (words.length === 0) {
    return ''; // Return empty if name is empty
  }

  // If there's only one word
  if (words.length === 1) {
    // Return the first two characters or just one character if the word is shorter
    return words[0].substring(0, 2).toUpperCase();
  }

  // If there are two or more words, return the first letter of the first and second word
  return (words[0][0] + words[1][0]).toUpperCase();
}

export const pluralizeUnit = (value, unit) => value > 0 ? `${value} ${value > 1 ? unit + 's' : unit}` : '';
