import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { retry } from '@src/utility/retry'
import BulkShipModule from '@src/views/bulk-ship'
import { BULK_SHIP_PATH_NAMES } from '@src/views/bulk-ship/bulk-ship.constants'
import CreateNewGRN from '@src/views/inventory/components/inventoryOperations/grn-revamped'
import { BASE_PATH as POS_BASE_PATH } from '@src/views/pos/pos.constant'
import CreateNewReturnOrderProcessing from '@src/views/returns/pages/return-grn-order-processing/revamp-create-return-order-processing'
import { ROUTE_PATHS as BILLING_ROUTE_PATHS } from '@src/views/settings/billing/billing.constants'
import { lazy } from 'react'

const BillingModule = lazy(() => retry(() => import('@src/views/settings/billing')))
const CreateOrders = lazy(() => retry(() => import('@src/views/sales/pending-actions/pages/createOrder')))
const Home = lazy(() => retry(() => import('@src/views/home')))
const AnalyticsHomeDashBoard = lazy(() => retry(() => import('@src/views/home/analytics-home-dashboard/AnalyticsHomeDashBoard')))
const Sales = lazy(() => retry(() => import('@src/views/sales')))
const StartPacking = lazy(() => retry(() => import('@src/views/sales/live-orders/newPacking')))
const OrderManagementSettings = lazy(() => retry(() => import('@src/views/settings/orderSettings/OrderManagementSettings')))
const RoleSettings = lazy(() => retry(() => import('@src/views/settings/role-settings/RoleSettings')))
const UserSettings = lazy(() => retry(() => import('@src/views/settings/user-settings/UserSettings')))
const ManageInvoice = lazy(() => retry(() => import('@src/views/settings/manage-invoice')))
const ChangePassword = lazy(() => retry(() => import('@src/views/settings/user-profile/ChangePassword')))
const OrderTags = lazy(() => retry(() => import('@src/views/settings/orderSettings/OrderTags/OrderTags')))
const PackagingAutomation = lazy(() => retry(() => import('@src/views/settings/packaging-automation')))
const BulkUploadSettings = lazy(() => retry(() => import('@src/views/settings/catalog-settings/BulkUploadSettings')))
const AutomationRules = lazy(() => retry(() => import('@src/views/settings/orderSettings/AutomationRules')))
const Catalog = lazy(() => retry(() => import('@src/views/catalog')))
// Shipping Module
const Hubs = lazy(() => retry(() => import('@src/views/hubs')))
// Integrations Module
const SalesChannel = lazy(() => retry(() => import('@src/views/sales-channel')))
const ShippingPartners = lazy(() => retry(() => import('@src/views/shipping')))
const TaxAuthorities = lazy(() => retry(() => import('@src/views/taxAuthorities')))
const CustomApp = lazy(() => retry(() => import('@src/views/sales-channel/customApp')))
// Inventory Module
const Inventory = lazy(() => retry(() => import('@src/views/inventory')))
// Purchase Module
const Purchases = lazy(() => retry(() => import('@src/views/Purchases')))
const ReturnOrders = lazy(() => retry(() => import('@src/views/returns')))
const ReturnShipment = lazy(() => retry(() => import('@src/views/sales/shipments/returnShipment')))
const ForwardShipment = lazy(() => retry(() => import('@src/views/sales/shipments/forwardShipment/forwardShipment')))
const Analytics = lazy(() => import('@src/views/analytics'))
const Reports = lazy(() => retry(() => import('@src/views/reports')))
const TenantSettings = lazy(() => retry(() => import('@src/views/settings/tenant-settings/tenantSettings')))
const ShipmentOrders = lazy(() => retry(() => import('@src/views/sales/shipmentOrders/shipmentOrdersTabs')))
const CreateShipmentOrder = lazy(() => retry(() => import('@src/views/sales/shipmentOrders')))
// const TrackingPage = lazy(() => retry(() => import('@src/views/settings/tracking/TrackingPage')))
const CityMapping = lazy(() => retry(() => import('@src/views/settings/city-mapping/CityMapping')))
const SkuConfigurations = lazy(() => retry(() => import('@src/views/settings/sku-configurations')))
const Pos = lazy(() => retry(() => import('@src/views/pos')))
const PosSettings = lazy(() => retry(() => import('@src/views/settings/pos-settings')))
const PickupLocation = lazy(() => retry(() => import('@src/views/sales/pickup-location')))
const HubRoutingAutomation = lazy(() => retry(() => import('@src/views/settings/hub-routing-automation')))
// Fleet Module
// const Fleet = lazy(() => retry(() => import('@src/views/fleet')))

const Modules = [
  {
    element: <Home />,
    path: '/',
    title: 'Dashboard'
  },
  {
    element: <AnalyticsHomeDashBoard />,
    path: '/dashboard/analytics',
    title: 'Analytics Dashboard',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.home_dashboard.view.action,
      resource: abilityMap.home_dashboard.view.resource
    }
  },
  {
    element: <Hubs />,
    path: '/hubs',
    title: 'Hubs',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.hub.view.action,
      resource: abilityMap.hub.view.resource
    }
  },
  {
    element: <Hubs />,
    path: '/hubs/:hubId',
    title: 'Hubs - Selected Hub',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.hub.view.action,
      resource: abilityMap.hub.view.resource
    }
  },
  {
    element: <Hubs />,
    path: '/hubs/:hubId/overview',
    title: 'Hubs - Overview',
    meta: {
      hasSecondaryMenu: true,
      restricted: true
    }
  },
  {
    element: <Hubs />,
    path: '/hubs/:hubId/location-layout',
    title: 'Hubs - Location Layout',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.hub_locations_zones_view.view.action,
      resource: abilityMap.hub_locations_zones_view.view.resource
    }
  },
  {
    element: <Hubs />,
    path: '/hubs/:hubId/zones',
    title: 'Hubs - Zones',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.hub_locations_zones_view.view.action,
      resource: abilityMap.hub_locations_zones_view.view.resource
    }
  },
  {
    element: <Hubs />,
    path: '/hubs/:hubId/bins',
    title: 'Hub - Bins',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.hub_assets_view.view.action,
      resource: abilityMap.hub_assets_view.view.resource
    }
  },
  {
    element: <Hubs />,
    path: '/hubs/:hubId/carts',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.hub_assets_view.view.action,
      resource: abilityMap.hub_assets_view.view.resource
    }
  },
  {
    element: <Hubs />,
    path: '/hubs/:hubId/pallets',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.hub_assets_view.view.action,
      resource: abilityMap.hub_assets_view.view.resource
    }
  },
  {
    element: <Hubs />,
    path: '/hubs/:hubId/boxes',
    title: 'Hub - Boxes',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.hub_assets_view.view.action,
      resource: abilityMap.hub_assets_view.view.resource
    }
  },
  {
    element: <Hubs />,
    path: '/hubs/:hubId/delivery-zone',
    title: 'Delivery Zones',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.delivery_zone.view.action,
      resource: abilityMap.delivery_zone.view.resource
    }
  },
  {
    element: <Sales />,
    path: '/sales',
    title: 'Sales',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.order.view.action,
      resource: abilityMap.order.view.resource
    }
  },
  {
    element: <Sales />,
    path: '/sales/live-orders',
    title: 'Live Orders',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.order.view.action,
      resource: abilityMap.order.view.resource
    }
  },
  {
    element: <StartPacking />,
    path: '/sales/live-orders/packing',
    title: 'Packing',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.order.view.action,
      resource: abilityMap.order.view.resource
    }
  },
  {
    element: <Sales />,
    path: '/sales/pending-actions',
    title: 'Pending Actions',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.order.view_on_hold.action,
      resource: abilityMap.order.view_on_hold.resource
    }
  },
  {
    element: <Sales />,
    path: '/sales/stock-transfer-requests',
    title: 'Stock Transfer',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.sto_requests_view.view.action,
      resource: abilityMap.sto_requests_view.view.resource
    }
  },
  {
    element: <Sales />,
    path: '/sales/manifest',
    title: 'Manifest',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.manifest_view.action,
      resource: abilityMap.custom.manifest_view.resource
    }
  },
  {
    element: <Sales />,
    path: '/sales/create/picking-wave',
    title: 'Picking Wave',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.picking_wave_view.view.action,
      resource: abilityMap.picking_wave_view.view.resource
    }
  },
  {
    element: <Sales />,
    path: '/sales/tax-invoices',
    title: 'Tax Invoices',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.tax_invoices.action,
      resource: abilityMap.custom.tax_invoices.resource
    }
  },
  {
    element: <Sales />,
    path: '/sales/customers',
    title: 'Customers',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.customer.view.action,
      resource: abilityMap.customer.view.resource
    }
  },
  {
    element: <Sales />,
    path: '/sales/shipments/shipments-tracking',
    title: 'Shipments Tracking',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.shipments_tracking_view.action,
      resource: abilityMap.custom.shipments_tracking_view.resource
    }
  },
  {
    element: <Reports />,
    title: 'Reports',
    path: '/reports',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.report.view.action,
      resource: abilityMap.report.view.resource
    }
  },
  {
    element: <Reports />,
    title: 'Reports Detail',
    path: '/reports/:category/:report',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.report.view.action,
      resource: abilityMap.report.view.resource
    }
  },
  {
    element: <ForwardShipment />,
    title: 'Forward Shipment',
    path: '/sales/shipments/forward-shipment',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.shipment.view.action,
      resource: abilityMap.shipment.view.resource
    }
  },
  {
    element: <ReturnShipment />,
    title: 'Return Shipment',
    path: '/sales/shipments/return-shipment',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.shipment.view.action,
      resource: abilityMap.shipment.view.resource
    }
  },
  {
    element: <CreateOrders />,
    path: '/sales/create/order',
    title: 'Create Order',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.order.create.action,
      resource: abilityMap.order.create.resource
    }
  },

  {
    element: <ReturnOrders />,
    title: 'Return Request',
    path: '/order/return/return-request',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.return.view_request.action,
      resource: abilityMap.return.view_request.resource
    }
  },
  {
    element: <ReturnOrders />,
    title: 'Return Order',
    path: '/order/return/return-order',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.return.view_order.action,
      resource: abilityMap.return.view_order.resource
    }
  },
  {
    element: <ReturnOrders />,
    title: 'Create Return Order',
    path: '/order/return/create-return-order',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.return.create_order.action,
      resource: abilityMap.return.create_order.resource
    }
  },
  {
    element: <ReturnOrders />,
    title: 'Create Return Request',
    path: '/orders/return/create-return-request',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.return.create_request.action,
      resource: abilityMap.return.create_request.resource
    }
  },
  {
    element: <ReturnOrders />,
    title: 'Return Order Processing',
    path: '/order/return/return-order-processing',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.grn_view.action,
      resource: abilityMap.custom.grn_view.resource
    }
  },
  {
    element: <CreateNewReturnOrderProcessing />,
    title: 'Create Return Order Processing',
    path: '/order/return/return-order-processing/create-return-order-processing',
    meta: {
      restricted: true,
      action: abilityMap.grn.create.action,
      resource: abilityMap.grn.create.resource
    }
  },
  {
    element: <ReturnOrders />,
    title: 'Return Gate Entry',
    path: '/order/return/return-gate-entry',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.gate_entry_view.action,
      resource: abilityMap.custom.gate_entry_view.resource
    }
  },
  {
    element: <SalesChannel />,
    path: '/settings/sales-channel',
    title: 'Sales Channel',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.sales_channel_app.view.action,
      resource: abilityMap.sales_channel_app.view.resource
    }
  },
  {
    element: <ShippingPartners />,
    path: '/settings/shipping-partners',
    title: 'Shipping Partners',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.shipping_app.view.action,
      resource: abilityMap.shipping_app.view.resource
    }
  },
  {
    element: <TaxAuthorities />,
    path: '/settings/tax-authority',
    title: 'Tax Authority',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.tax_authority.view.action,
      resource: abilityMap.tax_authority.view.resource
    }
  },
  {
    element: <CustomApp />,
    path: '/settings/custom-apps',
    title: 'Custom Apps',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom_app.view.action,
      resource: abilityMap.custom_app.view.resource
    }
  },
  {
    element: <Inventory />,
    path: '/inventory/inventory-reports/total-inventory',
    title: 'Total Inventory',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.inventory.view.action,
      resource: abilityMap.inventory.view.resource
    }
  },
  {
    element: <Inventory />,
    path: '/inventory/inventory-reports/location-inventory',
    title: 'Location Inventory',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.inventory_view.action,
      resource: abilityMap.custom.inventory_view.resource
    }
  },
  {
    element: <Inventory />,
    path: '/inventory/inventory-reports/sku-locations',
    title: 'SKU Locations',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.sku_locations_view.action,
      resource: abilityMap.custom.sku_locations_view.resource
    }
  },
  {
    element: <Inventory />,
    path: '/inventory/inventory-reports/batches',
    title: 'Batches',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.inventory_view.action,
      resource: abilityMap.custom.inventory_view.resource
    }
  },
  {
    element: <Inventory />,
    path: '/inventory/inventory-reports/demand-forecasting',
    title: 'Demand Forecasting',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.forecast.generate.action,
      resource: abilityMap.forecast.generate.resource
    }
  },
  {
    element: <Inventory />,
    path: '/inventory/inventory-operations/gate-entry',
    title: 'Gate Entry',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.gate_entry_view.action,
      resource: abilityMap.custom.gate_entry_view.resource
    }
  },
  {
    element: <Inventory />,
    path: '/inventory/inventory-operations/grn',
    title: 'GRN',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.grn_view.action,
      resource: abilityMap.custom.grn_view.resource
    }
  },
  {
    element: <CreateNewGRN />,
    path: '/inventory/inventory-operations/grn/new-grn',
    title: 'GRN',
    meta: {
      restricted: true,
      action: abilityMap.grn.create.action,
      resource: abilityMap.grn.create.resource
    }
  },
  {
    element: <Inventory />,
    path: '/inventory/inventory-operations/putaway',
    title: 'Putaway',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.put_away_view.action,
      resource: abilityMap.custom.put_away_view.resource
    }
  },
  {
    element: <Inventory />,
    path: '/inventory/inventory-operations/pending-putaway',
    title: 'Pending Putaway',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.put_away.view.action,
      resource: abilityMap.put_away.view.resource
    }
  },
  {
    element: <Inventory />,
    path: '/inventory/inventory-operations/cycle-count',
    title: 'Cycle Count',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.cycle_count.view.action,
      resource: abilityMap.cycle_count.view.resource
    }
  },
  {
    element: <Inventory />,
    path: '/inventory/inventory-operations/cycle-count/cycle-count-details',
    title: 'Cycle Count Details',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.cycle_count.view.action,
      resource: abilityMap.cycle_count.view.resource
    }
  },
  // {
  //   element: <Inventory />,
  //   path: '/inventory/inventory-operations/assembly',
  //   title: 'Assembly',
  //   meta: {
  //     hasSecondaryMenu: true,
  //     restricted: true,
  //     action: abilityMap.custom.assembly_view.action,
  //     resource: abilityMap.custom.assembly_view.resource
  //   }
  // },
  {
    element: <Inventory />,
    path: '/inventory/inventory-operations/ad-hoc-usage',
    title: 'Ad-Hoc Usage',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.adhoc_usage.view.action,
      resource: abilityMap.adhoc_usage.view.resource
    }
  },
  {
    element: <Inventory />,
    path: '/inventory/stock-transfer',
    title: 'Stock Transfer',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.stock_transfer_view.action,
      resource: abilityMap.custom.stock_transfer_view.resource
    }
  },
  {
    element: <BulkShipModule />,
    path: BULK_SHIP_PATH_NAMES.CLUSTERS,
    title: 'Bulk Ship',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.bulk_ship.action,
      resource: abilityMap.custom.bulk_ship.resource
    }
  },
  {
    element: <BulkShipModule />,
    path: BULK_SHIP_PATH_NAMES.PROCESS_CLUSTER,
    title: 'Process Bulk Ship',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.bulk_ship.action,
      resource: abilityMap.custom.bulk_ship.resource
    }
  },
  {
    element: <Catalog />,
    path: '/catalog/skus',
    title: 'Catalog - SKUs',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.product.view.action,
      resource: abilityMap.product.view.resource
    }
  },
  {
    element: <Catalog />,
    path: '/catalog/kits',
    title: 'Kits',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.kits.action,
      resource: abilityMap.custom.kits.resource
    }
  },
  // {
  //   element: <Catalog />,
  //   path: '/catalog/assembled-kits',
  //   title: 'Assembled Kits',
  //   meta: {
  //     hasSecondaryMenu: true,
  //     restricted: true,
  //     action: abilityMap.product.view.action,
  //     resource: abilityMap.product.view.resource
  //   }
  // },
  {
    element: <Catalog />,
    path: '/catalog/sales-channel-listing',
    title: 'Sales Channel Listing',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.sales_channel_listing.action,
      resource: abilityMap.custom.sales_channel_listing.resource
    }
  },
  {
    element: <Catalog />,
    path: '/catalog/catalog-comparision/:loc?',
    title: 'Catalog Comparison',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.catalog.compare.action,
      resource: abilityMap.catalog.compare.resource
    }
  },
  {
    element: <Catalog />,
    path: '/catalog/barcode-config',
    title: 'Barcode Config',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.catalog.view_configuration_product.action,
      resource: abilityMap.catalog.view_configuration_product.resource
    }
  },
  {
    element: <Purchases />,
    path: '/purchases/purchase-order',
    title: 'Purchase Order',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.purchase_orders_view.view.action,
      resource: abilityMap.purchase_orders_view.view.resource
    }
  },
  {
    element: <Purchases />,
    path: '/purchases/all-suppliers',
    title: 'All Suppliers',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.supplier.view.action,
      resource: abilityMap.supplier.view.resource
    }
  },
  {
    element: <Purchases />,
    path: '/purchases/sellers',
    title: 'Purchases - Sellers',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.seller_view.action,
      resource: abilityMap.custom.seller_view.resource
    }
  },
  {
    element: <OrderManagementSettings />,
    path: '/settings/order-settings/oms-settings',
    title: 'OMS - Settings',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.configuration.view.action,
      resource: abilityMap.configuration.view.resource
    }
  },
  {
    element: <OrderTags />,
    path: '/settings/order-settings/order-tags',
    title: 'Order Tags',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.order.view_tag.action,
      resource: abilityMap.order.view_tag.resource
    }
  },
  {
    element: <AutomationRules />,
    path: '/settings/order-settings/shipping-automation',
    title: 'Shipping Automation',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.shipping_rule.view.action,
      resource: abilityMap.shipping_rule.view.resource
    }
  },
  {
    element: <BulkUploadSettings />,
    path: '/settings/order-settings/default-skus-settings',
    title: 'Automation Rules',
    meta: {
      hasSecondaryMenu: true,
      restricted: true
    }
  },
  // {
  //   element: <BillingModule />,
  //   path: BILLING_ROUTE_PATHS.AD_HOC_ACTIVITIES,
  //   title: 'Billing Ad-Hoc Activites',
  //   meta: {
  //     hasSecondaryMenu: true,
  //     restricted: true,
  //     action: abilityMap.configuration.view.action,
  //     resource: abilityMap.configuration.view.resource
  //   }
  // },
  {
    element: <BillingModule />,
    path: BILLING_ROUTE_PATHS.BILLING_PROFILE_MODULE,
    title: 'Billing Profile',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.billing_profile.view.action,
      resource: abilityMap.billing_profile.view.resource
    }
  },
  {
    element: <BillingModule />,
    path: BILLING_ROUTE_PATHS.BILLS,
    title: 'Bills',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.bill.view.action,
      resource: abilityMap.bill.view.resource
    }
  },
  {
    element: <BillingModule />,
    path: BILLING_ROUTE_PATHS.SINGLE_BILLS_MODULE,
    title: 'Single Bill',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.bill.view.action,
      resource: abilityMap.bill.view.resource
    }
  },
  {
    element: <BillingModule />,
    path: BILLING_ROUTE_PATHS.CONTRACTS,
    title: 'Contracts',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.contract.view.action,
      resource: abilityMap.contract.view.resource
    }
  },
  {
    element: <BillingModule />,
    path: BILLING_ROUTE_PATHS.SINGLE_CONTRACTS_MODULE,
    title: 'Single Contract',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.contract.view.action,
      resource: abilityMap.contract.view.resource
    }
  },
  // {
  //   element: <BillingModule />,
  //   path: BILLING_ROUTE_PATHS.AD_HOC_USAGE,
  //   title: 'Billing Ad-Hoc Usage',
  //   meta: {
  //     hasSecondaryMenu: true,
  //     restricted: true,
  //     action: abilityMap.adhoc_usage.view.action,
  //     resource: abilityMap.adhoc_usage.view.resource
  //   }
  // },
  {
    element: <BillingModule />,
    path: BILLING_ROUTE_PATHS.AD_HOC_ACTIVITIES,
    title: 'Adhoc Activities',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.adhoc.view.action,
      resource: abilityMap.adhoc.view.resource
    }
  },
  {
    element: <UserSettings />,
    path: '/settings/user-settings',
    title: 'User Settings',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.user.view.action,
      resource: abilityMap.user.view.resource
    }
  },
  {
    element: <RoleSettings />,
    path: '/settings/role-settings',
    title: 'Role Settings',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.role.view.action,
      resource: abilityMap.role.view.resource
    }
  },
  {
    element: <ManageInvoice />,
    path: '/settings/manage-invoice',
    title: 'Manage Invoice',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.custom.manage_invoice.action,
      resource: abilityMap.custom.manage_invoice.resource
    }
  },
  {
    element: <ChangePassword />,
    path: '/settings/user-profile/change-password',
    title: 'Change Password',
    meta: {
      hasSecondaryMenu: true,
      restricted: true
    }
  },
  {
    element: <Analytics />,
    path: '/analytics/seller',
    title: 'Seller',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.analytics_seller_dashboard.view.action,
      resource: abilityMap.analytics_seller_dashboard.view.resource
    }
  },
  {
    element: <Analytics />,
    path: '/analytics/hub',
    title: 'Hub',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.analytics_hub_dashboard.view.action,
      resource: abilityMap.analytics_hub_dashboard.view.resource
    }
  },
  {
    element: <Analytics />,
    path: '/analytics/shipping',
    title: 'Shipping',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.analytics_shipping_dashboard.view.action,
      resource: abilityMap.analytics_shipping_dashboard.view.resource
    }
  },
  {
    element: <TenantSettings />,
    path: '/settings/company-settings',
    title: 'Company Settings',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.tenant.view.action,
      resource: abilityMap.tenant.view.resource
    }
  },
  {
    element: <ShipmentOrders />,
    title: 'Order Status',
    path: '/sales/shipments-orders/order-status',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.shipment_order.view.action,
      resource: abilityMap.shipment_order.view.resource
    }
  },
  {
    element: <CreateShipmentOrder />,
    title: 'Create Shipment Order',
    path: '/sales/shipments-orders/create-shipment-order',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.shipment_order.add.action,
      resource: abilityMap.shipment_order.add.resource
    }
  },
  {
    element: <PickupLocation />,
    title: 'Create Shipment Order',
    path: '/sales/shipments-orders/locations',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.pickup_location.view.action,
      resource: abilityMap.pickup_location.view.resource
    }
  },
  {
    element: <CityMapping />,
    path: '/settings/city-mapping/system-cities',
    title: 'System Cities',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.tenant_city_mapping.view.action,
      resource: abilityMap.tenant_city_mapping.view.resource
    }
  },
  {
    element: <CityMapping />,
    path: '/settings/city-mapping/shipping-partner-cities',
    title: 'Shipping Partner Cities',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.tenant_city_mapping.view.action,
      resource: abilityMap.tenant_city_mapping.view.resource
    }
  },
  {
    element: <SkuConfigurations />,
    path: '/settings/sku-configurations',
    title: 'SKU Configurations',
    meta: {
      hasSecondaryMenu: true,
      restricted: true
      //permision mandatory
    }
  },
  {
    element: <PackagingAutomation/>,
    path: '/settings/automation-rules/packaging-automation',
    title: 'Packaging Automation',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.automation_rules.packaging.view.action,
      resource: abilityMap.automation_rules.packaging.view.resource
    }
  },
  // {
  //   element: <Fleet />,
  //   path: '/fleet',
  //   meta: {
  //     hasSecondaryMenu: true,
  //     restricted: true
  //   },
  //   children: [
  //     {
  //       element: <Fleet />,
  //       path: '/fleet/create-trips',
  //       meta: {
  //         hasSecondaryMenu: true,
  //         restricted: true,
  //         action: abilityMap.fleet.view_trip.action,
  //         resource: abilityMap.fleet.view_trip.resource
  //       }
  //     },
  //     {
  //       element: <Fleet />,
  //       path: '/fleet/pending-trips',
  //       meta: {
  //         hasSecondaryMenu: true,
  //         restricted: true,
  //         action: abilityMap.fleet.view_trip.action,
  //         resource: abilityMap.fleet.view_trip.resource
  //       }
  //     },
  //     {
  //       element: <Fleet />,
  //       path: '/fleet/live-trips',
  //       meta: {
  //         hasSecondaryMenu: true,
  //         restricted: true,
  //         action: abilityMap.fleet.view_trip.action,
  //         resource: abilityMap.fleet.view_trip.resource
  //       }
  //     },
  //     {
  //       element: <Fleet />,
  //       path: '/fleet/all-trips',
  //       meta: {
  //         hasSecondaryMenu: true,
  //         restricted: true,
  //         action: abilityMap.fleet.view_trip.action,
  //         resource: abilityMap.fleet.view_trip.resource
  //       }
  //     },
  //     {
  //       element: <Fleet />,
  //       path: '/fleet/all-drivers',
  //       meta: {
  //         hasSecondaryMenu: true,
  //         restricted: true,
  //         action: abilityMap.fleet.view_driver.action,
  //         resource: abilityMap.fleet.view_driver.resource
  //       }
  //     },
  //     {
  //       element: <Fleet />,
  //       path: '/fleet/drivers-app',
  //       meta: {
  //         hasSecondaryMenu: true,
  //         restricted: true,
  //         action: abilityMap.fleet.view_driver.action,
  //         resource: abilityMap.fleet.view_driver.resource
  //       }
  //     }
  //   ]
  //   }
  {
    element: <Pos />,
    path: '/pos',
    title: 'POS',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.custom.pos_view.action,
      resource: abilityMap.custom.pos_view.resource
    }
  },
  {
    element: <Pos />,
    path: '/pos/registers',
    title: 'Registers',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.register.view.action,
      resource: abilityMap.register.view.resource
    }
  },
  {
    element: <Pos />,
    path: '/pos/registers/:id/open',
    title: 'Registers Open',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.register.open.action,
      resource: abilityMap.register.open.resource
    }
  },
  {
    element: <Pos />,
    path: '/pos/registers/:id/close',
    title: 'Registers Close',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.register.close.action,
      resource: abilityMap.register.close.resource
    }
  },
  {
    element: <Pos />,
    path: '/pos/registers/:id/sell',
    title: 'Registers Sell',
    meta: {
      layout: 'blank',
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.custom.pos_view.action,
      resource: abilityMap.custom.pos_view.resource
    }
  },
  {
    element: <Pos />,
    path: '/pos/cash-management',
    title: 'Cash Management',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.cash_management.view.action,
      resource: abilityMap.cash_management.view.resource
    }
  },
  {
    element: <Pos />,
    path: '/pos/orders',
    title: 'Orders',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.order.view.action,
      resource: abilityMap.order.view.resource
    }
  },
  {
    element: <Pos />,
    path: `${POS_BASE_PATH}/orders/:id/details`,
    title: 'Order Details',
    meta: {
      hasSecondaryMenu: false,
      restricted: true,
      action: abilityMap.order.view.action,
      resource: abilityMap.order.view.resource
    }
  },
  {
    element: <PosSettings />,
    path: '/settings/pos-settings',
    title: 'POS Settings',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.pos_setting.set.action,
      resource: abilityMap.pos_setting.set.resource
    }
  },
  {
    element: <HubRoutingAutomation/>,
    path: '/settings/automation-rules/hub-routing-automation',
    title: 'Hub Routing Automation',
    meta: {
      hasSecondaryMenu: true,
      restricted: true,
      action: abilityMap.automation_rules.hub_routing.view.action,
      resource: abilityMap.automation_rules.hub_routing.view.resource
    }
  }
]

export default Modules