import CustomTag from '@src/@core/components/badge/CustomTag'
import SKUDetailsCol from '@src/@core/components/sku-details/index'
import { AlertCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { UncontrolledTooltip } from 'reactstrap'

const COLOR_CONSTANT = {
  red:'#C21808',
  green:'#067603',
  orange:'#F9720A',
  black:'#222222'
}

export const tableFilterReactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    borderColor: state.isFocused ? '#d8d6de !important' : '#d8d6de !important',
    minHeight: '30px',
    maxHeight: '30px',
    minWidth: '180px',
    fontSize: '12px',
    paddingTop: '0px',
    '&:hover': {
      borderColor: '#5468FA !important',
      cursor: 'pointer'
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black !important'
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: state.hasValue || state.selectProps.inputValue ? '-37%' : '20%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
    backgroundColor: 'white'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0
    // fontSize: 2
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    marginInlineEnd: '10px',
    width: '20px'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  })
}

export const TABS_DATA = [
  { id: 1, name: 'Ongoing', badge_size: 1 },
  { id: 2, name: 'Completed', badge_size: 4 }
]

export const PICKING_WAVE_STATUS = {
  ON_GOING: {id: 1, name: 'On Going Wave', key: 'ongoing'},
  COMPLETED: {id: 2, name: 'Completed Wave', key: 'completed'}
}

export const ALL_PICKLIST_OF_STATUS_CONFIG = {
  unassigned: {
    title: 'Un-Assigned Picklists'
  },
  picked: {
    title: 'Picked Picklists'
  },
  in_picking: {
    title: 'In Picking Picklist'
  }
}

export const ITEMS_DETAILS_OF_PICKLIST_CONFIG = (status, picklistData) => {
  const {t} = useTranslation()
  return (picklist_id) => {
    switch (status) {
    case 'unassigned': {
      return {
        title: `Item Details of Un-Assigned Picklists (${picklist_id})`,
        columns: [
          {
            id: 1,
            name: 'SKU Details',
            minWidth: '115px',
            key: 'skuName',
            cell: (row) => {
              return (
                <SKUDetailsCol
                  skuDetailData={{
                    id: row.id,
                    isValidSku: true,
                    image: {
                      src: row.image,
                      alt: row.sku_name
                    },
                    details: {
                      name: row.sku_name,
                      skuCode: row.sku_code,
                      columnKey: 'unassigned_sku_details'
                    }
                  }}
                />
              )
            }
          },
          {
            id: 2,
            name: <div className="w-100 text-end text-truncate" title={t('Qty to be Picked')}>{t('Qty to be Picked')}</div>,
            key: 'pickedQty',
            minWidth: '179px',
            cell: row => <div className="w-100 text-end">{row.items_to_be_picked || 0}</div>
          },
          {
            id: 3,
            name: <div className="w-100 text-end text-truncate" title={t('Weight to be Picked')}>{t('Weight to be Picked')}</div>,
            key: 'weightQty',
            minWidth: '179px',
            omit: !picklistData?.is_weighted,
            cell: row => <div className="w-100 text-end">{row.is_weighted ? row.ordered_weight?.value ? `${row.ordered_weight?.value}${row.ordered_weight?.uom}` : '0' : '-'}</div>
          }
        ]
      }
    }
    case 'picked': {
      return {
        // title: `Item Details of Picked Picklist (${picklist?.picklist_number})`,
        title: `Item Details of Picked Picklist (${picklist_id})`,
        columns: [
          {
            id: 1,
            name: 'SKU Details',
            minWidth: '240px',
            maxWidth: '300px',
            key: 'skuName',
            cell: (row) => {
              return (
                <SKUDetailsCol
                  skuDetailData={{
                    isValidSku: true,
                    id:row.id,
                    image: {
                      src: row.image,
                      alt: row.sku_name
                    },
                    details: {
                      name: row.sku_name,
                      skuCode: row.sku_code,
                      columnKey: 'picked_sku_details'
                    }
                  }}
                />
              )
            }
          },
          {
            id: 2,
            name: <div className="w-100 text-end text-truncate" title={t('Qty to be Picked')}>{t('Qty to be Picked')}</div>,
            minWidth: '150px', 
            key: 'pickedToBeQty',
            cell: row => <div className="w-100 text-end">{row.items_to_be_picked || 0}</div>
          },
          {
            id: 3,
            name: <div className="w-100 text-end text-truncate" title={t('Weight to be Picked')}>{t('Weight to be Picked')}</div>,
            key: 'pickedQty',
            minWidth: '150px',
            omit: !picklistData?.is_weighted,
            cell: row => <div className="w-100 text-end">{row.is_weighted ? row.ordered_weight?.value ? `${row.ordered_weight?.value}${row.ordered_weight?.uom}` : '0' : '-'}</div>
          },
          {
            id: 4,
            name: <div className="w-100 text-end text-truncate" title={t('Picked Qty')}>{t('Picked Qty')}</div>,
            minWidth: '120px',
            key: 'pickedQty',
            cell: row => <div className="w-100 text-end">{row.picked_items || 0}</div>
          },
          {
            id: 5,
            name: <div className="w-100 text-end text-truncate" title={t('Picked Weight')}>{t('Picked Weight')}</div>,
            key: 'pickedQty',
            minWidth: '179px',
            omit: !picklistData?.is_weighted,
            cell: row => <div className="w-100 text-end">{row.is_weighted ? row.picked_weight?.value ? `${row.picked_weight?.value}${row.picked_weight?.uom}` : '0' : '-'}</div>
          },
          {
            id: 6,
            name: 'Status',
            minWidth: '150px',
            key: 'status',
            cell: (row) => (row.status && <CustomTag 
              style={{color:row.color, backgroundColor: `${COLOR_CONSTANT[row.color]}1A`}} title={row.status}/>
              // <>
              //   {!row.picked_items &&
              //     row.items_to_be_picked > row.picked_items && (
              //       <span className="text-danger">No Items Found</span>
              //     )}

              //   {row.picked_items > 0 &&
              //     row.items_to_be_picked > row.picked_items && (
              //       <span className="text-warning">Partially Picked</span>
              //     )}

              //   {row.items_to_be_picked === row.picked_items && (
              //     <span className="text-success">Picked!</span>
              //   )}
              // </>
            )
          }
        ]
      }
    }
    case 'in_picking': {
      return {
        // title: `Item Details of In-Picking Picklists (${picklist?.picklist_number})`,
        title: `Item Details of In-Picking Picklists (${picklist_id})`,
        columns: [
          {
            id: 1,
            name: 'SKU Details',
            minWidth: '200px',
            key: 'skuName',
            cell: (row) => {
              return (
                <SKUDetailsCol
                  skuDetailData={{
                    isValidSku: true,
                    id: row.id,
                    image: {
                      src: row.image,
                      alt: row.sku_name
                    },
                    details: {
                      name: row.sku_name,
                      skuCode: row.sku_code,
                      columnKey: 'sku_details_in_picking'
                    }
                  }}
                />
                  
              )
            }
          },
          {
            id: 2,
            name: <div className="w-100 text-end text-truncate" title={t('Qty to be Picked')}>{t('Qty to be Picked')}</div>,
            minWidth: '150px',
            key: 'qtyToBePicked',
            cell: row => <div className="w-100 text-end">{row.items_to_be_picked || 0}</div>
          },
          {
            id: 3,
            name: <div className="w-100 text-end text-truncate" title={t('Weight to be Picked')}>{t('Weight to be Picked')}</div>,
            key: 'pickedQty',
            minWidth: '200px',
            omit: !picklistData?.is_weighted,
            cell: row => <div className="w-100 text-end">{row.is_weighted ? row.ordered_weight?.value ? `${row.ordered_weight?.value}${row.ordered_weight?.uom}` : '0' : '-'}</div>
          },
          {
            id: 4,
            name: <div className="w-100 text-end text-truncate" title={t('Picked Qty')}>{t('Picked Qty')}</div>,
            minWidth: '150px',
            key: 'pickedQty',
            cell: row => <div className="w-100 text-end">{row.picked_items || 0}</div>
          },
          {
            id: 5,
            name: <div className="w-100 text-end text-truncate" title={t('Picked Weight')}>{t('Picked Weight')}</div>,
            key: 'pickedQty',
            minWidth: '200px',
            omit: !picklistData?.is_weighted,
            cell: row => <div className="w-100 text-end">{row.is_weighted ? row.picked_weight?.value ? `${row.picked_weight?.value}${row.picked_weight?.uom}` : '0' : '-'}</div>
          },
          {
            id: 6,
            name: 'Status',
            minWidth: '150px',
            width: '20%',
            key: 'status',
            cell: (row) => (row.status && <CustomTag 
              style={{color:row.color, backgroundColor: `${COLOR_CONSTANT[row.color]}1A`}} title={row.status}/>
              // <>
              //   {!row.picked_items &&
              //     row.items_to_be_picked > row.picked_items && (
              //       <span className="text-danger">No Items Found</span>
              //     )}

              //   {row.picked_items > 0 &&
              //     row.items_to_be_picked > row.picked_items && (
              //       <span className="text-warning">Partially Picked</span>
              //     )}

              //   {row.items_to_be_picked === row.picked_items && (
              //     <span className="text-success">Picked!</span>
              //   )}
              // </>
            )
          }
        ]
      }
    }
    case 'partial_picked': {
      return {
        // title: `Item Details of In-Picking Picklists (${picklist?.picklist_number})`,
        title: `Item Details of In-Picking Picklists (${picklist_id})`,
        columns: [
          {
            id: 1,
            name: 'SKU Details',
            minWidth: '200px',
            key: 'skuName',
            cell: (row) => {
              return (
                <SKUDetailsCol
                  skuDetailData={{
                    isValidSku: true,
                    id: row.id,
                    image: {
                      src: row.image,
                      alt: row.sku_name
                    },
                    details: {
                      name: row.sku_name,
                      skuCode: row.sku_code,
                      columnKey: 'sku_details_in_picking'
                    }
                  }}
                />
                  
              )
            }
          },
          {
            id: 2,
            name: <div className="w-100 text-end text-truncate" title={t('Qty to be Picked')}>{t('Qty to be Picked')}</div>,
            minWidth: '150px',
            key: 'qtyToBePicked',
            cell: row => <div className="w-100 text-end">{row.items_to_be_picked || 0}</div>
          },
          {
            id: 3,
            name: <div className="w-100 text-end text-truncate" title={t('Weight to be Picked')}>{t('Weight to be Picked')}</div>,
            key: 'pickedQty',
            minWidth: '200px',
            omit: !picklistData?.is_weighted,
            cell: row => <div className="w-100 text-end">{row.is_weighted ? row.ordered_weight?.value ? `${row.ordered_weight?.value}${row.ordered_weight?.uom}` : '0' : '-'}</div>
          },
          {
            id: 4,
            name: <div className="w-100 text-end text-truncate" title={t('Picked Qty')}>{t('Picked Qty')}</div>,
            minWidth: '150px',
            key: 'pickedQty',
            cell: row => <div className="w-100 text-end">{row.picked_items || 0}</div>
          },
          {
            id: 5,
            name: <div className="w-100 text-end text-truncate" title={t('Picked Weight')}>{t('Picked Weight')}</div>,
            key: 'pickedQty',
            minWidth: '200px',
            omit: !picklistData?.is_weighted,
            cell: row => <div className="w-100 text-end">{row.is_weighted ? row.picked_weight?.value ? `${row.picked_weight?.value}${row.picked_weight?.uom}` : '0' : '-'}</div>
          },
          {
            id: 6,
            name: 'Status',
            minWidth: '150px',
            width: '20%',
            key: 'status',
            cell: (row) => (row.status && <CustomTag 
              style={{color:row.color, backgroundColor: `${COLOR_CONSTANT[row.color]}1A`}} title={row.status}/>
              // <>
              //   {!row.picked_items &&
              //     row.items_to_be_picked > row.picked_items && (
              //       <span className="text-danger">No Items Found</span>
              //     )}

              //   {row.picked_items > 0 &&
              //     row.items_to_be_picked > row.picked_items && (
              //       <span className="text-warning">Partially Picked</span>
              //     )}

              //   {row.items_to_be_picked === row.picked_items && (
              //     <span className="text-success">Picked!</span>
              //   )}
              // </>
            )
          }
        ]
      }
    }
    default: {
      return {}
    }
    }
  }
}

export const columnsOfAllPicklistsOfStatus = () => {
  const {t} = useTranslation()
  return [
    {
      id: 1,
      name: <div className="w-100 text-center">{t('S. No.')}</div>,
      width: '60px',
      key: 'serialNumber',
      selector: (row) => row,
      cell: (row, index) => (
        <div className="w-100 d-flex justify-content-center align-items-center">
          {index + 1}
        </div>
      )
    },
    // {
    //   id: 2,
    //   name: "Zone Name",
    //   cell: (row) => row.zone.name || "-"
    // },
    // {
    //   id: 3,
    //   name: "Picklist ID",
    //   cell: (row) => {
    //     return (
    //       <button
    //         className={`bg-transparent border-0 cusrsor-pointer text-primary`}
    //         onClick={() => row.nextSidebarToggler({ picklist: row })}
    //       >
    //         {row.picklist_id || "-"}
    //       </button>
    //     )
    //   }
    // },
    {
      id: 4,
      name: 'Picklist Status',
      key: 'pickListStatus',
      cell: (row) => (
        <div className="text-capitalize">{row.picklist_status.split('_').join(' ')}</div>
      )
    },
    {
      id: 5, 
      name: <div className="w-100 text-end text-truncate" title={t('Item Quantity')}>{t('Item Quantity')}</div>,
      key: 'itemQuantity',
      cell: (row) => (
        <div className="text-primary cursor-pointer text-end w-100" onClick={() => row.nextSidebarToggler({ picklist: row })} >
          {row.number_of_items}
        </div>
      )
    },
    {
      id: 6,
      name: <div className="w-100 text-end text-truncate" title={t('Picked Quantity')}>{t('Picked Quantity')}</div>,
      key: 'pickedQuantity',
      cell: (row) => {
        return (
          <div className="w-100 text-end">
            {row.picklist_status === 'picked' ? (
              <div className="flex-center-end gap-50 text-success w-100 text-end">
                <span>
                  {row.picked_quantity}{' '}
                  {row.picked_quantity > 1 ? 'Items' : 'Item'}
                </span>
                {(row.picked_quantity === 0 ||
                      row.picked_quantity < row.number_of_items) && (
                  <div>
                    <AlertCircle
                      fill="currentColor"
                      stroke="white"
                      className="text-warning"
                      size={20}
                      id={`picked${row.picklist_id}`}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`picked${row.picklist_id}`}
                    >
                          Few items mark as not found
                    </UncontrolledTooltip>
                  </div>
                )}
              </div>
            ) : (
              <span>
                {row.picked_quantity}{' '}
                {row.picked_quantity > 1 ? 'Items' : 'Item'}
              </span>
            )}
          </div>
        )
      }
    },
    {
      id: 7,
      name: 'Picker',
      key: 'picker',
      cell: (row) => row.picker.name || '-'
    }
  ]
  //   }
  // }
}

export const PICKLIST_STATUS = {
  UNASSIGNED: { label: 'Unassingned', value: 'unassigned' },
  IN_PICKING: { label: 'In Picking', value: 'in_picking' },
  PICKED: { label: 'Picked', value: 'picked' }
}

export const WAVE_TYPE = {
  CREATE: { title: 'Start Picking Wave', type: 'create' },
  EDIT: {title: 'Edit Picking Wave', type: 'edit'}
}

export const WAVE_TYPE_STATUS = {
  SUCCESS: {value: 'success', label: 'Success'}
}