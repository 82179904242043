import { useRef, useState } from 'react'
import { X } from 'react-feather'
import { Modal, ModalBody, Spinner } from 'reactstrap'
// import no_sku_image from "@src/assets/images/omniful/no_sku_image.svg"
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
// import { useTranslation } from "react-i18next"
import { yupResolver } from '@hookform/resolvers/yup'
import BarcodeScanner from '@src/@core/components/barcodeScanner'
import Button from '@src/@core/components/button'
import CustomToast from '@src/@core/components/custom-toast/CustomToast'
import { KEYBOARD_KEYS, PRINT_PAGE_STYLE } from '@src/App.constants'
import { GRN_CODE_TYPE, GRN_CONTAINER_TYPES } from '@src/views/inventory/inventory.constants'
import {
  clearSkuQtyUpdated,
  generateFailBinId,
  generatePassBinId,
  scanFailBinAtGrn,
  scanPassBinAtGrn
  // getBinStatus
  // getSkuDetailInGrnBin,
  // updateSkuQtyInGrnBin
} from '@src/views/inventory/store'
import classNames from 'classnames'
import Barcode from 'react-barcode'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { useReactToPrint } from 'react-to-print'
import * as yup from 'yup'
import { BIN_BARCODE_STATUS, BIN_STATUS, BIN_TYPE } from '../../constant'

const AddBinModal = ({ isAddBinModalOpen, setIsAddBinModalOpen, grnId, containerType, setContainerType, singleGrnDetail }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // ** States
  const [codeType, setCodeType] = useState(GRN_CODE_TYPE.BARCODE)
  const passBarcodeRef = useRef()
  const passQRCodeRef = useRef()
  const failBarcodeRef = useRef()
  const failQRCodeRef = useRef()
  const scanBinBarcodeRef = useRef()

  // const bin_status = useSelector((store) => store.inventory.grn.bin_status)
  const loadingState = useSelector((store) => store.inventory.loadingState)
  const selectedGlobalHubId = useSelector(
    (store) => store.auth.selectedGlobalHubId
  )
  // const loading = useSelector((store) => store.inventory.loadingState)
  // const skuDetailInBin = useSelector(
  //   (store) => store.inventory.grn.skuDetailInBin
  // )
  const passBinId = useSelector((state) => state.inventory.grn.pass_bin_id)
  const failBinId = useSelector((state) => state.inventory.grn.fail_bin_id)

  //      const passBinMatched = scannedBarcode && bin_status === 'Pass'
  //   const failBinMatched = scannedBarcode && bin_status === 'Fail'

  const UpdateBinQtySchema = yup.object().shape({
    pass_bin_barcode: yup.string(),
    fail_bin_barcode: yup.string()
  })
  const {
    control,
    watch,
    reset
  } = useForm({
    defaultValues: {
      source_bin_barcode: '',
      sku_barcode: ''
    },
    mode: 'onChange',
    resolver: yupResolver(UpdateBinQtySchema)
  })

  const handleOnClosed = () => {
    dispatch(clearSkuQtyUpdated())
    reset()
    setContainerType(GRN_CONTAINER_TYPES.BIN)
  }

  const handleToggleModal = () => {
    setIsAddBinModalOpen({ isOpen: false, type: null })
  }

  const handlePrintPassBarcode = useReactToPrint({
    content: () => passBarcodeRef.current,
    pageStyle: '@page {size: 378px 200px; margin: auto 0}'
  })
  const handlePrintPassQRCode = useReactToPrint({
    content: () => passQRCodeRef.current,
    pageStyle: '@page {size: 378px 200px; margin: auto 0}'
  })
  const handlePrintFailBarcode = useReactToPrint({
    content: () => failBarcodeRef.current,
    pageStyle: '@page {size: 378px 284px; margin: auto 0}'
  })
  const handlePrintFailQRCode = useReactToPrint({
    content: () => failQRCodeRef.current,
    pageStyle: '@page {size: 378px 284px; margin: auto 0}'
  })

  const handleGenerateBinBarcode = async (status, code_type) => {
    setCodeType(code_type)
    if (status === BIN_BARCODE_STATUS.pass) {
      await dispatch(
        generatePassBinId({
          grn_id: grnId,
          status,
          hub_id: selectedGlobalHubId,
          is_pallet: containerType === GRN_CONTAINER_TYPES.PALLET
        })
      )
      if (code_type === GRN_CODE_TYPE.BARCODE) {
        handlePrintPassBarcode()
      } else {
        handlePrintPassQRCode()
      }
    }
    if (status === BIN_BARCODE_STATUS.fail) {
      await dispatch(
        generateFailBinId({
          grn_id: grnId,
          status,
          hub_id: selectedGlobalHubId,
          is_pallet: containerType === GRN_CONTAINER_TYPES.PALLET
        })
      )
      if (code_type === GRN_CODE_TYPE.BARCODE) {
        handlePrintFailBarcode()
      } else {
        handlePrintFailQRCode()
      }
     
    }
  }
  const handleAddExistingBin = (type, e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      if (e.target.value === '') {
        CustomToast(('Please enter a barcode'), { my_type: 'error' })
        return
      }
      if (singleGrnDetail?.pass_bin?.some(bin => bin.barcode === e.target.value) || singleGrnDetail?.fail_bin?.some(bin => bin.barcode === e.target.value)) {
        CustomToast(`The scanned ${containerType} is already in use. Please add a different ${containerType}.`, { my_type: 'error' })
        return
      }
      const body = {
        grn_id: grnId,
        // bin_qc_type: type,
        barcode: e.target.value.toString().trim(),
        hub_id: selectedGlobalHubId
      }
      if (type === BIN_STATUS.PASS) {
        body.bin_qc_type = BIN_BARCODE_STATUS.pass
        dispatch(scanPassBinAtGrn(body))
      } else if (type === BIN_STATUS.FAIL) {
        body.bin_qc_type = BIN_BARCODE_STATUS.fail
        dispatch(scanFailBinAtGrn(body))
      }
    }
  }

  const handleFocusScanBinInput = () => {
    if (scanBinBarcodeRef.current) scanBinBarcodeRef.current?.focus()
  }

  return (
    <Modal
      isOpen={isAddBinModalOpen.isOpen}
      centered
      toggle={handleToggleModal}
      onClosed={handleOnClosed}
      onOpened={handleFocusScanBinInput}
      modalClassName="rounded-12px"
    >
      <ModalBody className="d-flex flex-column gap-24px py-16px px-20px ">
        <div className="d-flex justify-content-between align-items-center">
          <div className="txt-body-md text-dark">
            {t(`Add ${containerType === GRN_CONTAINER_TYPES.BIN ? 'Bin' : 'Pallet'}`)}
          </div>
          <X
            onClick={handleToggleModal}
            size={16}
            className="text-dark cursor-pointer"
          />
        </div>
        {isAddBinModalOpen.type?.id === BIN_TYPE.pass_bin.id ? (
          <div className="d-flex flex-column gap-16px align-items-center">
            <div className="w-100">
              <BarcodeScanner
                ref={scanBinBarcodeRef}
                startIcon={
                  <img  width="16px" height="16px" src={isAddBinModalOpen.type?.bin_border_icon} />
                }
                placeholder={`Scan Existing Pass ${containerType === GRN_CONTAINER_TYPES.BIN ? 'Bin' : 'Pallet'} Barcode`}
                control={control}
                name="pass_bin_barcode"
                hasValue={watch('pass_bin_barcode')}
                disabled={!grnId}
                onKeyDown={(e) => handleAddExistingBin(BIN_STATUS.PASS, e)}
              />
            </div>
            <>
              <text>OR</text>
              <div className="d-flex gap-1 w-100" >
                <Button
                  disabled={loadingState.generatePassBinId && codeType === GRN_CODE_TYPE.BARCODE}
                  // icon={Printer}
                  onClick={() => handleGenerateBinBarcode(BIN_BARCODE_STATUS.pass,  GRN_CODE_TYPE.BARCODE)}
                  className="w-100 justify-content-center"
                // ofStyle={"noBackground"}
                >
                  {loadingState.generatePassBinId && codeType === GRN_CODE_TYPE.BARCODE && <Spinner size="sm" />}
                  <span
                    className={classNames({
                      'ms-50': loadingState.generatePassBinId && codeType === GRN_CODE_TYPE.BARCODE
                    })}
                  >
                    {t('Print New Barcode')}
                  </span>
                </Button>
                <Button
                  disabled={loadingState.generatePassBinId && codeType === GRN_CODE_TYPE.QR_CODE}
                  // icon={Printer}
                  onClick={() => handleGenerateBinBarcode(BIN_BARCODE_STATUS.pass, GRN_CODE_TYPE.QR_CODE)}
                  className="w-100 justify-content-center"
                // ofStyle={"noBackground"}
                >
                  { loadingState.generatePassBinId && codeType === GRN_CODE_TYPE.QR_CODE && <Spinner size="sm" />}
                  <span
                    className={classNames({
                      'ms-50': loadingState.generatePassBinId && codeType === GRN_CODE_TYPE.QR_CODE
                    })}
                  >
                    {t('Print New QR Code')}
                  </span>
                </Button>
              </div>
              <div className="d-none">
                <style>{ PRINT_PAGE_STYLE }</style>
                <Barcode ref={passBarcodeRef} value={passBinId.barcode} />
              </div>
              <div className="d-none">
                
                <div className="d-flex mt-1 justify-content-center align-items-center gap-8px"  ref={passQRCodeRef}>
                  <style>{ `
  @media print {
    body {
      height: fit-content !important;
    }
    @page {
      size: 364px 150px;
      margin: auto 0 !important;
      padding: 0 !important;
    }` }</style>
                  <QRCode size={50} value={passBinId.barcode || ''} />
                  {passBinId.barcode}
                </div>
              </div>
            </>
          </div>
        ) : (
          <div className="d-flex flex-column gap-16px align-items-center">
            <div className="w-100">
              <BarcodeScanner
                ref={scanBinBarcodeRef}
                startIcon={
                  <img  width="16px" height="16px" src={isAddBinModalOpen.type?.bin_border_icon} />
                }
                placeholder={`Scan Existing Fail ${containerType === GRN_CONTAINER_TYPES.BIN ? 'Bin' : 'Pallet'} Barcode`}
                control={control}
                name="fail_bin_barcode"
                hasValue={watch('fail_bin_barcode')}
                // persistPlaceholder="Fail Bin - "
                disabled={!grnId}
                onKeyDown={(e) => handleAddExistingBin(BIN_STATUS.FAIL, e)}
              />
            </div>
            <>
              <text>OR</text>
              <div className="d-flex gap-1 w-100">
                <Button
                  className="w-100 justify-content-center"
                  disabled={loadingState.generateFailBinId && codeType === GRN_CODE_TYPE.BARCODE}
                  onClick={() => handleGenerateBinBarcode(BIN_BARCODE_STATUS.fail,  GRN_CODE_TYPE.BARCODE)}
                  // ofStyle={"noBackground"}
                >
                  {loadingState.generateFailBinId && codeType === GRN_CODE_TYPE.BARCODE && <Spinner size="sm" />}
                  <span
                    className={classNames({
                      'ms-50': loadingState.generateFailBinId && codeType === GRN_CODE_TYPE.BARCODE
                    })}
                  >
                    {t('Print New Barcode')}
                  </span>
                </Button>
                <Button
                  className="w-100 justify-content-center"
                  disabled={ loadingState.generateFailBinId && codeType === GRN_CODE_TYPE.QR_CODE}
                  onClick={() => handleGenerateBinBarcode(BIN_BARCODE_STATUS.fail, GRN_CODE_TYPE.QR_CODE )}
                  // ofStyle={"noBackground"}
                >
                  { loadingState.generateFailBinId && codeType === GRN_CODE_TYPE.QR_CODE && <Spinner size="sm" />}
                  <span
                    className={classNames({
                      'ms-50':  loadingState.generateFailBinId && codeType === GRN_CODE_TYPE.QR_CODE
                    })}
                  >
                    {t('Print New QR Code')}
                  </span>
                </Button>
              </div>
              <div className="d-none">
                <style>{ PRINT_PAGE_STYLE }</style>    
                <Barcode ref={failBarcodeRef} value={failBinId.barcode} />
              </div>
              <div className="d-none">
                <div className="d-flex justify-content-center align-items-center gap-8px  mt-1"  ref={failQRCodeRef}>
                  <style>{ `
  @media print {
    body {
      height: fit-content !important;
    }
    @page {
      size: 364px 150px;
      margin: auto 0 !important;
      padding: 0 !important;
    }` }</style>
                  <QRCode size={50} value={failBinId.barcode || ''} />
                  {failBinId.barcode}
                </div>
              </div>
            </>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default AddBinModal
