import CustomTag from '@src/@core/components/badge/CustomTag'
import SideSheet from '@src/@core/components/sideSheet'
import ComponentSpinner from '@src/@core/components/spinner/Loading-spinner'
import { PageNotFound } from '@src/components/pageNotFound/pageNotFound'
import { clearSingleSplitOrderDetails, getAllSplittedOrders, getCreateSplitOrderDetails, getSingleSplittedOrder, resetSuccess } from '@src/views/sales/store/store'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Col, Row } from 'reactstrap'
import SplittedOrderDetailsTabsPage from './splittedOrderDetailsTabsPage'
import './style.scss'

const SplittedOrderDetailsSideSheet = (props) => {
  const { isModalOpen, handleCloseSideSheet, isSplitOrderCreated = true, order_id = '' } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loading = useSelector(store => store.sales.loading)
  const error = useSelector(store => store.sales.error)
  const success = useSelector(store => store.sales.success)
  const singleSplitOrderDetails = useSelector(store => store.sales.singleSplitOrderDetails)

  const handleSideBarOpened = () => {
    if (isSplitOrderCreated) {
      dispatch(getSingleSplittedOrder({ order_id }))
    } else {
      dispatch(getCreateSplitOrderDetails({ order_id }))
    }
  }

  const handleSideBarClosed = () => {
    dispatch(clearSingleSplitOrderDetails())
  }

  useEffect(() => {
    if (success.updateSplitOrder) {
      handleSideBarOpened()
      dispatch(getAllSplittedOrders())
      dispatch(resetSuccess())
    }
  }, [success])

  return (
    <SideSheet
      isOpen={isModalOpen}
      toggle={handleCloseSideSheet}
      unmountOnClose={true}
      onOpened={handleSideBarOpened}
      onClosed={handleSideBarClosed}
      modalClassName="modal-slide-in sidebar-todo-modal split-order-details-sidesheet"
      contentClassName="p-0 bg-white"
      size="lg"
      title={`Order ID: ${singleSplitOrderDetails?.display_id || ''}`}
    >
      <div className="p-24px split-order-details-wrapper">
        {error.getCreateSplitOrderDetails || error.getSingleSplittedOrder ? <PageNotFound /> : loading.getCreateSplitOrderDetails || loading.getSingleSplittedOrder ? <ComponentSpinner /> : singleSplitOrderDetails &&
          <>
            <div className="d-flex gap-24px">
              <Card className="details-card bg-white w-50 rounded-4 border border-primary-lighter-global">
                <div className="card-header bg-primary-lighter-global py-1 px-1 d-flex justify-content-between text-secondary mb-0 txt-h3-md">
                  <span>{t('Basic Details')}</span>
                </div>
                <div className='d-flex flex-column p-16px gap-16px'>
                  <Row>
                    <Col className="col-4">{t('Order Tags')}:</Col>
                    <Col className='col-8 text-black d-flex flex-wrap gap-8px'>
                      {
                        singleSplitOrderDetails.tags.map((tag) => {
                          return (
                            <CustomTag key={tag.name} style={{ color: tag.color, backgroundColor: `${tag.color}1A` }}
                              title={tag.name}
                            />
                          )
                        })
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-4">{t('Seller Name')}:</Col>
                    <Col className='col-8 text-black'>{singleSplitOrderDetails.seller_name}</Col>
                  </Row>
                  {singleSplitOrderDetails.sales_channel.store_name && <Row>
                    <Col className="col-4">{t('Store Name')}:</Col>
                    <Col className='col-8 text-black'>{singleSplitOrderDetails.sales_channel.store_name}</Col>
                  </Row>}
                  <Row>
                    <Col className="col-4">{t('Created At')}:</Col>
                    <Col className='col-8 text-black'>{singleSplitOrderDetails.order_created_at}</Col>
                  </Row>
                  <Row>
                    <Col className="col-4">{t('Hub Name')}:</Col>
                    <Col className='col-8 text-black'>{singleSplitOrderDetails.hub.name}</Col>
                  </Row>
                </div>
              </Card>
              <Card className="details-card bg-white w-50 rounded-4  border border-primary-lighter-global">
                <div className="card-header py-1 px-1 d-flex justify-content-between text-secondary mb-0 txt-h3-md  bg-primary-lighter-global">
                  <Col>{t('Additional Details')}</Col>
                </div>
                <div className='d-flex flex-column p-16px gap-16px'>
                  <Row>
                    <Col className="col-4">{t('Total Ordered Qty')}:</Col>
                    <Col className='col-8 text-black'>{singleSplitOrderDetails.total_ordered_quantity >= 0 ? singleSplitOrderDetails.total_ordered_quantity : '-'}</Col>
                  </Row>
                  <Row>
                    <Col className="col-4">{t('Total Fulfilled Qty')}:</Col>
                    <Col className='col-8 text-black'>{singleSplitOrderDetails.total_fulfilled_quantity >= 0 ? singleSplitOrderDetails.total_fulfilled_quantity : '-'}</Col>
                  </Row>
                  <Row>
                    <Col className="col-4">{t('Total Remaining Qty')}:</Col>
                    <Col className='col-8 text-black'>{singleSplitOrderDetails.total_remaining_quantity >= 0 ? singleSplitOrderDetails.total_remaining_quantity : '-'}</Col>
                  </Row>
                  <Row>
                    <Col className="col-4">{t('Number of Splits')}:</Col>
                    <Col className='col-8 text-black'>{singleSplitOrderDetails.split_count >= 0 ? singleSplitOrderDetails.split_count : '-'}</Col>
                  </Row>
                </div>
              </Card>
            </div>
            <div className="mt-24px">
              <SplittedOrderDetailsTabsPage isSplitOrderCreated={isSplitOrderCreated} order_id={order_id} />
            </div>
          </>
        }
      </div>
    </SideSheet>
  )
}

export default SplittedOrderDetailsSideSheet