import SKUDetailsCol from '@src/@core/components/sku-details'
// import no_sku_image from "@src/assets/images/omniful/no_sku_image.svg"
import { useRTL } from '@src/utility/hooks/useRTL'
import SimpleSkuDetailModal from '@src/views/catalog/components/simple-sku-detail-modal/SimpleSkuDetailModal'
import { PACKAGE_TYPE } from '@src/views/Purchases/purchase.constants'
import { showSkuTypeTag } from '@src/views/sales/sales.utils'
import classNames from 'classnames'
import { useState } from 'react'
import { ChevronDown, ChevronLeft, ChevronRight } from 'react-feather'

const ExpandRow = ({ data, nestedSkus, visibleSkus, setVisibleSkus, currIndex }) => {

  const [isRtl] = useRTL()
  const [skuForDetails, setSkuForDetails] = useState({})
  const [openSkuDetailsSidebar, setOpenSkuDetailsSidebar] = useState(false)

  const handleSkuDetailsSidebar = (data) => {
    if (data) {
      setSkuForDetails(data)
      setOpenSkuDetailsSidebar(true)
    } else {
      setOpenSkuDetailsSidebar(false)
    }
  }

  const toggleNextRow = () => {
    if (visibleSkus[currIndex + 1]) {
      const newArr = visibleSkus.slice(0, currIndex + 1)
      setVisibleSkus(newArr)
    } else {
      const newArr = [...visibleSkus, nestedSkus[currIndex + 1]]
      setVisibleSkus(newArr)
    }
  }

  return (
    <tr>
      <td>
        <div className='flex-center-start gap-16px' style={{ marginLeft: nestedSkus?.length > 1 ? isRtl ? '0px' : currIndex === nestedSkus?.length - 1 ? `${(currIndex * 20) + 20}px` : `${currIndex * 20}px` : '0px', 
          marginRight: nestedSkus?.length > 1 ? isRtl ? currIndex === nestedSkus?.length - 1 ? `${(currIndex * 20) + 20}px` : `${currIndex * 20}px` : '0px' : '0px'
        }}>
          {nestedSkus[currIndex + 1] ? <div onClick={toggleNextRow} className='cursor-pointer'>
            {visibleSkus[currIndex + 1] ? <ChevronDown size={20} strokeWidth={2.3} color="var(--bs-primary)" /> : isRtl ? <ChevronLeft size={20} strokeWidth={2.3} color="var(--bs-primary)"/> : <ChevronRight size={20} strokeWidth={2.3} color="var(--bs-primary)"/>}
          </div> : nestedSkus?.length > 1 ? <div></div> : null}
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: `${data.sku?.seller_sku_id}${data.sku?.name}`,
              image: {
                src: data.sku?.images?.[0]?.url,
                alt: data.sku?.name
              },
              details: {
                name: data.sku?.name,
                skuCode: data.sku?.seller_sku_code,
                columnKey: 'sku_details',
                handleSkuDetails: () => handleSkuDetailsSidebar(data)
              },
              customTag: {
                isCustomTagPresent: data.sku?.package_type === PACKAGE_TYPE.CASE_PACK || data.sku?.package_type === PACKAGE_TYPE.PALLET,
                className: classNames({
                  'bg-brown-light text-brown': data.sku?.package_type === PACKAGE_TYPE.PALLET,
                  'bg-info-light text-info': data.sku?.package_type === PACKAGE_TYPE.CASE_PACK
                }),
                title: showSkuTypeTag(data.seller_sku?.type)
              }
            }}
          />
        </div>
      </td>
      <td>
        <div className='txt-sub-rg text-end'>
          {data.display_quantity_per_bundle}
        </div>
      </td>
      <td>
        <div className='txt-sub-rg text-end'>
          {data.display_quantity}
        </div>
      </td>
      <td>
        <div className='txt-sub-rg text-end'>
          {data.total_quantity_to_be_picked}
        </div>
      </td>
      <SimpleSkuDetailModal
        isOpen={openSkuDetailsSidebar}
        skuForDetails={skuForDetails}
        toggleSimpleSkuModal={() => handleSkuDetailsSidebar(false)}
      />
    </tr>

  )
}

export default ExpandRow