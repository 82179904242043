import ComponentSpinner from '@src/@core/components/spinner/Loading-spinner'
import { REFUND_STATUS } from '@src/views/returns/returns.constants'
import { cleanupReturnOrderData, completeRefundProcess } from '@src/views/returns/store'
import { useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, ModalBody, Spinner } from 'reactstrap'
import RefundDetailTable from '../components/refundDetailTable'
import './refundDetail.scss'
const RefundDetailSummary = (props) => {
  const { details } = props
  return (
    <div className="refund-detail-summary">
      {details.map((element) => (
        <div className="refund-detail-summary-row" key={element.key}>
          <div className="key">{element.key}</div>
          <div className="colon">:</div>
          <div className={`${element.isBadge ? 'badge-value' : 'value'}`}>
            {element.value || '-'}
          </div>
        </div>
      ))}
    </div>
  )
}
const ModalHeader = (props) => {
  // ** Props
  const { children : {heading, subHeading}, handleRefundDetailModal } = props
  return (
    <div className="modal-header d-flex align-items-center justify-content-between mb-1">
      <div>
        <h4 className="modal-title">{heading}</h4>
        <div className="modal-heading-id">{subHeading}</div>
      </div>
      <X
        className="fw-normal cursor-pointer"
        size={20}
        onClick={handleRefundDetailModal}
      />
    </div>
  )
}
const RefundDetail = (props) => {
  const {
    isRefundDetailModalOpen,
    handleRefundDetailModal
  } = props
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {returnOrderDetail, loading, success} = useSelector((state) => state.returns)
  const [skuTableData, setSkuTableData] = useState(null)
  const [isNextButtonPressed, setNextButtonPressed] = useState(false)
  const [totalQuantityAndPriceSummary, setTotalQuantityAndPriceSummary] = useState({total_refunded_quantity: 0, total_refund_price: 0})

  const handleWhenModalClosed = () => {
    dispatch(cleanupReturnOrderData({variableName: 'returnOrderDetail'}))
    setSkuTableData(null)
    setNextButtonPressed(false)
    setTotalQuantityAndPriceSummary({total_refunded_quantity: 0, total_refund_price: 0})
  }

  const handleNextButtonPressed = () => {
    setNextButtonPressed(prev => !prev)
    const quantityAndPriceSummary = Object.values(skuTableData).reduce((sums, obj) => {
      return {
        total_refunded_quantity: sums.total_refunded_quantity + obj.refunded_quantity,
        total_refund_price: sums.total_refund_price + obj.refund_price
      }
    }, {total_refunded_quantity: 0, total_refund_price: 0})
    setTotalQuantityAndPriceSummary(quantityAndPriceSummary)
  }

  const handleMarkAsRefunded = () => {
    const refund_items = Object.values(skuTableData).map((item) => {
      return {
        seller_sku_code: item.seller_sku_code,
        refund_quantity: item.refunded_quantity
      }
    })
    const body = {
      refunded_amount: totalQuantityAndPriceSummary.total_refund_price,
      refund_items
    }
    dispatch(completeRefundProcess({return_order_id: returnOrderDetail.id, body}))
  }

  useEffect(() => {
    if (success.completeRefundProcess) {
      handleRefundDetailModal()
    }
  }, [success])

  useEffect(() => {
    if (returnOrderDetail) {
      const mappedData = {}
      returnOrderDetail.order_items?.forEach((item) => {
        mappedData[item.seller_sku_code] = {
          seller_sku_code: item.seller_sku_code,
          refunded_quantity: item.refunded_quantity,
          refund_price: 0,
          unit_price: item.unit_price
        }
      })
      setSkuTableData(mappedData)
    }
  }, [returnOrderDetail])
  return (
    <Modal
      isOpen={isRefundDetailModalOpen}
      toggle={handleRefundDetailModal}
      onClosed={handleWhenModalClosed}
      unmountOnClose={true}
      className="sidebar-xxxl refund-detail-modal"
      style={{ width: '1100px' }}
      contentClassName="p-0"
      modalClassName="modal-slide-in"
    >
      <ModalHeader
        children={{heading: t('Refund Details'), subHeading: `${t('Return Order ID')}: ${returnOrderDetail?.return_order_id}`}}
        handleRefundDetailModal={
          handleRefundDetailModal
        }
      />
      {loading.getReturnOrderDetail
        ? <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: '300px' }}
        >
          <ComponentSpinner />
        </div>
        : returnOrderDetail
          ? <ModalBody className="flex-grow-1 pb-sm-0 pb-3 overflow-auto">
            <RefundDetailSummary
              details={[
                { key: t('AWB Number'), value: returnOrderDetail?.shipment?.awb_number || '-' },
                { key: t('Refund Status'), value: <div className={`refund-status-badge ${returnOrderDetail?.refund_status === REFUND_STATUS.COMPLETETD.name ? 'completed' : 'pending'}`}>{returnOrderDetail?.refund_status}</div> || '-' },
                { key: t('Total Return Qty'), value: returnOrderDetail?.total_return_quantity },
                { key: t('Total Passed Qty'), value: returnOrderDetail?.total_passed_quantity },
                { key: t('Total Failed Qty'), value: returnOrderDetail?.total_failed_quantity }
              ]}
            />
            {skuTableData && <RefundDetailTable returnOrderDetail={returnOrderDetail} skuTableData={skuTableData} setSkuTableData={setSkuTableData} isNextButtonPressed={isNextButtonPressed}/>}
            {isNextButtonPressed && <div className="d-flex flex-column align-items-end pe-1">
              <div className="refund-detail-pass-fail-summary-container">
                <div className="refund-detail-pass-fail-summary-container-row">
                  <div className="key refund-approved-quantity">{t('Total Refund Approved Qty')}</div>
                  <div className="colon">:</div>
                  <div className="value refund-approved-quantity">{`${totalQuantityAndPriceSummary?.total_refunded_quantity} Item(s)`}</div>
                </div>
                <div className="refund-detail-pass-fail-summary-container-row fw-bolder">
                  <div className="key total-refund-price">{t('Est. Refundable Price')}</div>
                  <div className="colon">:</div>
                  <div className="value total-refund-price">{`${totalQuantityAndPriceSummary?.total_refund_price.toFixed(2)} ${returnOrderDetail?.invoice?.currency}`}</div>
                </div>
              </div>
            </div>}
            <div className="refund-detail-buttons-container d-flex justify-content-end pb-3 pe-1">
              { isNextButtonPressed
                ? <>
                  <Button outline color="primary" onClick={handleRefundDetailModal} disabled={loading.completeRefundProcess}>{t('Reject')}</Button>
                  <Button color="primary" onClick={handleMarkAsRefunded} disabled={loading.completeRefundProcess}>{loading.completeRefundProcess && <Spinner size={'sm'} className="me-50"/>}{t('Mark As Refunded')}</Button>
                </>
                : <Button color="primary" onClick={handleNextButtonPressed}>{t('Next')}</Button>
              }
            </div>
          </ModalBody>
          : null
      }
    </Modal>
  )
}
export default RefundDetail
