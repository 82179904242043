import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SortIcon from '../../../assets/images/svg/Sort.svg'
const SortHeaderColumn = (props) => {
  const { t } = useTranslation()
  const { title, className, icon } = props
  return (
    <div title={t(title)} className={classNames(`${className}`, {'flex-center-start' : !className})}>
      <span className="me-8px">{t(title)}</span>
      {icon && icon}
      <span><img src={SortIcon} alt={'sort icon'} loading="lazy" /></span>
    </div>
  )
}

SortHeaderColumn.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.element
}

SortHeaderColumn.defaultProps = {
  className: '',
  icon: null
}

export default SortHeaderColumn

// for reference 
// <SortHeaderColumn title="" className="" icon={} /> 
