import CustomToast from '@src/@core/components/custom-toast/CustomToast'
import SideSheet from '@src/@core/components/sideSheet'
import { uploadMultipleFilesOnS3 } from '@src/redux/authentication'
import { ATTACHMENTS_SIDEBAR_MODE } from '@src/views/sales/constant/orders.constants'
import { useState } from 'react'
import { ModalBody } from 'reactstrap'
import './attachments.scss'
import PreviewAttachments from './previewAttachments'
import UploadAttachments from './upload-attachment'
const Attachments = (props) => {
  const { isOpen, toggle, addedAttachments, setAddedAttachments, mode, onSaveAttachments, isLoading } = props
  const [isAttachmentsUploading, setIsAttachmentsUploading] = useState(false)
  const [addedFiles, setAddedFiles] = useState([])

  const isAddOrEditMode = mode === ATTACHMENTS_SIDEBAR_MODE.EDIT.id || mode === ATTACHMENTS_SIDEBAR_MODE.ADD.id
  const isAddMode = mode === ATTACHMENTS_SIDEBAR_MODE.ADD.id

  const handleUploadFiles = async() => {
    setIsAttachmentsUploading(true)
    const unUploadedFiles = addedFiles.filter(file => !file.file_url)
    const prevUploadedFiles = addedFiles.filter(file => file.file_url)
    if (unUploadedFiles.length) {
      const filesToUpload = unUploadedFiles.map(file => ({
        service: 'oms',
        usecase: 'order-attachments',
        file
      }))
      const uploadIds = await uploadMultipleFilesOnS3(filesToUpload)
      if (uploadIds) {
        const newUploadedFiles = unUploadedFiles.map((file, index) => {
          file.file_url = uploadIds[index]
          file.should_upload = true
          file.preview_url = URL.createObjectURL(file)
          return file
        })
        const allUploadedFiles = [...prevUploadedFiles, ...newUploadedFiles]
        setAddedFiles([...allUploadedFiles])
        setAddedAttachments([...allUploadedFiles])
        setIsAttachmentsUploading(false)
        if (onSaveAttachments) {
          onSaveAttachments(allUploadedFiles)
          return
        }
        CustomToast(`File${allUploadedFiles.length > 1 ? 's' : ''} Saved Successfully`, { my_type: 'success' })
        toggle()
      }
    } else {
      setAddedAttachments([...prevUploadedFiles])
      setIsAttachmentsUploading(false)
      if (onSaveAttachments) {
        onSaveAttachments(prevUploadedFiles)
        return
      }
      CustomToast(`"File${prevUploadedFiles.length > 1 ? 's' : ''} Saved Successfully`, { my_type: 'success' })
      toggle()
    }
  }
  
  const handleOpenAndClose = () => {
    if (!isAddOrEditMode) return
    if (!addedAttachments.length && addedFiles.length) {
      setAddedFiles([])
      setAddedAttachments([])
    } else {
      setAddedFiles([...addedAttachments])
    }
  }

  return (
    <SideSheet
      isOpen={isOpen}
      toggle={toggle}
      onClosed={handleOpenAndClose}
      onOpened={handleOpenAndClose}
      size="sm"
      contentClassName="p-0"
      modalClassName="modal-slide-in sidebar-todo-modal"
      title={isAddMode ? 'Attach Files' : 'Attached Files'}
    >
      <ModalBody className="overflow-auto p-24px">
        {isAddOrEditMode 
          ? <UploadAttachments 
            handleUploadFiles={handleUploadFiles} 
            isUploading={isAttachmentsUploading || isLoading}
            addedFiles={addedFiles}
            setAddedFiles={setAddedFiles}
            addedAttachments={addedAttachments}
            mode={mode}
          /> 
          : <PreviewAttachments addedAttachments={addedAttachments}/>
        }
      </ModalBody>
    </SideSheet>
  )
}

export default Attachments