import CustomTag from '@src/@core/components/badge/CustomTag'
import { DROPDOWN_TYPES } from '@src/@core/components/customTableFilterComponent/customTableFilterComponent.constant'
import { KEYBOARD_KEYS, MANUAL_SHIPPING_ACCOUNT } from '@src/App.constants'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import { SKU_TYPES } from './constant/orders.constants'
import { ORDER_DETAILS_BUTTON_TYPE } from './sales.constant'
import { getFreeTagsForFilter } from './store/store'

export const showSkuTypeTag = (skuType) => {
  switch (skuType) {
  case SKU_TYPES.BUNDLE.key:
    return SKU_TYPES.BUNDLE.display_name
  case SKU_TYPES.ASSEMBLE.key:
    return SKU_TYPES.ASSEMBLE.display_name
  default:
    return false
  }
}
  
export const getStatusComponent = ({status}) => {
  switch (status) {
  case 'Unfulfilled':
    return (
      <CustomTag className="text-warning bg-warning-light" title='Unfulfilled' />
    )
  case 'Fulfilled':
    return (
      <CustomTag className="text-success bg-success-light" title='Fulfilled'/>
    )
  case 'Cancelled':
    return (
      <CustomTag className="text-danger bg-danger-light" title='Cancelled' />
    )
  case 'Refunded':
    return (
      <CustomTag className="text-purple bg-purple-light" title='Refunded' />
    )
  case 'Available':
    return (
      <CustomTag className="text-white bg-danger" title='Available'/>
    )
  case 'Complete':
    return (
      <CustomTag className="text-success bg-success-light" title='Complete' />
    )
  case 'Partial':
    return (
      <CustomTag className="text-warning bg-warning-light" title='Partial' />
    )
  default:
    return null
  }
}

export const OrderDetailsRow = ({ entityName, entityValue, entityNameColSpan = 3, entityValueColSpan = 9, modifyLabelStyle = '' }) => {
  const {t} = useTranslation()
  return <>
    <Row className="p-0 m-0">
      <Col className={`col-${entityNameColSpan} p-0 m-0 ${modifyLabelStyle || ''} text-break`}>{t(entityName)}</Col>
      <Col className={`col-${entityValueColSpan} p-0 ps-24px m-0 text-dark text-break`}>{entityValue}</Col>
    </Row>
  </>
}

export const categorizeorderDeatailsButtons = (onHoldOrderDetails, buttonArray) => {
  const buttons = onHoldOrderDetails?.buttons || []
  const buttonCategories = {
    actionButtons: [],
    syncButtons: {},
    editButtons: {}
  }

  buttonArray.forEach(({ id, name, onClick, hasPermission }) => {
    const button = buttons.find(button => button.key === id && button.type === ORDER_DETAILS_BUTTON_TYPE.ACTION_BUTTON.type && hasPermission)
    if (button) {
      buttonCategories.actionButtons.push({
        ...button,
        text: button.display_name,
        name,
        onClick
      })
    }
  })

  buttons.forEach(button => {
    if (button.type === ORDER_DETAILS_BUTTON_TYPE.SYNC_BUTTON.type) {
      buttonCategories.syncButtons[button.key] = button
    } else if (button.type === ORDER_DETAILS_BUTTON_TYPE.EDIT_BUTTON.type) {
      buttonCategories.editButtons[button.key] = button
    }
  })

  return buttonCategories
}

export const isValidUptoTwoDecimal = (value) => /^\d*\.?\d{0,2}$/.test(value)

export const customLabelIdsFilter = (t) => {
  return {
    filterKey: 'custom_label_ids',
    displayName: t('Tags'),
    type: DROPDOWN_TYPES.MULTI_ASYNC_DROPDOWN,
    options: getFreeTagsForFilter
  }
}

export function ShippingAccountColumn(shipment) {
  const accountName = shipment?.shipping_account?.name || ''
  const partnerName = shipment?.shipping_partner_name

  if (accountName || partnerName) {
    let partnerDisplay = ''
    if (partnerName) {
      partnerDisplay = (partnerName === MANUAL_SHIPPING_ACCOUNT && !accountName) ? MANUAL_SHIPPING_ACCOUNT : `(${partnerName})`
    }
    return `${accountName} ${partnerDisplay}`.trim()
  } else {
    return '-'
  }
}

export const disableEnterKeyEvent = (event) => {
  if (event.key === KEYBOARD_KEYS.ENTER) {
    event.preventDefault()
  }
}

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength)}...`
  }
  return str
}

export  const mergeAddress = (addressDetails) => {
  if (!addressDetails) return null
  const parts = [
    addressDetails.address_line1 || addressDetails.address1,
    addressDetails.address_line2 || addressDetails.address2,
    addressDetails.country,
    addressDetails.state,
    addressDetails.city,
    addressDetails.postal_code || addressDetails.zip
  ]
  const address = parts.filter(Boolean).join(', ')
  return address
}

export const orderTypeFilter = (t) => {
  return {
    filterKey: 'type',
    displayName: t('Order Type'),
    type: DROPDOWN_TYPES.DROPDOWN,
    options: [
      {label:'B2C',value:'b2c'},
      {label:'B2B',value:'b2b'},
      {label:'STO',value:'sto'},
      {label:'RTS',value:'rtv'},
    ]
  }
}