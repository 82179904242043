import PropTypes from 'prop-types'
import { ModalFooter } from 'reactstrap'
import './styles.scss'

const SidesheetFooter = ({ children, className }) => {
  
  return (
    <ModalFooter id='modal-footer-container' className={`modal-footer-content ${className}`}>
      {children}
    </ModalFooter>
  )
}

// Define PropTypes for the props
SidesheetFooter.propTypes = {
  children: PropTypes.node, 
  className: PropTypes.string
}

export default SidesheetFooter