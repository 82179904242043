import CustomLabel from '@src/@core/components/badge/CustomLabel'
import SideSheetCloseButton from '@src/@core/components/page-header/sideSheetCloseButton'
import SideSheet from '@src/@core/components/sideSheet'
import ComponentSpinner from '@src/@core/components/spinner/Loading-spinner'
import { clearStoDetail, getStoRequestDetail } from '@src/views/sales/store/store'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import STODetail from './stoDetail'
import './stoDetailSidebar.scss'
const STODetailSidebar = (props) => {
  const { isShowSTODetail, handleShowSTODetail, stoRequestId, sto_id, } = props

  // Hooks
  const dispatch = useDispatch()
  const { t } = useTranslation()
  
  // Store
  const hub_id = useSelector(state => state.auth.selectedGlobalHubId)
  const loading = useSelector((store) => store.sales.loading)
  
  // const selectedHubConfiguration = useSelector(state => state.auth.selectedHubConfiguration)
  // const selectedGlobalHubID = useSelector(state => state.auth.selectedGlobalHubId)
  // const locationInventoryEnabled = !!(selectedGlobalHubID && selectedHubConfiguration && selectedHubConfiguration?.find(config => config.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)?.configuration_values.enabled)

  // const handleIsReceiveInventoryVisibleOnOrder = () => {
  //   const isCurrentHubIdSameAsDestinationHubId = orderToShowDetail?.destination_hub_id === selectedGlobalHubID
  //   const isDelivered = orderToShowDetail?.is_delivered
  //   return (isCurrentHubIdSameAsDestinationHubId && !locationInventoryEnabled && isDelivered)
  // }
    
  // const TABS_DATA = {
  //   ORDER_DETAILS: { id: 'ORDER_DETAILS', name: 'Item Details', omit: false },
  //   PURCHASE_ORDER_LOGS: { id: 'PURCHASE_ORDER_LOGS', name: 'Stock Transfer Log', omit: !handleIsReceiveInventoryVisibleOnOrder() }
  // }
    
  // const STO_TAB_LIST = Object.values(TABS_DATA)?.filter(el => !el.omit)
    
  // const componentMap = {
  //   [TABS_DATA.ORDER_DETAILS.id]: STODetail,
  //   [TABS_DATA.PURCHASE_ORDER_LOGS.id]: PurchaseOrderLogs
  // }
  // const [activeTab, setActiveTab] = useState(TABS_DATA.ORDER_DETAILS)
  // const toggleTab = (tab) => {
  //   setActiveTab(tab)
  // }
  // const ActiveComponent = componentMap[activeTab.id]
  const stoDetail = useSelector((store) => store.sales.stoDetail)
  
  const handleGetStoRequestDetail = () => {
    const params = { page: 1, per_page: 20 }
    dispatch(getStoRequestDetail({ params, order_id: stoRequestId }))
  }

  const handleStoSidebarClosed = () => {
    dispatch(clearStoDetail())
  }
  const handleSideBar = () => {
    handleShowSTODetail()
    // setActiveTab(TABS_DATA.ORDER_DETAILS)
  }

  const handleSidebarOpen = () => {
    // setActiveTab(TABS_DATA.ORDER_DETAILS)   
    handleGetStoRequestDetail()
  }


  return (
    <SideSheet
      isOpen={isShowSTODetail}
      toggle={handleSideBar}
      unmountOnClose={true}
      onOpened={handleSidebarOpen}
      onClosed={handleStoSidebarClosed}
      modalClassName="modal-slide-in sidebar-todo-modal"
      contentClassName="p-0 bg-white sto-request-detail-sidesheet"
      size="md"
    >
      <div className="sidesheet-header d-flex flex-column justify-content-between py-24px">
        <div className="d-flex justify-content-between align-items-start title-container px-24px">
          <div className="d-flex flex-column gap-6px">
            <div className="flex-center-start gap-12px">
              <div className="txt-h2-md text-dark">{t('Request Details')}</div>
              {stoDetail?.status && <CustomLabel className='text-primary-global bg-primary-light-global' title={stoDetail.status} />}
            </div>
            <div className="txt-sub-rg text-dark-6">STO ID: {stoDetail?.sto_id}</div>      
          </div>
          <SideSheetCloseButton clearButtonHandler={() => handleShowSTODetail()} />
        </div>
        {/* <div>
          <FilterTabs tabDataList={STO_TAB_LIST} selectedTabId={activeTab.id} onTabChange={toggleTab} customBackground />
        </div> */}
      </div>
      <div className={classNames('stoDetailSidebarBody overflow-auto')}>
        {/* <ActiveComponent stoRequestId={stoRequestId} hub_id={hub_id} sto_id={sto_id} /> */}
        {
          loading.getStoRequestDetail && !stoDetail ? (
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ height: '300px' }}
            >
              <ComponentSpinner />
            </div>
          ) :
            <STODetail stoRequestId={stoRequestId} hub_id={hub_id} sto_id={sto_id} />
        }
      </div>
    </SideSheet>
  )
}
export default STODetailSidebar