import Button from '@src/@core/components/button'
import { WarningLogo } from '@src/assets/images/omniful/warning'
import classNames from 'classnames'
import { Modal } from 'reactstrap'
import './AlertModal.scss'

const AlertModal = ({ isModalOpen, setIsModalOpen, title, description, backBtnText = '', ctaText,primaryCtaLoading =false,  ctaHandler = () => { } }) => {

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  }
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={handleModalToggle}
      centered
      modalClassName='alert-modal'
    >
      <div className='alert-modal-content'>
        <div className='alert-image-wrapper'>
          <WarningLogo size="128"/>
        </div>
        <div className='alert-text'>
          <div className='title'>{title}</div>
          <div className='desc'>{description}</div>
        </div>
        <div className='d-flex align-items-center gap-1 footer justify-content-between'>
          <div className='btn-wrapper'>
            { backBtnText && <Button ofStyle='outlined' onClick={handleModalToggle}>{backBtnText}</Button>}
          </div>
          <div className={classNames('btn-wrapper ', {'width-fit-content': !backBtnText})}>
            <Button loading={primaryCtaLoading} disabled={ primaryCtaLoading}  onClick={ctaHandler}>{ctaText}</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AlertModal