import Button from '@src/@core/components/button'
import { useRef } from 'react'
import { AlertTriangle, X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, Spinner } from 'reactstrap'

const SkuNotPartOfOrderModal = ({isOpen, toggle, handleSaveAnyway, handleSkipThisSku, loading}) => {
  const loadingState = useSelector(store => store.inventory.loadingState)
  const {t} = useTranslation()

  const primaryCtaRef = useRef()

  const onOpened = () => {
    if (primaryCtaRef.current) primaryCtaRef.current?.focus()
  }

  return (
    <Modal
      isOpen={isOpen}
      centered
      toggle={toggle}
      onOpened={onOpened}
      contentClassName='rounded-12px'
    >
      <ModalBody className="d-flex flex-column py-16px px-20px ">
        <div className="d-flex justify-content-end align-items-center">
          <X onClick={toggle} size={16} className="text-dark" />
        </div>
        <div className="d-flex flex-column gap-8px mb-32px px-36px align-items-center">
          <AlertTriangle style={{ strokeWidth: 1.5, color: '#FF991F' }} size={80} fill="currentColor" strokeColor="white" stroke="white" />
          <div className='txt-h3-sb text-dark text-center'>
            {t('This SKU was not the part of Purchase Order')}
          </div>
          <div className='txt-body-md text-dark-6 text-center'>{t('Are you sure you want to do the GRN of this SKU?')}</div>
        </div>
        <div className='d-flex gap-20px'>
          <Button
            ofStyle="outlined"
            className='w-100 justify-content-center'
            onClick={handleSkipThisSku}
            disabled={loading}
          >
            {t('Skip this SKU')}
          </Button>
          <Button
            className='w-100 justify-content-center'
            onClick={handleSaveAnyway}
            ref={primaryCtaRef}
            disabled={loadingState.addSkuInGrnBin || loading}
          >
            {(loadingState.addSkuInGrnBin || loading) && <Spinner size="sm" />}
            <span className="align-middle ms-25">{t('Save anyway')}</span>
        
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default SkuNotPartOfOrderModal