import FloatingDropDown from '@src/@core/components/floating-dropdown'
import ToggleTabs from '@src/@core/components/toggleTabs'
import { TENANT_TYPE } from '@src/App.constants'
import { clearGrnResponse, createNewGrnForSellerAndSupplier, getAsyncSuppliersList, setSellerId } from '@src/views/inventory/store'
import { clearSingleReturnOrderDetail } from '@src/views/returns/store'
import { getSellersAsyncData } from '@src/views/settings/store'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { SELLER_SUPPLIER_OPTIONS } from '../constant'
import GrnWithOffTypeReturns from './grnWithOffTypeReturns'

const GrnWithSellerAndSupplier = ({control, errors, setValue, watch, reset, isBarcodePrint, isNextOrderProcess, setIsNextOrderProcess, isUsedInPoSto = false}) => {
  const {t} = useTranslation()
  const [selectedSeller, setSelectedSeller] = useState()
  const tenantType = useSelector((state) => state.auth.userData.tenant.type)
  const globalSellerId = useSelector((state) => state.auth.globalSeller?.seller_id)
  const [selectedTabId, setSelectedTabId] = useState(
    SELLER_SUPPLIER_OPTIONS.seller_supplier.id
  )
  const dispatch = useDispatch()
  const selectedGlobalHubID = useSelector(  
    (store) => store.auth.selectedGlobalHubId
  )

  const tabChangeHandler = (tab) => {
    setSelectedTabId(tab.id)
    dispatch(clearGrnResponse())
    reset()
    // dispatch(clearCreateReturnGrnResponse())
    dispatch(clearSingleReturnOrderDetail())
  }

  const handleSelectedSeller = (data) => {
    setValue('supplier_id', null)
    setSelectedSeller(data)
  }
  
  const handleChangeSupplier = (data) => {
    if (data?.id) {
      const body = {
        supplier_id : data.id,
        seller_id: selectedSeller?.value || globalSellerId,
        hub_id: selectedGlobalHubID
      }
      dispatch(setSellerId(selectedSeller?.value || globalSellerId)) 
      dispatch(createNewGrnForSellerAndSupplier(body))
    }
  }

  useEffect(() => {
    if (isNextOrderProcess) setSelectedTabId(SELLER_SUPPLIER_OPTIONS.off_system_returns.id)
  }, [])

  return (
    <div className="d-flex flex-column gap-24px">
      {isUsedInPoSto ? null : <ToggleTabs
        tabDataList={Object.values(SELLER_SUPPLIER_OPTIONS)}
        selectedTabId={selectedTabId}
        onTabChange={tabChangeHandler}
        isDisabled={isNextOrderProcess || isBarcodePrint}
      />}
      {selectedTabId === SELLER_SUPPLIER_OPTIONS.seller_supplier.id && <div className='d-flex flex-column gap-24px'>
        <div className='text-dark txt-body-md'>{t('Entry details')}</div>
        {tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && <FloatingDropDown
          control={control}
          errors={errors}
          name="seller_id"
          loadOptions={getSellersAsyncData}
          isAsync={true}
          title="Seller Name"
          value={watch('seller_id')}
          validationSchema={{seller_id: {required : 'This field is required'}}}
          isRequired
          isClearable
          onChangeFunc={handleSelectedSeller}
          additional={{
            page: 1
          }}
          openMenuOnFocus={false}
          closeMenuOnSelect={true}
          blurInputOnSelect={true}
        />}
        <FloatingDropDown
          control={control}
          errors={errors}
          name="supplier_id"
          loadOptions={getAsyncSuppliersList}
          isAsync={true}
          title="Supplier Name"
          cacheUniqs={[watch('seller_id')]}
          validationSchema={{supplier_id: {required : 'This field is required'}}}
          isRequired
          isClearable
          value={watch('supplier_id')}
          disabled={tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && !selectedSeller}
          onChangeFunc={handleChangeSupplier}
          additional={{
            page: 1,
            seller_id: selectedSeller?.value || globalSellerId
          }}
          openMenuOnFocus={false}
          closeMenuOnSelect={true}
          blurInputOnSelect={true}
        />
      </div>}
      {selectedTabId === SELLER_SUPPLIER_OPTIONS.off_system_returns.id && <GrnWithOffTypeReturns isNextOrderProcess={isNextOrderProcess} setIsNextOrderProcess={setIsNextOrderProcess}/>}
    </div>
  )
}

export default GrnWithSellerAndSupplier