import Button from '@src/@core/components/button'
import QuantityButton from '@src/@core/components/quantity-button'
import { SKU_TYPE } from '@src/views/inventory/inventory.constants'
import { handleBreakOpen } from '@src/views/inventory/store'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Modal } from 'reactstrap'

const OpenedQtyModal = ({ isOpen, toggle, qty, skuDetails, grnResponse, qtyToOpen, setQtyToOpen }) => {

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleOpened = () => {
    setQtyToOpen(qty)
  }

  const handleOpenItem = async () => {
    const body = {
      is_root_parent: !!skuDetails.isRoot,
      parent_id: skuDetails.isRoot ? 0 : skuDetails?.parent_id,
      over_receive: false,
      quantity: qtyToOpen,
      seller_sku_id: skuDetails.seller_sku_id,
      grn_id: grnResponse.id
    }
    dispatch(handleBreakOpen(body))
    toggle()
  }

  const handleIncrease = () => {
    setQtyToOpen(prev => prev + 1)
  }

  const handleDecrease = () => {
    setQtyToOpen(prev => prev - 1)
  }

  const onChange = (e) => {
    const number = e.target.value.trim()
    const value = +number
    if (e.nativeEvent.data === '+') {
      setQtyToOpen(prev => prev + 1)
      return
    } else if (e.nativeEvent.data === '-') {
      if (qty > 0) {
        setQtyToOpen(prev => prev - 1)
      }
      return
    }
    if (/^[1-9]\d*$/.test(value) || e.target.value === '') {
      if (value > qty) {
        setQtyToOpen(qty)
      } else {
        setQtyToOpen(value || 0)
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      contentClassName='rounded-12px'
      onOpened={handleOpened}
    >
      <div className='my-16px ms-20px me-16px'>
        <div className='txt-h3-md text-dark mb-8px'>
          {t(`Please enter number of ${skuDetails.package_type === SKU_TYPE.PALLET ? 'Pallets' : 'Case Packs'} you want to break open`)}
        </div>
        <div className='txt-sub-rg text-dark-6 mb-24px'>
          {t(`The remaining unbroken ${skuDetails.package_type === SKU_TYPE.PALLET ? 'pallets' : 'case packs'} will be operated just like an individual SKU`)}
        </div>
        <div className='mb-32px'>
          <QuantityButton
            quantity={qtyToOpen}
            increaseQuantity={handleIncrease}
            decreaseQuantity={handleDecrease}
            minQuantityEnabled={qtyToOpen === 1}
            maxQuantityEnabled={qtyToOpen === qty}
            onChange={onChange}
          />
        </div>
        <div className='mb-16px height-1px bg-dark-2' style={{ height: '1px' }}></div>
        <div className='flex-center-end gap-10px'>
          <Button ofStyle='outlined' onClick={toggle}>Cancel</Button>
          <Button onClick={handleOpenItem}>Continue</Button>
        </div>
      </div>
    </Modal>
  )
}

export default OpenedQtyModal