import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CustomToast from '@src/@core/components/custom-toast/CustomToast'
import { HUBS_CONFIGURATION } from '@src/App.constants'
import ability from '@src/configs/acl/ability'
import { axiosInstance } from '@src/network/AxiosInstance'
import { bindToChannelEvent } from '@src/network/RealTime'
import { store } from '@src/redux/store'
import { paramsSerializer, pusherCallback } from '@src/utility/Utils'
import toast from 'react-hot-toast'
import { LOCATION_ATTRIBUTES_ADDITIONAL_OPTIONS } from './constants.hubs'

const LOCATION_CREATION_STATUS = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  NOT_SET: 'NOT_SET'
}

const TENANT_PREFIX = '/api/v1/tenant'
const WMS_PREFIX = '/api/v1/wms'
const CREATE_PREFIX = '/api/v2/wms/hubs'

export const getSingleHub = createAsyncThunk('/hubs/hub', async (params, store) => {
  const permissions = store.getState()?.auth.permissions
  const response = await axiosInstance.get(`${WMS_PREFIX}/hubs/${params.hubId}`)
  return {...response?.data, permissions}
})

export const getHubs = createAsyncThunk('/hubs/list', async (params) => {
  const response = await axiosInstance.get(`${WMS_PREFIX}/hubs`, {
    params
  })
  return response?.data
})

export const addReasonConfig = createAsyncThunk('/reasons-config/hub', async (body) => {
  const response = await axiosInstance.post(`${WMS_PREFIX}/reasons`, body)
  return response?.data
})

export const deleteReasonConfig = createAsyncThunk('/delete/reasons-config/hub', async (id) => {
  const response = await axiosInstance.delete(`${WMS_PREFIX}/reasons/${id}`)
  return response?.data
})

export const editReasonConfig = createAsyncThunk('/edit/reasons-config/hub', async ({id, body}) => {
  const response = await axiosInstance.put(`${WMS_PREFIX}/reasons/${id}`, body)
  return response?.data
})

export const swapReasonConfig = createAsyncThunk('/swap/reasons-config/hub', async (body) => {  
  const response = await axiosInstance.put(`${WMS_PREFIX}/sort`, body)
  return response?.data
})

export const getReasonPackingConfig = createAsyncThunk('/packing-config/hub', async (params) => {
  const response = await axiosInstance.get(`${WMS_PREFIX}/reasons?reason_type=${params.type}`)
  return response?.data
})

export const getStandardLayoutConfig = createAsyncThunk(
  '/hubs/layout/config',
  async (params) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/layout/configurations`,
      { params }
    )
    return response?.data
  }
)

// export const getHubLayoutConfiguration = createAsyncThunk(
//   "/hubs/configuration",
//   async ({ hubId }) => {
//     const response = await axiosInstance.get(
//       `${WMS_PREFIX}/hubs/${hubId}/configurations/hub_location_mapping`
//     )
//     return response?.data
//   }
// )

export const getHubLayoutConfiguration = createAsyncThunk(
  '/hubs/configuration',
  async ({ hubId }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/configurations`
    )
    return response?.data
  }
)

// Layout
export const getFloors = createAsyncThunk(
  '/hubs/floors',
  async ({ hubId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/floors`,
      { params }
    )
    return response?.data
  }
)

export const getAisles = createAsyncThunk(
  '/hubs/aisles',
  async ({ hubId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/aisles`,
      { params }
    )
    return response?.data
  }
)

export const getRacks = createAsyncThunk(
  '/hubs/racks',
  async ({ hubId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/racks`,
      { params }
    )
    return response?.data
  }
)

export const getShelves = createAsyncThunk(
  '/hubs/shelves',
  async ({ hubId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/shelves`,
      { params }
    )
    return response?.data
  }
)

export const getLocations = createAsyncThunk(
  '/hubs/locations',
  async (allParams) => {
    const hub_id = allParams.hubId
    delete allParams.hubId
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hub_id}/locations?${paramsSerializer({...allParams})}`
    )
    return response?.data
  }
)

// export locations 
export const exportLocationBarcode = createAsyncThunk(
  '/hubs/export/location/barcode',
  async ({ hubId, params}) => {
    const updatedParams = {...params, zone: true}
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/locations/export_file`, { params: updatedParams }
    )
    return response?.data
  }
)
export const getLocationsWithoutPagination = createAsyncThunk(
  '/hubs/allLocations',
  async ({ hubId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/locations`,
      { params }
    )
    return response?.data
  }
)

export const getBinsWithoutPagination = createAsyncThunk(
  '/hubs/allBins',
  async ({ hubId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/bins`,
      { params }
    )
    return response?.data
  }
)

// get zone_coverage
export const getZoneCoverage = createAsyncThunk(
  '/hubs/zoneCoverage',
  async ({ hubId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/zones/zone_coverage`,
      { params }
    )
    return response?.data
  }
)

// get zones
export const getZones = createAsyncThunk(
  '/hubs/all/zones',
  async ({ hubId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/zones`,
      { params }
    )
    return response?.data
  }
)

// get zones locations
export const getZonesLocations = createAsyncThunk(
  '/hubs/zones/locations',
  async ({ hubId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/zones/locations`,
      { params }
    )
    return response?.data
  }
)

// get locations of a zone  
export const getLocationsOfZone = createAsyncThunk(
  '/hubs/locations/of/a/zone',
  async ({ hubId, zoneId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/zones/${zoneId}/locations`,
      { params }
    )
    return response?.data
  }
)

// delete locations of a zone  
export const deleteLocationsOfZone = createAsyncThunk(
  '/hubs/locations/of/a/zone/delete',
  async ({ hubId, zoneId, body }) => {
    const response = await axiosInstance.put(
      `${WMS_PREFIX}/hubs/${hubId}/zones/${zoneId}/locations`,
      { ...body, action_type: 'delete' }
    )
    return response?.data
  }
)

// update locations of a zone  
export const updateLocationsOfZone = createAsyncThunk(
  '/hubs/locations/of/a/zone/update',
  async ({ hubId, zoneId, body }) => {
    const response = await axiosInstance.put(
      `${WMS_PREFIX}/hubs/${hubId}/zones/${zoneId}/locations`,
      { ...body, action_type: 'update' }
    )
    return response?.data
  }
)

// edit zones w.r.t floor or aisle  wms/hubs/361/zones/375
export const editZones = createAsyncThunk(
  '/hubs/zones/edit',
  async ({ hubId, zoneId, body }) => {
    const response = await axiosInstance.put(
      `${WMS_PREFIX}/hubs/${hubId}/zones/${zoneId}`,
      { ...body}
    )
    return response?.data
  }
)

// get Bins
export const getBins = createAsyncThunk(
  '/hubs/all/bins',
  async ({ hubId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/bins`,
      { params }
    )
    return response?.data
  }
)

// get Bins
export const getBinDetails = createAsyncThunk(
  '/hubs/bins/items',
  async ({ hubId, binId, ...params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/bins/${binId}/items`,
      { params }
    )
    return response?.data
  }
)

// upload bins in bulk 
export const bulkBinsUpload = createAsyncThunk('hub/bulk/bins/upload',
  async ({hubId, body}) => {
    const res = await axiosInstance.post(`${WMS_PREFIX}/hubs/${hubId}/bins/upload`, body)
    return res.data
  }
)

// get Carts
export const getCarts = createAsyncThunk(
  '/hubs/all/carts',
  async ({ hubId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/warehouse_carts`,
      { params }
    )
    return response?.data
  }
)

export const getBoxes = createAsyncThunk(
  '/hubs/all/boxes',
  async ({ hubId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/boxes`,
      { params }
    )
    return response?.data
  }
)

// Delivery Zone
export const getDeliveryZone = createAsyncThunk(
  '/hubs/delivery-zone',
  async ({ hubId, deliveryType, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/delivery?delivery_method=${deliveryType}`,
      { params }
    )
    return response?.data
  }
)

export const getAssociatedBins = createAsyncThunk(
  '/hubs/all/carts/associated-bins',
  async ({ hubId, params }) => {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/warehouse_carts/details`,
      { params }
    )
    return response?.data
  }
)

export const getParticularFloor = createAsyncThunk('/hubs/floor', async ({ hubId, id }) => {
  const response = await axiosInstance.get(`${WMS_PREFIX}/hubs/${hubId}/floors/${id}`)
  if (response?.data) {
    return response?.data
  }
}
)

export const getParticularAisle = createAsyncThunk('/hubs/aisle', async ({ hubId, id }) => {
  const response = await axiosInstance.get(`${WMS_PREFIX}/hubs/${hubId}/aisles/${id}`)
  if (response?.data) {
    return response?.data
  }
}
)
export const getParticularRack = createAsyncThunk('/hubs/rack', async ({ hubId, id }) => {
  const response = await axiosInstance.get(`${WMS_PREFIX}/hubs/${hubId}/racks/${id}`)
  if (response?.data) {
    return response?.data
  }
}
)

export const getParticularShelf = createAsyncThunk('/hubs/shelf', async ({ hubId, id }) => {
  const response = await axiosInstance.get(`${WMS_PREFIX}/hubs/${hubId}/shelves/${id}`)
  if (response?.data) {
    return response?.data
  }
}
)

export const getParticularLocation = createAsyncThunk('/hubs/location', async ({ hubId, id }) => {
  const response = await axiosInstance.get(`${WMS_PREFIX}/hubs/${hubId}/locations/${id}`)
  if (response?.data) {
    return response?.data
  }
}
)

// create hub
export const createHubs = createAsyncThunk('/hub/createhub', async (body) => {
  const res = await axiosInstance.post(`${CREATE_PREFIX}`, body)
  return res?.data
})

// create zone
export const createZone = createAsyncThunk(
  'create/zones',
  async ({ body, hubId }) => {
    const response = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/zones`,
      body
    )
    return response
  }
)

// create zone
export const createZoneLocations = createAsyncThunk(
  'create/zones/locations',
  async ({ body, hubId }) => {
    const response = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/zones/locations`,
      body
    )
    return response?.data
  }
)

// create bins
export const createBins = createAsyncThunk(
  '/hubs/bin/createbins',
  async ({ hubId, body }) => {
    const res = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/bin`,
      body
    )
    return res?.data
  }
)

// create bulk bins
export const createBulkBins = createAsyncThunk(
  '/hubs/bin/createbulkbins',
  async ({ hubId, body }) => {
    const res = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/bins`,
      body
    )
    if (res?.data?.is_success) {
      CustomToast('Bins were successfully created in bulk',  { my_type: 'success', duration: 2000 })
    }
    return res?.data
  }
)
// create carts
export const createCarts = createAsyncThunk(
  '/hubs/cart/createcarts',
  async ({ hubId, body }) => {
    const res = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/warehouse_carts`,
      body
    )
    return res?.data
  }
)

// create carts
export const createDeliveryZone = createAsyncThunk(
  '/hubs/cart/create/deliveryzone',
  async ({ hubId, body }) => {
    const res = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/delivery`,
      body
    )
    return res?.data
  }
)

export const createBoxes = createAsyncThunk(
  '/hubs/box/createboxes',
  async ({ hubId, body }) => {
    const res = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/boxes`,
      body
    )
    return res?.data
  }
)

export const createStandardLayout = createAsyncThunk(
  '/hub/createStandardLayout',
  async (data) => {
    const { hubId, ...body } = data
    const res = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/hub_layout`,
      body
    )
    return res?.data
  }
)

export const createFloor = createAsyncThunk(
  'hubs/floor/create',
  async ({ hubId, formData }) => {
    const res = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/floors`,
      formData
    )
    return res?.data
  }
)

export const createAisle = createAsyncThunk(
  'hubs/aisle/create',
  async ({ hubId, formData }) => {
    const res = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/aisles`,
      formData
    )
    return res?.data
  }
)

export const createRack = createAsyncThunk(
  'hubs/rack/create',
  async ({ hubId, formData }) => {
    const res = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/racks`,
      formData
    )
    return res?.data
  }
)

export const createShelf = createAsyncThunk(
  'hubs/shelf/create',
  async ({ hubId, formData }) => {
    const res = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/shelves`,
      formData
    )
    return res?.data
  }
)

export const createLocation = createAsyncThunk(
  'hubs/location/create',
  async ({ hubId, formData }) => {
    const res = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/locations`,
      formData
    )
    return res?.data
  }
)

export const createConfigurations = createAsyncThunk(
  '/hubs/configurations/create',
  async ({ hubId, body }) => {
    const response = await axiosInstance.post(
      `${WMS_PREFIX}/hubs/${hubId}/configurations`,
      body
    )
    return response
  }
)

export const editHub = createAsyncThunk('hub/updateSingleHub', async ({ hubId, body }) => {
  const response = await axiosInstance.put(`${WMS_PREFIX}/hubs/${hubId}`, body)
  return response.data
})

export const editSingleFloor = createAsyncThunk('hubs/updateSingleFloor', async ({ hubId, id, body }) => {
  const response = await axiosInstance.put(`${WMS_PREFIX}/hubs/${hubId}/floors/${id}`, body)
  return response.data
})
export const editSingleAisle = createAsyncThunk('hubs/updateSingleAisle', async ({ hubId, id, body }) => {
  const response = await axiosInstance.put(`${WMS_PREFIX}/hubs/${hubId}/aisles/${id}`, body)
  return response.data
})
export const editSingleRack = createAsyncThunk('hubs/updateSingleRack', async ({ hubId, id, body }) => {
  const response = await axiosInstance.put(`${WMS_PREFIX}/hubs/${hubId}/racks/${id}`, body)
  return response.data
})
export const editSingleShelf = createAsyncThunk('hubs/updateSingleShelf', async ({ hubId, id, body }) => {
  const response = await axiosInstance.put(`${WMS_PREFIX}/hubs/${hubId}/shelves/${id}`, body)
  return response.data
})
export const editSingleLocation = createAsyncThunk('hubs/updateSingleLocation', async ({ hubId, id, body }) => {
  const response = await axiosInstance.put(`${WMS_PREFIX}/hubs/${hubId}/locations/${id}`, body)
  return response.data
})

export const getAllCities = createAsyncThunk('hubs/getAllCities', async (params) => {
  const response = await axiosInstance.get('/api/v1/address', { params })
  return response.data
})

export const getHubLocationLayout = createAsyncThunk('hubs/get-hub-location', async ({hubId}) => {
  const response = await axiosInstance.get(`/api/v1/wms/hubs/${hubId}/locations/layout`)
  return response.data
})

export const createLocationConfiguration = createAsyncThunk('hubs/create-location-file', async ({ hubId, body }) => {

  const response = await axiosInstance.post(`/api/v1/wms/hubs/${hubId}/locations/upload`, body)
  return response.data
})

export const setlocationCreationStatus = createAsyncThunk('hubs/setLocationBeingCreated', ({ value }) => {
  return value
})

export const setLocationToastId = createAsyncThunk('hubs/setLocationToastId', (value) => {
  return value
})

export const hubsSlice = createSlice({
  name: 'revampedHubs',

  initialState: {
    // Static States
    loading: {},
    success: {},
    hubCreate: {
      basicDetails: {},
      workingHours: {},
      configurations: {}
    },
    getHubId: null,
    hubLayoutType: 'not_defined',
    skuMappingUploadedFile: null,
    // API States
    
    binItemDetails: { loading: false, is_success: false, data: null },
    singleHub: { loading: false, is_success: false, data: {} },
    hubCollection: { loading: false, is_success: false, data: null },
    hubLocationConfig: {
      loading: false,
      is_success: false,
      data: null
    },
    hubLayoutConfig: { loading: false, is_success: false, data: {} },
    floors: { loading: false, is_success: false, data: null },
    aisles: { loading: false, is_success: false, data: null },
    racks: { loading: false, is_success: false, data: null },
    shelves: { loading: false, is_success: false, data: null },
    locations: { loading: false, is_success: false, data: null },
    floorsaisles: { loading: false, is_success: false, data: null },
    createHubResponse: { loading: false, is_success: false, data: null },
    floorCreateResponse: { loading: false, is_success: false, data: null },
    aisleCreateResponse: { loading: false, is_success: false, data: null },
    rackCreateResponse: { loading: false, is_success: false, data: null },
    shelfCreateResponse: { loading: false, is_success: false, data: null },
    locationsCreateResponse: { loading: false, is_success: false, data: null },
    customConfigCreateResponse: {
      loading: false,
      is_success: false,
      data: null
    },
    selectedHubId: '',
    zoneCoverage: { loading: false, is_success: false, data: null },
    getAllZones: { loading: false, is_success: false, data: null },
    getAllZonesLocations: { loading: false, is_success: false, error: false, data: null },
    getLocationsOfZoneData: { loading: false, is_success: false, data: null },
    deleteLocationsOfZoneData: { loading: false, is_success: false, data: null },
    updateLocationsOfZoneData: { loading: false, is_success: false, data: null },
    editZonesResponse: { loading: false, is_success: false, data: null },
    createZoneResponse: { loading: false, is_success: false, data: null },
    createZoneLocationResponse: { loading: false, is_success: false, data: null },
    createStandardLayoutResponse: {
      loading: false,
      is_success: false,
      data: null
    },
    editHubResponse: { loading: false, is_success: false, data: null },
    getAllCarts: { loading: false, is_success: false, error: false, data: null },
    getAllBoxes: { loading: false, is_success: false, error: false, data: null },
    getAllBins: { loading: false, is_success: false, error: false, data: null },
    associatedBinsData: { loading: false, is_success: false, data: null },
    createBinsResponse: { loading: false, is_success: false, data: null },
    createDeliveryZoneResponse: { loading: false, is_success: false, data: null },
    createBulkBinsResponse: { loading: false, is_success: false, data: null },
    uploadBulkBinsResponse: { loading: false, is_success: false, data: null },
    createCartsResponse: { loading: false, is_success: false, data: null },
    createBoxesResponse: { loading: false, is_success: false, data: null },
    particularFloorResponse: { loading: false, is_success: false, data: null },
    particularAisleResponse: { loading: false, is_success: false, data: null },
    particularRackResponse: { loading: false, is_success: false, data: null },
    particularShelfResponse: { loading: false, is_success: false, data: null },
    particularLocationResponse: { loading: false, is_success: false, data: null },
    editSingleFloorResponse: { loading: false, is_success: false, data: null },
    editSingleAisleResponse: { loading: false, is_success: false, data: null },
    editSingleRackResponse: { loading: false, is_success: false, data: null },
    editSingleShelfResponse: { loading: false, is_success: false, data: null },
    editSingleLocationResponse: { loading: false, is_success: false, data: null },
    getAllCitiesData: { loading: false, is_success: false, data: null },
    exportLocationBarcodeData: { loading: false, is_success: false, data: null },
    getCreateLocationData: { loading: false, is_success: false, data: null },
    locationsWithoutPagination: { loading: false, is_success: false, data: null },
    uploadLocationConfigData: {loading: false, is_success: false, data: null},
    packingConfigData: {loading: false, is_success: false, data: []},
    locationCreationStatus: LOCATION_CREATION_STATUS.NOT_SET,
    locationToastId: null,
    isReasonConfigAdded: false,
    isReasonConfigDeleted: false,
    isReasonConfigEdited: false,
    clearReasonConfigData: false,
    clearReasonConfigEdited: false,
    clearReasonConfigDeleted: false,
    clearAllReasonsConfig: {loading: false, is_success: false},
    reasonDetailsConfigData: [],
    binsWithoutPagination: { loading: false, is_success: false, data: null },
    selectedBinsToPrint: {},
    isAllBinsSelected: false,
    viewDeliveryZonesScreenData: {
      deliveryZonesData: [],
      selectedDeliveryZones: {},
      selectedHubs: {}
    }
  },

  reducers: {
    resetSuccess: (state) => {
      state.success = {}
    },
    reasonConfigDataDetails: (state, action) => {
      state.reasonDetailsConfigData = action.payload 
    },
    clearCreateBinsResponse: (state) => {
      state.createBinsResponse = { loading: false, is_success: false, data: {} }
    },
    clearReasonConfigData: (state) => {
      state.isReasonConfigAdded = false
    },
    clearReasonConfigEdited: (state) => {
      state.isReasonConfigEdited = false
    },
    clearReasonConfigDeleted: (state) => {
      state.isReasonConfigDeleted = false
    },
    clearAllReasonsConfig: (state) => {
      state.packingConfigData = {loading: false, is_success: false}
    },
    clearCreateBulkBinsResponse: (state) => {
      state.createBulkBinsResponse = { loading: false, is_success: false, data: {} }
    },
    clearCreateCartsResponse: (state) => {
      state.createCartsResponse = { loading: false, is_success: false, data: {} }
    },
    clearCreateBoxesResponse: (state) => {
      state.createBoxesResponse = { loading: false, is_success: false, data: {} }
    },
    clearAssociatedBinsResponse: (state) => {
      state.associatedBinsData = { loading: false, is_success: false, data: {} }
    },
    clearCreateZoneResponse: (state) => {
      state.createZoneResponse = { loading: false, is_success: false, data: {} }
    },
    clearCreateZoneLocatonsResponse: (state) => {
      state.createZoneLocationResponse = { loading: false, is_success: false, data: {} }
    },
    clearCreateHubResponse: (state) => {
      state.createHubResponse = { loading: false, is_success: false, data: {} }
    },
    clearCreateStandardLayoutResponse: (state) => {
      state.createStandardLayoutResponse = { loading: false, is_success: false, data: {} }
    },
    cleanupHubLayoutConfigResponse: (state) => {
      state.hubLayoutConfig = { loading: false, is_success: false, data: {} }
    },
    cleanupParticularFloorResponse: (state) => {
      state.particularFloorResponse = { loading: false, is_success: false, data: {} }
    },
    cleanupParticularAisleResponse: (state) => {
      state.particularAisleResponse = { loading: false, is_success: false, data: {} }
    },
    cleanupParticularRackResponse: (state) => {
      state.particularRackResponse = { loading: false, is_success: false, data: {} }
    },
    cleanupParticularShelfResponse: (state) => {
      state.particularShelfResponse = { loading: false, is_success: false, data: {} }
    },
    cleanupParticularLocationResponse: (state) => {
      state.particularLocationResponse = { loading: false, is_success: false, data: {} }
    },
    cleanupEditFloorResponse: (state) => {
      state.editSingleFloorResponse = { loading: false, is_success: false, data: {} }
    },
    cleanupEditAisleResponse: (state) => {
      state.editSingleAisleResponse = { loading: false, is_success: false, data: {} }
    },
    cleanupEditRackResponse: (state) => {
      state.editSingleRackResponse = { loading: false, is_success: false, data: {} }
    },
    cleanupEditShelfResponse: (state) => {
      state.editSingleShelfResponse = { loading: false, is_success: false, data: {} }
    },
    cleanupEditLocationResponse: (state) => {
      state.editSingleLocationResponse = { loading: false, is_success: false, data: {} }
    },
    cleanupEditHubResponse: (state) => {
      state.editHubResponse = { loading: false, is_success: false, data: {} }
    },
    cleanupUpdateResponsesOfZonesLocation: (state) => {
      state.deleteLocationsOfZoneData = { loading: false, is_success: false, data: {} }
      state.updateLocationsOfZoneData = { loading: false, is_success: false, data: {} }
    },
    cleanupEditZoneResponse: (state) => {
      state.editZonesResponse = { loading: false, is_success: false, data: {} }
    },
    cleanupCreateDeliveryZoneResponse: (state) => {
      state.createDeliveryZoneResponse = { loading: false, is_success: false, data: {} }
    },

    clearHubCreateData: (state) => {
      state.hubCreate = {
        basicDetails: {},
        workingHours: {},
        configurations: {}
      }
    },
    clearSingleHub: (state) => {
      state.singleHub = { loading: false, is_success: false, data: {} }
    },
    setWorkingHours: (state, { payload }) => {
      state.hubCreate.workingHours = payload
    },
    setHubBasicDetails: (state, { payload }) => {
      state.hubCreate.basicDetails = payload
    },
    setHubConfiguration: (state, { payload }) => {
      state.hubCreate.configurations = payload
    },
    setLayoutType: (state, { payload }) => {
      state.hubLayoutType = payload
    },
    setSelectedHubId: (state, { payload }) => {
      state.selectedHubId = payload
    },
    setlocationCreationStatus: (state, {payload}) => {
      state.locationCreationStatus = payload
    },

    // cleanups
    cleanupCreateFloor: (state) => {
      state.floorCreateResponse = {
        is_success: false,
        loading: false,
        data: null
      }
    },
    cleanupCreateAisle: (state) => {
      state.aisleCreateResponse = {
        is_success: false,
        loading: false,
        data: null
      }
    },
    cleanupCreateRack: (state) => {
      state.rackCreateResponse = {
        is_success: false,
        loading: false,
        data: null
      }
    },
    cleanupCreateShelf: (state) => {
      state.shelfCreateResponse = {
        is_success: false,
        loading: false,
        data: null
      }
    },
    cleanupCreateLocation: (state) => {
      state.locationsCreateResponse = {
        is_success: false,
        loading: false,
        data: null
      }
    },
    cleanupCreateCustomConfig: (state) => {
      state.customConfigCreateResponse = {
        is_success: false,
        loading: false,
        data: null
      }
    },
    setBinsMappingUploadedFile: (state, action) => {
      state.skuMappingUploadedFile = { ...action.payload }
    },
    cleanupBinsSkusBulkResponse: (state) => {
      state.uploadBulkBinsResponse = { loading: false, is_success: false, data: null }
    },
    cleanupBinsMappingUploadedFile: (state) => {
      state.skuMappingUploadedFile = null
    },
    selectBinToprint: (state, action) => {
      state.selectedBinsToPrint = {...action.payload}
    },
    allBinsSelectedHandler: (state, action) => {
      state.isAllBinsSelected = action.payload
    },
    setViewDeliveryZonesScreenData: (state, action) => {
      const {variableName, value} = action.payload
      state.viewDeliveryZonesScreenData = {
        ...state.viewDeliveryZonesScreenData,
        [variableName]: value
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSingleHub.fulfilled, (state, action) => {
        state.singleHub = {
          ...action.payload,
          loading: false,
          is_success: true
        }
        state.reasonDetailsConfigData = action.payload.data.reason_details
        const permissions = action.payload.permissions
        const updatedPermissions = permissions.filter((permission) => permission.subject !== 'hub_bin')
        const configurations = action.payload?.data?.configurations
        const isBinDisabled = !configurations?.find(item => item.configuration_type === HUBS_CONFIGURATION.BIN_CONFIG.value)?.configuration_values?.enabled
        if (isBinDisabled) {
          ability.update(updatedPermissions)
        } else {
          ability.update(permissions)
        }
      })
      .addCase(getSingleHub.rejected, (state) => {
        state.singleHub = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })
      .addCase(getSingleHub.pending, (state) => {
        state.singleHub = {
          loading: true,
          is_success: false,
          data: {}
        }
      })

    builder
      .addCase(getHubs.fulfilled, (state, action) => {
        state.hubCollection = {
          ...action.payload,
          loading: false,
          is_success: true,
          error: false
        }
      })
      .addCase(getHubs.rejected, (state) => {
        state.hubCollection = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })
      .addCase(getHubs.pending, (state) => {
        state.hubCollection = {
          loading: true,
          is_success: false,
          error: false
        }
      })

    builder
      .addCase(getStandardLayoutConfig.fulfilled, (state, action) => {
        state.hubLocationConfig = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getStandardLayoutConfig.rejected, (state) => {
        state.hubLocationConfig = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })
      .addCase(getStandardLayoutConfig.pending, (state) => {
        state.hubLocationConfig = {
          loading: true,
          is_success: false,
          data: null
        }
      })

    builder
      .addCase(getHubLayoutConfiguration.fulfilled, (state, action) => {
        state.hubLayoutConfig = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getHubLayoutConfiguration.rejected, (state) => {
        state.hubLayoutConfig = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })
      .addCase(getHubLayoutConfiguration.pending, (state) => {
        state.hubLayoutConfig = { loading: true, is_success: false, data: null }
      })

    // Floors
    builder
      .addCase(getFloors.fulfilled, (state, action) => {
        state.floors = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getFloors.rejected, (state) => {
        state.floors = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })
      .addCase(getFloors.pending, (state) => {
        state.floors = { loading: true, is_success: false, data: null }
      })

    // Aisles
    builder
      .addCase(getAisles.fulfilled, (state, action) => {
        state.aisles = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getAisles.rejected, (state) => {
        state.aisles = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })
      .addCase(getAisles.pending, (state) => {
        state.aisles = { loading: true, is_success: false, data: null }
      })

    // Racks
    builder
      .addCase(getRacks.fulfilled, (state, action) => {
        state.racks = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getRacks.rejected, (state) => {
        state.racks = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })
      .addCase(getRacks.pending, (state) => {
        state.racks = { loading: true, is_success: false, data: null }
      })

    // Shelves
    builder
      .addCase(getShelves.fulfilled, (state, action) => {
        state.shelves = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getShelves.rejected, (state) => {
        state.shelves = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })
      .addCase(getShelves.pending, (state) => {
        state.shelves = { loading: true, is_success: false, data: null }
      })

    // Locations
    builder
      .addCase(getLocations.fulfilled, (state, action) => {
        state.locations = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getLocations.rejected, (state) => {
        state.locations = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })
      .addCase(getLocations.pending, (state) => {
        state.locations = { loading: true, is_success: false, data: null }
      })

    // get zone_coverage
    builder
      .addCase(getZoneCoverage.pending, (state) => {
        state.zoneCoverage = { loading: true, is_success: false, data: null }
      })
      .addCase(getZoneCoverage.fulfilled, (state, action) => {
        state.zoneCoverage = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getZoneCoverage.rejected, (state) => {
        state.zoneCoverage = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })

    // get zones
    builder
      .addCase(getZones.pending, (state) => {
        state.getAllZones = { loading: true, is_success: false, data: null }
      })
      .addCase(getZones.fulfilled, (state, action) => {
        state.getAllZones = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getZones.rejected, (state) => {
        state.getAllZones = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })

    // get zones locations
    builder
      .addCase(getZonesLocations.pending, (state) => {
        state.getAllZonesLocations = { loading: true, is_success: false, error: false, data: null }
      })
      .addCase(getZonesLocations.fulfilled, (state, action) => {
        state.getAllZonesLocations = {
          ...action.payload,
          loading: false,
          is_success: true,
          error: false
        }
      })
      .addCase(getZonesLocations.rejected, (state) => {
        state.getAllZonesLocations = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })

    // export locations
    builder
      .addCase(exportLocationBarcode.pending, (state) => {
        state.exportLocationBarcodeData = { loading: true, is_success: false, data: null }
      })
      .addCase(exportLocationBarcode.fulfilled, (state, action) => {
        state.exportLocationBarcodeData = {
          ...action.payload,
          loading: false,
          is_success: true
        }
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(exportLocationBarcode.rejected, (state) => {
        state.exportLocationBarcodeData = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })

    // get zones locations
    builder
      .addCase(getLocationsOfZone.pending, (state) => {
        state.getLocationsOfZoneData = { loading: true, is_success: false, data: null }
      })
      .addCase(getLocationsOfZone.fulfilled, (state, action) => {
        state.getLocationsOfZoneData = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getLocationsOfZone.rejected, (state) => {
        state.getLocationsOfZoneData = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })

    // delete locations of a zone
    builder
      .addCase(deleteLocationsOfZone.pending, (state) => {
        state.deleteLocationsOfZoneData = { loading: true, is_success: false, data: null }
      })
      .addCase(deleteLocationsOfZone.fulfilled, (state, action) => {
        state.deleteLocationsOfZoneData = {
          ...action.payload,
          loading: false,
          is_success: true
        }
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(deleteLocationsOfZone.rejected, (state) => {
        state.deleteLocationsOfZoneData = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })

    // delete locations of a zone
    builder
      .addCase(updateLocationsOfZone.pending, (state) => {
        state.updateLocationsOfZoneData = { loading: true, is_success: false, data: null }
      })
      .addCase(updateLocationsOfZone.fulfilled, (state, action) => {
        state.updateLocationsOfZoneData = {
          ...action.payload,
          loading: false,
          is_success: true
        }
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(updateLocationsOfZone.rejected, (state) => {
        state.updateLocationsOfZoneData = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })

    // edit zones w.r.t floor or aisle
    builder
      .addCase(editZones.pending, (state) => {
        state.editZonesResponse = { loading: true, is_success: false, data: null }
      })
      .addCase(editZones.fulfilled, (state, action) => {
        state.editZonesResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(editZones.rejected, (state) => {
        state.editZonesResponse = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })

    // get bins
    builder
      .addCase(getBins.pending, (state) => {
        state.getAllBins = { loading: true, is_success: false, error: false, data: null }
      })
      .addCase(getBins.fulfilled, (state, action) => {
        state.getAllBins = {
          ...action.payload,
          loading: false,
          error: false,
          is_success: true
        }
      })
      .addCase(getBins.rejected, (state) => {
        state.getAllBins = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })
    
    builder
      .addCase(getBinDetails.pending, (state) => {
        state.binItemDetails = { loading: true, is_success: false, data: null }
      })
      .addCase(getBinDetails.fulfilled, (state, action) => {
        state.binItemDetails = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getBinDetails.rejected, (state) => {
        state.binItemDetails = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })

    // get carts
    builder
      .addCase(getCarts.pending, (state) => {
        state.getAllCarts = { loading: true, is_success: false, error: false, data: null }
      })
      .addCase(getCarts.fulfilled, (state, action) => {
        state.getAllCarts = {
          ...action.payload,
          loading: false,
          error: false,
          is_success: true
        }
      })
      .addCase(getCarts.rejected, (state) => {
        state.getAllCarts = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })
    
    builder
      .addCase(getBoxes.pending, (state) => {
        state.getAllBoxes = { loading: true, is_success: false, error: false, data: null }
      })
      .addCase(getBoxes.fulfilled, (state, action) => {
        state.getAllBoxes = {
          ...action.payload,
          loading: false,
          error: false,
          is_success: true
        }
      })
      .addCase(getBoxes.rejected, (state) => {
        state.getAllBoxes = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })
    
    // Delivery Zone
    builder
      .addCase(getDeliveryZone.pending, (state) => {
        state.deliveryZone = { loading: true, is_success: false, data: null }
      })
      .addCase(getDeliveryZone.fulfilled, (state, action) => {
        state.deliveryZone = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getDeliveryZone.rejected, (state) => {
        state.deliveryZone = {
          loading: false,
          is_success: false
        }
      })

    // get associated bins
    builder
      .addCase(getAssociatedBins.pending, (state) => {
        state.associatedBinsData = { loading: true, is_success: false, data: null }
      })
      .addCase(getAssociatedBins.fulfilled, (state, action) => {
        state.associatedBinsData = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getAssociatedBins.rejected, (state) => {
        state.associatedBinsData = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })

    // get cities data
    builder
      .addCase(getAllCities.pending, (state) => {
        state.getAllCitiesData = { loading: true, is_success: false, data: null }
      })
      .addCase(getAllCities.fulfilled, (state, action) => {
        state.getAllCitiesData = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getAllCities.rejected, (state) => {
        state.getAllCitiesData = {
          loading: false,
          is_success: false,
          error: {
            message:'We are unable to process your request right now, please try again in sometime.'
          }
        }
      })

    // Create Hub
    builder
      .addCase(createHubs.pending, (state) => {
        state.loading = { ...state.loading, createHubs: true }
      })
      .addCase(createHubs.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createHubs: false }
        state.getHubId = action?.payload?.data?.id
        CustomToast('Hub created successfully', {
          my_type: 'success',
          duration: 2000
        })
        state.createHubResponse = action.payload
      })
      .addCase(createHubs.rejected, (state) => {
        state.loading = { ...state.loading, createHubs: false }
      })

    // add new reason config
    builder
      .addCase(addReasonConfig.pending, (state) => {
        state.loading = { ...state.loading, addReasonConfig: true }
      })
      .addCase(addReasonConfig.fulfilled, (state, action) => {
        state.loading = { ...state.loading, addReasonConfig: false }
        state.isReasonConfigAdded = action?.payload?.is_success
        CustomToast('Reason added successfully', {
          my_type: 'success',
          duration: 2000
        })
      })
      .addCase(addReasonConfig.rejected, (state) => {
        state.loading = { ...state.loading, addReasonConfig: false }
      })

    builder
      .addCase(getReasonPackingConfig.pending, (state) => {
        state.packingConfigData = {loading: true, error: false, success: false, data: [] }
      })
      .addCase(getReasonPackingConfig.fulfilled, (state, action) => {
        state.packingConfigData = {loading: false, error: false, success: true, data: action?.payload?.data }
      })
      .addCase(getReasonPackingConfig.rejected, (state) => {
        state.packingConfigData = {loading: false, error: true, success: false, data: [] }
      })

    builder
      .addCase(deleteReasonConfig.pending, (state) => {
        state.loading = { ...state.loading, deleteReasonConfig: true }
      })
      .addCase(deleteReasonConfig.fulfilled, (state, action) => {
        state.loading = { ...state.loading, deleteReasonConfig: false }
        state.isReasonConfigDeleted = action?.payload?.is_success
        CustomToast('Reason deleted successfully', {
          my_type: 'success',
          duration: 2000
        })
      })
      .addCase(deleteReasonConfig.rejected, (state) => {
        state.loading = { ...state.loading, deleteReasonConfig: false }
      })

    builder
      .addCase(editReasonConfig.pending, (state) => {
        state.loading = { ...state.loading, editReasonConfig: true }
      })
      .addCase(editReasonConfig.fulfilled, (state, action) => {
        state.loading = { ...state.loading, editReasonConfig: false }
        state.isReasonConfigEdited = action?.payload?.is_success
        CustomToast('Reason edited successfully', {
          my_type: 'success',
          duration: 2000
        })
      })
      .addCase(editReasonConfig.rejected, (state) => {
        state.loading = { ...state.loading, editReasonConfig: false }
      })

    builder
      .addCase(swapReasonConfig.pending, (state) => {
        state.loading = { ...state.loading, swapReasonConfig: true }
      })
      .addCase(swapReasonConfig.fulfilled, (state, action) => {
        state.loading = { ...state.loading, swapReasonConfig: false }
        state.success = {...state.success, swapReasonConfig: action?.payload?.is_success}
        CustomToast('Reason sorted successfully', {
          my_type: 'success',
          duration: 2000
        })
      })
      .addCase(swapReasonConfig.rejected, (state) => {
        state.loading = { ...state.loading, swapReasonConfig: false }
      })

    // create zones
    builder
      .addCase(createZone.pending, (state) => {
        state.createZoneResponse = { loading: true, data: null }
      })
      .addCase(createZone.fulfilled, (state, action) => {
        state.createZoneResponse = {
          loading: false,
          is_success: true,
          data: action.payload
        }
      })
      .addCase(createZone.rejected, (state) => {
        state.createZoneResponse = { loading: false }
      })

    // create zones locatons
    builder
      .addCase(createZoneLocations.pending, (state) => {
        state.createZoneLocationResponse = { loading: true, data: null }
      })
      .addCase(createZoneLocations.fulfilled, (state, action) => {
        state.createZoneLocationResponse = {
          loading: false,
          is_success: true,
          data: action.payload
        }
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(createZoneLocations.rejected, (state) => {
        state.createZoneLocationResponse = { loading: false }
      })

    // create bins
    builder
      .addCase(createBins.pending, (state) => {
        state.createBinsResponse = {
          loading: true,
          is_success: false,
          data: null
        }
      })
      .addCase(createBins.fulfilled, (state, action) => {
        state.createBinsResponse = {
          loading: false,
          is_success: true,
          data: action.payload
        }
      })
      .addCase(createBins.rejected, (state) => {
        state.createBinsResponse = { loading: false, is_success: false }
      })

    // create bulk bins
    builder
      .addCase(createBulkBins.pending, (state) => {
        state.createBulkBinsResponse = {
          loading: true,
          is_success: false,
          data: null
        }
      })
      .addCase(createBulkBins.fulfilled, (state, action) => {
        state.createBulkBinsResponse = {
          loading: false,
          is_success: true,
          data: action.payload
        }
      })
      .addCase(createBulkBins.rejected, (state) => {
        state.createBulkBinsResponse = { loading: false, is_success: false }
      })

    // upload bulk bins
    builder
      .addCase(bulkBinsUpload.pending, (state) => {
        state.uploadBulkBinsResponse = {
          loading: true,
          is_success: false,
          data: null
        }
      })
      .addCase(bulkBinsUpload.fulfilled, (state, action) => {
        state.uploadBulkBinsResponse = {
          loading: false,
          is_success: true,
          data: action.payload
        }
        const eventName = action.payload.data.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(bulkBinsUpload.rejected, (state) => {
        state.uploadBulkBinsResponse = { loading: false, is_success: false }
      })

    // create carts
    builder
      .addCase(createCarts.pending, (state) => {
        state.createCartsResponse = {
          loading: true,
          is_success: false,
          data: null
        }
      })
      .addCase(createCarts.fulfilled, (state, action) => {
        state.createCartsResponse = {
          loading: false,
          is_success: true,
          data: action.payload
        }
      })
      .addCase(createCarts.rejected, (state) => {
        state.createCartsResponse = { loading: false, is_success: false }
      })
    
    builder
      .addCase(createBoxes.pending, (state) => {
        state.createBoxesResponse = {
          loading: true,
          is_success: false,
          data: null
        }
      })
      .addCase(createBoxes.fulfilled, (state, action) => {
        state.createBoxesResponse = {
          loading: false,
          is_success: true,
          data: action.payload
        }
      })
      .addCase(createBoxes.rejected, (state) => {
        state.createBoxesResponse = { loading: false, is_success: false }
      })

    // Create Layout
    builder
      .addCase(createStandardLayout.pending, (state) => {
        state.createStandardLayoutResponse = { loading: true, data: null }
      })
      .addCase(createStandardLayout.fulfilled, (state, action) => {
        state.createStandardLayoutResponse = {
          loading: false,
          is_success: true,
          data: action.payload
        }
      })
      .addCase(createStandardLayout.rejected, (state) => {
        state.createStandardLayoutResponse = { loading: false }
      })

    builder
      .addCase(createFloor.pending, (state) => {
        state.floorCreateResponse = { loading: true, data: null }
      })
      .addCase(createFloor.fulfilled, (state, action) => {
        CustomToast('Floor created successfully', { my_type: 'success' })
        state.floorCreateResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(createFloor.rejected, (state) => {
        state.floorCreateResponse = { loading: false }
      })

    builder
      .addCase(createAisle.pending, (state) => {
        state.aisleCreateResponse = { loading: true, data: null }
      })
      .addCase(createAisle.fulfilled, (state, action) => {
        CustomToast('Aisle created successfully', { my_type: 'success' })
        state.aisleCreateResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(createAisle.rejected, (state) => {
        state.aisleCreateResponse = { loading: false }
      })

    builder
      .addCase(createRack.pending, (state) => {
        state.rackCreateResponse = { loading: true, data: null }
      })
      .addCase(createRack.fulfilled, (state, action) => {
        CustomToast('Rack created successfully', { my_type: 'success' })
        state.rackCreateResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(createRack.rejected, (state) => {
        state.rackCreateResponse = { loading: false }
      })

    builder
      .addCase(createShelf.pending, (state) => {
        state.shelfCreateResponse = { loading: true, data: null }
      })
      .addCase(createShelf.fulfilled, (state, action) => {
        CustomToast('Shelf created successfully', { my_type: 'success' })
        state.shelfCreateResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(createShelf.rejected, (state) => {
        state.shelfCreateResponse = { loading: false }
      })

    builder
      .addCase(createLocation.pending, (state) => {
        state.locationsCreateResponse = { loading: true, data: null }
      })
      .addCase(createLocation.fulfilled, (state, action) => {
        CustomToast('Location created successfully', { my_type: 'success' })
        state.locationsCreateResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(createLocation.rejected, (state) => {
        state.locationsCreateResponse = { loading: false }
      })

    builder
      .addCase(createConfigurations.pending, (state) => {
        state.customConfigCreateResponse = { loading: true, data: null }
      })
      .addCase(createConfigurations.fulfilled, (state, action) => {
        CustomToast('Configuration setup successful', { my_type: 'success' })
        state.customConfigCreateResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(createConfigurations.rejected, (state) => {
        state.customConfigCreateResponse = { loading: false }
      })

    builder
      .addCase(getParticularFloor.pending, (state) => {
        state.particularFloorResponse = { loading: true, data: null }
      })
      .addCase(getParticularFloor.fulfilled, (state, action) => {
        state.particularFloorResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getParticularFloor.rejected, (state) => {
        state.particularFloorResponse = { loading: false }
      })

    builder
      .addCase(getParticularAisle.pending, (state) => {
        state.particularAisleResponse = { loading: true, data: null }
      })
      .addCase(getParticularAisle.fulfilled, (state, action) => {
        state.particularAisleResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getParticularAisle.rejected, (state) => {
        state.particularAisleResponse = { loading: false }
      })

    builder
      .addCase(getParticularRack.pending, (state) => {
        state.particularRackResponse = { loading: true, data: null }
      })
      .addCase(getParticularRack.fulfilled, (state, action) => {
        state.particularRackResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getParticularRack.rejected, (state) => {
        state.particularRackResponse = { loading: false }
      })

    builder
      .addCase(getParticularShelf.pending, (state) => {
        state.particularShelfResponse = { loading: true, data: null }
      })
      .addCase(getParticularShelf.fulfilled, (state, action) => {
        state.particularShelfResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getParticularShelf.rejected, (state) => {
        state.particularShelfResponse = { loading: false }
      })

    builder
      .addCase(getParticularLocation.pending, (state) => {
        state.particularLocationResponse = { loading: true, data: null }
      })
      .addCase(getParticularLocation.fulfilled, (state, action) => {
        state.particularLocationResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getParticularLocation.rejected, (state) => {
        state.particularLocationResponse = { loading: false }
      })

    builder
      .addCase(editSingleFloor.pending, (state) => {
        state.editSingleFloorResponse = { loading: true, data: null }
      })
      .addCase(editSingleFloor.fulfilled, (state, action) => {
        state.editSingleFloorResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(editSingleFloor.rejected, (state) => {
        state.editSingleFloorResponse = { loading: false }
      })

    builder
      .addCase(editSingleAisle.pending, (state) => {
        state.editSingleAisleResponse = { loading: true, data: null }
      })
      .addCase(editSingleAisle.fulfilled, (state, action) => {
        state.editSingleAisleResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(editSingleAisle.rejected, (state) => {
        state.editSingleAisleResponse = { loading: false }
      })

    builder
      .addCase(editSingleRack.pending, (state) => {
        state.editSingleRackResponse = { loading: true, data: null }
      })
      .addCase(editSingleRack.fulfilled, (state, action) => {
        state.editSingleRackResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(editSingleRack.rejected, (state) => {
        state.editSingleRackResponse = { loading: false }
      })

    builder
      .addCase(editSingleShelf.pending, (state) => {
        state.editSingleShelfResponse = { loading: true, data: null }
      })
      .addCase(editSingleShelf.fulfilled, (state, action) => {
        state.editSingleShelfResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(editSingleShelf.rejected, (state) => {
        state.editSingleShelfResponse = { loading: false }
      })

    builder
      .addCase(editSingleLocation.pending, (state) => {
        state.editSingleLocationResponse = { loading: true, data: null }
      })
      .addCase(editSingleLocation.fulfilled, (state, action) => {
        state.editSingleLocationResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
        CustomToast('Location edited successfully', { my_type: 'success' })
      })
      .addCase(editSingleLocation.rejected, (state) => {
        state.editSingleLocationResponse = { loading: false }
      })

    builder
      .addCase(editHub.pending, (state) => {
        state.editHubResponse = { loading: true, data: null }
      })
      .addCase(editHub.fulfilled, (state, action) => {
        state.editHubResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(editHub.rejected, (state) => {
        state.editHubResponse = { loading: false, is_success: false }
      })
    builder
      .addCase(createDeliveryZone.pending, (state) => {
        state.createDeliveryZoneResponse = { loading: true, data: null }
      })
      .addCase(createDeliveryZone.fulfilled, (state, action) => {
        state.createDeliveryZoneResponse = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(createDeliveryZone.rejected, (state) => {
        state.createDeliveryZoneResponse = { loading: false, is_success: false }
      })
    
    builder
      .addCase(getLocationsWithoutPagination.pending, (state) => {
        state.locationsWithoutPagination = { loading: true, data: null }
      })
      .addCase(getLocationsWithoutPagination.fulfilled, (state, action) => {
        state.locationsWithoutPagination = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getLocationsWithoutPagination.rejected, (state) => {
        state.locationsWithoutPagination = { loading: false }
      })

    builder
      .addCase(getBinsWithoutPagination.pending, (state) => {
        state.binsWithoutPagination = { loading: true, data: null }
      })
      .addCase(getBinsWithoutPagination.fulfilled, (state, action) => {
        state.binsWithoutPagination = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getBinsWithoutPagination.rejected, (state) => {
        state.binsWithoutPagination = { loading: false }
      })
    
    builder
      .addCase(setlocationCreationStatus.fulfilled, (state, action) => {
        state.locationCreationStatus = action.payload
      })
    
    builder
      .addCase(setLocationToastId.fulfilled, (state, { payload }) => {
        state.locationToastId = payload
      })

    builder
      .addCase(getHubLocationLayout.pending, (state) => {
        state.getCreateLocationData = { loading: true, data: null, is_success:false }
      })
      .addCase(getHubLocationLayout.fulfilled, (state, action) => {
        state.getCreateLocationData = {
          ...action.payload,
          loading: false,
          is_success: true
        }
      })
      .addCase(getHubLocationLayout.rejected, (state) => {
        state.getCreateLocationData = { loading: false, is_success: false, data: null }
      })

    builder
      .addCase(createLocationConfiguration.pending, (state) => {
        state.uploadLocationConfigData = { loading: true, data: null, is_success:false }
      })
      .addCase(createLocationConfiguration.fulfilled, (state, action) => {
        state.uploadLocationConfigData = {
          ...action.payload,
          loading: false,
          is_success: true
        }
        state.locationCreationStatus = LOCATION_CREATION_STATUS.PENDING
        const eventName = action.payload.data.event
        const customCallback = (response) => {
          // logic 1-> 
          pusherCallback(response)
          const Id = store.getState().revampedHubs.locationToastId
          store.dispatch(setlocationCreationStatus({ value: LOCATION_CREATION_STATUS.SUCCESS }))
          if (Id) {
            toast.dismiss(Id)
            store.dispatch(setLocationToastId(null))
            store.dispatch(
              setlocationCreationStatus({ value: LOCATION_CREATION_STATUS.NOT_SET })
            )
            store.dispatch(getLocations({hubId: store.getState().revampedHubs.selectedHubId}))
          }
        }

        if (eventName) {
          bindToChannelEvent(
            eventName,
            customCallback
          )
        }
      })
      .addCase(createLocationConfiguration.rejected, (state) => {
        const Id = state.locationToastId
        state.locationCreationStatus = LOCATION_CREATION_STATUS.FAILURE
        state.uploadLocationConfigData = { loading: false, is_success: false, data: null }
        if (Id) {
          toast.dismiss(Id)
          state.locationToastId = null
          state.locationCreationStatus = LOCATION_CREATION_STATUS.NOT_SET
        }
      })
  }
})

export const {
  resetSuccess,
  clearCreateHubResponse,
  clearHubCreateData,
  clearSingleHub,
  setLayoutType,
  setHubBasicDetails,
  clearHubBasicDetails,
  setWorkingHours,
  setHubConfiguration,
  setSelectedHubId,
  cleanupCreateFloor,
  cleanupCreateAisle,
  cleanupCreateRack,
  cleanupCreateShelf,
  cleanupCreateLocation,
  cleanupEditZoneResponse,
  clearCreateZoneResponse,
  clearCreateZoneLocatonsResponse,
  clearCreateBinsResponse,
  clearCreateBulkBinsResponse,
  clearCreateCartsResponse,
  clearCreateBoxesResponse,
  clearAssociatedBinsResponse,
  cleanupCreateCustomConfig,
  clearCreateStandardLayoutResponse,
  cleanupParticularFloorResponse,
  cleanupParticularAisleResponse,
  cleanupParticularRackResponse,
  cleanupParticularShelfResponse,
  cleanupParticularLocationResponse,
  cleanupEditFloorResponse,
  cleanupEditAisleResponse,
  cleanupEditRackResponse,
  cleanupEditShelfResponse,
  cleanupEditLocationResponse,
  cleanupHubLayoutConfigResponse,
  cleanupEditHubResponse,
  cleanupCreateDeliveryZoneResponse,
  cleanupUpdateResponsesOfZonesLocation,
  setBinsMappingUploadedFile,
  cleanupBinsSkusBulkResponse,
  cleanupBinsMappingUploadedFile,
  clearReasonConfigData,
  clearReasonConfigEdited,
  clearReasonConfigDeleted,
  clearAllReasonsConfig,
  reasonConfigDataDetails,
  selectBinToprint,
  allBinsSelectedHandler,
  setViewDeliveryZonesScreenData
} = hubsSlice.actions

export const getCurrencyData = async (search, loadedOptions, { page, countries }) => {
  try {
    const param = {
      params: { currency: search, page, countries}
    }
    const response = await axiosInstance.get(`${TENANT_PREFIX}/countries?${paramsSerializer({...param.params})}`)

    const options = response.data?.data?.map((ele) => ({
      value: ele.currency?.code,
      label: ele.currency?.display_name,
      ...ele.currency
    }))

    return {
      options,
      hasMore: page < response.data?.meta?.last_page,
      additional: { page: page + 1 }
    }
  } catch (error) {
    console.warn(error)
    return {
      options: [],
      hasMore: false,
      additional: { page }
    }
  }
}

export const getCountriesData = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get(`${TENANT_PREFIX}/countries`, {
      params: { search_column: 'name', search_query: search, page }
    })
    const data = response?.data
    return {
      options: data?.data?.map((ele) => ({
        value: ele.id,
        label: ele.name,
        code: ele.iso2
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getStatesAsyncData = async (
  search,
  loadedOptions,
  { page, country_id }
) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/tenant/countries/${country_id}/states`,
      { params: { search_column: 'name', search_query: search, page } }
    )
    const data = response?.data
    return {
      options: data?.data?.map((ele) => ({
        value: `${ele.id}`,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        country_id
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        country_id
      }
    }
  }
}

export const getAllCitiesAsyncData = async (
  search,
  loadedOptions,
  { page, country_id }
) => {
  try {
    const response = await axiosInstance.get(
      '/api/v1/tenant/cities',
      { params: { search_column: 'name', search_query: search, page } }
    )
    const data = response?.data
    return {
      options: data?.data?.map((ele) => ({
        value: `${ele.id}`,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        country_id
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}
export const getCitiesAsyncData = async (
  search,
  loadedOptions,
  { page, country_id }
) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/tenant/states/${country_id}/cities`,
      { params: { search_column: 'name', search_query: search, page } }
    )
    const data = response?.data
    return {
      options: data?.data?.map((ele) => ({
        value: `${ele.id}`,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        country_id
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getFloorsAsync = async (
  search,
  loadedOptions,
  { page, hubId, params }
) => {
  try {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/floors`,
      {
        params: {
          search_column: 'name',
          search_query: search,
          page,
          ...params
        }
      }
    )
    const data = response?.data
    return {
      options: data?.data?.map((ele) => ({
        value: `${ele.id}`,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hubId,
        params
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getAislesAsync = async (
  search,
  loadedOptions,
  { page, hubId, params }
) => {
  try {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/aisles`,
      {
        params: {
          search_column: 'name',
          search_query: search,
          page,
          ...params
        }
      }
    )
    const data = response?.data
    return {
      options: data?.data?.map((ele) => ({
        value: `${ele.id}`,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hubId,
        params
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getRacksAsync = async (
  search,
  loadedOptions,
  { page, hubId, params }
) => {
  try {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/racks`,
      {
        params: {
          search_column: 'name',
          search_query: search,
          page,
          ...params
        }
      }
    )
    const data = response?.data
    return {
      options: data?.data?.map((ele) => ({
        value: `${ele.id}`,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hubId,
        params
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getShelvesAsync = async (
  search,
  loadedOptions,
  { page, hubId, params }
) => {
  try {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/shelves`,
      {
        params: {
          search_column: 'name',
          search_query: search,
          page,
          ...params
        }
      }
    )
    const data = response?.data
    return {
      options: data?.data?.map((ele) => ({
        value: `${ele.id}`,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hubId,
        params
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getLocationsAsync = async (
  search,
  loadedOptions,
  { page, hubId, params }
) => {
  try {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/locations`,
      { params: { page, ...params } }
    )
    const data = response?.data
    return {
      options: data?.data?.map((ele) => ({
        value: `${ele.id}`,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hubId,
        params
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getZonesAsync = async (
  search,
  loadedOptions,
  { page, hubId, params }
) => {
  try {
    const response = await axiosInstance.get(
      `${WMS_PREFIX}/hubs/${hubId}/zones`,
      { params: { page, ...params } }
    )
    const data = response?.data
    return {
      options: data?.data?.map((ele) => ({
        value: `${ele.id}`,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hubId,
        params
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getLocationAttributes = async (
  search,
  loadedOptions,
  { page, hubId }
) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/wms/hubs/${hubId}/locations/attributes`,
      { params: { search_column: 'name', search_query: search, page } }
    )
    const data = response?.data?.data ?? []
    
    if (search) data.unshift({id: search, name: `${LOCATION_ATTRIBUTES_ADDITIONAL_OPTIONS.ADD_ATTRIBUTE.label} "${search}"`, tag: 'add_attribute'})

    const modifiedData = data?.map((ele) => ({
      value: ele.id,
      label: ele.name,
      tag: ele.tag
    }))

    return {
      options: modifiedData,
      hasMore: page < response?.data?.meta?.last_page,
      additional: {
        page: page + 1,
        hubId
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        hubId
      }
    }
  }
}

export const getAllHubsAsyncData = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/wms/hubs', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data
    if (page === 1 && data?.data?.length) data.data.unshift({id: '', name: 'button'})
    return {
      options: data?.data.map(ele => ({
        value: ele.id,
        label: ele.name,
        center: {
          lat: ele?.address?.latitude,
          lng: ele?.address?.longitude
        }
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export default hubsSlice.reducer