import CustomTag from '@src/@core/components/badge/CustomTag'
import SKUDetailsCol from '@src/@core/components/sku-details/index'
import { formattedLongDate } from '@src/utility/Utils'
import { SKU_TYPES } from '@src/views/sales/constant/orders.constants'
import { Info, Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'

const WmsDetailsTableColumns = (
  { handleBarcodesSidesheet, handleBatchesSidesheet, handleFailedSkuSidesheet, handleNonSerialisedSidesheet }) => {
  const { t } = useTranslation()
  return [
    {
      id: 1,
      name: t('SKU Details'),
      key: 'wms_details_sku_details',
      minWidth: '400px',
      maxWidth: '450px',
      cell: (row) => <div className='flex-center-between w-100'>
        <div className='w-75'>
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id:`sku_details_abcd_unique_${row.sku.seller_sku_id}`,
              image: {
                src: row.sku.images?.[0]?.url,
                alt: row.sku.name
              },
              details: {
                name: row.sku.name,
                skuCode: row.sku.seller_sku_code,
                columnKey: 'sku_details'
              }
            }}
          />      
        </div>
        <div className='flex-center-start gap-6px flex-wrap'>
          {row.sku.is_gtin ? <CustomTag className='bg-dark-1 text-dark' title="GTIN" /> : null}
          {row.type === SKU_TYPES.BUNDLE.key ? <CustomTag className='bg-info-light text-info' title={SKU_TYPES.BUNDLE.display_name} /> : null}
          {row.type === SKU_TYPES.ASSEMBLE.key ? <CustomTag className='bg-info-light text-info' title={SKU_TYPES.ASSEMBLE.display_name} /> : null}
        </div>
      </div>
    },
    {
      id: 2,
      name: <div className="w-100 text-end text-truncate" title={t('Picked Qty')}>{t('Picked Qty')}</div>,
      key: 'wms_details_picked_qty',
      minWidth: '120px',
      cell: (row) => <div className='txt-sub-rg text-dark px-2px text-end w-100'>
        {row.picked_quantity}
      </div>
    },
    {
      id: 3,
      name: t('Barcode'),
      key: 'wms_details_barcodes',
      minWidth: '200px',
      cell: (row) => <>
        {row.sku.is_serialised ?
          row.serialised_barcodes?.[0]?.barcode ? <div className="flex-center-between gap-8px w-100 ps-2px pe-6px">
            <span className='txt-sub-rg text-dark'>{row.serialised_barcodes[0].barcode}</span>
            {row.serialised_barcodes.length > 1 ?
              <p className="cursor-pointer flex-center-center gap-2px m-0 ps-6px pe-8px"
                style={{
                  color: 'var(--bs-primary)',
                  border: '1px solid var(--bs-primary)',
                  borderRadius: '12px'
                }}
                onClick={() => handleBarcodesSidesheet({ data: row.serialised_barcodes, is_batched: row.sku?.is_batched })}>
                <Plus size={12} color="var(--bs-primary)" />
                <span className="txt-asst-rg pt-2px">{row.serialised_barcodes.length - 1}</span>
              </p>
              : null}
          </div> : '-'
          :
          row.barcodes?.length ? <div className="flex-center-between w-100 ps-2px pe-6px">
            <span className='txt-sub-rg text-dark'>{row.barcodes[0]}</span>
            {row.barcodes.length > 1 ?
              <p className="cursor-pointer flex-center-center m-0 ps-6px pe-8px"
                style={{
                  color: 'var(--bs-primary)',
                  border: '1px solid var(--bs-primary)',
                  borderRadius: '12px'
                }}
                onClick={() => handleNonSerialisedSidesheet(row)}>
                <Plus size={12} color="var(--bs-primary)" />
                <span className="txt-asst-rg pt-2px">{row.barcodes.length - 1}</span>
              </p>
              : null}
          </div> : '-'
        }
      </>
    },
    {
      id: 4,
      name: t('Batch Number'),
      key: 'wms_details_batch_numbers',
      minWidth: '200px',
      cell: (row) => <>
        {
          row.batch?.[0]?.batch?.external_batch_id ?
            <div className="w-100 px-2px flex-center-start gap-8px">
              <div>
                <p className='txt-sub-rg text-dark m-0'>{row.batch[0].batch.external_batch_id}</p>
                <p className="m-0 text-dark-6 txt-asst-rg">{t('Expiry')}:{formattedLongDate(row.batch[0].batch.expiry_date)}</p>
              </div>
              {row.batch.length > 1 ?
                <p className="cursor-pointer flex-center-center gap-2px m-0  ps-6px pe-8px"
                  style={{
                    color: 'var(--bs-primary)',
                    border: '1px solid var(--bs-primary)',
                    borderRadius: '12px'
                  }}
                  onClick={() => handleBatchesSidesheet(row)}>
                  <Plus size={12} color="var(--bs-primary)" />
                  <span className="txt-asst-rg pt-2px">{row.batch.length - 1}</span>
                </p>
                : null}
            </div>
            : '-'
        }
      </>
    },
    {
      id: 5,
      name: <div className="w-100 text-end text-truncate" title={t('Packed Qty')}>{t('Packed Qty')}</div>,
      key: 'wms_details_packed_qty',
      minWidth: '120px',
      cell: (row) => <div className='txt-sub-rg text-success px-2px w-100 text-end'>
        {row.packed_quantity}
      </div>
    },
    {
      id: 6,
      name: <div className="w-100 text-end text-truncate" title={t('Failed Qty')}>{t('Failed Qty')}</div>,
      key: 'wms_details_failed_qty',
      minWidth: '120px',
      cell: (row) => <div className='txt-sub-rg text-danger flex-center-end px-2px w-100'>
        <div className="w-100 text-end gap-8px flex-center-end">
          <span>{row.failed_quantity}</span>
          {row.failed_quantity ?
            <Info size={16} color='var(--bs-primary)' className='cursor-pointer'
              strokeWidth={2} onClick={() => handleFailedSkuSidesheet(row)} /> : null}
        </div>
      </div>
    },
    {
      id: 7,
      name: <div className="w-100 text-end text-truncate" title={t('Putaway Qty')}>{t('Putaway Qty')}</div>,
      key: 'putaway_qty',
      minWidth: '120px',
      cell: (row) => <div className='txt-sub-rg text-danger flex-center-end px-2px w-100'>
        <div className="w-100 text-end gap-8px flex-center-end">
          <span>{row.pending_put_away_quantity}</span>
          {!!row.pending_put_away_quantity && !!row.sku.is_serialised ?
            <Info size={16} color='var(--bs-primary)' className='cursor-pointer'
              strokeWidth={2} onClick={() => handleBarcodesSidesheet({ data: row.pending_put_away_serialised_barcodes, is_batched: row.sku?.is_batched })} /> : null}
        </div>
      </div>
    }
  ]
}

export default WmsDetailsTableColumns