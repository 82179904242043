import { axiosInstance } from './network/AxiosInstance'

const API_VERSION = 'v1'
const TENANT_PREFIX = `/api/${API_VERSION}/tenant`
const OMS_PREFIX = `/api/${API_VERSION}/oms`
const WMS_PREFIX = `/api/${API_VERSION}/wms`
const SHIPPING_PREFIX = `/api/${API_VERSION}/shipping`
export const TAX_AUTHORITIES_PREFIX = `/api/${API_VERSION}/shipping/tax_authority_integrations`
const PRODUCT_PREFIX = `/api/${API_VERSION}/products`
const SALES_CHANNEL_PREFIX = `/api/${API_VERSION}/sales_channels`
const PRODUCT_PREFIX_WITH_SELLER = `/api/${API_VERSION}/products/sellers/:sellerId`
const FLEET_PREFIX = `/api/${API_VERSION}/fleet`
const USER_PREFIX = `/api/${API_VERSION}/users`
const ANALYTICS_PREFIX = `/api/${API_VERSION}/analytics`
const BILLING_PREFIX = `/api/${API_VERSION}/billing`
const POS_PREFIX = `/api/${API_VERSION}/pos`
export const CUSTOMER_PREFIX = `/api/${API_VERSION}/cs`
//First search and check thoroughly if API is already there, repetitions should be avioded at any cost

//the bottom of this file shows the way how we can use the API endpoints efficiently

export const API_ENDPOINTS = {
  COMMON: {
    GET_ALL_SELLERS: `${TENANT_PREFIX}/sellers`,
    GET_ALL_COUNTRIES: `${TENANT_PREFIX}/countries`,
    GET_STATES_BASED_ON_THE_COUNTRY: `${TENANT_PREFIX}/countries/:countryId/states`,
    GET_CITIES_BASED_ON_THE_COUNTRY: `${TENANT_PREFIX}/states/:countryId/cities`,
    GET_CITIES_BASED_ON_THE_STATE: `${TENANT_PREFIX}/states/:stateId/cities`,
    GET_ALL_CITIES: `${TENANT_PREFIX}/cities`,
    GET_SHIPPING_PARTNERS: `${SHIPPING_PREFIX}/shipping_partners`, //also used for edit shipping partner
    GET_COURIER_PARTNERS: `${SHIPPING_PREFIX}/courier_partners`,
    GET_TOROD_COURIER_PARTNERS: `${SHIPPING_PREFIX}/shipping_partners/torod`,
    GET_SELLER_SALES_CHANNEL: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/sales_channels`,
    GET_ALL_OR_CREATE_SUPPLIER: `${WMS_PREFIX}/suppliers`,
    GET_ALL_SUPPLIERS_OF_SELLER: `${WMS_PREFIX}/sellers/:sellerId/suppliers`,
    GET_CURRENCIES: `${TENANT_PREFIX}/currencies`,
    GET_CREATE_SHIPMENT_DETAILS: `${OMS_PREFIX}/orders/shipments/details`,
    TENANT_CONFIG: `${TENANT_PREFIX}/configurations`,
    GET_OMS_REASONS: `${OMS_PREFIX}/reasons`, 
  },
  ORDERS: {
    GET_ORDERS: `${OMS_PREFIX}/orders`, //also used for get orders by status, create order
    GET_ORDER_DETAILS: `${OMS_PREFIX}/orders/:orderId`, //also used to approve the order
    CANCEL_ORDER: `${OMS_PREFIX}/orders/:orderId/cancel`,
    CHANGE_ORDER_STATUS: `${OMS_PREFIX}/orders/status`,
    CREATE_BULK_ORDER: `${OMS_PREFIX}/orders/bulk`,
    GET_PENDING_ORDERS: `${OMS_PREFIX}/orders/pending`,
    GET_ORDER_LOGS: `${OMS_PREFIX}/orders/:orderId/order_logs`,
    GET_ORDERS_IN_CONSOLIDATION: `${WMS_PREFIX}/hubs/:hubId/orders/consolidation`,
    GET_CONSOLIDATION_DETAILS: `${WMS_PREFIX}/hubs/:hubId/consolidation/orders/details`,
    GET_CONSOLIDATING_ORDERS_DETAILS: `${WMS_PREFIX}/hubs/:hubId/orders/:warehouseOrderId/consolidation/items`,
    GET_ORDERS_IN_PICKING: `${WMS_PREFIX}/hubs/:hubId/orders/picking`,
    GET_PACKING_ORDER_DETAILS: `${WMS_PREFIX}/hubs/:hubId/order/packing/details`,
    CREATE_PACKING: `${WMS_PREFIX}/hubs/:hubId/order/packing/order/:orderId`,
    CREATE_PICKING_WAVE: `${WMS_PREFIX}/hubs/:hubId/picking/wave`,
    GET_PICKING_WAVES: `${OMS_PREFIX}/hubs/:hubId/picking/waves`,
    GET_PICKING_WAVE_CONFIGURATION: `${WMS_PREFIX}/hubs/:hubId/picking/waves/details`,
    GET_PICKLISTS_DETAILS: `${WMS_PREFIX}/hubs/:hubId/waves/:waveId/picklists/details`,
    GET_PICKLISTS_IN_ZONE: `${WMS_PREFIX}/hubs/:hubId/waves/:waveId/zones/:zoneId/picklists`,
    GET_ITEM_QUANTITY_DETAILS_OF_PICKLIST: `${WMS_PREFIX}/hubs/:hubId/picking/:picklistId/picklist_details`,
    GET_ORDER_TAGS: `${OMS_PREFIX}/orders/tags`,
    CREATE_SHIPMENT: `${OMS_PREFIX}/orders/shipments/:orderId`,
    GET_SHIPMENT_DETAILS: `${OMS_PREFIX}/orders/shipments/details`, //used for get shipping details, get create shipment details
    CREATE_SHIPMENT_WITH_SALES_CHANNEL: `${OMS_PREFIX}/orders/:orderId/shipments`,
    CANCEL_SHIPMENT: `${OMS_PREFIX}/orders/shipments/:shipmentOrderId/cancel`,
    PRINT_AWB: `${OMS_PREFIX}/orders/shipments/:shipmentOrderId/awb`,
    GET_AWB_DETAILS: `${OMS_PREFIX}/orders/:orderId/awb`,
    GET_PACKING_REASONS: `${WMS_PREFIX}/reasons`, //also used for get quantity check fail reasons
    GET_SELLER_SKUS: `${PRODUCT_PREFIX}/sellers/:sellerId/skus`,
    GET_SELLER_SKUS_CREATE_ORDER: `${PRODUCT_PREFIX}/sellers/:sellerId/order_skus`,
    GET_LOCATION_INVENTORY_FOR_PACKING: `${WMS_PREFIX}/hubs/:hubId/location_inventory/skus`,
    CREATE_PICKING: `${WMS_PREFIX}/hubs/:hubId/order/packing/order/:orderId/pick_order_item`,
    GET_PICKING_LOCATION_SKU_BARCODE: `${WMS_PREFIX}/hubs/:hubId/location_inventory/serialised_skus`,
    GET_ALL_OMS_HUBS: `${OMS_PREFIX}/hubs/all`,
    CREATE_STO_ORDER: `${OMS_PREFIX}/orders/sto`, //also used for get single sto list
    CREATE_BULK_STO_ORDER: `${OMS_PREFIX}/orders/sto/bulk`,
    GET_STO_ORDER_DETAILS: `${OMS_PREFIX}/orders/sto/:orderId`,
    STO_REQUEST: `${OMS_PREFIX}/orders/sto/requests/:orderId`, //used for get-sto-request-details, update-sto-request,add-new-sku-in-sto-request
    APROVE_STO_REQUEST: `${OMS_PREFIX}/orders/sto/requests/:orderId/approve`,
    MARK_STO_DELIVERED: `${OMS_PREFIX}/orders/sto/requests/:orderId/delivered`,
    CANCEL_STO_REQUEST: `${OMS_PREFIX}/orders/sto/requests/:orderId/reject`,
    GET_ALL_OR_CREATE_STO_REQUEST: `${OMS_PREFIX}/orders/sto/requests`,
    CREATE_MANIFEST_OR_GET_ALL_MANIFEST_ORDER: `${OMS_PREFIX}/manifests`,
    MANIFEST_OPERATIONS: `${OMS_PREFIX}/manifests/:id`, //used for complete manifest, draft manifest, handover manifest, add order in manifest, remove manifest order, cancel manifest
    GET_FORWARD_SHIPMENT_OR_UPDATE_SHIPMENT_STATUS: `${OMS_PREFIX}/orders/shipments`, //also used for mark orders as returned to hub
    CREATE_BULK_RETURN_ORDER: `${OMS_PREFIX}/returns/orders/bulk`,
    SYNC_PENDING_ORDER_WITH_SALES_CHANNEL: `${SALES_CHANNEL_PREFIX}/seller_sales_channels/:sellerSalesChannelId/orders/:sellerSalesChannelOrderId}`,
    OMS_HUBS: `${OMS_PREFIX}/hubs`, //used for add new virtual hub
    GET_OR_CREATE_ADDRESSES: `${OMS_PREFIX}/addresses`,
    GET_CUSTOMER_DETAILS_OR_CREATE_CUSTOMER: `${OMS_PREFIX}/sellers/:sellerId/customers`,
    GET_OR_EDIT_CUSTOMER_ADDRESS: `${OMS_PREFIX}/addresses/:addressId`,
    GET_INVOICE: `${OMS_PREFIX}/orders/:orderId/print_invoice`,
    GET_WMS_ORDER_DETAILS: `${WMS_PREFIX}/hubs/:hubId/order/details`,
    GET_SHIPMENT_ORDERS: `${OMS_PREFIX}/shipment_orders`,
    GET_SINGLE_SHIPMENT_ORDER: `${OMS_PREFIX}/shipment_orders/:orderId`,
    GET_SINGLE_ON_HOLD_ORDER: `${OMS_PREFIX}/orders/:orderId/pending_details`,
    CANCEL_SHIPMENT_ORDERS_IN_BULK: `${OMS_PREFIX}/shipment_orders/cancel`,
    GET_INVOICE_DATA: `${OMS_PREFIX}/orders/:orderId/print_invoice`,
    DELIVERY_ZONES: `${OMS_PREFIX}/hubs/:hubId/delivery_zones`,
    ALL_DELIVERY_ZONES: `${OMS_PREFIX}/delivery_zones`,
    SINGLE_DELIVERY_ZONE: `${OMS_PREFIX}/hubs/:hubId/delivery_zones/:deliveryZoneId`,
    GET_DELIVERY_ZONE_COORDINATES: `${OMS_PREFIX}/polygon/:latitude/:longitude`,
    DOWNLOAD_REPORT: `/api/${API_VERSION}/downloads/orders`,
    FORCEFUL_HUB_ROUTE: `${OMS_PREFIX}/hubs/:hubId/orders/:orderId/route`,
    INVOICE_SETTINGS: `${OMS_PREFIX}/invoice/settings/sellers/:id`,
    EDIT_ORDER: `${OMS_PREFIX}/orders/:orderId/edit`,
    REASSIGN_HUB: `${OMS_PREFIX}/orders/:orderId/reassign_hub`
  },
  RETURNS: {
    RETURN_ORDER_REQUEST: `${OMS_PREFIX}/returns/orders/request`, //also used of create return request
    GET_RETURN_ORDER_REQUEST_DETAILS: `${OMS_PREFIX}/returns/orders/request/:returnRequestId`,
    GET_RETURN_REQUEST_ORDER_DETAILS: `${OMS_PREFIX}/returns/orders/request/orders/:sellerOrderId`,
    APPROVE_RETURN_ORDER_REQUEST: `${OMS_PREFIX}/returns/orders/request/:returnRequestId/approve`,
    REJECT_RETURN_ORDER_REQUEST: `${OMS_PREFIX}/returns/orders/request/reject`,
    RETURN_ORDERS_OPERATIONS: `${OMS_PREFIX}/returns/orders`, //get return orders based on the order status
    CANCEL_RETURN_ORDER: `${OMS_PREFIX}/returns/orders/:orderId/cancel`,
    GET_RETURN_ORDER_DETAILS: `${OMS_PREFIX}/returns/orders/:orderId`,
    GET_REVERSE_SHIPMENT_DETAILS: `${OMS_PREFIX}/returns/orders/:orderId/shipments`,
    GET_RETURN_ORDER_TO_CREATE_RETURN_REQUEST: `${OMS_PREFIX}/returns/orders/request/orders/:sellerSalesChannelOrderId`,
    GET_RETURN_ORDER_BY_AWB_NUMBER: `${OMS_PREFIX}/returns/orders/awb`,
    COMPLETE_RETURN_ORDER_PROCESSING: `${OMS_PREFIX}/returns/orders/:orderId/qc`,
    CREATE_REVERSE_SHIPMENT: `${OMS_PREFIX}/returns/orders/:returnId/shipments`,
    COMPLETE_REFUND_PROCESS: `${OMS_PREFIX}/returns/orders/:returnOrderId/refund`,
    IMPORT_RETURN_ORDER_IN_BULK: `${OMS_PREFIX}/returns/orders/import`,
    GET_RETURN_ORDER_LOGS: `${OMS_PREFIX}/returns/orders/:orderId/logs`,
    RETURN_GATE_ENTRIES: `${WMS_PREFIX}/hubs/:hubId/returns/gate_entries`, //used for get and create return gate entry
    GET_RETURN_GATE_ENTRY_DETAILS: `${WMS_PREFIX}/hubs/:hubId/returns/gate_entries/:returnGateEntryId`,
    RETURN_GRNS: `${WMS_PREFIX}/hubs/:hubId/returns/grns`, //used for get and create return grns
    GET_RETURN_GRN_OVERVIEW: `${WMS_PREFIX}/hubs/:hubId/returns/grns/:grnId`,
    GET_RETURN_GRN_ORDER_DETAILS: `${WMS_PREFIX}/hubs/:hubId/returns/grns/:grnId/order/:orderId/details`,
    GET_RETURN_GRN_ITEM_PUTAWAY: `${WMS_PREFIX}/hubs/:hubId/grns/:grnId/item_put_away`,
    GET_RETURN_GRN_BUN_PUTAWAY: `${WMS_PREFIX}/hubs/:hubId/grns/:grnId/bin_put_away`,
  },
  HUBS: {
    GET_HUBS: `${WMS_PREFIX}/hubs`,
    CREATE_HUB: '/api/v2/wms/hubs',
    GET_OR_EDIT_SINGLE_HUB: `${WMS_PREFIX}/hubs/:hubId`,
    GET_STANDARD_LAYOUT_CONFIG: `${WMS_PREFIX}/hubs/layout/configurations`,
    CREATE_STANDARD_LAYOUT: `${WMS_PREFIX}/hubs/:hubId/hub_layout`,
    GET_HUB_LAYOUT_CONFIGURATION: `${WMS_PREFIX}/hubs/:hubId/configurations/hub_location_mapping`,
    GET_FLOORS_OR_CREATE_FLOOR: `${WMS_PREFIX}/hubs/:hubId/floors`,
    GET_AISLES_OR_CREATE_AISLE: `${WMS_PREFIX}/hubs/:hubId/aisles`,
    GET_RACKS_OR_CREATE_RACK: `${WMS_PREFIX}/hubs/:hubId/racks`,
    GET_SHELVES_OR_CREATE_SHELVE: `${WMS_PREFIX}/hubs/:hubId/shelves`,
    GET_LOCATIONS_OR_CREATE_LOCATION: `${WMS_PREFIX}/hubs/:hubId/locations`,
    EXPORT_LOCATION_BARCODE: `${WMS_PREFIX}/hubs/:hubId/locations/export_file`,
    GET_ZONE_COVERAGE: `${WMS_PREFIX}/hubs/:hubId/zones/zone_coverage`,
    GET_OR_CREATE_ZONES: `${WMS_PREFIX}/hubs/:hubId/zones`,
    GET_OR_CREATE_ZONES_LOCATIONS: `${WMS_PREFIX}/hubs/:hubId/zones/locations`,
    GET_UPDATE_DELETE_LOCATIONS_OF_ZONE: `${WMS_PREFIX}/hubs/:hubId/zones/:zoneId/locations`,
    EDIT_ZONES: `${WMS_PREFIX}/hubs/:hubId/zones/:zoneId`,
    GET_BINS_OR_CREATE_BULK_BINS: `${WMS_PREFIX}/hubs/:hubId/bins`,
    CREATE_BIN: `${WMS_PREFIX}/hubs/:hubId/bin`,
    GET_BIN_DETAILS: `${WMS_PREFIX}/hubs/:hubId/bins/:binId/items`,
    GET_OR_CREATE_CARTS: `${WMS_PREFIX}/hubs/:hubId/warehouse_carts`,
    GET_OR_CREATE_DELIVERY_ZONE: `${WMS_PREFIX}/hubs/:hubId/delivery`,
    GET_ASSOCIATED_BINS: `${WMS_PREFIX}/hubs/:hubId/warehouse_carts/details`,
    GET_OR_EDIT_SINGLE_FLOOR: `${WMS_PREFIX}/hubs/:hubId/floors/:floorId`,
    GET_OR_EDIT_SINGLE_AISLE: `${WMS_PREFIX}/hubs/:hubId/aisles/:aisleId`,
    GET_OR_EDIT_SINGLE_RACK: `${WMS_PREFIX}/hubs/:hubId/racks/:rackId`,
    GET_OR_EDIT_SINGLE_SHELF: `${WMS_PREFIX}/hubs/:hubId/shelves/:shelfId`,
    GET_OR_EDIT_SINGLE_LOCATION: `${WMS_PREFIX}/hubs/:hubId/locations/:locationId`,
    CREATE_CONFIGURATIONS: `${WMS_PREFIX}/hubs/:hubId/configurations`, //also used for get hub configuration,
  },
  CATALOG: {
    CREATE_ATTRIBUTE: `${PRODUCT_PREFIX_WITH_SELLER}/attributes/create`,
    CREATE_ATTRIBUTE_GROUP: `${PRODUCT_PREFIX_WITH_SELLER}/attributes/create/group`,
    CREATE_BULK_ATTRIBUTE_GROUP: `${PRODUCT_PREFIX_WITH_SELLER}/attributes/groups`,
    CREATE_SKU: `${PRODUCT_PREFIX_WITH_SELLER}/products/:bodyId/skus`,
    IMPORT_SKU_IN_BULK: `${PRODUCT_PREFIX_WITH_SELLER}/skus/import`,
    EXPORT_MASTER_SKU: `${PRODUCT_PREFIX_WITH_SELLER}/skus/export`,
    EXPORT_SALES_CHANNEL_SKU: `${PRODUCT_PREFIX_WITH_SELLER}/sales_channel_skus/export`,
    CREATE_PRODUCT: `${PRODUCT_PREFIX_WITH_SELLER}/products`,
    ADD_VARIATIONS: `${PRODUCT_PREFIX_WITH_SELLER}/attributes/:bodyAttributeId/variations`,
    CREATE_CATEGORY: `${PRODUCT_PREFIX_WITH_SELLER}/categories/create`,
    CREATE_BULK_CATEGORIES: `${PRODUCT_PREFIX_WITH_SELLER}/categories/create_bulk`,
    SKU_UPSERT: `${PRODUCT_PREFIX_WITH_SELLER}/skus/upsert`,
    GET_CATEGORY_TREE: `${PRODUCT_PREFIX_WITH_SELLER}/categories/tree`,
    GET_CATEGORY_LIST: `${PRODUCT_PREFIX_WITH_SELLER}/categories`,
    SKU_OPERATIONS: `${PRODUCT_PREFIX_WITH_SELLER}/skus`, //used for get sku master, get skus, get products, get affected barcode list
    SKU_STATUS: `${PRODUCT_PREFIX_WITH_SELLER}/skus/sku_status`,
    GET_PRODUCTS: `${PRODUCT_PREFIX_WITH_SELLER}/skus`,
    GET_MANUFACTURERS: `${PRODUCT_PREFIX_WITH_SELLER}/manufacturers`,
    GET_SINGLE_PRODUCT: `${PRODUCT_PREFIX_WITH_SELLER}/products/:productId`,
    GET_SINGLE_SKU: `${PRODUCT_PREFIX_WITH_SELLER}/skus/:skuId`,
    GET_SALES_CHANNEL_SKUS: `${PRODUCT_PREFIX_WITH_SELLER}/sales_channel_skus/:skuId`,
    GET_SELLER_SALES_CHANNEL_SKUS: `${PRODUCT_PREFIX_WITH_SELLER}/sales_channel_skus`, //also used for sku listing details, expandable row data for sku issues in catalog comparison
    GET_ATTRIBUTES: `${PRODUCT_PREFIX_WITH_SELLER}/attributes`, //also used for get attribute by attribute id
    GET_ATTRIBUTE_GROUPS: `${PRODUCT_PREFIX_WITH_SELLER}/attributes/groups`, //also used for get attribute group by category id, get group data, create bulk attribute group
    GET_SET_TABLE_DATA: `${PRODUCT_PREFIX_WITH_SELLER}/catalog/setTableData`,
    GET_VARIATION_BY_ATTRIBUTE_ID: `${PRODUCT_PREFIX_WITH_SELLER}/attributes/variations`, //also used for get variations by attribute ids
    GET_WEIGHTED_BARCODE_DETAILS: `${PRODUCT_PREFIX}/configurations/weighted_barcode/details`,
    GET_ALL_OR_CREATE_WEIGHTED_BARCODE: `${PRODUCT_PREFIX}/configurations`,
    UPDATE_WEIGHTED_BARCODE: `${PRODUCT_PREFIX}/configurations/:configId`,
    COMPARE_CATALOGS_OPERATIONS: `${PRODUCT_PREFIX_WITH_SELLER}/catalog/comparison`, //used for comparing catalogs, get sku issues data, to show already compared catalogs
    GET_MASTER_OR_SELLER_CATALOG_TOTAL_SKU_COUNT: `${PRODUCT_PREFIX_WITH_SELLER}/skus/count`,
    GET_PREVIOUS_COMPARISON_DATA: `${PRODUCT_PREFIX_WITH_SELLER}/catalog/comparison/stats`,
    EXPORT_SKU_ISSUES_SHEET: `${PRODUCT_PREFIX_WITH_SELLER}/catalog/comparison/export`,
    DELETE_LISTING_SKU: `${PRODUCT_PREFIX_WITH_SELLER}/catalog/comparison/delete_listing`,
    GET_POS_SKUS: `${PRODUCT_PREFIX}/sellers/:seller_id/pos/skus`,
  },
  INVENTORY: {
    GET_TOTAL_INVENTORY: `${WMS_PREFIX}/hubs/:hubId/inventory`,
    GET_LOCATION_INVENTORY: `${WMS_PREFIX}/hubs/:hubId/location_inventory/skus`,
    GET_SKU_TO_LOCATION: `${WMS_PREFIX}/locations/skus`,
    GET_OR_CREATE_GATE_ENTRY: `${WMS_PREFIX}/hubs/:hubId/gate_entries`,
    GET_SINGLE_GATE_ENTRY: `${WMS_PREFIX}/hubs/:hubId/gate_entries/:Id`,
    CREATE_GATE_ENTRY_FOR_PURCHASE_ORDER: `${WMS_PREFIX}/hubs/:hubId/gate_entries/purchase_order`,
    CREATE_NEW_GRN_FOR_GATE_ENTRIES: `${WMS_PREFIX}/hubs/:hubId/grns/gate_entries`,
    CREATE_NEW_GRN_FOR_GATE_ENTRY: `${WMS_PREFIX}/hubs/:hubId/grns/gate_entry`,
    CREATE_NEW_GRN_FOR_PURCHASE_ORDER: `${WMS_PREFIX}/hubs/:hubId/grns/purchase_order`,
    CREATE_NEW_GRN_FOR_STO: `${WMS_PREFIX}/hubs/:hubId/grns/stock_transfer_order`,
    CREATE_NEW_GRN_FOR_SELLER_AND_SUPPLIER: `${WMS_PREFIX}/hubs/:hubId/sellers/:ellerId/grns`,
    GET_GRN_SKU_DETAILS: `${WMS_PREFIX}/hubs/:hubId/grns/sku_details`,
    GENERATE_CARTONS_BARCODE: `${WMS_PREFIX}/hubs/:hubId/gate_entries/cartons`,
    SCAN_PASS_OR_FAIL_BIN_GRN: `${WMS_PREFIX}/hubs/:hubId/grn/bin/scan`,
    GENERATE_BARCODE: `${WMS_PREFIX}/hubs/:hubId/grns/sku/barcode`,
    CREATE_BATCH: `${WMS_PREFIX}/hubs/:hubId/grn/:grnId/batch`,
    ADD_SKU_IN_GRN_BIN: `${WMS_PREFIX}/hubs/:hubId/grn/bin/sku`,
    GENERATE_PASS_OR_FAIL_BIN_ID: `${WMS_PREFIX}/hubs/:hubId/grn/bin`,
    GET_GRNS: `${WMS_PREFIX}/hubs/:hubId/grns`,
    GET_BIN_PUTAWAY: `${WMS_PREFIX}/hubs/:hubId/bin_put_away`,
    GET_ITEM_PUTAWAY: `${WMS_PREFIX}/hubs/:hubId/item_put_away`,
    GET_PUTAWAY_ITEMS: `${WMS_PREFIX}/hubs/:hubId/put_away_items`,
    GET_BIN_STATUS: `${WMS_PREFIX}/hubs/:hubId/bins`,
    CREATE_HUB_SKU_SERIALISATION_STATUS: `${WMS_PREFIX}/hubs/:hubId/grns/serialisation`,
    GET_STO_LIST: `${WMS_PREFIX}/hubs/:hubId/stock_transfer_order`,
    GET_BATCHES: `${WMS_PREFIX}/batches`,
    GET_GRN_ITEMS_OVERVIEW_DETAILS: `${WMS_PREFIX}/hubs/:hubId/grns/:grnId/items`,
    GET_GRN_ITEM_PUTAWAY_DETAILS: `${WMS_PREFIX}/hubs/:hubId/grns/:grnId/item_put_away`,
    GET_GRN_BIN_PUTAWAY_DETAILS: `${WMS_PREFIX}/hubs/:hubId/grns/:grnId/bin_put_away`,
    HUB_WISE_INVENTORY_DETAILS: `${WMS_PREFIX}/hubs/inventory/:inventoryId`,
    BULK_SKU_UPLOAD_TOTAL_INVENTORY: `${WMS_PREFIX}/hubs/:hubId/sellers/:sellerId/inventory`,
    UPLOAD_SKU_TO_LOCATION_MAPPING: `${WMS_PREFIX}/hubs/:hubId/sellers/:sellerId/location/skus/upload`,
    GET_GRN_PENDING_PUTAWAY: `${WMS_PREFIX}/hubs/:hubId/grns/pending/putaways`,
    GET_GRN_PENDING_PUTAWAY_TOTAL_SKUS: `${WMS_PREFIX}/hubs/:hubId/grns/:grnId/bins/:binId/items`,
    GET_CANCELLED_ITEM_PUTAWAY: `${WMS_PREFIX}/:hubId/orders/put_away/pending`,
    GET_UNMAPPED_SKUS: `${WMS_PREFIX}/hubs/:hubId/locations/skus/unmapped`,
    GET_CANCEL_PUTAWAY_ORDERS: `${WMS_PREFIX}/:hubId/orders/put_away/pending/skus/:sellerSkuId`,
    GET_BATCHES_DETAILS: `${WMS_PREFIX}/batches/details`,
    IMPORT_BULK_BATCHES: `${WMS_PREFIX}/sellers/:sellerId/batches/upload`,
    GET_RETURN_GRN_DETAILS: `${WMS_PREFIX}/hubs/:hubId/returns/grns/sku_details`,
    SET_QUALITY_CHECK_TYPE: `${WMS_PREFIX}/hubs/:hubId/returns/grns/order/details`,
    GET_AWB_DETAILS: `${WMS_PREFIX}/hubs/:hubId/gate_entries/awb/:awbNumber`,
    CHANGE_SKU_WEIGHT: `${PRODUCT_PREFIX_WITH_SELLER}/skus/:sellerSkuId`,
    GET_CYCLE_COUNT_LOCATIONS: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/items`,
    CYCLE_COUNT_UPDATE_INVENTORY_LOCATIONS: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/update_inventory`,
    DELETE_CYCLE_COUNT_LOCATIONS: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/locations/delete`,
    GET_ALL_OR_CREATE_CYCLE_COUNT: `${WMS_PREFIX}/hubs/:hubId/cycle_counts`,
    EDIT_CYCLE_COUNT: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/edit`,
    ADD_LOCATION_CYCLE_COUNT: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/locations/add`,
    GET_LOCATION_ITEM_DETAILS: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/locations/:locationId/items`,
    GET_SINGLE_BIN_SKUS: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/locations/:locationId/bins/:binId/items`,
    MARK_LOCATIONS_FOR_RECOUNT_OR_REJECT_INVENTORY_LOCATIONS: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/status`,
    GET_LOCATION_COUNT_FOR_PREFIXES: `${WMS_PREFIX}/hubs/:hubId/locations/prefix`,
    BULK_UPLOAD_SERIALISE_BARCODE: `${WMS_PREFIX}/hubs/:hubId/grns/:grnId/bulk/serialised`,
    GET_ASSEMBLED_TASKS: `${WMS_PREFIX}/hubs/:hubId/assembly/tasks`,
    GET_ASSEMBLED_TASK_DETAIL: `${WMS_PREFIX}/hubs/:hubId/assembly/tasks/items`,
    GET_ADJUST_INVENTORY_LIST: `${WMS_PREFIX}/hubs/:hubId/adjust_inventory/pending`,
    GET_ADJUST_INVENTORY_SKU_LIST: `${WMS_PREFIX}/hubs/:hubId/adjust_inventory/bins/:binId/items`,
    UPDATE__INVENTORY_QTY: `${WMS_PREFIX}/hubs/:hubId/sku/inventory`,
    DEMAND_FORCASTING: `${ANALYTICS_PREFIX}/forecasts/hubs/:hub_id/sellers/:seller_id/generate`,
    CREATE_INV_OFF_CYCLE_COUNT: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/create`,
    GET_INV_OFF_CYCLE_COUNT_DETAILS: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/items/details`,
    CYCLE_COUNT_COUNT_SKUS: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/counts/skus`,
    UPDATE_CYCLE_COUNT_STATUS: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/update/status`,
    UPDATE_CYCLE_COUNT_ITEMS_STATUS: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/skus/update/status`,
    MARK_CYCLE_COUNT_AS_COMPLETE: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/mark/complete`,
    CYCLE_COUNT_CANCEL: `${WMS_PREFIX}/hubs/:hubId/cycle_counts/:cycleCountId/cancel`,
    MARK_FOR_PUTAWAY: `${WMS_PREFIX}/hubs/:hubId/put_away`,
    STOCK_TRANSFER: `${WMS_PREFIX}/hubs/:hubId/stock_transfer_order`,
    STOCK_TRANSFER_DETAILS: `${WMS_PREFIX}/hubs/:hubId/stock_transfer_order/:stoId`,
    STOCK_TRANSFER_LOG: `${WMS_PREFIX}/hubs/:hubId/stock_transfer_order/:stoId/logs`,
    POST_BULK_PO: `${WMS_PREFIX}/purchase_orders/bulk`
  },
  PURCHASE_ORDER: {
    GET_PURCHASE_ORDERS: `${WMS_PREFIX}/hubs/:hubId/purchase_order`, //also used to create purchase order
    GET_SINGLE_PURCHASE_ORDER: `${WMS_PREFIX}/hubs/:hubId/purchase_order/:sellerId`,
    GET_SINGLE_GATE_ENTRY_DETAILS: `${WMS_PREFIX}/hubs/:hubId/purchase_order/:sellerId/gate_entry`,
    GET_SINGLE_GRN_LIST: `${WMS_PREFIX}/hubs/:hubId/purchase_order/:sellerId/grn`,
    GET_SINGLE_PUTAWAY_LIST: `${WMS_PREFIX}/hubs/:hubId/purchase_order/:sellerId/put_away`,
    GET_GRN_REASONS: `${WMS_PREFIX}/hubs/:hubId/purchase_order/:sellerId/grn/sku`,
    CREATE_BULK_PURCHASE_ORDER: `${WMS_PREFIX}/hubs/:hubId/purchase_order/bulk`,
    CANCEL_PO_SHIPMENT: `${WMS_PREFIX}/hubs/:hubId/purchase_orders/:purchaseOrderId/shipments`,
  },
  FLEET: {
    GET_NATIONALITIES: `${TENANT_PREFIX}/nationalities`, //also used for get drivers filter options
    GET_HUB_USERS: `${TENANT_PREFIX}/hubs/:hubId/users`,
    GET_DRIVERS_OR_CREATE_NEW_DRIVER: `${FLEET_PREFIX}/drivers`,
    DRIVER_OPERATIONS: `${FLEET_PREFIX}/drivers/:driverId`, //used for get and edit driver details, delete driver
    GET_UNASSIGNED_SHIPMENT_ORDERS: `${FLEET_PREFIX}/drivers/unassigned_shipment_orders`,
    GET_NEARBY_DRIVERS: `${FLEET_PREFIX}/drivers/hubs/:hubId/nearby_drivers`,
    GET_ETA_INFO: `${FLEET_PREFIX}/drivers/hubs/:hubId/eta`,
    TRIP_OPERATIONS: `${FLEET_PREFIX}/drivers/trips`, //used for get all trips, create trip, get {pending,requested,rejected,accepted,ongoing,completed} trips
    RE_ASSIGN_TRIP: `${FLEET_PREFIX}/drivers/trips/:tripId/assign_driver`,
    GET_OR_CANCEL_TRIP: `${FLEET_PREFIX}/drivers/trips/:tripId`,
    DRIVER_CONFIGURATION: `${FLEET_PREFIX}/drivers/configurations`, //used for get or save driver configuration
  },
  REPORTS: {
    GET_REPORTS_CATEGORIES: `${ANALYTICS_PREFIX}/reports`,
    GET_REPORT_PREVIEW: `${ANALYTICS_PREFIX}/reports/:reportCategory/:report`,
    DOWNLOAD_REPORTS: `${ANALYTICS_PREFIX}/reports/:reportCategory/:report/download`,
    RETRY_DOWNLOAD_REPORTS: `${ANALYTICS_PREFIX}/reports/:reportId/retry`,
    USER_REPORTS_OPERATIONS: `${ANALYTICS_PREFIX}/reports/users/downloads`, //used for get user reports and get first four user reports
  },
  SETTINGS: {
    GET_ROLES_OR_CREATE_ROLE: `${TENANT_PREFIX}/roles`,
    GET_SINGLE_USER_OR_DELETE_USER: `${TENANT_PREFIX}/rss/:userId`,
    GET_USERS: `${TENANT_PREFIX}/hubs/:hubId/users`,
    CREATE_USER: `${TENANT_PREFIX}/users`, //also used for update user information
    UPDATE_USER: `${TENANT_PREFIX}/users/:userId`,
    GET_SINGLE_USER: `${TENANT_PREFIX}/users/:userId`,
    RESET_USER_PASSWORD: `${USER_PREFIX}/:userId/set_password`,
    GET_PERMISSIONS: `${TENANT_PREFIX}/permissions`,
    ROLE_OPERATIONS: `${TENANT_PREFIX}/roles/:roleId`, //used for get, update and delete role
    INVOICE_OPERATIONS: `${OMS_PREFIX}/invoice/settings/sellers/:sellerId`, //used for get, create and update invoice settings
    CHANGE_PASSWORD: `${USER_PREFIX}/change_password`,
    GET_OR_CREATE_ORDER_SETTINGS: `${OMS_PREFIX}/configurations`,
    GET_ORDER_TAGS_CONDITION: `${OMS_PREFIX}/orders/tags/conditions`,
    GET_ALL_OR_CREATE_ORDER_TAGS: `${OMS_PREFIX}/orders/tags`,
    ORDER_TAGS_OPERATIONS: `${OMS_PREFIX}/orders/tags/:tagId`, //used for {get single, update and delete} order tags, change order tags tags satatus
    GET_ELIGIBILITY_CONDITIONS: `${SHIPPING_PREFIX}/rules/conditions`,
    GET_OR_CREATE_ELIGIBILITY_RULES: `${SHIPPING_PREFIX}/rules`,
    GET_SINGLE_OR_CHANGE_ELIGIBILITY_RULES: `${SHIPPING_PREFIX}/rules/:ruleId`,
  },
  SHIPPING: {
    GET_ALL_OR_INTEGRATE_SHIPPING_CHANNEL: `${SHIPPING_PREFIX}/partner_shipping_methods`,
    EDIT_INTEGRATED_SHIPPING_CHANNEL: `${SHIPPING_PREFIX}/partner_shipping_methods/:integrationId`,
    GET_SHIPPING_PARTNER_DETAILS: `${SHIPPING_PREFIX}/shipping_partners/:shippingPartnerId`,
    GET_NEARBY_POINTS: `${SHIPPING_PREFIX}/delivery_details`,
  },
  TAX_AUTHORITIES: {
    EDIT_TAX_AUTHORITIES: `${TAX_AUTHORITIES_PREFIX}/:integrationId`,
  },
  SELLER: {
    SUPPLIER_OPERATIONS: `${WMS_PREFIX}/suppliers/:supplierId`,
    GET_SINGLE_OR_UPDATE_SELLER: `${TENANT_PREFIX}/sellers/:sellerId`,
  },
  SALES_CHANNEL: {
    GET_SALES_CHANNEL: `${SALES_CHANNEL_PREFIX}/:sellerId`,
    GET_SELLER_SALES_CHANNELS: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/sales_channels`,
    GET_SELLER_SALES_CHANNEL_DETAILS: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/sales_channels/:storeId`,
    UPDATE_SYNC_STATUS: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/sales_channels/:storeId/configurations`,
    SYNC_ORDERS_OR_PRODUCTS: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/sales_channels/:storeId/sync_products`,
    UPDATE_HUBS_MAPPING: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/sales_channels/:sellerSalesChannelId/hubs`,
    UPDATE_ORDER_STATUS_MAPPING: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/sales_channels/:sellerSalesChannelId/order_status`,
    UPDATE_ORDER_TAGS: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/sales_channels/:sellerSalesChannelId/order_tags`,
    SALES_CHANNEL_MAPPING: `${SHIPPING_PREFIX}/sales_channel_mappings`, //also used for add seller sales channel shipping partner
    REMOVE_SELLER_SALES_CHANNEL_SHIPPING_PARTNER: `${SHIPPING_PREFIX}/sales_channel_mappings/:salesChannelMappingId`,
    ZID_ACTIVATION: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/zid/activate`,
    ZID_EMAIL: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/zid/email`,
    SALLA_ACTIVATION: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/salla/activate`,
    SALLA_EMAIL: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/salla/email`,
    GET_SALES_CHANNEL_HUB_LOCATIONS: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/sales_channels/:storeId/locations`,
    GET_ALL_MAGENTO_STORES: `${SALES_CHANNEL_PREFIX}/sellers/magento/stores/:sellerId`,
    CHANGE_STORE_NAME: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/sales_channels/:storeId`,
    GENERATE_EXTERNAL_WMS_CREDENTIALS: `${SALES_CHANNEL_PREFIX}/external_wms/generate_creds`,
    GET_EXTERNAL_WMS_DETAILS: `${SALES_CHANNEL_PREFIX}/external_wms/details`,
    GET_ALL_EXTERNAL_WMS: `${SALES_CHANNEL_PREFIX}/external_wms`,
    UPDATE_EXTERNAL_WMS_HUB_TYPE: `${SALES_CHANNEL_PREFIX}/external_wms/hubs/:hub_id`,
    UPDATE_EXTERNAL_WMS_CONFIGURATION: `${SALES_CHANNEL_PREFIX}/external_wms/configurations`,
    GET_EXTERNAL_WMS_ACCESS_TOKEN: `${SALES_CHANNEL_PREFIX}/external_wms/access_token`,
    GET_CUSTOM_APP_REPORT_DETAILS: `${SALES_CHANNEL_PREFIX}/reports/details`,
    GET_CUSTOM_APP_ALL_REPORTS: `${SALES_CHANNEL_PREFIX}/reports`,
    GENERATE_CUSTOM_APP_REPORT_CREDENTIALS: `${SALES_CHANNEL_PREFIX}/reports/login`,
    GENERATE_CUSTOM_APP_REPORT_ACCESS_TOKEN: `${SALES_CHANNEL_PREFIX}/reports/token`,
    UPDATE_CUSTOM_APP_REPORTS_CONFIGURATION: `${SALES_CHANNEL_PREFIX}/reports/configurations`,
    GET_SSC_ALL_FAILED_EVENT_DETAILS: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/sales_channels/:storeId/failed_events`,
    GET_SSC_ALL_FAILED_EVENT_COUNTS: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/sales_channels/:storeId/failed_events/status_counts`,
    EXTERNAL_WMS_SELLER_MAPPINGS: `${SALES_CHANNEL_PREFIX}/external_wms/seller_mappings`,
    TENANT_VERIFY: `${SALES_CHANNEL_PREFIX}/external_wms/tenant/verify`,
    EXTERNAL_WMS_HUB_MAPPINGS: `${SALES_CHANNEL_PREFIX}/external_wms/hub_mappings`,
    GET_ALL_INDUSTRY: `${SALES_CHANNEL_PREFIX}/sellers/:sellerId/sales_channels/:storeId/sc_industries`,
  },
  ANALYTICS: {
    GET_MATRICES: `${ANALYTICS_PREFIX}/dashboard/matrices`,
    GET_MATRICES_DETAIL: `${ANALYTICS_PREFIX}/dashboard/:matrix`,
    GET_SELLER_MATRICES_DETAIL: `${ANALYTICS_PREFIX}/dashboard/sellers/:sellerId/:matrix`,
    GET_HUBS_MATRICES_DETAIL: `${ANALYTICS_PREFIX}/dashboard/hubs/:hubId/:matrix`,
    GET_SHIPPING_MATRICES_DETAIL: `${ANALYTICS_PREFIX}/dashboard/shipping/:matrix`,
    EXPORT_HOME_MATRIX: `${ANALYTICS_PREFIX}/dashboard/:matrix/download`,
    EXPORT_SELLER_MATRIX: `${ANALYTICS_PREFIX}/dashboard/sellers/:sellerId/:matrix/download`,
    EXPORT_HUBS_MATRIX: `${ANALYTICS_PREFIX}/dashboard/hubs/:hubId/:matrix/download`,
    EXPORT_SHIPPING_MATRIX: `${ANALYTICS_PREFIX}/dashboard/shipping/:matrix/download`,
  },
  BILLINGS: {
    GET_BILLING_PROFILE_METRICS: `${BILLING_PREFIX}/metric/?type=fulfilment_centre`,
    GET_BILLING_PROFILE: `${BILLING_PREFIX}/billing_profiles/`,
    GET_SINGLE_BILLING_PROFILE: `${BILLING_PREFIX}/billing_profiles/billing_profile?billing_profile_name=:name`,
    GET_DRAFT_BILLING_PROFILE: `${BILLING_PREFIX}/billing_profiles/?is_draft=true`,
    GET_AD_HOC_ACTIVITIES: `${BILLING_PREFIX}/adhoc`,
    GET_AD_HOC_ACTIVITY: `${BILLING_PREFIX}/adhoc/:id`,
    CREATE_AD_HOC_ACTIVITY: `${BILLING_PREFIX}/adhoc`,
    UPDATE_AD_HOC_ACTIVITY: `${BILLING_PREFIX}/adhoc/:id`,
    CREATE_AD_HOC_USAGE: `${WMS_PREFIX}/adhoc_usages`,
    GET_AD_HOC_USAGES: `${WMS_PREFIX}/adhoc_usages`,
    GET_CONTRACTS: `${BILLING_PREFIX}/contracts`,
    CREATE_CONTRACT: `${BILLING_PREFIX}/contracts`,
    GET_CONTRACT: `${BILLING_PREFIX}/contracts/:contract_id`,
    TERMINATE_CONTRACT: `${BILLING_PREFIX}/contracts/:contract_id`,
    GET_BILLS: `${BILLING_PREFIX}/bills`,
    FINALISE_BILL: `${BILLING_PREFIX}/bills/:bill_id`,
    GET_HUB_BILL: `${BILLING_PREFIX}/bills/hubs/:bill_id`,
    MARK_BILL_AS_PAID: `${BILLING_PREFIX}/bills/:bill_id`,
    GET_BILL: `${BILLING_PREFIX}/bills/:bill_id`,
    SEND_VIA_EMAIL: `${BILLING_PREFIX}/bills/:bill_id/email`,
    VALIDATE_BILLING_PROFILE_NAME: `${BILLING_PREFIX}/billing_profiles/validate`,
    GENERATE_BILL: `${BILLING_PREFIX}/bills/generate`,
  },
  BULK_SHIP: {
    FETCH_SUGGESTED_CLUSTERS: `${WMS_PREFIX}/hubs/:hubId/order/bulk`,
    FETCH_DRAFT_CLUSTERS: `${WMS_PREFIX}/hubs/:hubId/clusters/save`,
    FETCH_CLUSTERS: `${WMS_PREFIX}/hubs/:hubId/clusters/waves`,
    SAVE_CLUSTER: `${WMS_PREFIX}/hubs/:hubId/clusters/save`,
    REMOVE_SAVED_CLUSTER: `${WMS_PREFIX}/hubs/:hubId/clusters/:clusterId`,
    GET_CLUSTER_PICKING_WAVE: `${WMS_PREFIX}/hubs/:hubId/clusters/waves/:waveId`,
    GET_CLUSTER_PICKING_WAVE_WITH_IDENTIFICATION: `${WMS_PREFIX}/hubs/:hubId/clusters/wave`,
    GENERATE_CLUSTER_PICKING_WAVE: `${WMS_PREFIX}/hubs/:hubId/picking/wave`,
    SAVE_PACKAGING_DETAILS: `${WMS_PREFIX}/hubs/:hubId/waves/:waveId/packing/details`,
    FETCH_WAVE_SHIPMENT_DATA: `${OMS_PREFIX}/orders/bulk_ship`,
  },
  POS: {
    REGISTERS: `${POS_PREFIX}/registers`,
    OPEN_REGISTER: `${POS_PREFIX}/registers/:id/open`,
    CLOSE_REGISTER: `${POS_PREFIX}/registers/:id/close`,
    CREATE_CART: `${POS_PREFIX}/hubs/:hub_id/registers/:register_id/carts`,
    ADD_ITEM_TO_CART: `${POS_PREFIX}/hubs/:hub_id/registers/:register_id/carts/:cart_id/items`,
    DELETE_CART_ITEM: `${POS_PREFIX}/hubs/:hub_id/registers/:register_id/carts/:cart_id/items/:item_id`,
    UPDATE_CART_STATUS: `${POS_PREFIX}/hubs/:hub_id/registers/:register_id/carts/:cart_id`,
    ADD_CUSTOMER_TO_CART: `${POS_PREFIX}/hubs/:hub_id/registers/:register_id/carts/:cart_id/customer`,
    APPLY_DISCOUNT: `${POS_PREFIX}/hubs/:hub_id/registers/:register_id/carts/:cart_id/discounts`,
    REMOVE_DISCOUNT: `${POS_PREFIX}/hubs/:hub_id/registers/:register_id/carts/:cart_id/discounts/remove`,
    GET_PAUSED_SALES: `${POS_PREFIX}/hubs/:hub_id/registers/:register_id/carts`,
    TRANSACT_AMOUNT: `${POS_PREFIX}/hubs/:hub_id/registers/:register_id/carts/transactions`,
    COMPLETE_PAYMENT: `${POS_PREFIX}/hubs/:hub_id/registers/:register_id/carts/:cart_id/payment`,
    CONTINUE_SALE: `${POS_PREFIX}/hubs/:hub_id/registers/:register_id/carts/:cart_id/continue`,
    CONTINUE_REGISTER: `${POS_PREFIX}/registers/:register_id/continue`,
  },
  PACKAGING_AUTOMATION: {
    FETCH_PACKAGING_AUTOMATION_RULES: `${WMS_PREFIX}/hubs/:hubId/packing/tags`,
    FETCH_PACKAGING_AUTOMATION_RULE: `${WMS_PREFIX}/hubs/:hubId/packing/tags/:id`,
    CREATE_PACKAGING_AUTOMATION_RULE: `${WMS_PREFIX}/hubs/:hubId/packing/tags`,
    EDIT_PACKAGING_AUTOMATION_RULE: `${WMS_PREFIX}/hubs/:hubId/packing/tags/:id`,
    FETCH_CONDITIONS: `${WMS_PREFIX}/hubs/:hubId/packing/tags/conditions`,
    EDIT_PRIORITY_RULES: `${WMS_PREFIX}/hubs/:hubId/packing/tags/edit/precedence`
  },
  AUTOMATION: {
    HUB_ROUTING: {
      FETCH_RULES: `${OMS_PREFIX}/sellers/:sellerId/hubs/routing/rules`,
      FETCH_RULE_DETAIL: `${OMS_PREFIX}/sellers/:sellerId/hubs/routing/rules/:ruleId`,
      CREATE_RULE: `${OMS_PREFIX}/sellers/:sellerId/hubs/routing/rules`,
      EDIT_RULE: `${OMS_PREFIX}/sellers/:sellerId/hubs/routing/rules/:ruleId`,
      EDIT_PRIORITY: `${OMS_PREFIX}/sellers/:sellerId/hubs/routing/rules/edit/precedence`,
      FETCH_CONDITIONS: `${OMS_PREFIX}/sellers/:sellerId/hubs/routing/conditions`,
    }
  }
}
// m/api/v1/wms/adhoc_usages

//we will use api's like this
// "/api/v1/sales_channels/sellers/:sellerId/sales_channels/:storeId"
//getApiUrl :-> present in Utils.js
// getApiUrl(API_ENDPOINTS.SALES_CHANNEL.CHANGE_STORE_NAME, {
//     sellerId: 123,
//     storeId: 456,
// })

//https://example.com/api/v1/sales_channels/sellers/123/sales_channels/456

export const getApiUrl = (url, params) => {
  for (const key in params) {
    url = url.replace(`:${key}`, params[key])
  }
  return url
}

export const GET_SELLERS = async (search = '', loadedOptions, { page = 1, is_active = true }) => {
  const params = search ? { search_column: 'name', search_query: search } : { page }
  try {
    const res = await axiosInstance.get(API_ENDPOINTS.COMMON.GET_ALL_SELLERS, { params: { ...params, sort: 'name_asc', is_active } })
    const arr = res?.data?.data
    return {
      options: arr.map((seller) => ({
        value: seller.id,
        label: seller.name,
      })),
      hasMore: res?.data?.meta?.current_page < res?.data?.meta?.last_page,
      additional: {
        page: res?.data?.meta?.current_page + 1,
      },
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
      },
    }
  }
}
