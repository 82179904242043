import { ExpandableCustomTable } from '@src/@core/components/globalTable/ExpandableCustomTable'
import SKUDetailsCol from '@src/@core/components/sku-details'
import SkuCard from '@src/views/catalog/components/skuCard'
import ExpandableChildTable from '@src/views/sales/components/ExpandableChildTable/ExpandableChildTable'
import { SKU_TYPES } from '@src/views/sales/constant/orders.constants'
import { useState } from 'react'
import { Copy } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { UncontrolledTooltip } from 'reactstrap'

const ReturnOrderTable = ({ tableData }) => {

  const [isCopied, setIsCopied] = useState(false)

  const { t } = useTranslation()

  const handleCopy = (text, copy) => {
    navigator.clipboard.writeText(text)
    setIsCopied(copy)
    setTimeout(() => setIsCopied(false), 300)
  }

  const TableColumns = [
    {
      id: 1,
      name: t('SKU Details'),
      minWidth: '60%',
      maxWidth: '150px',
      reorder: true,
      cell: (row) => {
        return (
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: row.id,
              image: {
                src: row.seller_sku.images?.[0]?.default,
                alt: row.seller_sku.name
              },
              details: {
                name: row.seller_sku.name,
                skuCode: row.seller_sku_code,
                columnKey: 'sku_details'
              },
              customTag: {
                isCustomTagPresent: row.seller_sku.type === SKU_TYPES.BUNDLE.key,
                title: 'Kit',
                className: 'bg-info-light text-info'
              }
            }}
          /> 
        )
      } 
      // <SkuCard url={row.seller_sku.images?.[0]?.default} skuName={row.seller_sku.name} skuTag={row.seller_sku.type === SKU_TYPES.BUNDLE.key ? <div className="py-2px px-10px rounded-10px align-self-center bg-info-light text-info">{t('Kit')}</div> : null}/>
      // )
    },
    {
      id: 2,
      name: <div className="w-100 text-end text-truncate" title={t('Qty')}>{t('Qty')}</div>,
      minWidth: '10%',
      reorder: true,
      cell: (row) => <div className="w-100 text-end">{row.return_quantity}</div>
    }
  ]

  const childTableColumns = [
    {
      id: 1,
      name: t('SKU Details'),
      minWidth: '50%',
      cell: (row) => {
        return (
          <SkuCard url={row.images?.[0]?.default} skuName={row.name} toolTipID={row.id}/>
        )
      }
    },
    {
      id: 2,
      name: 'SKU Code',
      minWidth: '20%',
      maxWidth: '100px',
      reorder: true,
      cell: (row) => (
        <div className="sku-code-container d-flex align-items-center gap-1">
          <div>{row.seller_sku_code}</div>
          <div
            className="copy-sku-code text-primary cursor-pointer"
            id="seller_sku_code"
            onClick={() => handleCopy(row.seller_sku_code, true)}
          >
            <Copy size={16} />
          </div>
          <UncontrolledTooltip placement="top" target="seller_sku_code">
            {isCopied ? t('Copied') : t('Copy')}
          </UncontrolledTooltip>
        </div>
      )
    },
    {
      id: 4,
      name: <div className="w-100 text-end">{t('Qty Per Kit')}</div>,
      minWidth: '20%',
      cell: (row) => <div className="w-100 text-end">{row.quantity || 0}</div>
    },
    {
      id: 5,
      name: <div className="w-100 text-end">{t('Qty')}</div>,
      minWidth: '10%',
      cell: (row) => <div className="w-100 text-end">{row.return_quantity || 0}</div>
    }
  ]

  return (
    <div className="align-self-start w-100">
      {/* <CustomTable
        data={tableData || []}
        columns={TableColumns}
        useReactPaginate={false}
        showColumnsTableHeader={false}
        showPagination={false}
      /> */}
      <ExpandableCustomTable
        loading={false}
        error={false}
        success={true}
        data={tableData || []}
        showPagination={false}
        columns={TableColumns}
        useReactPaginate={false}
        showColumnsTableHeader={false}
        expandableRows
        expandableRowsHideExpander
        expandableRowsComponent={ExpandableChildTable}
        expandableRowsComponentProps={{childTableColumns }}
        expandableRowExpanded={(row) => row.seller_sku?.type === SKU_TYPES.BUNDLE.key}
      />
    </div>
  )
}

export default ReturnOrderTable
