// ** Reactstrap Imports
import Button from '@src/@core/components/button'
import { useState } from 'react'
import { Info } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  Modal,
  ModalBody
} from 'reactstrap'
import { cancelShipment } from '../store/store'

const CancelShipmentPopUp = (prop) => {

  const { shipmentOrderID, deleteModalOpen, setDeleteModalOpen, shipping_partner_tag, awb_number } = prop
  const [cancelReason, setCancelReason] = useState('User Cancel')
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const handleReason = (e) => {
    setCancelReason(e.target.value)
  }
  const handleShipment = () => {
    dispatch(cancelShipment({ reason: cancelReason, shipping_partner_tag, awb_number}))
    setDeleteModalOpen(false)
  }

  return (
    <Modal
      isOpen={deleteModalOpen}
      style={{ height: '300px', width: '500px', marginTop: '200px' }}
      onClosed={() => setCancelReason('User Cancel')}
    >
      <ModalBody>
        <div className="d-flex justify-content-center">
          <Info style={{ strokeWidth: 1.5 }} className="text-warning" size={70} />
        </div>
        <div className="mt-1 d-flex flex-column align-items-start mt-2">
          <h4 className="mb-1"> {t('Are you sure you want to cancel this shipment')}</h4>
          {shipmentOrderID && <div>
            <h5> {t('Shipment Order ID')}: {shipmentOrderID}</h5>
          </div>}
          <div className="form-floating w-100 mt-1">
            <input
              className={'form-control w-100'}
              id="reason"
              style={{height:'60px'}}
              onChange={handleReason}
              value={cancelReason}
            />
            <label
              htmlFor="reason">
              <span className="textRed"></span>
            </label>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center gap-2 mt-2 pb-1">
          <Button className="w-50 flex-center-center" onClick={() => setDeleteModalOpen(prev => !prev)}  ofStyle='outlined'>
            {t('No')}
          </Button>
          <Button className="w-50 flex-center-center" onClick={handleShipment}>
            {t('Yes')}
          </Button>
        </div>
      </ModalBody>
    </Modal >
  )
}

export default CancelShipmentPopUp
