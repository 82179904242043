import { Info } from 'react-feather'
import { useTranslation } from 'react-i18next'

const QunatityEditAbleInfo = () => {
  const { t } = useTranslation()
  return (
    <div className='mb-24px mx-0'>
      <div className='text-dark p-8px txt-sub-rg flex-center-start rounded-4px bg-primary-lighter-global'>
        <div className='flex-center-start'>
          <div className='flex-center-start gap-50'>
            <span className='flex-center-center'><Info size={14} /></span>
            <span>{t('The order has been synchronised and item quantities have been updated accordingly')}.</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QunatityEditAbleInfo