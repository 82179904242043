import InputField from '@src/@core/components/input-field'
import { useRTL } from '@src/utility/hooks/useRTL'
import classNames from 'classnames'
import 'flatpickr/dist/themes/material_blue.css'
import { X } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { Controller } from 'react-hook-form'
import { InputGroup, InputGroupText } from 'reactstrap'
import './datePicker.scss'

const IsRequiredIcon = (
  <svg width="5" height="5" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.98852 3.564C1.70052 3.564 1.43652 3.496 1.19652 3.36C0.964516 3.216 0.776516 3.028 0.632516 2.796C0.496516 2.556 0.428516 2.292 0.428516 2.004C0.428516 1.564 0.580516 1.192 0.884516 0.888C1.18852 0.584 1.55652 0.432 1.98852 0.432C2.43652 0.432 2.81252 0.584 3.11652 0.888C3.42052 1.192 3.57252 1.564 3.57252 2.004C3.57252 2.444 3.42052 2.816 3.11652 3.12C2.81252 3.416 2.43652 3.564 1.98852 3.564Z" fill="#C21808" />
  </svg>
)

const ErrorIcon = (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.333496 13.3333L7.66683 0.666626L15.0002 13.3333H0.333496ZM7.73641 11.3833C7.87891 11.3833 7.99739 11.3351 8.09183 11.2387C8.18627 11.1423 8.2335 11.0229 8.2335 10.8804C8.2335 10.7379 8.1853 10.6194 8.08891 10.525C7.99251 10.4305 7.87307 10.3833 7.73058 10.3833C7.58808 10.3833 7.46961 10.4315 7.37516 10.5279C7.28072 10.6243 7.2335 10.7437 7.2335 10.8862C7.2335 11.0287 7.28169 11.1472 7.37808 11.2416C7.47448 11.3361 7.59392 11.3833 7.73641 11.3833ZM7.2335 9.53329H8.2335V5.79996H7.2335V9.53329Z" fill="#C21808" />
  </svg>
)

const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.35035 13.5415C6.78345 13.5415 6.30556 13.3458 5.91667 12.9544C5.52778 12.5629 5.33333 12.0838 5.33333 11.5169C5.33333 10.95 5.52905 10.4721 5.92048 10.0832C6.31191 9.69428 6.79108 9.49984 7.35798 9.49984C7.92488 9.49984 8.40278 9.69555 8.79167 10.087C9.18056 10.4784 9.375 10.9576 9.375 11.5245C9.375 12.0914 9.17928 12.5693 8.78785 12.9582C8.39642 13.3471 7.91726 13.5415 7.35035 13.5415ZM3.75 18.3332C3.41667 18.3332 3.125 18.2082 2.875 17.9582C2.625 17.7082 2.5 17.4165 2.5 17.0832V4.1665C2.5 3.83317 2.625 3.5415 2.875 3.2915C3.125 3.0415 3.41667 2.9165 3.75 2.9165H5.10417V1.6665H6.45833V2.9165H13.5417V1.6665H14.8958V2.9165H16.25C16.5833 2.9165 16.875 3.0415 17.125 3.2915C17.375 3.5415 17.5 3.83317 17.5 4.1665V17.0832C17.5 17.4165 17.375 17.7082 17.125 17.9582C16.875 18.2082 16.5833 18.3332 16.25 18.3332H3.75ZM3.75 17.0832H16.25V8.12484H3.75V17.0832Z"
        fill="black"
      />
    </svg>
  )
}

const DatePickerInput = ({ control, setValue, watch, name, title, isClearable = false, validationSchema, onChange, isRequired, className, customOptions = {}, errors, ...rest }) => {
  const [isRTL] = useRTL()
    
  const handleClearDateFilter = () => {
    setValue(name, null)
    if (onChange) onChange()
  }

  return (
    <div className='w-100 custom-date-picker'>
      <InputGroup className={`dropdown ${className}`}>
        <InputGroupText className={classNames('dropdown-container', { 'border border-danger': errors?.[name]?.message })}>
          <div className='d-flex gap-4px align-items-center w-100'>
            <div className="calendar-icon">
              <CalendarIcon />
            </div>
            <Controller
              control={control}
              id='value'
              name={name}
              rules={validationSchema?.[name]}
              render={({ field }) => (
                <Flatpickr
                  className={classNames('date-input w-100', { 'text-danger': errors?.[name]?.message })} 
                  value={watch(name)}
                  options={{
                    dateFormat: 'Y-m-d',
                    locale: isRTL ? 'ar' : 'en',
                    static: true,
                    allowClear: true,
                    ...customOptions
                  }}
                  render={
                    ({ ...props }, ref) => {
                      return <InputField 
                        {...props} 
                        {...field} 
                        value={watch(name)} 
                        ref={ref} 
                        label={!watch(name) ? title : null} 
                        isRequired={!watch(name) ? isRequired : false}
                        errors={errors}
                        hideErrorText
                      />
                    }
                  }
                  {...rest}
                  onChange={(e) => {
                    field.onChange(e)
                    if (onChange) {
                      onChange(e)
                    }
                  }}

                />
              )}
            />
          </div>
          {(isClearable && watch(name)) && <X
            className='cursor-pointer text-dark-4'
            onClick={handleClearDateFilter}
            size={16}
          />}
        </InputGroupText>
      </InputGroup>
      {(errors?.[name]) && (
        <div className="d-flex align-items-sm-center error-state ms-12px gap-8px mt-2px">
          <span className="info-icon"> {ErrorIcon} </span>
          <span className="error-text text-danger txt-sub-rg">
            {errors?.[name]?.message}
          </span>
        </div>
      )}
    </div>
  )
}

export default DatePickerInput