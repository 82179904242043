import ComponentSpinner from '@src/@core/components/spinner/Loading-spinner'
import noMatchesFound from '@src/assets/images/svg/table/noMatchesFound.svg'
import { getReturnOrderDetail } from '@src/views/returns/store'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReturnOrderTable from './ReturnOrderTable'

const ReturnOrderDetail = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const loading = useSelector((state) => state.returns.loading)
  const returnOrderDetail = useSelector(
    (state) => state.returns.returnOrderDetail
  )
  
  const singleReturnGrnOrderDetail = useSelector((state) => state.returns.singleReturnGrnOrderDetail)
  useEffect(() => {
    if (!returnOrderDetail && singleReturnGrnOrderDetail) dispatch(getReturnOrderDetail(singleReturnGrnOrderDetail.oms_return_order_id))
  }, [])
  return (
    <div className="mx-16px mt-24px flex-center-center flex-column">
      {loading.getReturnOrderDetail ? (
        <ComponentSpinner className="py-3" />
      ) : returnOrderDetail ? (
        <ReturnOrderTable tableData={returnOrderDetail.order_items} />
      ) : (
        <div className="py-3 flex-center-center flex-column">
          <img
            src={noMatchesFound}
            alt="No Data Image"
            width={160}
            height={160}
          />
          <div className="flex-center-center flex-column gap-8px mt-12px">
            <div className="text-dark txt-h2-sb">{t('No Details found')}</div>
            <div className="text-dark-6 txt-anon-rg">
              {t(
                'Please scan AWB Number or enter Return Order ID to view details'
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReturnOrderDetail
