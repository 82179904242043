import Button from '@src/@core/components/button';
import CustomToast from '@src/@core/components/custom-toast/CustomToast';
import FloatingDropDown from '@src/@core/components/floating-dropdown';
//@ts-expect-error FIXME
import arrowRight from '@src/assets/images/svg/arrowRight.svg';
import OrderBox from '@src/assets/images/svg/icons-component/orderBox';
import ReassignHub from '@src/assets/images/svg/icons-component/reassignHub';
import WareHouse from '@src/assets/images/svg/icons-component/wareHouse';
import { useApi } from '@src/configs/react-query/useApi';
import { axiosInstance } from '@src/network/AxiosInstance';
import { getApiUrl } from '@src/utility/Utils';
import { getAllHubListAsyncData } from '@src/views/sales-channel/store';
import { OMS_REASON_CONFIG } from '@src/views/settings/settings.constants';
import React from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, Modal, ModalBody } from 'reactstrap';
import { ORDER_STATUS } from '../constant/orders.constants';
import { reassignHubApi } from '../sales.apis';
import { getReasonsForCancelOrders } from '../store/store';

interface iOrderDetail {
  id: string
  hub_id: string,
  status: string,
  assigned_hub: {
    id: string,
    name: string
  }
}

interface iReassignHubModalProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  orderDetail: iOrderDetail
  handleReassignHubSuccess: () => void
}

interface iReassignHubForm {
  reassignReasons: {
    label: string
    value: string
  } | null
  reassignHub: {
    label: string
    value: string
  } | null
}

interface iReassignHubBody {
  reason: string
  hub_id: string
}

interface iHubOptions {
  label: string
  value: string
}

interface iHubReassignResponse {
  is_success: boolean
  status_code: number
  data: {
    message: string
  }
  meta: null | Record<string, unknown>
}

const ReassignHubModal: React.FC<iReassignHubModalProps> = ({ isOpen, setIsOpen, orderDetail, handleReassignHubSuccess }) => {
  
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<iReassignHubForm>({
    defaultValues: {
      reassignReasons: null,
      reassignHub: null
    },
    mode: 'onChange',
  });

  //@ts-expect-error FIXME
  const { mutate: handleReassignHub, isPending: isReassingHubPending } = useApi({
    isMutation: true,
    //@ts-expect-error FIXME
    apiKey: reassignHubApi.apiKey,
    apiFn: (body: iReassignHubBody) => axiosInstance.put(getApiUrl(reassignHubApi.url, { orderId: orderDetail.id }), body),
    onSuccess: (response: iHubReassignResponse) => {
      CustomToast(response.data.message, { my_type: 'success' });
      handleReassignHubSuccess();
      reset();
    },
  });

  const handleCancel = () => {
    setIsOpen((prev) => !prev);
    reset();
  };

  const handleReassignOrder = (data: iReassignHubForm) => {
    const body = {
      reason: data.reassignReasons?.value,
      hub_id: data.reassignHub?.value,
    };
    handleReassignHub(body);
  };

  const hubId = orderDetail?.status === ORDER_STATUS.ON_HOLD.name ? orderDetail?.assigned_hub.id : orderDetail?.hub_id;

  return (
    <Modal isOpen={isOpen} centered contentClassName='rounded-16px'>
      <ModalBody className='p-24px d-flex flex-column gap-16px width-600'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center gap-16px'>
            <div className='p-12px bg-primary-lighter-global rounded-8px'>
              <ReassignHub />
            </div>
            <div className='txt-h1-sb text-dark'>{t('Reassign Order To Another Hub')}</div>
          </div>
          <div>
            <X className='cursor-pointer' color='var(--bs-secondary)' size={24} onClick={handleCancel} />
          </div>
        </div>
        <Form onSubmit={handleSubmit(handleReassignOrder)} className='d-flex flex-column gap-16px'>
          <div className='width-400 flex-center-center gap-12px m-auto'>
            <div className='flex-center-center shadow rounded-circle p-10px'>
              <OrderBox />
            </div>
            <img src={arrowRight} alt='arrowRight' height='40px' width='132px' className='object-fit-contain' />
            <div className='flex-center-center shadow rounded-circle p-10px'>
              <WareHouse />
            </div>
          </div>

          <div className='text-dark'>
            {t('Once reassigned, the order will be moved to the newly assigned hub and will be canceled at the current hub. Please note that this action is irreversible.')}
          </div>

          <FloatingDropDown
            name='reassignHub'
            control={control}
            errors={errors}
            loadOptions={getAllHubListAsyncData}
            isAsync
            isRequired
            title='Reassign to (Select Hub)'
            isClearable
            additional={{
              page: 1,
            }}
            //@ts-expect-error FIXME
            validationSchema={{ reassignHub: { required: 'This field is required' } }}
            disabled={isReassingHubPending}
            filterOption={(option: iHubOptions) => option.value !== hubId}
          />

          <FloatingDropDown
            name='reassignReasons'
            control={control}
            errors={errors}
            loadOptions={getReasonsForCancelOrders}
            isAsync
            isRequired
            title='Select Reason'
            isClearable
            additional={{
              page: 1,
              entity_type: OMS_REASON_CONFIG.hub_reassignment.key,
            }}
            //@ts-expect-error FIXME
            validationSchema={{ reassignReasons: { required: 'This field is required' } }}
            disabled={isReassingHubPending}
          />
          <div className='d-flex justify-content-end mt-24px'>
            <div className='d-flex gap-16px'>
              <Button className='flex-center-center' onClick={handleCancel} ofStyle='noBackground' disabled={isReassingHubPending} type='button'>
                {t('Cancel')}
              </Button>
              <Button className='flex-center-center' type='submit' loading={isReassingHubPending}>
                {t('Reassign Hub')}
              </Button>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ReassignHubModal;
