import { ExpandableCustomTable } from '@src/@core/components/globalTable/ExpandableCustomTable'
import RowExpanderWrapper from '@src/@core/components/row-expander-wrapper'
import './ExpandableChildTable.scss'
const ExpandableChildTable = (props) => {
  const { data, childTableColumns, ...rest } = props
  return (
    <div id="expandable-child-table-container">
      <RowExpanderWrapper data={data}>
        <ExpandableCustomTable
          columns={childTableColumns}
          data={data.seller_sku?.child_skus}
          striped={false}
          responsive
          showPagination={false}
          useReactPaginate={false}
          showColumnsTableHeader={false}
          {...rest}
        />
      </RowExpanderWrapper>
    </div>
  )
}
export default ExpandableChildTable
