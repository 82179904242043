import Button from '@src/@core/components/button'
import InputField from '@src/@core/components/input-field'
import { putOrderOnHold } from '@src/views/sales/store/store'
import { AlertTriangle } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Form, Modal, ModalBody } from 'reactstrap'

const OnHoldConfirmationPopUp = (props) => {
  const { isPopUpOpen, setIsOnHoldOrderCofirmationPopUpOpen, order_id } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      reason: ''
    },
    mode: 'onChange'
  })

  const handleClosePopUp = () => {
    setIsOnHoldOrderCofirmationPopUpOpen(false)
    reset()
  }
  const handleFormSubmit = (data) => {
    handleClosePopUp()
    const body = {
      reason:data.reason
    }
    dispatch(putOrderOnHold({ order_id, body }))
  }

  return (
    <Modal
      isOpen={isPopUpOpen}
      size="lg"
      centered
      className="width-600"
      contentClassName="rounded-24px"
    >
      <ModalBody className='p-24px'>
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="d-flex justify-content-center">
            <AlertTriangle color="var(--bs-warning)" size={100} fill="var(--bs-warning)" strokeColor="white" stroke="white" strokeWidth={1.5} />
          </div>
          <div className="mt-12px">
            <div className="txt-h1-sb text-dark">
              {t('Are you sure you want to put this order on hold?')}
            </div>
            <div className="mt-12px txt-body-rg">
              {t('Placing this order on hold will require approval to proceed with processing.')}
            </div>
            <div className="mt-12px">
              <InputField
                name='reason'
                label='Enter Reason'
                {...register('reason', {
                  required:'This field is required'
                })}
                errors={errors}
                isClearable
                isRequired
              />
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <div className='d-flex gap-16px mt-24px'>
              <Button type="button" className="bg-transparent" ofStyle="noBackground" onClick={handleClosePopUp} >
                {t('Close')}
              </Button>
              <Button type="submit">
                {t('Confirm')}
              </Button>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default OnHoldConfirmationPopUp