import Button from '@src/@core/components/button'
import { uploadMultipleFilesOnS3 } from '@src/redux/authentication'
import { updateEnforcedFailUploadIds } from '@src/views/inventory/store'
import { useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Modal, Spinner } from 'reactstrap'
import ImageUploaderBinDisabled from './serialized-mark-as-fail/imageUploader'

const BinDisabledRestrictShelfLifeModal = (props) => {
  const { t } = useTranslation()
  const { isOpen, toggle } = props
  const [images, setImages] = useState([])
  const [imageUploadLoading, setImageUploadLoading] = useState(false)
  const dispatch = useDispatch()

  const handleConfirm = async () => {
    let uploadIds
    if (images?.length) {
      setImageUploadLoading(true)
      const imagesToUpload = images.map(item => ({
        service: 'serialised_qc_fail_reason_images',
        usecase: 'reason_images',
        file: item
      }))

      uploadIds = await uploadMultipleFilesOnS3(imagesToUpload)
    }

    if (images?.length && !uploadIds) {
      setImageUploadLoading(false)
      return
    }

    dispatch(updateEnforcedFailUploadIds(uploadIds || []))
    setImageUploadLoading(false)
    toggle()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClosed={() => setImages([])}
        className='max-shelf-life-grn-warning-modal-wrapper w-100'
        centered
      >
        <div className='flex-center-start gap-16px'>
          <div className='p-10px rounded-8px bg-warning-light flex-center-center'>
            <AlertTriangle
              size={38}
              color='var(--bs-warning)'
              fill='var(--bs-warning)'
              strokeWidth={1.5}
              stroke="var(--bs-warning-light)"
            />
          </div>
          <div className='text-dark txt-h1-sb'>
            {t('The scanned item is below the acceptable shelf life threshold.')}
          </div>
        </div>
        <div className='text-dark-6 txt-body-rg pb-16px'>
          {t('Since the remaining days until expiry are less than the minimum required shelf life for receiving, the items of this batch will be marked as failed.')}
        </div>
        <div className='flex-center-end gap-16px w-100'>
          <div className='pt-px'>
            <ImageUploaderBinDisabled
              imageSize={40}
              maxImages={3}
              customizedUploadIcon={true}
              customRadius={true}
              data={images}
              setData={setImages}
              cloudIcon={true}
              uploadingInProgress={imageUploadLoading}
            />
          </div>
          <Button onClick={handleConfirm} disabled={imageUploadLoading}>
            {imageUploadLoading && <Spinner size='sm' />}
            {t('Confirm')}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default BinDisabledRestrictShelfLifeModal